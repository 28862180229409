"use client";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  InputText,
  StaticText,
  ErrorAlert,
} from "@/components/common";
import { Box, useToast } from "@/components/ChakraUiManager";
import styles from "./GeneralFeature.module.scss";
import React, { useContext, useEffect, useState } from "react";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";

import {
  GeneralFeatureProps,
  GeneralSetup,
  commonTitleProps,
  getValidationSchema,
} from "./GeneralFeatureValidation";
import {
  createFeeComponent,
  createInterestComponent,
  createLimitComponent,
  createNSFComponent,
  createCollateralComponent,
  createRepaymentComponent,
} from "../product-config-client-service";
import { useParams } from "next/navigation";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { COMPONENT_CLASS } from "@/utils";
import { PostSchema } from "../../model/types";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

interface Props extends commonTitleProps {
  componentClass: string;
  data: {
    formData: {
      componentName: string;
      version: number;
    } | null;
    generalComponentOptionsData: PostSchema;
  };
}

const GeneralFeature = (props: Props) => {
  const {
    data: { formData: featureData, generalComponentOptionsData },
    componentClass,
  } = props;
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    tooltipFlyoutDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    getWorflowStatusToBeUpdated,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const toastId = "general-feature-toast";

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.generalComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.generalComponentOptionsData]);

  // Submit form
  const onSubmit = async (values: GeneralFeatureProps, actions: any) => {
    // If component already exists, navigate to next page, since componentName is read only, no update required.
    if (!dirty && featureData) {
      navigateToNextPage?.();

      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: componentClass,
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      let response;
      const formData = {
        componentName: values.componentName,
        version: 1,
      };
      const commonToastOptions = {
        toast,
        toastId,
      };
      switch (componentClass) {
        case COMPONENT_CLASS.fee:
          response = await createFeeComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "Fee component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;
        case COMPONENT_CLASS.interest:
          response = await createInterestComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "Interest component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;

        case COMPONENT_CLASS.limit:
          response = await createLimitComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "Limit component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;

        case COMPONENT_CLASS.nsf:
          response = await createNSFComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "NSF component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;

        case COMPONENT_CLASS.collateral:
          response = await createCollateralComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "Collateral component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;

        case COMPONENT_CLASS.repay:
          response = await createRepaymentComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "Repayment component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;

        default:
          console.error("Invalid feature");
          break;
      }

      // Navigate only when there is a successful response
      if (response && productDetails?.name) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues: featureData ?? GeneralSetup,
    validationSchema,
    onSubmit,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  const handleInputChange = (value: string) => {
    setFieldValue("componentName", value);
  };
  // We are Checking Error Validation for InputField
  const displayInputError = errors.componentName && touched.componentName && (
    <ErrorAlert>
      <span>{errors.componentName}</span>
    </ErrorAlert>
  );

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (canCheckFormStatus) handleSubmit();
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CommonTitle
        depositName={props.name}
        featureName={values.componentName ?? ""}
        version={`VERSION ${featureData?.version || 1}`}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
            : "General Component Setup"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            : ""
        }
      />
      <Box className={styles["general-feature-container"]}>
        <CardContainer customClass="app-form-container">
          <AppFormLabel
            labelName="What is the component name?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("componentName")
                ?.tooltip_text_main
            }
            isRequired
          />
          <InputText
            disabled={Boolean(props.data.formData?.componentName)}
            name="componentName"
            value={values.componentName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder="[Component_name]"
          />
          {/* We are displaying validation Error here */}
          {displayInputError}
          <Box className={styles["top-spacing-feature-required"]}>
            <AppFormLabel
              labelName="Version"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("version")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <StaticText textValue={featureData?.version || "1"} />
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
};
export default GeneralFeature;
