"use client";
import React, { useContext, useEffect, useState } from "react";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  SplitButton,
} from "@/components/common";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  RestrictionConfigurationProps,
  getValidationSchema,
} from "./RestrictionConfigValidation";
import { useFormik } from "formik";
import { LimitComponentData, PostSchema } from "../../../model/types";
import { createLimitComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type RestrictionConfigurationsProps = {
  data: {
    formData: LimitComponentData | null;
    limitComponentOptionsData: PostSchema;
  };
};

export default function RestrictionConfiguration(
  props: RestrictionConfigurationsProps
) {
  const toast = useToast();
  const { limitComponentOptionsData } = props.data;

  // Form Submit Handler
  const onSubmit = async (
    values: RestrictionConfigurationProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["limit"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = props.data;
    if (formData) {
      if (productDetails) {
        const res = await createLimitComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            restrictCr: values.restrictCr,
            restrictCrFundExp: values.restrictCrFundExp,
            restrictDr: values.restrictDr,
          },
          toastOptions: {
            toast,
            toastId: "res-config",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : "Restriction Configuration"
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(limitComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [limitComponentOptionsData]);

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      restrictCr: props.data.formData?.restrictCr ?? undefined,
      restrictCrFundExp: props.data.formData?.restrictCrFundExp ?? undefined,
      restrictDr: props.data.formData?.restrictDr ?? undefined,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    ifxAcctProductType,
    productDetails,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMenuList,
    sideBarMainMenuIndex,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const restrictionFormDetailsHandler =
    (key: string) => (value: SplitBtnInputValType) => {
      setFieldValue(key, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    //do form validation here
    if (navigateToNextPage) navigateToNextPage();
  }, [canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CommonTitle
        depositName={getConstructedFeatureNameWithComponent(
          "componentLimit",
          getIfxProdType(productDetails),
          true
        )}
        featureName={props.data.formData?.componentName ?? "[COMPONENT NAME]"}
        version={`VERSION ${props.data.formData?.version ?? "1"}`}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
            : "Restriction Configuration"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            : ""
        }
      />
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want to restrict credits after funding expiration?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("restrictCrFundExp")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={restrictionFormDetailsHandler("restrictCrFundExp")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.restrictCrFundExp}
          />
          {errors.restrictCrFundExp && touched.restrictCrFundExp && (
            <ErrorAlert>
              <span>{errors.restrictCrFundExp}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want to restrict all credits?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("restrictCr")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={restrictionFormDetailsHandler("restrictCr")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.restrictCr}
          />
          {errors.restrictCr && touched.restrictCr && (
            <ErrorAlert>
              <span>{errors.restrictCr}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want to restrict all debits?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("restrictDr")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            onSelect={restrictionFormDetailsHandler("restrictDr")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.restrictDr}
          />
          {errors.restrictDr && touched.restrictDr && (
            <ErrorAlert>
              <span>{errors.restrictDr}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
}
