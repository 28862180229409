"use client";

import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  ProductTitle,
  RadioButtonGroup,
} from "@/components/common";
import { useFormik } from "formik";
import { AppRadioProps } from "@/components/common/form-fields/radio-button-group/RadioButtonGroup";
import {
  AccountGroupFoundationDetailsProps,
  accountGroupFoundationDetails,
  getValidationSchema,
} from "./AccountGroupFoundationalDetailsValidation";
import { Box, Heading, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import { AccountGroupData } from "../../model";
import {
  createAccountGroup,
  updateAccountGroup,
} from "../../ic-config-api-service";
import {
  ACCOUNT_GROUP_STAGE_ORGER,
  ACCOUNT_GROUP_STEPS,
  DUPLICATE_CONFIG,
  IC_ENTITY,
  SIDEBAR_STATUS,
  useEntityId,
  useQueryParams,
} from "@/utils";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  checkIfICWorflowNeedsToBeCreated,
  getIcCopyFlagInStorage,
  setIcCopyFlagInStorage,
} from "../../ic-utils-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type AccountGroupsConfigurationProps = {
  data: {
    accountClassOptions: AppRadioProps<number>[];
    formData: AccountGroupData | null;
    accountGroupOptionsData: PostSchema;
  };
};

const AccountGroupFoundationalDetails = (
  props: AccountGroupsConfigurationProps
) => {
  const accountGroupData = props.data?.formData ?? null;
  const updateQueryParams = useQueryParams();
  const toast = useToast();
  const toastId = "account-group-foundational-details-toast";
  const entityId = useEntityId();
  const isDuplicateConfig = getIcCopyFlagInStorage();

  const { accountGroupOptionsData } = props.data;

  const onSubmit = async (
    values: AccountGroupFoundationDetailsProps,
    actions: any
  ) => {
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.account_group
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.account_group,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!accountGroupData || isDuplicateConfig) {
      response = await createAccountGroup({
        formData: {
          ...props.data?.formData,
          acctGroup: parseInt(values.acctGroup),
          acctClass: values.acctClass!,
          desc: values.desc,
          isChkPosn: false,
          isRtPosnUpd: false,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Account group created.",
        },

        stageName: isDuplicateConfig
          ? DUPLICATE_CONFIG
          : getCurrentStageStatusName?.() ?? "",
      });
    } else {
      response = await updateAccountGroup({
        formData: {
          _vn: accountGroupData._vn,
          acctGroup: parseInt(values.acctGroup),
          // PATCH Fields
          acctClass: values.acctClass!,
          desc: values.desc,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
        },
        // Don't udpate workflow for first step of each entity as it will be handled while creating the Entity
        stageName: "",
      });
    }
    if (response) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.account_group
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.account_group,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      setIcCopyFlagInStorage(false);
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      setSideBarCurrentIndex?.(
        sideBarMainMenuIndex,
        ACCOUNT_GROUP_STAGE_ORGER.acc_grp_number_identification
      );
      navigateTo(
        `${
          ROUTE_PATH.IC_ACCOUNT_GROUP_NUMBERING_IDENTIFICATION
        }?${updateQueryParams("entityId", response.acctGroup.toString())}`
      );
    }
  };
  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  const handleOnChangeAccountClass = (key: string) => (value: number) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(accountGroupOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [accountGroupOptionsData]);

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      acctGroup: isDuplicateConfig
        ? accountGroupFoundationDetails.acctGroup
        : accountGroupData?.acctGroup.toString() ??
          accountGroupFoundationDetails.acctGroup,
      acctClass:
        accountGroupData?.acctClass ?? accountGroupFoundationDetails.acctClass,
      desc: accountGroupData?.desc ?? accountGroupFoundationDetails.desc,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    setSideBarCurrentIndex,
    sideBarMainMenuIndex,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const formTitle = (
    <CommonTitle
      depositName="Account group"
      featureName=""
      version=""
      mainTitle={
        tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
        "Account group foundational details"
      }
      subText={
        tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
        "Classify accounts with similar processing parameters"
      }
    />
  );

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      {formTitle}
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Assign a number to this account group"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctGroup")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.acctGroup}
            onChange={handleOnChange("acctGroup")}
            onBlur={handleBlur}
            label="accountGroupNumber"
            disabled={Boolean(
              props.data.formData?.acctGroup && !isDuplicateConfig
            )}
          />
          {errors.acctGroup && touched.acctGroup && (
            <ErrorAlert>
              <span>{errors.acctGroup}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account description?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
          />
          <InputText
            value={values.desc}
            onChange={handleOnChange("desc")}
            onBlur={handleBlur}
            label="desc"
          />
          {errors.desc && touched.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account class?"
            isRequired
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctClass")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.acctClass!}
            onChange={handleOnChangeAccountClass("acctClass")}
            radioPropList={props.data.accountClassOptions}
            stackDirection={"row"}
            spacing={"3rem"}
          />
          {errors.acctClass && touched.acctClass && (
            <ErrorAlert>
              <span>{errors.acctClass}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AccountGroupFoundationalDetails;
