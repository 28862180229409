"use client";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, useToast } from "@/components/ChakraUiManager";
import { OptionsSchema } from "../../model/types";
import { cloneDeep } from "lodash-es";
import { componentRuleUiSchema } from "@finxact/finxact-shared-ui";
import {
  addNewComponent,
  createRulesComponent,
} from "../product-config-client-service";
import {
  productConfigurationContext,
  ProductConfigurationContextType,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
  MAIN_FORM_ID,
  preventMainFormSubmitOnRJSFSubmit,
  RjsfData,
} from "@/utils";
import { useFormik } from "formik";
import {
  AddNewButton,
  AppModal,
  CommonTitle,
  ErrorAlert,
} from "@/components/common";
import RjsfForm from "@/components/common/json-schema-form/RJSForm";
import { modalBtnProps } from "@/components/data/global-config";
import { getIfxProdType } from "../../product-management-util-service";
import { PRODUCT_STATUS } from "@/components/data/form-data";

type RulesComponentProps = {
  data: {
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    formData: any;
  };
};

const RulesComponent = (props: RulesComponentProps) => {
  const rjsfFormId = "rjsf-form";
  const { data } = props;
  const modalRef = useRef<any>();
  const toast = useToast();
  const toastId = "rules-component";

  const [compName, setCompName] = useState("");
  const [showNoRulesError, setShowNoRulesError] = useState<boolean>(false);

  const schema = useMemo(() => {
    const { actions, name } = data.addNewSchema;
    return { name, ...actions.POST };
  }, [data.addNewSchema]);

  const commonToastOptions = {
    toast,
    toastId,
  };

  const onSubmit = () => {
    if (compName || data?.formData?.componentName) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["rules"],
          status: PRODUCT_STATUS.completed,
        });
      setShowNoRulesError(false);
    } else {
      setShowNoRulesError(true);
    }
  };

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      data.addNewOptionEndpoint,
      rjsfData.formData,
      toast,
    );
    if (!response) {
      throw new Error("Error");
    }
    setCompName(response.componentName);
    setShowNoRulesError(false);
    return response;
  };

  const formik = useFormik({
    onSubmit,
    initialValues: {},
  });

  const { handleSubmit, dirty } = formik;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  function setUiSchema() {
    const defaultObj: any = cloneDeep(componentRuleUiSchema);
    if (data?.formData?.componentName) {
      defaultObj["componentName"] = {
        "ui:disabled": true,
      };
    }
    return defaultObj;
  }

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const handlePrimaryBtnClick = () => {
    if (modalRef.current) {
      modalRef.current?.openModal();
    }
  };

  const handleCancel = () => {
    if (modalRef.current) {
      modalRef.current?.closeModal();
    }
  };

  return (
    <>
      <form
        onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
        id={MAIN_FORM_ID}
      >
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            COMPONENT_CLASS["rules"],
            getIfxProdType(productDetails),
            true,
          )}
          featureName={`${data.formData?.componentName ? data.formData?.componentName : compName ? compName : ""}`}
          version={`VERSION ${data.formData?.version ?? "1"}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Rule component configuration"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : "Set up a rule component"
          }
        />
        <Box className="app-form-container app-form-field-container" mt={13}>
          <AddNewButton
            label={
              data.formData?.componentName
                ? "Edit rule component"
                : "Set up rule component"
            }
            onClick={handlePrimaryBtnClick}
          />
          {showNoRulesError ? (
            <Box mt={3}>
              <ErrorAlert>
                <span>Add rule component</span>
              </ErrorAlert>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </form>
      <AppModal
        customClass="app-modal-selector"
        ref={modalRef}
        modalTitle="Add new rule"
        primaryBtnSelect={null}
        primaryBtnProp={{
          ...modalBtnProps.primaryBtnProp,
          form: rjsfFormId,
        }}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-reg-secondary",
        }}
        secondaryBtnSelect={handleCancel}
      >
        <RjsfForm
          id={rjsfFormId}
          schema={schema as any}
          uiSchema={setUiSchema()}
          initialFormData={
            data.formData
              ? {
                  ...data.formData,
                  version: data.formData.version + 1,
                }
              : { version: 1 }
          }
          onSubmit={async (data: any) => {
            await addNewOption?.(data)
              .then(async (res) => {
                if (!toast.isActive(toastId)) {
                  toast({
                    id: toastId,
                    description: `${data?.formData?.version > 1 ? "Rule component updated" : "Rule component created"} successfully.`,
                    status: "success",
                  });
                }
                await createRulesComponent({
                  productDetails: productDetails!,
                  formData: {
                    componentName: res.componentName,
                    version: res.version,
                  },
                  toastOptions: {
                    ...commonToastOptions,
                    successMessage: `${data.version > 1 ? "Rule component updated" : "Rule component created"} successfully.`,
                  },

                  stageName: getWorflowStatusToBeUpdated?.() ?? "",
                }).then((response) => {
                  if (response && productDetails?.name) {
                    getExistingProduct?.(productDetails.name, false);
                  }
                  modalRef.current?.closeModal();
                });

                return res;
              })
              .catch((err) => {
                let message = err;
                if (typeof err === "object") {
                  message = err.errors?.[0]?.errorDesc;
                }
                if (!toast.isActive(toastId) && message) {
                  toast({
                    id: toastId,
                    description: message,
                    status: "error",
                  });
                }
                return null;
              });
          }}
          onError={(err) => {}}
          noHtml5Validate={true}
        />
      </AppModal>
    </>
  );
};

export default RulesComponent;
