'use client';
import {
  Box,
  Text,
  VStack,
} from '@/components/ChakraUiManager';
import {
  CardContainer,
} from '@/components/common';
import './DocumentList.scss';
import DocumentListCard from '../doc-card/DocumentListCard';
interface DocumentListCardDetails {
  title: string;
  description: string;
  url: string;
}
type DocumentListProp = {
  title: string;
  subTitle: string;
  docList: DocumentListCardDetails[];
};
function DocumentList(props: DocumentListProp) {
  const { title, subTitle, docList } = props;
  return (
    <CardContainer customClass="doc-list-container">
      <Box className="doc-card-list-content">
        <Text className="doc-heading">
          {title}
        </Text>
        <Text className="doc-sub-heading">
          {subTitle}
        </Text>
      </Box>
      <VStack gap={0}>
      {docList.length > 0 && docList.map((item: any, itemIndex: number) => (
            <DocumentListCard
              title={item.title}
              description={item.description}
              url={item.url}
              key={`docCard${itemIndex}`}
              type={item.type}
            />
          ))}
      </VStack>
    </CardContainer>
  );
}
export default DocumentList;
