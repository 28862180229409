import React, { createContext, useContext, useState, useEffect } from "react";
import { MultiTieredSideMenuDataModel } from "../../components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { docCenterSidemenuIconHome } from "public/assets";
import { getAllAPILibraryNavigationItems } from "@/prismic-api/prismic-api-service";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";

export type NavigationDocumentItemData = {
  uid: string;
  type: string;
  title: string;
  path: string;
};

type NavigationDataContextType = {
  docCenterNavigationItems: any[];
  breadCrumbs: any[];
  loading: boolean;
  nextDocumentItems: NavigationDocumentItemData[];
};

const defaultNavigationDataContextVal: NavigationDataContextType = {
  docCenterNavigationItems: [],
  breadCrumbs: [],
  loading: true,
  nextDocumentItems: [],
};

// Create a context
const NavigationDataContext = createContext(defaultNavigationDataContextVal);

// Create a provider component
export const NavigationDataProvider = ({ children }: any) => {
  const prismicClient = useSelector(getAppPrismicClient);
  const [data, setData] = useState(defaultNavigationDataContextVal);
  const pathName = usePathname()?.replace(/\/$/, "");

  // Fetch data and set it
  useEffect(() => {
    const breadCrumbs: any = [];
    const nextDocumentItems: NavigationDocumentItemData[] = [];
    const buildNavigationItems = (
      topicData: any,
      title: string,
      prevBreadCrumb: string,
    ) => {
      const topicToBuild = topicData.find((td: any) => td.uid === title);
      let navItems: MultiTieredSideMenuDataModel[] = [];
      if (topicToBuild && topicToBuild.data.topics) {
        for (let topic of topicToBuild.data.topics) {
          if (topic.child.uid === "test-navigation") {
            continue;
          }

          const title = topic.title[0].text;
          const defaultUID = title.toLowerCase().replaceAll(" ", "-");

          const breadCrumb = topic.child.uid
            ? `${prevBreadCrumb},${topic.title[0].text}`
            : prevBreadCrumb;

          let navItem: MultiTieredSideMenuDataModel = {
            title: topic.title[0].text,
            uid: topic.document.uid ?? defaultUID,
            childrens: topic.child.uid
              ? buildNavigationItems(topicData, topic.child.uid, breadCrumb)
              : [],
            path: topic.document.uid
              ? encodeURI(
                  ROUTE_PATH.API_LIBRARY_DETAIL +
                    ROUTE_PATH.DOC_TYPE_QUERY +
                    topic.document.type +
                    ROUTE_PATH.API_ID_QUERY_WITH_AND +
                    topic.document.uid,
                )
              : "",
            type: topic.type?.toLowerCase(),
            isSelected: pathName === topic.document.uid,
          };
          if (navItem.type === "item" && navItem.path.length > 0) {
            nextDocumentItems.push({
              type: navItem.type,
              uid: navItem.uid,
              title: navItem.title,
              path: navItem.path,
            });
          }
          navItems.push(navItem);

          if (!breadCrumbs[topic.document.type]) {
            breadCrumbs[topic.document.type] = [];
          }
          breadCrumbs[topic.document.type][topic.document.uid] = breadCrumb;
        }
      }
      return navItems;
    };
    getAllAPILibraryNavigationItems(prismicClient).then((response) => {
      if (response) {
        const topObj: MultiTieredSideMenuDataModel = {
          type: "none",
          title: "",
          path: "",
          isSelected: false,
          childrens: [
            {
              type: "item",
              title: "Home",
              childrens: [],
              path: ROUTE_BASE_URL.API_LIBRARY,
              isSelected: pathName === ROUTE_BASE_URL.API_LIBRARY,
              uid: ROUTE_BASE_URL.API_LIBRARY,
            },
            ...buildNavigationItems(response, "main", "API References Home"),
          ],
          uid: "",
        };

        setData({
          docCenterNavigationItems: [topObj],
          breadCrumbs: breadCrumbs,
          loading: false,
          nextDocumentItems,
        });
      }
    });
  }, []);

  return (
    <NavigationDataContext.Provider value={data}>
      {children}
    </NavigationDataContext.Provider>
  );
};

// Custom hook to consume the data
export const useAPILibSideMenuData = () => useContext(NavigationDataContext);
