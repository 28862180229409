"use client";

import * as prismic from "@prismicio/client";
import {
  Link,
  Card,
  Stack,
  CardBody,
  Text,
  CardFooter,
  HStack,
} from "@/components/ChakraUiManager";
import "./TutorialPreview.scss";
import { ROUTE_PATH } from "@/route-config/route-path";

export default function TutorialPreview(props: any) {
  const { data, key } = props;
  
  return (
    <Card className="tutorial-preview">
      <Link
        key={key}
        href={`${ROUTE_PATH["TUTORIAL_DETAIL"]}${ROUTE_PATH["TUTORIAL_ID_QUERY"]}${data.uid}`}
      >
        <Stack>
          <CardBody>
            <Text className="title">{data.title}</Text>
            <Text className="preview-text">
              {prismic.asText(data.previewText)}
            </Text>
          </CardBody>
          <CardFooter>
            <HStack>
              {data.tags.map((tag: any, i: number) => (
                <Text className="tag" key={i}>
                  {tag}
                </Text>
              ))}
            </HStack>
          </CardFooter>
        </Stack>
      </Link>
    </Card>
  );
}
