"use client";
import React, { useContext, useEffect, useState } from "react";

import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  StaticText,
} from "@/components/common";

import { useToast } from "@/components/ChakraUiManager";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  RewardGeneralSetupProps,
  getValidationSchema,
} from "./RewardsGeneralFeatureValidation";
import { useFormik } from "formik";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import { createRewardComponent } from "../../product-config-client-service";

import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: {
      _vn?: number;
      featureName: string;
      version: number;
      goalAmt: string;
      goalTerm: string;
    } | null;
    generalComponentOptionsData: PostSchema;
  };
};

const RewardsGeneralFeatureSetup = (props: Props) => {
  const {
    data: { formData: featureData, generalComponentOptionsData },
  } = props;

  const toast = useToast();

  const toastId = "reward-general-feature-toast";
  // Form Submit Handler
  const onSubmit = async (values: RewardGeneralSetupProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["reward"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      let response;
      const commonToastOptions = {
        toast,
        toastId,
      };
      if (!featureData) {
        response = await createRewardComponent({
          productDetails,
          formData: {
            componentName: values.componentName,
            version: 1,
          },
          toastOptions: {
            ...commonToastOptions,
            successMessage: "Reward component created.",
          },

          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
      }
      if (response && productDetails?.name) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.generalComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.generalComponentOptionsData]);
  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      componentName: props.data.formData?.featureName ?? "",
      savingGoalReward: props.data.formData?.goalAmt ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const rewardsGeneralFeatureFormHandler =
    (key: string) => (value: string | number | boolean) => {
      setFieldValue(key, value);
    };

  const formTitle = (
    <CommonTitle
      depositName={getConstructedFeatureNameWithComponent(
        COMPONENT_CLASS["reward"],
        getIfxProdType(productDetails),
        true
      )}
      featureName={
        values.componentName === "" ? "[Component_name]" : values.componentName
      }
      version={`VERSION ${props.data.formData?.version ?? "1"}`}
      mainTitle={
        tooltipFlyoutDetails?.pageHeaderDetails?.heading
          ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
          : "General Component Setup"
      }
      subText={
        tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
          ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
          : "Let's start with the basics and then dive into the details"
      }
    />
  );

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      {formTitle}
      <CardContainer customClass="app-form-container">
        <div className="app-form-field-container">
          <AppFormLabel
            labelName="What is the component name?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("componentName")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.componentName}
            onChange={rewardsGeneralFeatureFormHandler("componentName")}
            placeholder="[Component_name]"
            onBlur={handleBlur}
            disabled={Boolean(props.data.formData?.featureName)}
          />
          {errors.componentName && touched.componentName && (
            <ErrorAlert>
              <span>{errors.componentName}</span>
            </ErrorAlert>
          )}
        </div>
        <div className="app-form-filedcontainer">
          <AppFormLabel
            labelName="Version"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("version")
                ?.tooltip_text_main
            }
          />
          <StaticText textValue={props.data.formData?.version || "1"} />
        </div>
      </CardContainer>
    </form>
  );
};
export default RewardsGeneralFeatureSetup;
