"use client";

import { SliceZone } from "@prismicio/react";
import { components } from "@/slices";
import {
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  GridItem,
  VStack,
} from "@chakra-ui/react";
import {
  Box,
  Heading,
  Stack,
  Link,
  Text
} from "@/components/ChakraUiManager";
import "./APIDetailPage.scss";
import { useAPILibSideMenuData } from "@/app/apis/providers";
import { Key, useEffect, useState, createRef } from "react";
import NextApiReference from "@/slices/NextApiReference";
import { DocumentTypes } from "@/utils";
import { CardContainer, FooterWidgetIframe } from "@/components/common";
import APILibNotLoggedInFooter from "../not-logged-in-footer/APILibNotLoggedInFooter";
import DocCenterPageHeader from "@/components/documentcenter/doc-detail-page/doc-center-page-header/DocCenterPageHeader";
import { fetchUrlForResources } from "@/prismic-api/common-service";

function APIDetailPage(props: any) {
  const uid = props.home.uid;
  const type = props.home.type;
  const [breadCrumb, setBreadcrumb] = useState<string[]>([]);
  const { loading, breadCrumbs, nextDocumentItems } = useAPILibSideMenuData();
  const ID_TOP_OF_THE_PAGE = "top-of-the-page";
  const docUrl = props.home.url;
  const dateString = props.home.firstPublicationDate;
  const [activeSection, setActiveSection] =
    useState<string>(ID_TOP_OF_THE_PAGE);
  const documentTitle = props.home?.title;
  const readTime = props.home.readTime || "10";
  const resourceSlice = props.home.slices.filter((slice: any) => {
    return slice.type === "resources_links_section";
  });
  const additionalResources = resourceSlice.length ? resourceSlice[0].data : [];
  const topRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (!loading) {
      const breadCrumb = breadCrumbs;
      if (breadCrumb[type][uid]) {
        setBreadcrumb(breadCrumb[type][uid].split(","));
      }
    }
  }, [loading, uid]);

  const getNextDocument = () => {
    const indexForCurrentItem = nextDocumentItems.findIndex(
      (item) => item.uid === uid
    );
    const nextItem = nextDocumentItems[indexForCurrentItem + 1];
    if (nextItem)
      return (
        <NextApiReference
          title={nextItem.title}
          type={nextItem.type}
          uid={nextItem.uid}
          path={nextItem.path}
        />
      );
    return null;
  };
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };
  const observer = new IntersectionObserver((entries) => {
    const id = entries.filter((entry) => entry.isIntersecting)[0]?.target.id;
    if (id) setActiveSection(id);
  }, observerOptions);

  const filteredPageIndexes = () =>
    props.home.pageIndex.filter((pI: any) => pI.content.length > 0);

  // Function to handle the activation of a link.
  const handleSetActive = (to: string) => {
    setActiveSection(to);
  };

  useEffect(() => {
    if (!loading) {
      const breadCrumb = breadCrumbs;
      if (breadCrumb[type][uid]) {
        setBreadcrumb(breadCrumb[type][uid].split(","));
      }
      topRef.current?.scrollTo(0, 0);
    }
  }, [loading, uid]);

  useEffect(() => {
    if (!loading && filteredPageIndexes()) {
      const pageIdsWithTopOfThePage = [
        ...filteredPageIndexes(),
        { content: ID_TOP_OF_THE_PAGE },
      ];
      setTimeout(() => {
        pageIdsWithTopOfThePage.map((pageIndex: any, index: number) => {
          const id = String(pageIndex.content.replaceAll(" ", "-"));
          let target = document.getElementById(id);
          if (target) {
            observer.observe(target);
          }
        });
        // As we do not not how long it takes to load all the prismic slices
        // After that we can add observer on that.
      }, 2000);
    }
  }, [loading, props.home.pageIndex]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      handleSetActive(ID_TOP_OF_THE_PAGE);
    }
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      handleSetActive(id);
    }
  };

  const getJumpLink = (key: Key, id: string, content: string) => {
    return (
      <Link
        key={key}
        onClick={(event: React.SyntheticEvent) => {
          event.preventDefault();
          scrollTo(id);
        }}
        className={`jump-link ${id === activeSection ? `active` : ``}`}
      >
        {content}
      </Link>
    );
  };

  return (
    <VStack
      id="doc-center-overview"
      align="stretch"
      overflowY="hidden"
      className="api-detail"
    >
      <Box className="overview-header" overflowY="hidden">
        {breadCrumb && (
          <Breadcrumb separator="|" className="breadcrumb">
            {breadCrumb.map((data, key) => (
              <BreadcrumbItem key={key}>
                <Text className="doc-upper-text">
                  {data.toUpperCase()}
                </Text>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
        <Heading className="headline" as="h1">
          {props.home?.title}
        </Heading>
      </Box>
      {type === DocumentTypes.ApiLibrary && (
        <Box overflowY="scroll" ref={topRef}>
          <SliceZone components={components} slices={props.home?.slices} />
          {getNextDocument()}
          <Box marginTop={32}>
            <FooterWidgetIframe />
          </Box>
          <APILibNotLoggedInFooter />
        </Box>
      )}
      {type !== DocumentTypes.ApiLibrary && (
        <Box
          className="document-page"
          id="doc-center-page"
          overflowY="scroll"
          onScroll={handleScroll}
          ref={topRef}
        >
          <Grid className="grid" id={ID_TOP_OF_THE_PAGE}>
            <GridItem className="doc-center-body">
              <CardContainer customClass="documents">
                <Stack gap={8}>
                  <DocCenterPageHeader
                    documentTitle={documentTitle}
                    slices={props.home.slices}
                    dateString={dateString}
                    docUrl={docUrl}
                    readTime={readTime}
                  />
                  <SliceZone
                    components={components}
                    slices={props.home?.slices}
                  />
                </Stack>
              </CardContainer>
              {getNextDocument()}
            </GridItem>
            <GridItem colSpan={1}>
              <Stack className="sticky">
                <Box className="on-this-page">
                  <div className="header">On this page</div>
                  <Stack as="nav">
                    {getJumpLink(-1, ID_TOP_OF_THE_PAGE, documentTitle)}
                    {filteredPageIndexes().map(
                      (pageIndex: any, index: number) => {
                        const id = String(
                          pageIndex.content.replaceAll(" ", "-")
                        );
                        return getJumpLink(index, id, pageIndex.content);
                      }
                    )}
                  </Stack>
                </Box>
                {additionalResources?.length > 0 && (
                  <Box className="additional-resources">
                    <div className="header">Additional resources</div>
                    <Stack>
                      {additionalResources.map(
                        (additionalResource: any, key: any) => {
                          return (
                            <Box className="resource-box" key={key}>
                              <Link
                                className="app-btn-link"
                                href={fetchUrlForResources(additionalResource)}
                              >
                                {additionalResource.link_title}
                              </Link>
                            </Box>
                          );
                        }
                      )}
                    </Stack>
                  </Box>
                )}
              </Stack>
            </GridItem>
          </Grid>
          <Box marginTop={32}>
            <FooterWidgetIframe />
          </Box>
          <APILibNotLoggedInFooter />
        </Box>
      )}
    </VStack>
  );
}

export default APIDetailPage;
