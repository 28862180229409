"use client";
import { useContext, useEffect, useState } from "react";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  FrequencyModule,
} from "@/components/common";
import { Box, Divider, Stack, useToast } from "@/components/ChakraUiManager";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import {
  PromotionRateAdjustmentRangesProps,
  promotionRateAdjustmentRangesDetails,
  getValidationSchema,
} from "./PromotionalRateAdjustValidation";
import { InterestComponentData, PostSchema } from "../../../model/types";
import { createInterestComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
  DEFAULT_CONSTS,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    formData: InterestComponentData | null;
    interestComponentOptionsData: PostSchema;
  };
};

const PromotionalRateAdjustRangeForm = (props: Props) => {
  const { depositName, featureName, version, mainTitle, subText, data } = props;
  const { formData, interestComponentOptionsData } = props.data;
  const toast = useToast();
  // Form Submit Handler
  const onSubmit = async (values: PromotionRateAdjustmentRangesProps) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            promoDtl: {
              ...formData.promoDtl,
              minRate: parseFloat(values.promoDtl.minRate),
              maxRate: parseFloat(values.promoDtl.maxRate),
              maxChngFirst: parseFloat(values.promoDtl.maxChngFirst),
              maxChngNext: parseFloat(values.promoDtl.maxChngNext),
              nextChngFreq: values.promoDtl.nextChngFreq,
            },
          },
          toastOptions: {
            toast,
            toastId: "promotional-rate-adjust-range",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : mainTitle
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      promoDtl: {
        minRate:
          formData?.promoDtl?.minRate?.toString() ??
          promotionRateAdjustmentRangesDetails.promoDtl.maxRate,
        maxRate:
          formData?.promoDtl?.maxRate?.toString() ??
          promotionRateAdjustmentRangesDetails.promoDtl.maxRate,
        maxChngFirst:
          formData?.promoDtl?.maxChngFirst?.toString() ??
          promotionRateAdjustmentRangesDetails.promoDtl.maxChngFirst,
        maxChngNext:
          formData?.promoDtl?.maxChngNext?.toString() ??
          promotionRateAdjustmentRangesDetails.promoDtl.maxChngNext,
        nextChngFreq: formData?.promoDtl?.nextChngFreq ?? "",
      },
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]); //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const promotionalRateAdjustFormHandler = (key: string) => (value: string) => {
    setFieldValue("promoDtl", { ...values.promoDtl, [key]: value });
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CommonTitle
        depositName={getConstructedFeatureNameWithComponent(
          "componentInt",
          getIfxProdType(productDetails),
          true
        )}
        featureName={formData?.componentName ?? featureName}
        version={`VERSION ${formData?.version ?? "1"}`}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
            : mainTitle
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            : subText
        }
      />
      <Box className="app-form-container">
        <CardContainer>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What are the minimum nominal rate floor and maximum promotional rate (or rate ceiling)?"
              tooltipDesc={
                (tooltipFlyoutDetails?.tooltipsMap?.get("minRate")
                  ?.tooltip_text_main || "") +
                (tooltipFlyoutDetails?.tooltipsMap?.get("maxRate")
                  ?.tooltip_text_main || "")
              }
            />
            <Box display="flex" gap={12}>
              <Divider
                orientation="vertical"
                className="dashed-left-border"
                height="72px"
                mt={4}
                mr={-14}
              />

              <Stack flexBasis="25%">
                <AppFormLabel labelName="Min" />
                <AppNumberInput
                  value={values.promoDtl.minRate}
                  onChange={promotionalRateAdjustFormHandler("minRate")}
                  valueSuffix="%"
                  placeholder={DEFAULT_CONSTS.percentage0}
                  onBlur={handleBlur}
                />
                {errors.promoDtl?.minRate && touched.promoDtl?.minRate && (
                  <ErrorAlert>
                    <span>{errors.promoDtl.minRate}</span>
                  </ErrorAlert>
                )}
              </Stack>
              <Stack flexBasis="25%">
                <AppFormLabel labelName="Max" />
                <AppNumberInput
                  value={values.promoDtl.maxRate}
                  onChange={promotionalRateAdjustFormHandler("maxRate")}
                  valueSuffix="%"
                  onBlur={handleBlur}
                  placeholder={DEFAULT_CONSTS.percentage0}
                />
                {errors.promoDtl?.maxRate && touched.promoDtl?.maxRate && (
                  <ErrorAlert>
                    <span>{errors.promoDtl?.maxRate}</span>
                  </ErrorAlert>
                )}
              </Stack>
            </Box>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum allowable rate adjustment during the initial adjustment period? "
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("maxChngFirst")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={values.promoDtl.maxChngFirst}
              onChange={promotionalRateAdjustFormHandler("maxChngFirst")}
              valueSuffix="%"
              placeholder="0%"
            />
            {errors.promoDtl?.maxChngFirst &&
              touched.promoDtl?.maxChngFirst && (
                <ErrorAlert>
                  <span>{errors.promoDtl?.maxChngFirst}</span>
                </ErrorAlert>
              )}
          </Box>

          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum allowable rate adjustment during the subsequent rate adjustment? "
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("maxChngNext")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={values.promoDtl.maxChngNext}
              onChange={promotionalRateAdjustFormHandler("maxChngNext")}
              valueSuffix="%"
              placeholder="0%"
            />
            {errors.promoDtl?.maxChngNext && touched.promoDtl?.maxChngNext && (
              <ErrorAlert>
                <span>{errors.promoDtl?.maxChngNext}</span>
              </ErrorAlert>
            )}
          </Box>

          <Box className="app-form-field-container">
            <FrequencyModule
              value={values.promoDtl.nextChngFreq}
              onChange={promotionalRateAdjustFormHandler("nextChngFreq")}
              frequencyQuestionTitle="How often should a rate adjustment be applied? "
              frequencyQuestionDescription={
                tooltipFlyoutDetails?.tooltipsMap?.get("nextChngFreq")
                  ?.tooltip_text_main
              }
              monthDropdownLabel="On what date should the frequency be implemented?"
              monthDropdownTooltip={
                tooltipFlyoutDetails?.tooltipsMap?.get("nextChngFreqMon")
                  ?.tooltip_text_main
              }
              notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the adjustment be applied?  "
              notBusinessDayTooltip={
                tooltipFlyoutDetails?.tooltipsMap?.get(
                  "nextChngFreqNotBusiness"
                )?.tooltip_text_main
              }
            />
            {errors.promoDtl?.nextChngFreq &&
              touched.promoDtl?.nextChngFreq && (
                <ErrorAlert>
                  <span>{errors.promoDtl?.nextChngFreq}</span>
                </ErrorAlert>
              )}
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
};

export default PromotionalRateAdjustRangeForm;
