"use client";

import { Box, Divider, Flex, useToast } from "@/components/ChakraUiManager";
import {
  CommonTitle,
  CardContainer,
  AppFormLabel,
  SplitButton,
  CoupleRadioWithDropdown,
  ErrorAlert,
  DurationInput,
  InputText,
  AppNumberInput,
  RadioButtonGroup,
  AppSingleSelectDropdown,
} from "@/components/common";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { INTEREST_DEPOSIT_LABELS } from "@/components/data/component-config-label";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  PromotionalRateOffsetTermsProps,
  promotionalRateOffsetTerms,
  getValidationSchema,
} from "./PromotionalRateOffsetTermsValidation";
import { useFormik } from "formik";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import {
  addNewComponent,
  createInterestComponent,
} from "../../product-config-client-service";
import {
  InterestComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";
import { RjsfData } from "@/utils/types";
import {
  COMPONENT_CLASS,
  MAIN_FORM_ID,
  preventMainFormSubmitOnRJSFSubmit,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    indexRateOffsetOptions: string[];
    formData: InterestComponentData | null;
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    interestComponentOptionsData: PostSchema;
  };
};

export default function PromotionalRateOffsetTerms(props: Props) {
  const { YES, NO, ADD_RATE_OFFSET, WHAT_IS_RATE_OFFSET_VAL } =
    INTEREST_DEPOSIT_LABELS;
  const { featureName, mainTitle, subText } = props;
  const {
    formData,
    addNewSchema,
    addNewOptionEndpoint,
    interestComponentOptionsData,
  } = props.data;
  const toast = useToast();

  const RadioSingleBtnProps = [
    {
      label: "Use existing rate offset matrix",
      value: 0,
    },
    {
      label: "Add new rate offset value",
      value: 1,
    },
  ];

  // Form Submit Handler
  const onSubmit = async (values: PromotionalRateOffsetTermsProps) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            promoDtl: {
              ...formData.promoDtl,
              offsetMatrix: values.addPromotionalRateOffset
                ? values.promoDtl.offsetMatrix
                : null,
              offSet: values.addPromotionalRateOffset
                ? values.promoDtl.offSet
                : null,
              round: values.promoDtl.round,
            },
          },
          toastOptions: {
            toast,
            toastId: "promotional-rate-offset-terms",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : mainTitle
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };
  // Schema to pass into RJSF (Add New flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("promoDtl", {
      ...values.promoDtl,
      offsetMatrix: response.matrixName,
    });
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      addPromotionalRateOffset: Boolean(
        formData?.promoDtl?.offsetMatrix || formData?.promoDtl?.offSet
      )
        ? true
        : undefined,
      promoDtl: {
        offsetMatrix:
          formData?.promoDtl?.offsetMatrix ??
          promotionalRateOffsetTerms.promoDtl.offsetMatrix,
        round:
          formData?.promoDtl?.round ??
          promotionalRateOffsetTerms.promoDtl.round,
        offSet:
          formData?.promoDtl?.offSet ??
          promotionalRateOffsetTerms.promoDtl.offSet,
      },
    },
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    errors,
    setFieldValue,
    touched,
    dirty,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const promotionalRateOffsetFormHandler =
    (key: string) => (value: string | number | boolean) => {
      if (key === "offsetMatrix") {
        values.promoDtl.offSet = "";
      } else if (key === "offSet") {
        values.promoDtl.offsetMatrix = "";
      }
      setFieldValue("promoDtl", { ...values.promoDtl, [key]: value });
    };

  /**
   * Component Handler
   */
  const promotionalRateOffsetHandler = (value: SplitBtnInputValType) => {
    setFieldValue("addPromotionalRateOffset", value).then((res) => {
      setFieldValue("promoDtl", {
        ...promotionalRateOffsetTerms.promoDtl,
      });
    });
  };

  const handleChange = (key: string) => (value: any) => {
    if (value === 0) {
      setFieldValue("promoDtl.offSet", "");
    } else if (value === 1) {
      setFieldValue("promoDtl.offsetMatrix", "");
    }
    setFieldValue(key, value);
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <Box className="app-form-container">
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            "componentInt",
            getIfxProdType(productDetails),
            true
          )}
          featureName={formData?.componentName ?? featureName}
          version={`VERSION ${formData?.version ?? "1"}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : mainTitle
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : subText
          }
        />
        <CardContainer>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={ADD_RATE_OFFSET}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get(
                  "addPromotionalRateOffset"
                )?.tooltip_text_main
              }
            />
            <SplitButton
              leftBtnName={YES}
              rightBtnName={NO}
              value={values.addPromotionalRateOffset}
              onSelect={promotionalRateOffsetHandler}
            />
            {errors.addPromotionalRateOffset && (
              <ErrorAlert>
                <span>{errors.addPromotionalRateOffset}</span>
              </ErrorAlert>
            )}
          </Box>
          {values.addPromotionalRateOffset ? (
            <>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName={WHAT_IS_RATE_OFFSET_VAL}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("offsetMatrix")
                      ?.tooltip_text_main
                  }
                  labelFor="promotional_rate_offset_input"
                />
                <CoupleRadioWithDropdown
                  primaryOptionValue={values.promoDtl.offsetMatrix}
                  tertiaryOptionValue={values.promoDtl.offSet}
                  primaryRadioName={"Use existing rate offset matrix"}
                  secondaryRadioName={"Add new rate offset value"}
                  tertiaryRadioName={"Enter a single promotional offset amount"}
                  tertiaryFieldType="number"
                  handlePrimaryOptionSelect={
                    promotionalRateOffsetFormHandler("offsetMatrix") as any
                  }
                  handleTertiaryOption={
                    promotionalRateOffsetFormHandler("offSet") as any
                  }
                  placeHolderName={"Select offset"}
                  dropdownList={props.data.indexRateOffsetOptions}
                  id="promotional_rate_offset"
                  schema={schema}
                  uiSchema={matrixTypeUiSchema}
                  onAddNewSubmit={addNewOption}
                />

                {errors.promoDtl?.offsetMatrix &&
                  touched.promoDtl?.offsetMatrix && (
                    <ErrorAlert>
                      <span>{errors.promoDtl.offsetMatrix}</span>
                    </ErrorAlert>
                  )}
                {errors.promoDtl?.offSet && touched.promoDtl?.offSet && (
                  <ErrorAlert>
                    <span>{errors.promoDtl.offSet}</span>
                  </ErrorAlert>
                )}
              </Box>
            </>
          ) : (
            ""
          )}
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the rounding expression?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("round")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.promoDtl.round}
              onChange={promotionalRateOffsetFormHandler("round")}
              label="round"
            />
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
}
