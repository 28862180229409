"use client";

import {
  AccordionComponent,
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
} from "@/components/common";
import AddNewButton from "@/components/common/add-new-button/AddNewButton";

import { Box, Heading, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  AccountingSegmentValues,
  AccountingSegmentsArrayProps,
  accountingSegments,
  accountingSegmentsArray,
  getValidationSchema,
} from "./AccountingSegmentsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  createAccountingSegments,
  updateAccountingSegments,
} from "../gl-config-api-service";
import { AccountingSegmentsData } from "../model";
import { ROUTE_PATH } from "@/route-config/route-path";
import { GL_CONFIG_ORDER, GL_ENTITY, GL_STEPS } from "@/utils";
import { useSearchParams } from "next/navigation";
import {
  GLWorkflowResponse,
  WorkflowResponseUnion,
  createWorkflowGeneric,
  getAllWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import { getGLCopyFlagInStorage } from "../general-ledger-util-service/general-ledger-util-service";
import "./AccountingSegments.scss";
import { PostSchema } from "@/components/product-management/model/types";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
type AccountingSegmentsProps = {
  data: {
    formData: AccountingSegmentsData | null;
    accountingSegmentsOptionsData: PostSchema;
  };
};

const AccountingSegments = (props: AccountingSegmentsProps) => {
  const toast = useToast();
  const toastId = "accounting-segments-toast";
  const accountingSegmentsData = props.data?.formData ?? null;
  const [workFlowData, setWorkFlowData] = useState<
    WorkflowResponseUnion[] | null
  >(null);

  const { accountingSegmentsOptionsData } = props.data;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    tooltipFlyoutDetails,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    navigateTo,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const params = useSearchParams();

  const moveToNextStep = () => {
    if (params?.get("initialFlow")) {
      navigateTo(
        `${ROUTE_PATH.GL_INTERSTITIAL_SCREEN}?entityId=${values.acctgSeg}&entityType=${GL_ENTITY.accounting_segments}&initialFlow=true`
      );
    } else {
      navigateTo(
        `${ROUTE_PATH.GL_COMPLETION_SCREEN}?entityId=${values.acctgSeg}&entityType=${GL_ENTITY.accounting_segments}`
      );
    }
  };

  // Form Submit Handler
  const onSubmit = async (values: AccountingSegmentValues) => {
    if (!dirty) {
      if (workFlowData?.some((data) => data.modelKey === values.acctgSeg)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: values.acctgSeg,
          stage: GL_ENTITY.accounting_segments,
          status: GL_STEPS.accounting_segments,
        });
      } else {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: values.acctgSeg,
          stage: GL_ENTITY.accounting_segments,
          status: GL_STEPS.accounting_segments,
        });
      }

      moveToNextStep?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!accountingSegmentsData || getGLCopyFlagInStorage()) {
      response = await createAccountingSegments({
        formData: {
          acctgSeg: values.acctgSeg,
          desc: values.desc,
          domainVals: values.domainVals.map((account: any) => ({
            acctgSegVal:
              account.acctgSegVal !== "" ? account.acctgSegVal : null,
            acctgSegDesc:
              account.acctgSegDesc !== "" ? account.acctgSegDesc : null,
          })),
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} created.`,
        },
        stageName: GL_STEPS.accounting_segments,
      });
    } else {
      response = await updateAccountingSegments({
        formData: {
          _vn: accountingSegmentsData._vn,
          acctgSeg: values.acctgSeg,
          // PATCH Fields
          desc: values.desc,
          domainVals: values.domainVals.map((account: any) => ({
            acctgSegVal:
              account.acctgSegVal !== "" ? account.acctgSegVal : null,
            acctgSegDesc:
              account.acctgSegDesc !== "" ? account.acctgSegDesc : null,
          })),
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
        },
        stageName: GL_STEPS.accounting_segments,
        updateWorkFlow: workFlowData?.some(
          (data) => data.modelKey === values.acctgSeg
        )
          ? true
          : false,
      });
    }
    if (response) {
      moveToNextStep();
    }
  };

  // Handler for all the form fields
  const handleOnChangeAccordion =
    (key: string, index: number) => (value: any) => {
      setFieldValue(`domainVals.${index}.${key}`, value);
    };

  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        accountingSegmentsOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [accountingSegmentsOptionsData]);

  let initialDomainVals: AccountingSegmentsArrayProps[] =
    props.data?.formData?.domainVals !== undefined &&
    props.data?.formData?.domainVals?.length > 0
      ? props.data?.formData?.domainVals.map((account: any, idx: number) => ({
          idx: idx,
          acctgSegVal:
            account.acctgSegVal ?? accountingSegmentsArray.acctgSegVal,
          acctgSegDesc:
            account.acctgSegDesc ?? accountingSegmentsArray.acctgSegDesc,
        }))
      : [];

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      acctgSeg: getGLCopyFlagInStorage()
        ? accountingSegments.acctgSeg
        : props.data.formData?.acctgSeg ?? accountingSegments.acctgSeg,
      desc: props.data.formData?.desc ?? accountingSegments.desc,
      domainVals: initialDomainVals,
    },
  });
  const { values, handleSubmit, dirty, touched, setFieldValue } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const errors: any = formik.errors;

  useEffect(() => {
    (async () => {
      // Get all GL workflows to determine what page to display
      const [response] = await Promise.all([
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.generalLedgerConfig),
      ]);
      // Sort before storing
      response.sort(
        (entityA: GLWorkflowResponse, entityB: GLWorkflowResponse) => {
          return (
            GL_CONFIG_ORDER[entityA.stage] - GL_CONFIG_ORDER[entityB.stage]
          );
        }
      );
      setWorkFlowData(response);
    })();
  }, []);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={formik.handleSubmit} id="finxact-form" noValidate>
      <CommonTitle
        depositName=""
        featureName=""
        version=""
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
            : "Accounting segments"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            : "Begin formatting your accounting segment, they play a vital role in organizing your financial data"
        }
        mainTitleStyleClass="main-title-style"
      />
      <CardContainer customClass="app-form-container app-form-field-container">
        <Box className="app-form-field-container">
          <Heading as="h3" className="card-title">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
              ?.section_title || "Foundational setup"}
          </Heading>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the accounting segment name?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctgSeg")
                ?.tooltip_text_main
            }
            isRequired
          />
          <InputText
            value={values.acctgSeg}
            onChange={handleOnChange("acctgSeg")}
            disabled={Boolean(
              props.data.formData?.acctgSeg && !getGLCopyFlagInStorage()
            )}
            label="Accounting segment name"
          />
          {touched.acctgSeg && errors.acctgSeg && (
            <ErrorAlert>
              <span>{errors.acctgSeg}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the accounting segment description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
          />
          <InputText
            value={values.desc}
            onChange={handleOnChange("desc")}
            label="Accounting segment description"
          />
          {touched.desc && errors.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
      <FormikProvider value={formik}>
        <FieldArray
          name="domainVals"
          render={({ insert, remove, push }) => (
            <Box className="app-form-container">
              {values.domainVals?.map((account, index) => (
                <CardContainer
                  customClass="app-form-field-container"
                  key={account.idx}
                >
                  <AccordionComponent
                    accordionTitle={`Accounting segment domain value ${
                      index + 1
                    }`}
                    deleteIcon
                    onDeleteHandler={() => remove(index)}
                    isExpand={0}
                  >
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the accounting segment value?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("acctgSegVal")
                            ?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={account.acctgSegVal}
                        onChange={handleOnChangeAccordion("acctgSegVal", index)}
                        label={`Accounting segment value ${index + 1}`}
                      />
                      {touched.domainVals &&
                        touched.domainVals[index]?.acctgSegVal &&
                        errors?.domainVals &&
                        errors?.domainVals?.[index]?.acctgSegVal && (
                          <ErrorAlert>
                            <span>{errors.domainVals[index].acctgSegVal}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the accounting segment description?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("acctgSegDesc")
                            ?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={account.acctgSegDesc}
                        onChange={handleOnChangeAccordion(
                          "acctgSegDesc",
                          index
                        )}
                        label={`Accounting segment description ${index + 1}`}
                      />
                      {touched.domainVals &&
                        touched.domainVals[index]?.acctgSegDesc &&
                        errors?.domainVals &&
                        errors?.domainVals?.[index]?.acctgSegDesc && (
                          <ErrorAlert>
                            <span>{errors.domainVals[index].acctgSegDesc}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                  </AccordionComponent>
                </CardContainer>
              ))}
              <Box>
                <AddNewButton
                  label={"Add new accounting segment domain value"}
                  onClick={() => {
                    push({
                      ...accountingSegmentsArray,
                      idx: Math.random(),
                    });
                  }}
                />
              </Box>
            </Box>
          )}
        ></FieldArray>
      </FormikProvider>
    </form>
  );
};

export default AccountingSegments;
