// ProjEnvTable.tsx

import React from "react";
import {
  OrgEnvDropdownMenuItemBase,
  OrgEnvDropdownMenuItemOrg,
} from "./Models";
import { Box, Spacer, Text, VStack } from "@chakra-ui/react";
import OrgEnvProjTableHeader from "./OrgEnvProjTableHeader";
import OrgEnvProjTableItem from "./OrgEnvProjTableItem";
import "./ProjEnvTable.scss";

interface ProjEnvTableProps {
  selectedOrganization: OrgEnvDropdownMenuItemOrg | null;
  selectedProjOrEnv: OrgEnvDropdownMenuItemBase | null;
  onEnvProjSelect: (index: number, isProject: boolean) => void;
  searchedQuery: string;
}

const ProjEnvTable: React.FC<ProjEnvTableProps> = ({
  selectedOrganization,
  selectedProjOrEnv,
  onEnvProjSelect,
  searchedQuery,
}) => {
  if (!selectedOrganization) {
    return null; // Don't render anything if no category is selected
  }

  const handleEnvProjSelection = (index: number, isProject: boolean) => {
    onEnvProjSelect(index, isProject);
  };

  const searchedProjects = selectedOrganization.projects.filter((proj) =>
    proj.name.includes(searchedQuery)
  );

  const searchedEnvironments = selectedOrganization.environments.filter((env) =>
    env.name.includes(searchedQuery)
  );

  return (
    <VStack className="proj-env-table-container">
      {selectedProjOrEnv && (
        <OrgEnvProjTableItem
          data={selectedProjOrEnv}
          key={selectedProjOrEnv.name}
          shouldShowFavourite={false}
          onSelect={() => {}}
          onFavourite={() => {}}
          isFirstSelectedItem={true}
          itemType="env"
        />
      )}
      <Box className="box-project">
        <OrgEnvProjTableHeader
          title="Projects"
          isExpanded={true}
          onExpand={() => {}}
          shouldShowBorder={false}
          shouldShowExpandIcon={false}
          alwaysExpanded={true}
        />
        {searchedProjects.length > 0 ? (
          searchedProjects.map((item, index) => (
            <OrgEnvProjTableItem
              data={item}
              onSelect={() => handleEnvProjSelection(index, true)}
              key={item.name}
              shouldShowFavourite={false}
              onFavourite={() => {}}
              isFirstSelectedItem={false}
              itemType="proj"
            />
          ))
        ) : (
          <>
            <Spacer width="24px" />
            <Text className="no-element-text">No projects to show</Text>
          </>
        )}
      </Box>
      <Box className="box-env">
        <OrgEnvProjTableHeader
          title="Environments"
          isExpanded={true}
          onExpand={() => {}}
          shouldShowBorder={true}
          shouldShowExpandIcon={false}
          alwaysExpanded={true}
        />
        {searchedEnvironments.length > 0 ? (
          searchedEnvironments.map((item, index) => (
            <OrgEnvProjTableItem
              data={item}
              key={item.name}
              shouldShowFavourite={false}
              onSelect={() => handleEnvProjSelection(index, false)}
              onFavourite={() => {}}
              isFirstSelectedItem={false}
              itemType="env"
            />
          ))
        ) : (
          <>
            <Spacer width="24px" />
            <Text className="no-element-text">No environements to show</Text>
          </>
        )}
      </Box>
    </VStack>
  );
};

export default ProjEnvTable;
