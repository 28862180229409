"use client";

import { Box } from "@chakra-ui/react";
import SectionCard from "./section-card/SectionCard";
import BadgeHeader from "../catalog/badge-path/badge-header/BadgeHeader";

const BadgesCertifications = ({ pageData }: any) => {
  return (
    <>
      <BadgeHeader
        pageType="ACADEMY"
        title={pageData.headline || "Badges & Certifications"}
        subtitle={
          pageData.subhead ||
          "View all your earned achievements and the full catalog of ways you can upskill"
        }
        showBadgeProgress={false}
        showBackButton={false}
      />
      <Box className="academy-sub-content">
        <SectionCard type="certifications" />
        <SectionCard type="badges" />
      </Box>
    </>
  );
};

export default BadgesCertifications;
