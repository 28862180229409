// OrganizationSearchBar.tsx

import React from "react";
import {
  Box,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import "./OrgEnvProjSearchBar.scss";
import { IconEnvOrgDropDownSearch } from "public/assets";

interface OrgEnvProjSearchBarProps {
  onSearch: (query: string) => void;
}

const OrgEnvProjSearchBar: React.FC<OrgEnvProjSearchBarProps> = ({
  onSearch,
}) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    onSearch(query);
  };

  return (
    <Box className="env-org-search-input-container">
      <InputGroup className="org-search-input-group">
        <InputLeftElement className="left-element-search-icon">
          <Image
            src={IconEnvOrgDropDownSearch.src}
            alt="IconEnvOrgDropDownSearch"
          />
        </InputLeftElement>
        <Input
          aria-label="input-search"
          className="input-search"
          type="text"
          placeholder="Search"
          onChange={handleSearch}
        />
      </InputGroup>
    </Box>
  );
};

export default OrgEnvProjSearchBar;
