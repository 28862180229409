"use client";
import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import {
  AccordionComponent,
  AppFormLabel,
  AppSingleSelectDropdown,
  CardContainer,
  DurationInput,
  ErrorAlert,
  FrequencyModule,
  InputText,
  SplitButton,
  StaticText,
} from "@/components/common";
import {
  productConfigurationContext,
  ProductConfigurationContextType,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { PRODUCT_STATUS } from "@/components/data/form-data";
import { ProductType } from "@/components/product-management/model/product-models";
import { ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS, MAIN_FORM_ID } from "@/utils";
import { Box, CardBody } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  ProductEstablishFormDetails,
  ProductEstablishFormProps,
  getValidationSchema,
} from "./EligibilityAndComponentsConfigurationValidation";
import "./EligibilityAndComponentsConfiguration.scss";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useToast } from "@/components/ChakraUiManager";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    creditBureauOptions: { label: string; value: number }[];
    formData: ProductType | null;
    prodBkOptionsData: PostSchema;
  };
};

const EligibilityAndComponentsConfiguration = (props: Props) => {
  const toast = useToast();
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const { formData, creditBureauOptions } = props.data;

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(props.data.prodBkOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [props.data.prodBkOptionsData]);

  const onSubmit = async (values: ProductEstablishFormProps) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS[
              "eligibilityAndComponentsConfiguration"
            ],
          status: PRODUCT_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const payload = {
        // NOTE: _vn, componentName, version is required to do a PATCH update
        _vn: formData?._vn,
        // PATCH fields

        // Eligibility
        isCrBureau: values.isCrBureau,
        reviewTerm: values.reviewTerm,
        crBureauCycleFreq: values.crBureauCycleFreq,
        svcrId: values.svcrId,
        crBureauFirstRunOpt: values.crBureauFirstRunOpt ?? undefined,
      } as ProductType;
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify(payload),
      });
      if (res && productDetails?.name) {
        if (!toast.isActive("eligibility-components-configuration")) {
          toast({
            id: "eligibility-components-configuration",
            description: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
              "Eligibility & features configuration"
            } updated`,
            status: "success",
          });
        }
        getExistingProduct?.(productDetails.name, true);
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails.name,
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS[
              "eligibilityAndComponentsConfiguration"
            ],
          status: PRODUCT_STATUS.completed,
        });
      }
    }
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      // Eligibility
      isCrBureau: formData?.isCrBureau ?? undefined,
      reviewTerm: formData?.reviewTerm ?? "",
      crBureauCycleFreq: formData?.crBureauCycleFreq ?? "",
      svcrId: formData?.svcrId ?? "",
      crBureauFirstRunOpt:
        formData?.crBureauFirstRunOpt ??
        ProductEstablishFormDetails.crBureauFirstRunOpt,
    },
  });
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const handleFormData =
    (key: keyof ProductEstablishFormProps) => (value: any) => {
      if (key === "isCrBureau") {
        setFieldValue(key, value).then((res) => {
          setFieldValue(
            "crBureauCycleFreq",
            ProductEstablishFormDetails.crBureauCycleFreq
          ).then((res) => {
            setFieldValue("svcrId", ProductEstablishFormDetails.svcrId);
          });
        });
      } else {
        setFieldValue(key, value);
      }
    };

  // we are using useffect for left side navigation
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus]);
  return (
    <form onSubmit={handleSubmit} id={MAIN_FORM_ID}>
      <StaticText
        heading
        headingName={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
          "Eligibility & features configuration"
        }
        pt="24px"
        pb="24px"
      />
      <CardContainer
        customClass="all-four-form-section-component-seperator"
        styleProps={{ width: "65%" }}
      >
        <CardBody>
          <AccordionComponent
            accordionTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                ?.section_title ||
              "Ensure that the product adheres to necessary protocols around credit bureau reporting"
            }
            isExpand={0}
          >
            <Box marginTop="-10">
              <StaticText
                textValue={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                    ?.section_subhead ||
                  "Set up key flags to determine product eligibility criteria and functionalities. Ensuring that the product adheres to necessary protocols and incorporates additional features to enhance user experience."
                }
              />
            </Box>
            <Box mt={12}>
              <AppFormLabel
                labelName="Should this loan be reported to the Credit Bureau?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("isCrBureau")?.tooltip_text_main
                }
              />
              <SplitButton
                leftBtnName="Yes"
                rightBtnName="No"
                value={values.isCrBureau}
                onSelect={handleFormData("isCrBureau")}
              />
              {errors.isCrBureau && touched.isCrBureau && (
                <ErrorAlert>
                  <span>{errors.isCrBureau as string}</span>
                </ErrorAlert>
              )}
            </Box>
            {values.isCrBureau && (
              <>
                <Box className="all-four-form-section-component-seperator">
                  <AppFormLabel
                    labelName="What is the servicer ID?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("svcrId")?.tooltip_text_main
                    }
                  />
                  <InputText
                    value={values.svcrId}
                    onChange={handleFormData("svcrId")}
                  />
                  {errors.svcrId && touched.svcrId && (
                    <ErrorAlert>
                      <span>{errors.svcrId}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box className="all-four-form-section-component-seperator">
                  <FrequencyModule
                    value={values.crBureauCycleFreq}
                    onChange={handleFormData("crBureauCycleFreq")}
                    frequencyQuestionTitle="What is credit bureau cycle frequency?"
                    monthDropdownLabel="On what date should the credit bureau cycle frequency be implemented?"
                    frequencyQuestionDescription={
                      tooltipFlyoutDetails?.tooltipsMap?.get("crBureauCycleFreq")?.tooltip_text_main
                    }
                    monthDropdownTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get("crBureauCycleFreqMon")?.tooltip_text_main
                    }
                    notBusinessDayTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get("crBureauCycleFreqNotBusiness")?.tooltip_text_main
                    }
                    notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the loan be reported?"
                  />
                  {errors.crBureauCycleFreq && touched.crBureauCycleFreq ? (
                    <ErrorAlert>
                      <span>{errors.crBureauCycleFreq}</span>
                    </ErrorAlert>
                  ) : null}
                </Box>
                <Box className="all-four-form-section-component-seperator">
                  <AppFormLabel
                    labelName="What is the credit bureau first run option?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("crBureauFirstRunOpt")?.tooltip_text_main
                    }
                    labelFor="first_run-input"
                  />
                  <AppSingleSelectDropdown
                    value={values.crBureauFirstRunOpt}
                    onChange={handleFormData("crBureauFirstRunOpt")}
                    id="first_run"
                    keyName="label"
                    dropdownList={creditBureauOptions}
                  />
                  {errors.crBureauFirstRunOpt &&
                    touched.crBureauFirstRunOpt && (
                      <ErrorAlert>
                        <span>{errors.crBureauFirstRunOpt}</span>
                      </ErrorAlert>
                    )}
                </Box>
              </>
            )}
            <Box className="all-four-form-section-component-seperator">
              <DurationInput
                labelName={"Set the review term duration"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("reviewTerm")?.tooltip_text_main
                }
                value={values.reviewTerm}
                onChange={handleFormData("reviewTerm")}
                modalLinkName={"Enter code manually"}
                modalTitle={"Enter review term duration"}
                modalLabelName={"Set the review term duration"}
                modalLabelTooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("reviewTerm")?.tooltip_text_main
                }
              />
              {errors.reviewTerm && touched.reviewTerm && (
                <ErrorAlert>
                  <span>{errors.reviewTerm as string}</span>
                </ErrorAlert>
              )}
            </Box>
          </AccordionComponent>
        </CardBody>
      </CardContainer>
    </form>
  );
};

export default EligibilityAndComponentsConfiguration;
