import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For general-feature-setup
interface GeneralFeatureProps {
  componentName: string;
}
// Defining Intitial Value For transaction-general-feature-setup
const generalFeatureDetails: GeneralFeatureProps = {
  componentName: "",
};
// YUP LIBRARY :Defining Schema For validation of transaction-general-feature-setup

const generalFeatureSchema = async (optionsSchema: PostSchema) => {
  const fields = ["componentName"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { GeneralFeatureProps };
// Exporting Validation Schema and Intial value
export { generalFeatureDetails, generalFeatureSchema as getValidationSchema };
