"use client";

import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import { getCoreModelEndpoint } from "@/api-config/api-service";
import { FinOrgCorrespondingDestinationNetwork } from "@/components";
import {
  FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_DESTINATION,
  FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_ORIGIN,
} from "@/components/data/component-config-label";
import { useEffect, useState } from "react";
import { getFinancialOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { OptionsSchema } from "@/components/product-management/model/types";
import { useEntityId } from "@/utils";

type CorrespondingNetworkOriginationProps = {
  isDestinationNetwork: boolean;
};

const getData = async (finOrgId: string) => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const optionsData = (await api.options(
    API_ROUTE_CONFIGURATION.partyOrgFininst
  )) as OptionsSchema;
  const [country, fincialOrg, regioCodeData, addrIdList, finOrgType] =
    await Promise.all([
      api.get(API_ROUTE_CONFIGURATION.country),
      api.options(API_ROUTE_CONFIGURATION.financialOrg),
      api.get(API_ROUTE_CONFIGURATION.regionCode),
      api.get(API_ROUTE_CONFIGURATION.addrIdList),
      getFinancialOrg(finOrgId),
    ]).then((data) => data);

  const {
    MemoPartyFinInst: {
      properties: { otherIdType },
    },
    PartyAddrs: {
      properties: { addrType },
    },
  } = fincialOrg.actions.POST.definitions;

  const returnData = {
    addrTypeOptions: addrType.enumNames.map(
      (val: any, idx: string | number) => ({
        label: val,
        value: val,
      })
    ),
    otherIdTypeOptions:
      otherIdType?.enum?.map((val: any, idx: string | number) => ({
        label: otherIdType.enumNames[idx],
        value: val,
      })) || [],
    countryOptions: country?.data?.map((code: any) => ({
      value: code.cntry,
      label: code.cntryName,
    })),
    regioCodeOptions: regioCodeData?.data?.map((item: any) => ({
      label: item.subdivname,
      value: item.subdivcode,
    })),
    addIdOptions: addrIdList?.length
      ? addrIdList.map((item: any) => ({
          label: item._id,
          value: item._id,
        }))
      : [],
    formData: finOrgType.data[0] ?? null,
    partyOrgFininstOptionsData: optionsData.actions.POST,
  };
  return returnData;
};

const CorrespondingNetworkOrigination = (
  props: CorrespondingNetworkOriginationProps
) => {
  const { isDestinationNetwork = true } = props;
  const [data, setData] = useState<Awaited<ReturnType<typeof getData>> | null>(
    null
  );
  const finOrgId = useEntityId();
  useEffect(() => {
    (async () => {
      if (finOrgId) {
        const data = await getData(finOrgId);
        setData(data);
      }
    })();
  }, [finOrgId]);

  const formTitleConfig = isDestinationNetwork
    ? {
        ...FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_DESTINATION,
      }
    : { ...FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_ORIGIN };

  return data ? (
    <div>
      <FinOrgCorrespondingDestinationNetwork
        {...formTitleConfig}
        data={data}
        isDestinationNetwork={isDestinationNetwork}
      />
    </div>
  ) : null;
};

export default CorrespondingNetworkOrigination;
