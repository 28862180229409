"use client";

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { MultiTieredSideMenuDataModel } from "../common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { Global_SIDEBAR_MENU } from "@/data/sidebar-menu";
import { useSelector } from "react-redux";
import { getAppConfig, getEnvId, getEnvStatus, getEnvType, getSelectedOrg } from "@/store";
import { usePathname } from "next/navigation";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { Menu } from "@finxact/finxact-shared-ui";
import { useIsPermitted } from "@/utils";
import Link from "next/link";
import { getEnv } from "@/store/slices/environment";
import {getProj} from "@/store/slices/project";
import '../../styles/color-variables.scss';

interface GlobalSideMenuProps {
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const GlobalSideMenu = ({
  isSideMenuOpen,
  setIsSideMenuOpen,
}: GlobalSideMenuProps) => {
  const pathName: any = usePathname()?.replace(/\/$/, "") ?? null;
  const [selectedElements, setSelectedElements] = useState<
    MultiTieredSideMenuDataModel[]
  >([]);
  const envId = useSelector(getEnvId);
  const [selectedUID, setSelectedUID] = useState(pathName);
  const envStatus = useSelector(getEnvStatus);

  const envData = useMemo(() => {
    const currentEnv = envStatus.find((env) => env.id === envId);
    if (currentEnv) {
      const majorVersion = currentEnv.version?.majorVersion
      const minorVersion = currentEnv.version?.minorVersion
      const patchVersion = currentEnv.version?.patchVersion
      return {
        name: currentEnv.name || "",
        version: majorVersion && minorVersion && patchVersion ? `${majorVersion}.${minorVersion}.${patchVersion}` : "UNKNOWN",
        serviceEndpoint: currentEnv.serviceEndpoint,
      };
    }
  }, [envStatus, envId]);

  const handleSelection = (
    locData: MultiTieredSideMenuDataModel[],
    selectedUID: string | null
  ) => {
    locData.map((item: MultiTieredSideMenuDataModel) => {
      if (item.uid === selectedUID) {
        item.isSelected = true;
      } else {
        handleSelection(item.childrens, selectedUID);
        item.isSelected = item.childrens.filter((i) => i.isSelected).length > 0;
      }
    });
    return locData;
  };
  useEffect(() => {
    if (pathName === API_ROUTE_CONFIGURATION.dashboard) {
      setSelectedUID(API_ROUTE_CONFIGURATION.dashboard);
    }
  }, [pathName]);
  useEffect(() => {
    const items = handleSelection(
      selectedElements.length === 0 ? Global_SIDEBAR_MENU : selectedElements,
      selectedUID
    );
    setSelectedElements([...items]);
  }, [selectedUID]);

  const isPermitted = useIsPermitted();
  const appConfig = useSelector(getAppConfig);
  const organization = useSelector(getSelectedOrg);
  const environment = useSelector(getEnv);
  const project = useSelector(getProj);
  const envType = useSelector(getEnvType);

  const env = (envType === "env" && envId) ?
    { id: envId,
      name: envData?.name ?? "",
      endpoint: environment.endpoint,
      internal: environment.internal
    }
    : undefined;
  
  const proj = (envType === "proj" && envId) ? 
  { 
    id: envId, 
    name: project?.name ?? "",
    status: project?.status
  }
  : undefined;

  return (
    <Menu
      consoleConfig={{
        prodEnv: appConfig?.prodEnv ?? false,
        releaseCalendarConfigured: appConfig?.releaseCalendarConfigured ?? false,
      }}
      coreVersion={envData?.version}
      environment={env}
      organization={{
        id: organization.id,
        name: organization.name,
        implRepoURL: organization.implRepoURL,
        serviceTicketURL: organization.serviceTicketURL,
        implementationUrl: organization.implementationUrl,
      }}
      isPermitted={isPermitted}
      location={pathName}
      parent="fast"
      project={proj}
      serviceEndpoint={envData?.serviceEndpoint}
      nextRouterLinkComponent={Link}
       />
  )
};

export default GlobalSideMenu;
