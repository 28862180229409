//app independent components
import CloverConfig from "./clover-config";
import HeroManager from "./hero-manager/HeroManager";
import SidebarManager from "./sidebar-manager/SidebarManager";
import TopMenuManager from "./top-menu-manager/TopMenuManager";
import StatusSideBarMenu from "./status-sidebar-menu/StatusSideBarMenu";
import ProductSummary from "./product-management/product-summary/product-config-tab/ProductSummary";
import ProductSummaryFeatureDetails from "./product-management/product-summary/product-summary-feature-details/ProductSummaryFeatureDetails";
import MyProducts from "./product-management/product-launchpad/my-products/MyProducts";
import ProductCatalog from "./product-management/product-launchpad/product-catalog/ProductCatalog";
import ProductLaunchPad from "./product-management/product-launchpad/ProductLaunchPad";
import ProductCatalogCard from "./product-management/product-launchpad/product-catalog-card/ProductCatalogCard";
import AdditionalFeatures from "./product-management/additional-features/AdditionalFeatures";
import InterstialScreens from "./interstial-screens/InterstialScreens";
import ProductLaunchPadQuickStartModal from "./product-management/product-launchpad/product-launch-pad-quick-start-modal/ProductLaunchPadQuickStartModal";
import ConfigInterstitialScreen from "./product-management/product-configuration/config-interstitial-screen/ConfigInterstitialScreen";

import Dashboard from "./dashboard/Dashboard";
import AlgoliaSearch from "./algolia-search/AlgoliaSearch";
import ProductReview from "./product-management/product-summary/ProductReview";
import GlobalSideMenu from "./global-sidebar/GlobalSideMenu";

import CompletionScreen from "./completion-screen/CompletionScreen";
export {
  CloverConfig,
  HeroManager,
  SidebarManager,
  TopMenuManager,
  StatusSideBarMenu,
  ProductSummary,
  ProductSummaryFeatureDetails,
  AdditionalFeatures,
  InterstialScreens,
  ProductLaunchPad,
  MyProducts,
  ProductCatalog,
  ProductCatalogCard,
  ProductLaunchPadQuickStartModal,
  ConfigInterstitialScreen,
  Dashboard,
  AlgoliaSearch,
  ProductReview,
  GlobalSideMenu,
  CompletionScreen,
};

//Product Configuration Imports

//default general feature
import GeneralFeature from "./product-management/product-configuration/general-feature/GeneralFeature";

//fee deposit
import FeeConfiguration from "./product-management/product-configuration/fee-deposit/fee-configuration/FeeConfiguration";
import ServiceChargeDetails from "./product-management/product-configuration/fee-deposit/service-charge-details/ServiceChargeDetails";
import EarningAnalysis from "./product-management/product-configuration/fee-deposit/earning-analysis/EarningAnalysis";

//limit deposit
import RestrictionConfiguration from "./product-management/product-configuration/limit-deposit/restriction-configuration/RestrictionConfiguration";
import LimitConfig from "./product-management/product-configuration/limit-deposit/accumulated-transaction-limit-configuration/LimitConfig";
import SingleTransactionLimitConfig from "./product-management/product-configuration/limit-deposit/single-transaction-limit-config/SingleTransactionLimitConfig";
import AccountBalanceConfiguration from "./product-management/product-configuration/limit-deposit/account-balance-configuration/AccountBalanceConfiguration";

// Interest Deposit
import FoundationalInterestDeposit from "./product-management/product-configuration/interest-deposit/foundational-interest-detail/FoundationalInterest";
import IndexedRateFoundationalDetails from "./product-management/product-configuration/interest-deposit/indexed-rate-foundational-details/IndexedRateFoundationalDetails";
import IndexRateOffsetTerms from "./product-management/product-configuration/interest-deposit/indexed-rate-offset-terms/IndexRateOffsetTerms";
import PromotionalRateOffsetTerms from "./product-management/product-configuration/interest-deposit/promotional-rate-offset-terms/PromotionalRateOffsetTerms";
import InterestAccrual from "./product-management/product-configuration/interest-deposit/interest-accrual/InterestAccrual";
import PromotionalRateFoundationalDetails from "./product-management/product-configuration/interest-deposit/promotional-rate-foundational-details/PromotionalRateFoundationalDetails";
import RateAdjustRange from "./product-management/product-configuration/interest-deposit/rate-adjustment-component/RateAdjustRange";
import PromotionalRateAdjustRangeForm from "./product-management/product-configuration/interest-deposit/promotional-rate-adjust-range/PromotionalRateAdjustRange";
import ReviewFrequency from "./product-management/product-configuration/interest-deposit/review-frequency/ReviewFrequency";
import PostFrequencyRounding from "./product-management/product-configuration/interest-deposit/post-frequency/PostFrequencyRounding";

// Interest Loan Deposit
import LoanFoundationalInterestDeposit from "./product-management/product-configuration/interest-loan/foundational-interest-detail/FoundationalInterest";
import LoanInterestAccrual from "./product-management/product-configuration/interest-loan/interest-accrual/InterestAccrual";
import LoanPostFrequencyRounding from "./product-management/product-configuration/interest-loan/post-frequency/PostFrequencyRounding";
import LoanReviewFrequency from "./product-management/product-configuration/interest-loan/review-frequency/ReviewFrequency";

//rewards deposit
import RewardsGeneralFeatureSetup from "./product-management/product-configuration/rewards-deposit/rewards-general-feature-setup/RewardsGeneralFeatureSetup";
import RewardConfiguration from "./product-management/product-configuration/rewards-deposit/reward-configuration/RewardConfiguration";

//term deposit
import TermPenaltiesAndAdjustments from "./product-management/product-configuration/term-deposit/term-penalties-and-adjustments/TermPenaltiesAndAdjustments";
import TermGeneralFeatureComponent from "./product-management/product-configuration/term-deposit/term-general-feature-deposit/TermGeneralFeatureComponent";
import TermMaturityConfiguration from "./product-management/product-configuration/term-deposit/term-maturity-configuration/TermMaturityConfiguration";
import RolloverProductInterestRatesConfiguration from "./product-management/product-configuration/term-deposit/rollover-product-interest-rates-configuration/RolloverProductInterestRatesConfiguration";
import GracePeriodsAndExtension from "./product-management/product-configuration/term-deposit/grace-periods-and-extensions/GracePeriodsAndExtension";

//transaction deposit
import TransactionGeneralFeatureSetup from "./product-management/product-configuration/transaction-deposit/transaction-general-feature-setup/TransactionGeneralFeatureSetup";
import TransactionCodeSpecifications from "./product-management/product-configuration/transaction-deposit/transaction-code-specifications/TransactionCodeSpecifications";

//repayment loan
import RepaymentPaymentDirectionCharges from "./product-management/product-configuration/repayment-loan/repayment-payment-direction-charges/RepaymentPaymentDirectionCharges";
import RepaymentAdvancedComponentOptions from "./product-management/product-configuration/repayment-loan/repayment-advanced-component-options/RepaymentAdvancedComponentOptions";
import RepaymentPrincipalAndInterestConfiguration from "./product-management/product-configuration/repayment-loan/repayment-principal-and-interest-configuration/RepaymentPrincipalInterestConfiguration";
import RepaymentPaymentConfiguration from "./product-management/product-configuration/repayment-loan/repayment-payment-configuration/RepaymentPaymentConfiguration";
import RepaymentPrePaymentConfigurations from "./product-management/product-configuration/repayment-loan/repayment-pre-payment-configurations/RepaymentPrePaymentConfigurations";
import RepaymentPastDueConfigurationsAndTracking from "./product-management/product-configuration/repayment-loan/repayment-past-due-configurations-and-tracking/RepaymentPastDueConfigurationsAndTracking";

//collateral loan
import CollateralSecuritySpecifications from "./product-management/product-configuration/collateral-loan/collateral-security-specifications/CollateralSecuritySpecifications";

//nsf deposit
import NegativeLimitDetails from "./product-management/product-configuration/nsf-deposit/negative-limit-details/NegativeLimitDetails";
import NSFTransactionCodeSpecification from "./product-management/product-configuration/nsf-deposit/transactions-code-specifications/NSFTransactionCodeSpecification";

//charge-off deposit
import ChargeOffTimingAndThreshold from "./product-management/product-configuration/charge-off-deposit/charge-off-timing-and-threshold/ChargeOffTimingAndThreshold";
import ChargeOffTransactionCodeSpecifications from "./product-management/product-configuration/charge-off-deposit/charge-off-Transaction-code-specification/ChargeOffTransactionCodeSpecifications";

//rules component
import RulesComponent from "./product-management/product-configuration/rules-component/RulesComponent";

//additional product attributes
import BasicProduct from "./product-management/product-configuration/additional-product-attribute/basic-product/BasicProduct";
import AdditionalProductForm from "./product-management/product-configuration/additional-product-attribute/additional-product-feature/AdditionalProductForm";
import RegulatoryAdherenceLimitationWrapper from "./product-management/product-configuration/additional-product-attribute/regulatory-adherence-limitation-wrapper/RegulatoryAdherenceLimitationWrapper";
import ProductCandences from "./product-management/product-configuration/additional-product-attribute/cadences/ProductCandences";
import EligibilityAndComponentsConfiguration from "./product-management/product-configuration/additional-product-attribute/eligibility-and-components-configuration/EligibilityAndComponentsConfiguration";
import LoanDelinquency from "./product-management/product-configuration/additional-product-attribute/loan-delinquency/LoanDelinquency";

//ic - summary landing page
import IcSummaryLandingPage from "./institutional-configuration/ic-summary-landing-page/IcSummaryLandingPage";
import FoundationalDetails from "./institutional-configuration/financial-institution-bank-parameter/foundational-details/FoundationalDetails";
import FinancialCalenderFoundationalDetails from "./institutional-configuration/financial-calender/foundational-details/FinancialCalenderFoundationalDetails";

//Institutional Configuration pages
import AccountTypeFoundationalDetails from "./institutional-configuration/account-types/account-type-foundational-details/AccountTypeFoundationalDetails";
import UsFinancialInstitutionParametersInformation from "./institutional-configuration/financial-institution-bank-parameter/us-financial-institution-parameters-information/UsFinancialInstitutionParametersInformation";
import BaseSavingsRate from "./institutional-configuration/financial-institution-bank-parameter/base-savings-rate/BaseSavingsRate";
import LoanPenaltyRate from "./institutional-configuration/financial-institution-bank-parameter/loan-penalty-rate/LoanPenaltyRate";
import AdditionalSpecifications from "./institutional-configuration/financial-institution-bank-parameter/additional-specifications/AdditionalSpecifications";
import SBALendingPartyPhone from "./institutional-configuration/financial-institution-bank-parameter/sba-lending-party-phone/LendingPartyPhone";
import SBALendingPartyEmail from "./institutional-configuration/financial-institution-bank-parameter/sba-lending-party-email/LendingPartyEmail";

//IC - Financial Organization - Geenral Organization party parameters
import TaxSpecifications from "./institutional-configuration/financial-organization/general-organization-party-parameters/tax-specifications/TaxSpecifications";
import GeneralOrganizationPartyIdentification from "./institutional-configuration/financial-organization/general-organization-party-parameters/general-organization-party-identification/GeneralOrganizationPartyIdentification";
import LocationContactInfo from "./institutional-configuration/financial-organization/general-organization-party-parameters/location-contact-info/LocationContactInfo";

import AccountTypeRelationshipFoundationalDetails from "./institutional-configuration/account-types/account-type-relationship-foundational-details/AccountTypeRelationshipFoundationalDetails";
import AccountGroupFoundationalDetails from "./institutional-configuration/account-group/account-group-foundational-details/AccountGroupFoundationalDetails";
import AdvancedConfigurations from "./institutional-configuration/account-group/advanced-configurations/AdvancedConfigurations";
import CompositeFieldConfiguration from "./institutional-configuration/account-group/composite-field-configuration/CompositeFieldConfiguration";
import NumberingIdentification from "./institutional-configuration/account-group/numbering-identification/NumberingIdentification";
import AssetCatalogFoundationalDetails from "./institutional-configuration/asset-catlog/asset-catalog-foundational-details/AssetCatalogFoundationalDetails";
import OriginAndClassification from "./institutional-configuration/asset-catlog/origin-and-classification/OriginAndClassification";
import FinancialSpecifications from "./institutional-configuration/asset-catlog/financial-specifications/FinancialSpecifications";
import GeneralLedgerDetails from "./institutional-configuration/asset-catlog/general-ledger-details/GeneralLedgerDetails";

import AccountTypeRelationshipPartySpecifications from "./institutional-configuration/account-types/account-type-relationship-party-specifications/AccountTypeRelationshipPartySpecifications";
import AccountTypeRelationshipAdditionalSelections from "./institutional-configuration/account-types/account-type-relationship-additional-selections/AccountTypeRelationshipAdditionalSelections";
import AddLimit from "./institutional-configuration/account-types/add-limit/AddLimit";
import AccumulatedTransactionLimitForm from "./institutional-configuration/account-types/add-limit/AccumulatedTransactionLimitForm";
import AccountTypeSummary from "./institutional-configuration/account-types/account-type-summary/AccountTypeSummary";

import FinancialCalenderHolidayConfiguration from "./institutional-configuration/financial-calender/holiday-configuration/FinancialCalenderHolidayConfiguration";
import FinancialCalenderBusinessDaysConfig from "./institutional-configuration/financial-calender/business-days-configuration/FinancialCalenderBusinessDaysConfig";
import FIDCRelatedFields from "./institutional-configuration/financial-institution-bank-parameter/fdic-related-fields/FdicRelatedFields";
import RatesCurrenciesIdentifiers from "./institutional-configuration/financial-institution-bank-parameter/rates-currencies-identifiers/RatesCurrenciesIdentifiers";
import CutoffsLimitsThresholds from "./institutional-configuration/financial-institution-bank-parameter/cutoffs-limits-thresholds/CutoffsLimitsThresholds";
import RecommendedCourses from "./academy/my-learning-profile/recommended-courses/RecommendedCourses";
import LearningCatalog from "./academy/catalog/LearningCatalog";
import BadgesCertifications from "./academy/badges-and-certifications/BadgesCertifications";
import LearningStatusCardUserInfoWithAchivements from "./academy/learning-status-card-user-info-and-achivements/LearningStatusCardUserInfoWithAchivements";
import HeroBannerWithSlider from "./academy/my-learning-profile/hero-banner/HeroBannerWithSlider";
import InstructorCourse from "./academy/my-learning-profile/instructor-led-course/InstructorCourses";
import ScraProperties from "./institutional-configuration/financial-institution-bank-parameter/scra-properties/ScraProperties";
import PriorityPosting from "./institutional-configuration/financial-institution-bank-parameter/priority-posting/PriorityPosting";

//IC - Financial Organization General Party Parameter
import LocationContactInformation from "./institutional-configuration/financial-organization/general-party-parameter/location-contact-information/LocationContactInformation";
import ProcessFlags from "./institutional-configuration/financial-organization/general-party-parameter/process-flags/ProcessFlags";
import TaxPartyPhoneContact from "./institutional-configuration/financial-institution-bank-parameter/tax-party-phone-contact/TaxPartyPhoneContact";
import TaxPartyEmailContact from "./institutional-configuration/financial-institution-bank-parameter/tax-party-email-contact/TaxPartyEmailContact";

//IC Finacial Organization General Party Parameter
import GeneralPartyIdentification from "./institutional-configuration/financial-organization/general-party-parameter/general-party-identification/GeneralPartyIdentification";
import PartyAddressDetail from "./institutional-configuration/financial-organization/general-party-parameter/party-address-details/PartyAddressDetail";
import GeneralPartySpecifications from "./institutional-configuration/financial-organization/general-party-parameter/general-party-specification/GeneralPartySpecifications";
import TaxSpecification from "./institutional-configuration/financial-organization/general-party-parameter/tax-specification/TaxSpecification";
import PartyPhoneDetails from "./institutional-configuration/financial-organization/general-party-parameter/party-phone-details/PartyPhoneDetails";
import PartyEmailsDetails from "./institutional-configuration/financial-organization/general-party-parameter/party-emails-details/PartyEmailsDetails";

//IC Finacial Organization - Finacial Organization Specific Parameter
import FinancialOrganizationIdentification from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/financial-organization-identification/FinancialOrganizationIdentification";
import FinancialOrganizationSpecifications from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/financial-organization-specifications/FinancialOrganizationSpecifications";
import OtherIdentificationDetails from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/other-identification-details/OtherIdentificationDetails";
import FinOrgCorrespondingDestinationNetwork from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/corresponding-networks/financial-organziation-destination-network/FinOrgCorrespondingDestinationNetwork";
import CorrespondingNetworkOrigination from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/corresponding-networks/CorrespondingNetworkOrigination";
import FinOrgCorrespondingSummary from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/corresponding-networks/financial-organization-corresponding-summary/FinOrgCorrespondingSummary";
import CorrespondingNetworkIdentification from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/corresponding-network-identification/CorrespondingNetworkIdentification";
import FinOrgRulesComponent from "./institutional-configuration/financial-organization/financial-organization-specific-parameters/fin-org-rules-component/FinOrgRulesComponent";

//Party Limit
import AddPartyLimit from "./institutional-configuration/financial-organization/financial-organization-party-limit/add-party-limit/AddPartyLimit";
import AccumulatedTransactionLimit from "./institutional-configuration/financial-organization/financial-organization-party-limit/accumulated-transaction-limit/AccumulatedTransactionLimit";
import GeneralLimitDetails from "./institutional-configuration/financial-organization/financial-organization-party-limit/general-limit-details/GeneralLimitDetails";

import LearningProfile from "./academy/my-learning-profile/LearningProfile";
import SavedCourses from "./academy/my-learning-profile/saved-courses/SavedCourses";
import CourseCard from "./academy/catalog/course-card/CourseCard";
import RequestClassroom from "./academy/my-learning-profile/request-classroom/RequestClassroom";
import Frame from "./academy/my-learning-profile/course-profile/course-profile-box/Frame/Frame";
import FastInformationTag from "./academy/common/fast-information-tag/FastInformationTag";
import InternationalTransferIntent from "./institutional-configuration/financial-organization/general-party-parameter/international-transfer-intent/InternationalTransferIntent";

import ExternalReferences from "./institutional-configuration/financial-organization/general-party-parameter/external-references/ExternalReferences";
import GLExtractFileFormats from "./institutional-configuration/financial-institution-bank-parameter/ gl-extract-file-formats/GlExtractFileFormats";
import BadgePath from "./academy/catalog/badge-path/BadgePath";
import FedwireDetails from "./institutional-configuration/financial-organization/party-us-bank-parameter/fedwire-details/FedwireDetails";
import USSpecificationFinancialOrganizationIdentification from "./institutional-configuration/financial-organization/party-us-bank-parameter/us-specific-financial-organization-identification/USSpecificationFinancialOrganizationIdentification";
import CertificationPath from "@/components/academy/certification-path/CertificationPath";

//General Ledger
import AccountingSegments from "./general-ledger/accounting-segments/AccountingSegments";
import Accounts from "./general-ledger/accounts/Accounts";
import SystemAccounts from "./general-ledger/system-accounts/SystemAccounts";
import Sets from "./general-ledger/sets/Sets";

//Trans code
import TcFoundationalDetails from "./transaction-code/foundational-details/TcFoundationalDetails";
import TcTagSetup from "./transaction-code/tag-setup/TcTagSetup";
import TcEntryFoundationalDetails from "./transaction-code/entry-setup/entry-foundational-setup/TcEntryFoundationalDetails";
import TcEntryAdvancedSetup from "./transaction-code/entry-setup/entry-advanced-setup/TcEntryAdvancedSetup";
import TransactionCodeSummaryLanding from "./transaction-code/transaction-code-summary-landing/TransactionCodeSummaryLanding";
import StatisticalExpressionEntries from "./transaction-code/entry-setup/statistical-expressions-entries/StatisticalExpressionEntries";
import HoldSpecifications from "./transaction-code/entry-setup/hold-specification/HoldSpecifications";
import FieldSpecifications from "./transaction-code/entry-setup/field-specification/FieldSpecifications";
import TcEntrySummary from "./transaction-code/entry-setup/entry-summary/TcEntrySummary";

import Templates from "./transaction-code/entry-setup/templates/Templates";
import TransAccountingSegments from "./transaction-code/entry-setup/accounting-segments/AccountingSegments";
//CRT
import GeneralSetupDetails from "./customer-relationship-types/crt-configuration/general-setup/GeneralSetup";
import PartyRelationshipsDetails from "./customer-relationship-types/crt-configuration/party-relationships/PartyRelationships";
import CrtSummaryLandingPage from "./customer-relationship-types/cr-type-landing-page/CrtLandingPage";

// access management
import AccessManagementLandingPage from "./access-management/access-management-landing-page/AccessManagementLandingPage";
import ProfileTemplate from "./access-management/profile-template/ProfileTemplate";
import CustomRoles from "./access-management/custom-roles/CustomRoles";
import TeamTab from "./access-management/access-management-landing-page/team-tab/TeamTab";
import CustomRolesTab from "./access-management/access-management-landing-page/custom-roles-tab/CustomRolesTab";
import ProfileTemplateTab from "./access-management/access-management-landing-page/profile-template-tab/ProfileTemplateTab";
import CreateOrEditTeam from "./access-management/create-or-edit-team/CreateOrEditTeam";
import ShareCertification from "./academy/share-certification/ShareCertification";
import AcademySideMenu from "./academy/side-menu/AcademySideMenu";
//product configuration exports
export {
  //fee deposit
  GeneralFeature,
  EarningAnalysis,
  FeeConfiguration,
  ServiceChargeDetails,

  //limit deposit
  RestrictionConfiguration,
  LimitConfig,
  SingleTransactionLimitConfig,
  AccountBalanceConfiguration,

  //interest deposit
  FoundationalInterestDeposit,
  IndexedRateFoundationalDetails,
  IndexRateOffsetTerms,
  PromotionalRateOffsetTerms,
  InterestAccrual,
  PromotionalRateFoundationalDetails,
  RateAdjustRange,
  PromotionalRateAdjustRangeForm,
  ReviewFrequency,
  PostFrequencyRounding,

  // Interest Loan
  LoanFoundationalInterestDeposit,
  LoanInterestAccrual,
  LoanPostFrequencyRounding,
  LoanReviewFrequency,

  //reward deposit
  RewardsGeneralFeatureSetup,
  RewardConfiguration,

  //term deposit
  TermPenaltiesAndAdjustments,
  TermGeneralFeatureComponent,
  TermMaturityConfiguration,
  RolloverProductInterestRatesConfiguration,
  GracePeriodsAndExtension,

  //transaction deposit
  TransactionGeneralFeatureSetup,
  TransactionCodeSpecifications,

  //repayment loan
  RepaymentPaymentDirectionCharges,
  RepaymentAdvancedComponentOptions,
  RepaymentPrincipalAndInterestConfiguration,
  RepaymentPaymentConfiguration,
  RepaymentPrePaymentConfigurations,
  RepaymentPastDueConfigurationsAndTracking,

  //collateral loan
  CollateralSecuritySpecifications,

  //nsf deposit
  NegativeLimitDetails,
  NSFTransactionCodeSpecification,

  //charge-off deposit
  ChargeOffTimingAndThreshold,
  ChargeOffTransactionCodeSpecifications,

  //rules
  RulesComponent,

  //additional product attributes
  BasicProduct,
  AdditionalProductForm,
  ProductCandences,
  RegulatoryAdherenceLimitationWrapper,
  EligibilityAndComponentsConfiguration,
  LoanDelinquency,

  //ic - summary landing page
  IcSummaryLandingPage,
  TaxPartyPhoneContact,

  //ic - account types pages
  AccountTypeFoundationalDetails,
  UsFinancialInstitutionParametersInformation,
  AccountTypeRelationshipFoundationalDetails,
  AccountTypeRelationshipPartySpecifications,
  AccountTypeRelationshipAdditionalSelections,
  AddLimit,
  AccumulatedTransactionLimitForm,
  AccountTypeSummary,

  //IC - financial institution (bank) parameter
  AdditionalSpecifications,
  BaseSavingsRate,
  CutoffsLimitsThresholds,
  FIDCRelatedFields,
  LoanPenaltyRate,
  PriorityPosting,
  RatesCurrenciesIdentifiers,
  SBALendingPartyEmail,
  SBALendingPartyPhone,
  AccountGroupFoundationalDetails,
  AdvancedConfigurations,
  CompositeFieldConfiguration,
  NumberingIdentification,
  ScraProperties,

  //ic - asset catalog
  AssetCatalogFoundationalDetails,
  OriginAndClassification,
  FinancialCalenderHolidayConfiguration,
  FinancialSpecifications,
  FoundationalDetails,
  GeneralLedgerDetails,
  FinancialCalenderBusinessDaysConfig,

  // FinancialCalender
  FinancialCalenderFoundationalDetails,
  RecommendedCourses,
  LearningCatalog,
  BadgesCertifications,

  // Academy
  LearningProfile,
  LearningStatusCardUserInfoWithAchivements,
  HeroBannerWithSlider,
  InstructorCourse,
  CourseCard,
  SavedCourses,
  RequestClassroom,
  Frame,
  FastInformationTag,
  ShareCertification,

  //IC - financial organization general organization party parameters
  TaxSpecifications,
  GeneralOrganizationPartyIdentification,
  LocationContactInfo,

  //IC - Financial Organization General Party Parameter
  LocationContactInformation,
  ProcessFlags,
  GLExtractFileFormats,
  TaxPartyEmailContact,

  //IC Finacial Organization General Party Parameter
  GeneralPartyIdentification,
  PartyAddressDetail,
  GeneralPartySpecifications,

  //Party US Bank Parameter
  USSpecificationFinancialOrganizationIdentification,
  InternationalTransferIntent,
  ExternalReferences,
  TaxSpecification,

  //Financial Organization
  PartyPhoneDetails,
  PartyEmailsDetails,
  BadgePath,

  //IC Finacial Organization - Finacial Organization Specific Parameter
  FinancialOrganizationIdentification,
  OtherIdentificationDetails,
  FinancialOrganizationSpecifications,
  FinOrgCorrespondingSummary,
  FinOrgCorrespondingDestinationNetwork,
  CorrespondingNetworkOrigination,
  CorrespondingNetworkIdentification,
  FedwireDetails,
  FinOrgRulesComponent,

  //Party Limit
  AddPartyLimit,
  AccumulatedTransactionLimit,
  GeneralLimitDetails,

  //General Ledger
  AccountingSegments,
  CertificationPath,
  Accounts,
  SystemAccounts,
  Sets,

  //Trans code
  TcFoundationalDetails,
  TcTagSetup,
  TcEntryFoundationalDetails,
  TcEntryAdvancedSetup,
  StatisticalExpressionEntries,
  HoldSpecifications,
  FieldSpecifications,
  TransactionCodeSummaryLanding,
  TcEntrySummary,
  TransAccountingSegments,
  Templates,

  //CRT
  GeneralSetupDetails,
  PartyRelationshipsDetails,
  CrtSummaryLandingPage,

  //access management
  AccessManagementLandingPage,
  ProfileTemplate,
  CustomRoles,
  TeamTab,
  CustomRolesTab,
  ProfileTemplateTab,
  CreateOrEditTeam,
  AcademySideMenu,
};

//document center
import DocumentCenter from "./documentcenter/DocumentCenter";
import DocumentDetailPage from "./documentcenter/doc-detail-page/DocumentDetailPage";

export { DocumentCenter, DocumentDetailPage };
// Platform Setup
import PlatformSetup from "./platform-setup/PlatformSetup";

export { PlatformSetup };

//api library
import APIDetailPage from "./api-library/detail-page";
export { APIDetailPage };

// Tutorials
import TutorialsLandingPage from "./tutorials/landing-page/TutorialsLandingPage";

export { TutorialsLandingPage };
