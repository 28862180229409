"use client";

import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppSingleSelectDropdown,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultFormDetails } from "@/components/institutional-configuration/model";
import { TcTagsConfig, TransCodeResponse } from "../models";
import {
  DASHBOARD_ACCORDION_STATUS,
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_STAGE_ORDER,
  TC_STAGE_STATUS,
  WORKFLOW_STATUS,
  deepCopy,
  generateYupSchema,
  useQueryParams,
} from "@/utils";
import {
  FastDefaultDropdownProps,
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { Box, Flex, useToast } from "@/components/ChakraUiManager";
import { updateTransCode } from "../transaction-code-api-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import styles from "./TcTagSetup.module.scss";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import * as Yup from "yup";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useSelector } from "react-redux";
import { getEnvIsDisable } from "@/store";

interface TcTagSetupProp extends DefaultFormDetails {
  data: {
    formData: TransCodeResponse | null;
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    existingTagOptions: FastDefaultDropdownProps[];
    tagsOptionsData: PostSchema;
  };
}

export interface TcTagSetupFormProp {
  tags: {
    tag?: string;
  }[];
}

export const getValidationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["tags"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  shapeConfig.tags = Yup.array();
  return Yup.object().shape(shapeConfig);
};

export default function TcTagSetup(props: TcTagSetupProp) {
  const {
    depositName,
    featureName,
    version,
    mainTitle,
    subText,
    data: { formData, existingTagOptions, tagsOptionsData },
  } = props;

  const toast = useToast();
  const toastId = "tc-tag-setup";
  const updateQueryParams = useQueryParams();
  const tagDefaultInput = "true";
  const isEnvDisabled = useSelector(getEnvIsDisable);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    navigateTo,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    setSideBarCurrentIndex,
    checkFormStatus,
    sideBarMainMenuIndex,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const entrySetupStatus = useMemo(() => {
    return entityWorkflowData?.filter(
      (wfd) =>
        wfd.stage === TC_STAGE_STATUS.entry_setup &&
        wfd.status === WORKFLOW_STATUS.completed
    )?.[0]?.status;
  }, [entityWorkflowData]);

  const onSubmit = async (values: TcTagSetupFormProp, actions: any) => {
    if (!dirty) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: formData?.trnCode!,
        stage: TC_STAGE_STATUS.tag_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : DASHBOARD_ACCORDION_STATUS.completed,
      });
      if (!canCheckFormStatus) {
        moveToNextStep();
      }
      return;
    }
    const existingFormData = deepCopy(formData);
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    let tagPayload;
    if (entityId && values?.tags?.length) {
      tagPayload = values.tags
        ?.filter((item) => item.tag)
        .reduce((obj: TcTagsConfig, item) => {
          if (item.tag) {
            obj[item.tag] = tagDefaultInput;
          }
          return obj;
        }, {});
    }
    if (tagPayload) {
      existingFormData!.tags = tagPayload;
    } else {
      delete existingFormData?.tags;
    }

    response = await updateTransCode({
      formData: {
        ...existingFormData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },
      stageName: TC_STAGE_STATUS.tag_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : DASHBOARD_ACCORDION_STATUS.completed,
    });
    if (response) {
      moveToNextStep(response.trnCode);
    }
  };

  function constructUrl() {
    //Incase of multiple entries navigate the user to summary screen
    const baseUrl =
      entrySetupStatus && formData?.entries?.length
        ? formData?.entries?.length > 1
          ? ROUTE_PATH.TC_TRANSACTION_ENTRY_SUMMARY
          : ROUTE_PATH.TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS
        : ROUTE_PATH.TC_INTERSTITAL_SCREEN;
    const param =
      formData?.entries?.length && formData?.entries?.length === 1
        ? updateQueryParams(QUERY_PARAM_KEY["TC_ENTRY_INDEX"], "0")
        : updateQueryParams();
    return `${baseUrl}?${param}`;
  }

  function moveToNextStep(trnCode = "") {
    if (!canCheckFormStatus) {
      if (
        isEnvDisabled ||
        entrySetupStatus ||
        (entityId && !entityWorkflowData?.length)
      ) {
        navigateToNextPage?.();
      } else {
        const url = constructUrl();
        updateFormStatus?.(SIDEBAR_STATUS.completed);
        updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
        setSideBarCurrentIndex?.(
          TC_STAGE_ORDER.entry_setup,
          formData?.entries && formData?.entries?.length > 1
            ? TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_summary
            : TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_foundational
        );
        // reset checkFormStatus
        checkFormStatus?.(false);
        navigateTo?.(url);
      }
    }
  }

  const tagDefaultValue = {
    tag: "",
  };

  const initialValues = { tags: constructTagValue() };

  function constructTagValue() {
    let constructedTagList: any = [];
    if (
      formData?.tags &&
      Object.keys(formData.tags)?.length &&
      existingTagOptions?.length
    ) {
      const valueList = existingTagOptions.map((item) => item.value);
      const tagList = Object.keys(formData.tags).map((key) => {
        return {
          tagName: key,
        };
      });
      constructedTagList = tagList
        ?.filter((item) => valueList.includes(item.tagName))
        .map(({ tagName }) => {
          return {
            tag: tagName,
          };
        });
    }
    return constructedTagList?.length ? constructedTagList : [];
  }

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(tagsOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [tagsOptionsData]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const formTitle = (
    <>
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />
    </>
  );

  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`tags[${index}].${key}`, value);
  };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    setSideBarCurrentIndex?.(TC_STAGE_ORDER.tag_setup, 0);
  }, []);

  useEffect(() => {
    if (sideBarMainMenuIndex === TC_STAGE_ORDER.tag_setup) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarMainMenuIndex]);

  return (
    <FormikProvider value={formikConfigs}>
      <form onSubmit={handleSubmit} noValidate id="finxact-form">
        {formTitle}
        <Box className="app-form-container">
          <FieldArray
            name="tags"
            render={({ insert, remove, push }) => (
              <Box>
                {values?.tags.map(({ tag }, index: number) => (
                  <CardContainer
                    key={`tagContainer${index}`}
                    customClass={styles["tag-setup-card"]}
                  >
                    <AccordionComponent
                      key={`tagAccContainer${index}`}
                      accordionTitle={`Transaction tag key ${index + 1}`}
                      deleteIcon
                      onDeleteHandler={() => remove(index)}
                      isExpand={0}
                    >
                      <Flex
                        flexDirection={"column"}
                        gap={6}
                        key={`tagFormContainer${index}`}
                        className="app-form-field-container"
                      >
                        <AppFormLabel
                          labelName={`Select a transaction tag`}
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("tag")
                              ?.tooltip_text_main
                          }
                          labelFor={`tags${index}`}
                        />
                        <AppSingleSelectDropdown
                          key={`tagContainerDropdown${index}`}
                          id="tag"
                          value={tag}
                          onChange={handleOnChange("tag", index)}
                          placeholder="Select"
                          dropdownList={existingTagOptions}
                          keyName={"label"}
                        />
                        {touched.tags &&
                          touched.tags[index]?.tag &&
                          errors.tags &&
                          (errors.tags[index] as any)?.tag && (
                            <ErrorAlert>
                              <span>{(errors.tags[index] as any)?.tag}</span>
                            </ErrorAlert>
                          )}
                      </Flex>
                    </AccordionComponent>
                  </CardContainer>
                ))}
                <Box className="add-limit-add-new-button">
                  <AddNewButton
                    label={
                      !values.tags?.length
                        ? "Add a transaction tag key"
                        : "Add another transaction tag key"
                    }
                    onClick={() => {
                      push({
                        ...tagDefaultValue,
                      });
                    }}
                  />
                </Box>
              </Box>
            )}
          ></FieldArray>
        </Box>
      </form>
    </FormikProvider>
  );
}
