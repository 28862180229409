"use client";

import React, { useState, useEffect } from "react";
import { Box, HStack, SimpleGrid } from "@/components/ChakraUiManager";
import TutorialPreview from "./TutorialPreview";
import "./TutorialsLandingPage.scss";
import "../TutorialsFilter.scss";
import OverviewHeader from "../overview-header/OverviewHeader";
import TutorialsTagsFilter from "../TutorialsTagsFilter";
import TutorialsFilter from "../TutorialsFilter";

interface Tutorial {
  title: string;
  tags: string[];
  uid: string;
  previewText: Array<{
    type: string;
    text: string;
    spans: any[];
    direction: string;
  }>;
}

interface TutorialsLandingPageProps {
  tutorials: Tutorial[];
  loading: boolean;
  paramTags: any;
}

const TutorialsLandingPage: React.FC<TutorialsLandingPageProps> = (props) => {
  const { tutorials, loading, paramTags } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (paramTags) {
      setSelectedTags(paramTags);
    }
  }, [paramTags]);

  const handleTagChange = (tag: string) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  const clearFilters = () => {
    setSelectedTags([]);
  };

  const filteredTutorials =
    selectedTags.length === 0
      ? tutorials
      : tutorials.filter((tutorial) =>
          tutorial.tags.some((tag) => selectedTags.includes(tag))
        );

  return (
    <Box>
      <OverviewHeader
        documentTitle="Tutorials"
        overView="Imagine, configure, and launch new financial product value propositions with Finxact Tutorials."
        breadCrumb={["Docs"]}
      />
      <HStack gap={24} align="start">
        <TutorialsTagsFilter
          selectedTags={selectedTags}
          handleTagChange={handleTagChange}
          clearFilters={clearFilters}
          page="landing"
        />

        {!loading && tutorials.length > 0 ? (
          <SimpleGrid
            gap="var(--chakra-space-10)"
            columns={{ lg: 1, xl: 2 }}
            className="tutorial-grid"
          >
            {filteredTutorials.map((tutorial: Tutorial, key: number) => (
              <TutorialPreview data={tutorial} key={key} />
            ))}
          </SimpleGrid>
        ) : (
          <span className="no-tutorials">No Tutorials Found</span>
        )}
      </HStack>
      <Box h={100}></Box>
    </Box>
  );
};

export default TutorialsLandingPage;
