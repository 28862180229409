"use client";
import { ApiClient, API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  getCoreModelEndpoint,
  getAllWorkflowGeneric,
  getWorkflowGeneric,
  TcWorkflowResponse,
} from "@/api-config/api-service";
import {
  ConfigurationStatusComponent,
  CardContainer,
  Badges,
  CustomTable,
  Loader,
} from "@/components/common";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import {
  DASHBOARD_MODEL_KEY,
  ENTITLEMENT_STATUS,
  FOUNDATIONAL_SETUP_STEPS_NAME,
  NO_WORKFLOW_ORG_PHASES,
  ORG_PHASE,
  SUMMARY_LANDING_SCREEN_STATUS,
  TC_STAGE_ORDER,
  TC_TABLE_COLUMN_DEF,
  WORKFLOW_STATUS,
} from "@/utils";
import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Flex,
  HStack,
  TableContainer,
  Box,
  Text,
  Button,
  Divider,
  VStack,
  Icon,
  Spacer,
  Heading,
} from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import { kebabIcon, rightRotateYellow, noCatalogFound } from "public/assets";
import { useState, useEffect, useCallback } from "react";
import Image from "next/image";
import { getEnvIsDisable, getEntitlement, getSelectedOrg } from "@/store";
import { useSelector } from "react-redux";
import UpdatePreferences from "../UpdatePreferences";
import {
  CompletedTrnCode,
  InProgressTrnCode,
  TransCodeResponse,
  TrnCodeOption,
} from "../../models";
import { OptionsSchema } from "@/components/product-management/model/types";

const CompletedPage = () => {
  const router = useRouter();
  const [allTranCodes, setAllTranCodes] = useState<TrnCodeOption[]>([]);
  const [selectedPreferencesTransCodes, setSelectedPreferencesTransCodes] =
    useState<string[]>([]);
  const [transCodes, setTransCodes] =
    useState<ReturnType<typeof processTranscodes>>();
  const [networkStatus, setNetworkStatus] =
    useState<SUMMARY_LANDING_SCREEN_STATUS>(
      SUMMARY_LANDING_SCREEN_STATUS.not_started
    );
  const entitlement = useSelector(getEntitlement);
  const [reload, setReload] = useState(false);
  const [isTrnCodePreferencesSupported, setIsTrnCodePreferencesSupported] =
    useState(false);
  const currOrg = useSelector(getSelectedOrg);

  const renderDataCell = (value: any, key: string, row: any) => {
    if (key === "action")
      return (
        <Box textAlign={"end"}>
          {entitlement.tc !== ENTITLEMENT_STATUS.noAccess && (
            <Menu>
              <MenuButton
                border={0}
                className="kebab-btn"
                as={IconButton}
                aria-label="Options"
                icon={<Image src={kebabIcon} alt="image" />}
                variant="outline"
              />
              <MenuList className="menu-list-container">
                <MenuItem
                  onClick={() => {
                    router.push(row.url);
                  }}
                  p={4}
                >
                  {`${
                    envIsDisabled ||
                    entitlement.tc === ENTITLEMENT_STATUS.readOnly
                      ? "View"
                      : "Edit"
                  }`}
                </MenuItem>
                {entitlement.tc === ENTITLEMENT_STATUS.allAccess &&
                !envIsDisabled ? (
                  <MenuItem
                    p={4}
                    onClick={() => {
                      router.push(row.duplicateUrl);
                    }}
                  >
                    Duplicate
                  </MenuItem>
                ) : null}
              </MenuList>
            </Menu>
          )}
        </Box>
      );
    return value;
  };

  const envIsDisabled = useSelector(getEnvIsDisable);

  const processTranscodes = (
    transCodeData: TransCodeResponse[],
    workflowData: TcWorkflowResponse[]
  ) => {
    return transCodeData.reduce(
      (ret, entityData) => {
        const identifier = entityData.trnCode;
        const tcConfigForEntity = workflowData.filter(
          (wfd) => wfd.modelKey === identifier
        );
        const url = `${ROUTE_BASE_URL.TRANS_CODE_FORM}?entityId=${identifier}`;
        const duplicateUrl = `${url}&action=duplicate`;
        if (!entityData?.isHidden__) {
          const tcConfigCompletedStatus = tcConfigForEntity.filter(
            (wfd) => wfd.status === WORKFLOW_STATUS.completed
          );
          // If no workflow exists, or all workflow steps are completed or if org in prospect phase, add to completed list
          if (
            !tcConfigForEntity.length ||
            tcConfigCompletedStatus.length === TC_STAGE_ORDER.entry_setup + 1 ||
            currOrg?.phase === ORG_PHASE.prospect
          ) {
            ret["completed"].push({
              ...entityData,
              url,
              duplicateUrl,
            });
          } else {
            ret["inProgress"].push({
              ...entityData,
              url,
            });
          }
        }
        return ret;
      },
      {
        completed: [] as CompletedTrnCode[],
        inProgress: [] as InProgressTrnCode[],
      }
    );
  };

  const extractTransCodeForPreferences = (
    transCodeData: TransCodeResponse[]
  ) => {
    const allTransCodeData: TrnCodeOption[] = [];
    const selectedTransCodeData: string[] = [];
    transCodeData.map((transCode) => {
      const tempData = {
        value: transCode.trnCode,
        label: transCode.desc ?? "",
        _vn: transCode._vn,
        isHidden__: transCode?.isHidden__ || null,
      };
      if (!transCode.isHidden__) {
        selectedTransCodeData.push(tempData.value);
      }
      allTransCodeData.push(tempData);
    });
    return { allTransCodeData, selectedTransCodeData };
  };

  const saveTransCodePreferences = async (preferences: string[]) => {
    const api = new ApiClient({
      baseUrl: getCoreModelEndpoint(),
    });

    // hide unselected codes
    const hidePreferencePromises = allTranCodes.filter((trnCode) => {
      if (!preferences.includes(trnCode.value) && !trnCode.isHidden__) {
        return api.patch(
          `${API_ROUTE_CONFIGURATION.transCode}/${trnCode.value}`,
          {
            body: JSON.stringify({
              _vn: trnCode._vn,
              isHidden__: true,
            }),
          }
        );
      }
    });

    // Unhide newly added trn codes
    const unHidePreferencesPromises = preferences.filter((prefCode: any) => {
      const item = allTranCodes.find(
        (trnCode: any) => trnCode.value === prefCode
      );
      if (item?.isHidden__ === true) {
        return api.patch(`${API_ROUTE_CONFIGURATION.transCode}/${item.value}`, {
          body: JSON.stringify({
            _vn: item._vn,
            isHidden__: null,
          }),
        });
      }
    });
    await Promise.all([
      ...hidePreferencePromises,
      ...unHidePreferencesPromises,
    ]);
    setReload(true);
  };

  useEffect(() => {
    (async () => {
      let cPhase = currOrg.phase ?? "";

      if (!envIsDisabled) {
        const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
        const res = await Promise.all([
          api.get(API_ROUTE_CONFIGURATION.transCode),
          getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.tcConfig),
          api.options(
            API_ROUTE_CONFIGURATION.transCode
          ) as Promise<OptionsSchema>,
        ]);
        setReload(false);
        const data = processTranscodes(res?.[0]?.data ?? [], res?.[1]);
        setTransCodes(data);
        const { allTransCodeData, selectedTransCodeData } =
          extractTransCodeForPreferences(res?.[0]?.data);
        setAllTranCodes(allTransCodeData);
        setSelectedPreferencesTransCodes(selectedTransCodeData);
        setIsTrnCodePreferencesSupported(
          res?.[2]?.actions.POST.properties.hasOwnProperty("isHidden__")
        );
        if (NO_WORKFLOW_ORG_PHASES.includes(cPhase)) {
          setNetworkStatus(SUMMARY_LANDING_SCREEN_STATUS.completed);
        } else {
          const networkWorkflow = await getWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.dashboard,
            key: DASHBOARD_MODEL_KEY.foundationalSetup,
            stage: FOUNDATIONAL_SETUP_STEPS_NAME.networks,
          });
          setNetworkStatus(networkWorkflow.status);
        }
      }
    })();
  }, [reload, currOrg, envIsDisabled]);

  const InProgressCard = ({ transCode }: any) => {
    return (
      <Box className="in-progress-container" w={"93%"} m={4}>
        <HStack>
          <HStack alignItems={"start"} gap={4}>
            <Image src={rightRotateYellow} alt="in-progress" />
            <VStack className="in-progress-desc" alignItems="start" gap={3}>
              <Heading as="h3">{transCode?.trnCode}</Heading>
              <Text>{transCode?.desc}</Text>
              <Badges
                size="lg"
                variant="subtle"
                label="IN-PROGRESS"
                type="warning"
              />
            </VStack>
          </HStack>
          <Spacer />
          <HStack gap={10}>
            <Button
              className="app-btn-reg-secondary-transparent"
              onClick={() => {
                router.push(transCode?.url);
              }}
              isDisabled={entitlement.tc === ENTITLEMENT_STATUS.noAccess}
            >
              {entitlement.tc === ENTITLEMENT_STATUS.readOnly || envIsDisabled
                ? "View"
                : "Resume"}
              <Icon as={ChevronRightIcon} boxSize={8} ml={3} />
            </Button>
          </HStack>
        </HStack>
      </Box>
    );
  };
  return (
    <>
      {reload && <Loader isOverlay />}
      <ConfigurationStatusComponent
        title="Transaction Code Setup"
        status={SUMMARY_LANDING_SCREEN_STATUS.prod_completed}
        leftBadgeLabel="General Ledger Setup"
        leftBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.completed}
        rightBadgeLabel="Networks Setup"
        rightBadgeStatus={networkStatus}
      />
      <Flex direction={"column"} gap={5} align="center" mx={60} mt={32}>
        <Box width="60%" mx="auto" textAlign="center">
          <Text className="heading-text" as="h3">
            Review and create your transaction code(s)
          </Text>
          <Text className="paragraph">
            Set up transaction code as necessary or review the pre-populated
            list below. These codes will support additional foundational setup
            and product configuration actions
          </Text>
        </Box>
        <Box w={"100%"}>
          <CardContainer customClass="transaction-codes-container">
            {transCodes &&
              transCodes?.inProgress.length > 0 &&
              entitlement.tc !== ENTITLEMENT_STATUS.noAccess && (
                <>
                  <Text className="in-progress-header">Jump Back in</Text>
                  <VStack>
                    {transCodes?.inProgress.map((transCode: any) => (
                      <InProgressCard
                        key={transCode.trnCode}
                        transCode={transCode}
                      />
                    ))}
                  </VStack>
                </>
              )}
            <Divider my={13} />
            <Flex justifyContent={"space-between"} m={4} px={9}>
              <Badges
                size="lg"
                variant="subtle"
                label={`${transCodes?.completed?.length || 0} SETUP`}
                type="primary"
              />
              <HStack gap={8}>
                {entitlement.tc === ENTITLEMENT_STATUS.allAccess &&
                !envIsDisabled ? (
                  <>
                    {allTranCodes.length > 0 &&
                      isTrnCodePreferencesSupported && (
                        <UpdatePreferences
                          trnCodeOptions={allTranCodes}
                          selectedTrnCodes={selectedPreferencesTransCodes}
                          savePreferences={saveTransCodePreferences}
                        />
                      )}
                    <Button
                      className="app-btn-inverse-secondary"
                      onClick={() =>
                        router.push(`${ROUTE_PATH.TC_WALKTHROUGH}`)
                      }
                    >
                      <AddIcon boxSize={11} mr={4} />
                      Add new
                    </Button>
                  </>
                ) : null}
              </HStack>
            </Flex>
            {transCodes?.completed.length ? (
              <TableContainer className="transaction-codes-table" m={4} px={9}>
                <CustomTable
                  data={transCodes.completed}
                  columns={TC_TABLE_COLUMN_DEF}
                  renderDataCell={renderDataCell}
                  className="my-product-table"
                  isPagination={true}
                  pageSizeData={20}
                />
              </TableContainer>
            ) : (
              <VStack
                w={"100%"}
                justifyContent={"center"}
                mt={20}
                gap={6}
                className="no-transactions-found"
              >
                <Image src={noCatalogFound} alt={"No transaction codes"} />
                <Heading as="h3" mt={4}>
                  No transaction codes
                </Heading>
              </VStack>
            )}
          </CardContainer>
        </Box>
      </Flex>
    </>
  );
};

export default CompletedPage;
