import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

interface LabValProps {
  label: string;
  value: string;
}
interface InternationalTransferIntentValueProps {
  intrntlTfrIntent: LabValProps[];
}

const internationalTransferIntents: InternationalTransferIntentValueProps = {
  intrntlTfrIntent: [],
};

const internationalTransferIntentsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["intrntlTfrIntent"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  shapeConfig.intrntlTfrIntent = shapeConfig.intrntlTfrIntent.array();
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { InternationalTransferIntentValueProps, LabValProps };
// Exporting Validation Schema and Intial value
export {
  internationalTransferIntents,
  internationalTransferIntentsSchema as getValidationSchema,
};
