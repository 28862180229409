import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { REGEX_PATTERN, generateRegexToRestrictNoOfDigits, generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Rewards_General_Feature_Setup
interface RewardGeneralSetupProps {
  componentName: string;
}
// Defining Intitial Value For Rewards_General_Feature_Setup
const rewardsGeneralSetup: RewardGeneralSetupProps = {
  componentName: "",
};
// YUP LIBRARY :Defining Schema For validation of Rewards_General_Feature_Setup
const rewardsGeneralSetupSchema = async (optionsSchema: PostSchema) => {
  const fields = ["componentName"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { RewardGeneralSetupProps };
// Exporting Validation Schema and Intial value
export {
  rewardsGeneralSetup,
  rewardsGeneralSetupSchema as getValidationSchema,
  // goalReward,
};
