"use client";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Icon,
  List,
  ListItem,
  Text,
} from "../ChakraUiManager";
import "./SidebarManager.scss";
import { SidebarMenu } from "./sidebar-menu/SidebarMenu";
import React, { useEffect, useState } from "react";
import { CardContainer } from "../common";
import Link from "next/link";
import spanner from "../../../public/assets/images/spanner.png";
import Image from "next/image";
// import fsPromises from "fs/promises";s
// import path from "path";

import { PiHouse } from "react-icons/pi";
import { useRouter } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { usePathname } from "next/navigation";

type SideBarMenuDefaultProps = {
  name?: string;
  href?: string;
  baseHref?: string;
  menuHeading?: string;
  containsNestedSideBar?: boolean;
  enableAccordion?: boolean;
};

interface SideBarMenuProps extends SideBarMenuDefaultProps {
  subMenu?: SideBarMenuDefaultProps[];
}

export type SidebarManagerProp = {
  menuList: SideBarMenuProps[];
  homeTitle?: string;
  homeHref?: string;
  showFooter?: boolean;
};

export default function SidebarManager({ menu }: { menu: SidebarManagerProp }) {
  const { menuList = [], homeTitle, homeHref, showFooter } = menu;
  const pathName = usePathname();
  const [menuData, setMenuData] = useState<any>(null);
  const [currentMainMenuIndex, setMainMenuIndex] = useState<number | null>(
    null
  );
  const pathname = usePathname();

  function selectedMainMenuIndex(index: number) {
    setMainMenuIndex(index);
  }

  function constructSideBarMenu() {
    return (
      <>
        {menuList.map(
          (
            {
              name,
              href = "",
              menuHeading = "",
              subMenu = [],
              enableAccordion = false,
            }: SideBarMenuProps,
            menuIndex: number
          ) => (
            <React.Fragment key={menuHeading}>
              {subMenu?.length ? (
                <>
                  {constructMainMenu(
                    subMenu,
                    menuIndex,
                    menuHeading,
                    enableAccordion
                  )}
                </>
              ) : (
                <ListItem
                  className={`${pathName?.includes(href) || pathName?.includes(href + "/") ? "active" : ""}`}
                  key={`sideBarMenu${menuIndex}`}
                >
                  <Link href={href}>{name}</Link>
                </ListItem>
              )}
            </React.Fragment>
          )
        )}
      </>
    );
  }

  function constructMainMenu(
    subMenu: SideBarMenuProps[],
    menuIndex: number,
    menuHeading: string,
    enableAccordion: boolean
  ) {
    return (
      <>
        {enableAccordion ? (
          <Accordion defaultIndex={[0]} allowToggle>
            <AccordionItem>
              <Heading as="h6">
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {menuHeading}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel>
                {constructSideBarSubMenu(subMenu)}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ) : (
          <ListItem key={`sideBarMenu${menuIndex}`}>
            <Divider />
            <Box>
              <Heading as="h6" py={"8px"}>
                {menuHeading}
              </Heading>
              {constructSideBarSubMenu(subMenu)}
            </Box>
          </ListItem>
        )}
      </>
    );
  }

  function constructSideBarSubMenu(subMenu: SideBarMenuProps[] = []) {
    return (
      <List spacing={3}>
        {subMenu.map(
          ({ name: subMenuName, href: subMenuHref = "" }: SideBarMenuProps) => (
            <ListItem
              className={`${pathName?.includes(subMenuHref) ? "active" : ""}`}
              key={subMenuName}
            >
              <Link href={subMenuHref}>{subMenuName}</Link>
            </ListItem>
          )
        )}
      </List>
    );
  }

  return (
    <>
      <Box className="sidebar-manager-container" w={{ base: "100%" }}>
        <CardContainer>
          <List spacing={7} className="main-menu-list">
            <ListItem
              className={`top-nav-link ${
                pathName === homeHref || pathName === `${homeHref}/`
                  ? "active"
                  : ""
              }`}
            >
              <Link href={homeHref ?? ""}>
                <Icon as={PiHouse} mr={1.5} />
                {homeTitle ?? "Home"}
              </Link>
            </ListItem>
            {menuList?.length ? constructSideBarMenu() : ""}
            {showFooter && (
              <ListItem>
                <Divider style={{ marginBottom: "8px" }} />
                <Link
                  href="https://service.finxact.com/support/home"
                  style={{ paddingLeft: "15px" }}
                  target="_blank"
                >
                  <Image
                    src={spanner}
                    className="req-service-icon"
                    alt="Request service"
                  />
                  Request Service
                </Link>
              </ListItem>
            )}
          </List>
          {showFooter && (
            <Text className="copyright-text">
              Partner-PWC (277293) DEV-10 1.18.1 updates
            </Text>
          )}
        </CardContainer>
      </Box>
    </>
  );
}
