"use client";

import { DefaultFormDetails } from "@/components/institutional-configuration/model";
import { TransCodeResponse } from "../models";
import {
  TransCodeFoundationalDetailsForm,
  initialTcFoundationalValues,
  tcFoundationalDetailsValidationSchema as getValidationSchema,
} from "./TcFoundationalDetailsValidation";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  QUERY_PARAM_KEY,
  ROUTE_BASE_URL,
  ROUTE_PATH,
} from "@/route-config/route-path";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  MultiSelectDropdown,
  SplitButton,
} from "@/components/common";
import {
  FastDefaultDropdownProps,
  PostSchema,
} from "@/components/product-management/model/types";
import {
  createTransCode,
  updateTransCode,
} from "../transaction-code-api-service";
import {
  DASHBOARD_ACCORDION_STATUS,
  SIDEBAR_STATUS,
  TC_ACTIONS,
  TC_STAGE_ORDER,
  TC_STAGE_STATUS,
  useEntityId,
  WORKFLOW_STATUS,
} from "@/utils";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useSelector } from "react-redux";
import { getEnvIsDisable } from "@/store";
import { useRouter, useSearchParams } from "next/navigation";

interface TcFoundationalDetailsProp extends DefaultFormDetails {
  data: {
    formData: TransCodeResponse | null;
    defaultNetworkOptions: FastDefaultDropdownProps[];
    transLimitOptions: FastDefaultDropdownProps[];
    tcFoundationalDetailsOptionsData: PostSchema;
  };
}

export default function TcFoundationalDetails(
  props: TcFoundationalDetailsProp
) {
  const {
    depositName,
    featureName,
    version,
    mainTitle,
    subText,
    data: {
      formData,
      defaultNetworkOptions,
      transLimitOptions,
      tcFoundationalDetailsOptionsData,
    },
  } = props;
  const entityId = useEntityId();
  const toast = useToast();
  const toastId = "tc-foundational-details";
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    navigateTo,
    getQueryParam,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    setSideBarCurrentIndex,
    checkFormStatus,
    continueToConfigFlowFromTc,
    sideBarMainMenuIndex,
    entityWorkflowData,
    tooltipFlyoutDetails,
    saveAndExit,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const action = getQueryParam("action");
  const isEnvDisabled = useSelector(getEnvIsDisable);
  const searchParams = useSearchParams();
  const router = useRouter();

  const isSetupComplete = useMemo(() => {
    return entityWorkflowData?.filter(
      (wfd) =>
        wfd.stage === TC_STAGE_STATUS.entry_setup &&
        wfd.status === WORKFLOW_STATUS.completed
    )?.[0]?.status;
  }, [entityWorkflowData]);

  const onSubmit = async (
    values: TransCodeFoundationalDetailsForm,
    actions: any
  ) => {
    if (!dirty) {
      moveToNextStep(values.trnCode);
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    const payload = constructPayload(values);
    if (entityId && action !== TC_ACTIONS.DUPLICATE) {
      response = await updateTransCode({
        formData: payload,
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
        },
        stageName: TC_STAGE_STATUS.foundational_details,
        stageStatus:
          entityId && !entityWorkflowData?.length
            ? ""
            : DASHBOARD_ACCORDION_STATUS.completed,
      });
    } else {
      response = await createTransCode({
        formData: payload,
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Transaction code created.",
        },
        // Don't create workflow when duplicating, it will be always shown as completed
        stageName:
          action !== TC_ACTIONS.DUPLICATE
            ? TC_STAGE_STATUS.foundational_details
            : "",
        stageStatus: DASHBOARD_ACCORDION_STATUS.completed,
      });
    }
    if (response) {
      moveToNextStep(response?.trnCode);
    }
  };

  function moveToNextStep(trnCode = "") {
    if (!canCheckFormStatus) {
      if (
        action !== TC_ACTIONS.DUPLICATE &&
        (isEnvDisabled ||
          isSetupComplete ||
          (entityId && !entityWorkflowData?.length))
      ) {
        navigateToNextPage?.();
      } else {
        updateFormStatus?.(SIDEBAR_STATUS.completed);
        updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
        // reset checkFormStatus
        checkFormStatus?.(false);
        const entityQueryParam = !searchParams?.get(QUERY_PARAM_KEY.ENTITY_ID)
          ? `${ROUTE_PATH.ENTITY_ID_QUERY}${trnCode}`
          : "?";
        const defaultParam = `${entityQueryParam}&${searchParams?.toString()?.replace(`${QUERY_PARAM_KEY.ACTION}=${TC_ACTIONS.DUPLICATE}`, "")}`;
        if (
          saveAndExit?.status &&
          searchParams?.get(QUERY_PARAM_KEY.FLOW_NAME)
        ) {
          continueToConfigFlowFromTc?.(trnCode);
          return;
        }
        if (action === TC_ACTIONS.DUPLICATE) {
          navigateTo?.(`${ROUTE_PATH.TC_TAG_SETUP}${defaultParam}`);
        } else {
          navigateTo?.(`${ROUTE_PATH.TC_INTERSTITAL_SCREEN}${defaultParam}`);
        }
      }
    }
  }

  function constructPayload(values: TransCodeFoundationalDetailsForm) {
    return {
      ...values,
      isDisputable: values?.isDisputable,
      networkIncl: values?.networkIncl || [],
      trnLimits: values?.trnLimits || [],
    };
  }

  // Prepopulating with existing data
  const initialFoundationalValues: TransCodeFoundationalDetailsForm = {
    ...initialTcFoundationalValues,
    ...formData,
    networkIncl: formData?.networkIncl?.length
      ? formData?.networkIncl ?? []
      : [],
    trnLimits: formData?.trnLimits?.length ? formData?.trnLimits ?? [] : [],
    isDisputable: formData?.isDisputable ?? undefined,
  };
  if (action === TC_ACTIONS.DUPLICATE) initialFoundationalValues.trnCode = "";

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        tcFoundationalDetailsOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [tcFoundationalDetailsOptionsData]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    validationSchema,
    initialValues: initialFoundationalValues,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const formTitle = (
    <>
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />
    </>
  );

  const handleOnChange = (key: string) => (value: any) => {
    if (key === "trnLimits") {
      setFieldValue(
        key,
        value.map((data: any) => data.value)
      );
    } else if (key === "networkIncl") {
      setFieldValue(
        key,
        value.map((data: any) => data.value)
      );
    } else {
      setFieldValue(key, value);
    }
  };

  function handleConfigBackRoute() {
    if (searchParams?.get(QUERY_PARAM_KEY.FLOW_NAME)) {
      continueToConfigFlowFromTc?.();
      return;
    }
    navigateTo(ROUTE_BASE_URL["TRANS_CODE"]);
  }

  useEffect(() => {
    if (isGoingBack) {
      handleConfigBackRoute();
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    if (sideBarMainMenuIndex === TC_STAGE_ORDER.foundational_details) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarMainMenuIndex]);

  useEffect(() => {
    setSideBarCurrentIndex?.(TC_STAGE_ORDER.foundational_details, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <>{formTitle}</>
      <Box className="app-form-container">
        <CardContainer>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the transaction code name?"
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("trnCode")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.trnCode}
              disabled={
                action !== TC_ACTIONS.DUPLICATE && formData?.trnCode
                  ? true
                  : false
              }
              onChange={handleOnChange("trnCode")}
              onBlur={handleBlur}
              label="What is the transaction code name?"
              name="trnCode"
            />

            {errors?.trnCode && touched?.trnCode && (
              <ErrorAlert>
                <span>{errors.trnCode}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the transaction description?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("desc")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.desc}
              onChange={handleOnChange("desc")}
              onBlur={handleBlur}
              label="What is the transaction description?"
              name="desc"
            />

            {errors?.desc && touched?.desc && (
              <ErrorAlert>
                <span>{errors.desc}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Select the included networks"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("networkIncl")
                  ?.tooltip_text_main
              }
              labelFor="networkIncl_input"
            />
            <MultiSelectDropdown
              values={defaultNetworkOptions.filter((item) =>
                values.networkIncl.includes(item.value)
              )}
              onChange={handleOnChange("networkIncl")}
              dropdownList={defaultNetworkOptions}
              placeholder="Select"
              keyName="label"
              showCheckbox
              id="networkIncl"
            />
            {errors?.networkIncl && touched?.networkIncl && (
              <ErrorAlert>
                <span>{errors.networkIncl as string}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Select the individual transaction limits"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("trnLimits")
                  ?.tooltip_text_main
              }
            />
            <MultiSelectDropdown
              values={transLimitOptions.filter((item) =>
                values.trnLimits.includes(item.value)
              )}
              onChange={handleOnChange("trnLimits")}
              dropdownList={transLimitOptions}
              placeholder="Select"
              keyName="label"
              showCheckbox
              id="trnLimits"
            />
            {errors?.trnLimits && touched?.trnLimits && (
              <ErrorAlert>
                <span>{errors.trnLimits}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Do you want to make the transaction code eligible for disputes?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isDisputable")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              name="isDisputable"
              onSelect={handleOnChange("isDisputable")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.isDisputable}
            />
            {errors?.isDisputable && touched?.isDisputable && (
              <ErrorAlert>
                <span>{errors.isDisputable}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
}
