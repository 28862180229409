import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { ChatIcon, CloseIcon, StarIcon } from "@chakra-ui/icons";
import { IconButton, Button } from '@chakra-ui/react';
import './ChatWindow.scss'
import { v4 as uuidv4 } from 'uuid'

interface ChatWindowProps {
  messages: string[]
  setMessages: React.Dispatch<React.SetStateAction<string[]>>
  onClose: () => void
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  setMessages,
  onClose,
}) => {
  const [input, setInput] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [ratings, setRatings] = useState<{ [key: number]: number | null }>({})
  const [comments, setComments] = useState<{ [key: number]: string }>({})
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const conversationId = useRef<string>(uuidv4())

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    if (!isTyping) {
      inputRef.current?.focus()
    }
  }, [isTyping])

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, `user:${input}`])
      setInput('')
      setIsTyping(true)

      try {
        const response = await axios.post('/console/chat', {
          prompt: input,
          conversationId: conversationId.current,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        })

        const assistantMessageContent =
          response.data?.response?.choices?.[0]?.message?.content

        let assistantReply = 'I am currently unable to respond.'
        if (assistantMessageContent) {
          assistantReply = assistantMessageContent
        }

        setMessages((prevMessages) => [...prevMessages, `assistant:${assistantReply}:${response.data.chatCmplId || ''}`])
      } catch (error) {
        console.error('Error fetching the assistant reply:', error)
      } finally {
        setIsTyping(false)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isTyping) {
      handleSend()
    }
  }

  const handleRatingSave = async (index: number) => {
    try {
      const chatCmplId = messages[index]?.split(':')[2] // Assuming the chat completion ID is stored in the message suffix

      await axios.post('/console/rating', {
        chatCmplId,
        conversationId: conversationId.current,
        rating: ratings[index],
        comments: comments[index] || '',
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setMessages((prevMessages) => [...prevMessages, 'system:Thank you for your feedback!'])
    } catch (error) {
      console.error('Error saving the rating:', error)
    }
  }

  const formatMessage = (message: string, index: number) => {
    const [sender, text, chatCmplId] = message.split(':', 3)
    const isUserMessage = sender === 'user'
    const isSystemMessage = sender === 'system'
    return (
      <div className={`chatMessage ${isUserMessage ? 'userMessage' : 'assistantMessage'}`}>
        <div className='messageBubble'>
          {text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
        {!isUserMessage && !isSystemMessage && chatCmplId && (
          <div className='ratingSection'>
            <h5>Help us improve our assistant!</h5>
            <div style={{ textAlign: 'center' }}>How helpful was this response?</div>
            <div className='stars'>
              {[1, 2, 3, 4, 5].map((star) => (
                <IconButton
                  aria-label={`Rating ${star}`}
                  className={`star ${ratings[index] != null && ratings[index]! >= star ? 'selected' : ''}`}
                  icon={<StarIcon />}
                  key={star}
                  onClick={() => setRatings((prevRatings) => ({ ...prevRatings, [index]: star }))}
                />
              ))}
            </div>
            <div>Any comments you would like to add?</div>
            <textarea
              className='comments'
              onChange={(e) => setComments((prevComments) => ({ ...prevComments, [index]: e.target.value }))}
              placeholder='Enter comments...'
              rows={4}
              value={comments[index] || ''}>
            </textarea>
            <div className='buttons'>
              <Button 
                onClick={() => { 
                  setRatings((prevRatings) => ({ ...prevRatings, [index]: null })); 
                  setComments((prevComments) => ({ ...prevComments, [index]: '' })) 
                }} 
                variant='outline'
                size='lg'
              >
                Cancel
              </Button>
              <Button 
                isDisabled={ratings[index] == null} // Disable the button if no rating is selected
                onClick={() => handleRatingSave(index)} 
                variant='solid'
                size='lg'
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='chatWindow'>
      <div className='chatHeader'>
        <h5>Help Assistant</h5>
        <IconButton
          isRound={true}
          variant='ghost'
          aria-label='Close'
          icon={<CloseIcon />}
          onClick={onClose}
          className='closeIcon'
        />
      </div>
      <div className='chatBody'>
        {messages.map((message, index) => (
          <div className='chatMessage' key={index}>
            {formatMessage(message, index)}
          </div>
        ))}

        {isTyping && (
          <div className={`chatMessage typingIndicator`}>
            <span className="typingDot">•</span>
            <span className="typingDot">•</span>
            <span className="typingDot">•</span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className='chatFooter'>
        <input
          className='inputField'
          disabled={isTyping}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder='Ask a question...'
          ref={inputRef}
          type='text'
          value={input}
        />
        <IconButton
          className='sendButton'
          disabled={isTyping}
          onClick={handleSend}
          variant='ghost'
          icon={<ChatIcon />}
          aria-label='Send'
        />
      </div>
    </div>
  )
}

export default ChatWindow
