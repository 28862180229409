"use client";

import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { Box, Flex, Heading, useToast } from "@/components/ChakraUiManager";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  DurationInput,
  ErrorAlert,
  InputText,
  SplitButton,
  StaticText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { PRODUCT_STATUS } from "@/components/data/form-data";
import {
  LoanDelinquencyType,
  PostSchema,
} from "@/components/product-management/model/types";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  DASHBOARD_ACCORDION_STATUS,
  MAIN_FORM_ID,
  deepCopy,
  generateYupSchema,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import {
  createLoanDeliquency,
  deleteLoanDeliquency,
  updateLoanDeliquency,
} from "../../product-config-client-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

export type LoanDelinquencyProps = {
  data: {
    formData: LoanDelinquencyType;
    loanDelinquencyOptionsData: PostSchema;
  };
};

async function getLoanDelinquencySchema(optionsSchema: PostSchema) {
  const fields = [
    "nonAccr",
    "pastDueNotices",
    "pastDueRestrictDays",
    "pastDueTerms",
    "name",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
}

const initialPastDueTermVal = {
  term: "",
};

const initialPastDueNoticeVal = {
  noticeName: "",
  pastDueDays: 0,
};

const dueNumberTypeKeyList = [
  "pastDueDays",
  "nonAccr.minDue",
  "pastDueRestrictDays",
];

export default function LoanDelinquency(props: LoanDelinquencyProps) {
  const { formData, loanDelinquencyOptionsData } = props.data;

  const toast = useToast();

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
    navigateWithProductNameParam,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const [validationSchema, setValidationSchema] = useState<any>(null);

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateWithProductNameParam(
        `${ROUTE_PATH["PRODUCT_CONFIG_INTERSTITIAL_SCREEN"]}`
      );
    }
    return;
  };

  const onSubmit = async (values: LoanDelinquencyType, actions: any) => {
    const existingFormData = deepCopy(formData);
    const loanDelinquencyFormData = deepCopy(values);
    const needLoanDelinquency = loanDelinquencyFormData.needLoanDelinquency;
    delete loanDelinquencyFormData.needLoanDelinquency;
    if (!dirty) {
      moveToNextStep();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.loanDelinquency,
          status: PRODUCT_STATUS.completed,
        });
      return;
    }
    let response;
    if (needLoanDelinquency) {
      if (existingFormData?.name) {
        response = await updateLoanDeliquency(
          productDetails?.name!,
          {
            _vn: (existingFormData as any)._vn,
            ...loanDelinquencyFormData,
          } as any,
          {
            toastOptions: {
              toast,
              toastId: "loan-delinquency",
              successMessage: `${
                tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
                "Loan delinquency"
              } updated`,
            },
          } as any
        );
      } else {
        response = await createLoanDeliquency(
          {
            ...loanDelinquencyFormData,
          },
          {
            toastOptions: {
              toast,
              toastId: "loan-delinquency",
              successMessage: `${
                tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
                "Loan delinquency"
              } created`,
            },
          }
        );
      }
    } else if (existingFormData?.name && needLoanDelinquency === false) {
      //deleting delinquency if user opts out
      response = await deleteLoanDeliquency(productDetails?.name!);
    }
    if (response || needLoanDelinquency === false) {
      if (productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name!,
          stage: ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.loanDelinquency,
          status: PRODUCT_STATUS.completed,
        });
      }
      // reset checkFormStatus
      checkFormStatus?.(false);
      moveToNextStep();
    }
  };
  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      nonAccr: {
        cutoffDur: formData?.nonAccr?.cutoffDur || "",
        isAutoTrigger: formData?.nonAccr?.isAutoTrigger,
        isPaidToPrin: formData?.nonAccr?.isPaidToPrin,
        isReceivable: formData?.nonAccr?.isReceivable,
        minDue: formData?.nonAccr?.minDue || 0,
      },
      pastDueNotices: formData?.pastDueNotices?.length
        ? formData.pastDueNotices
        : [],
      pastDueRestrictDays: formData?.pastDueRestrictDays || 0,
      pastDueTerms: formData?.pastDueTerms?.length ? formData.pastDueTerms : [],
      name: productDetails?.name ?? "",
      needLoanDelinquency: formData?.name
        ? true
        : productDetails?.status === DASHBOARD_ACCORDION_STATUS.completed
          ? false
          : undefined,
    },
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
    dirty,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const handleOnChange = (key: string) => (value: any) => {
    if (dueNumberTypeKeyList.includes(key)) {
      value = Number(value);
    }
    setFieldValue(key, value);
  };

  const handleFormArrChange =
    (mainKey: string, key: string, index: number) => (value: any) => {
      if (dueNumberTypeKeyList.includes(key)) {
        value = Number(value);
      }
      setFieldValue(`${mainKey}.${index}.${key}`, value);
    };

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getLoanDelinquencySchema(
        loanDelinquencyOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [loanDelinquencyOptionsData]);

  // we are using useffect for left side navigation
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus]);

  return (
    <FormikProvider value={formikConfigs}>
      <form
        onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
        id={MAIN_FORM_ID}
      >
        <StaticText
          heading
          headingName={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
            "Loan delinquency"
          }
          pb={"9.6"}
          pt={"9.6"}
        />
        <Box className="app-form-container">
          <CardContainer>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Do you want to add loan delinquency details?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("needLoanDelinquency")?.tooltip_text_main
                }
              />
              <SplitButton
                name="needLoanDelinquency"
                onSelect={handleOnChange("needLoanDelinquency")}
                leftBtnName="Yes"
                rightBtnName="No"
                value={values?.needLoanDelinquency!}
              />
            </Box>
          </CardContainer>
          {values.needLoanDelinquency ? (
            <>
              <CardContainer>
                <AccordionComponent
                  accordionTitle={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                      ?.section_title || `Non-accrual attributes`
                  }
                  isExpand={0}
                >
                  <Box className="app-form-field-container">
                    <DurationInput
                      labelName={
                        "What is the cutoff duration for a position to enter non-accrual status?"
                      }
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("cutoffDur")?.tooltip_text_main
                      }
                      value={values.nonAccr?.cutoffDur!}
                      onChange={handleOnChange("nonAccr.cutoffDur")}
                      modalLinkName={"Enter code manually"}
                      modalTitle={"Enter cutoff duration"}
                      modalLabelName={"What is the cutoff duration?"}
                      modalLabelTooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("cutoffDur")?.tooltip_text_main
                      }
                    />
                    {errors.nonAccr?.cutoffDur &&
                      touched.nonAccr?.cutoffDur && (
                        <ErrorAlert>
                          <span>{errors.nonAccr?.cutoffDur as string}</span>
                        </ErrorAlert>
                      )}
                  </Box>
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName="What is the minimum past due amount for a position to enter non-accrual status?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("minDue")?.tooltip_text_main
                      }
                      labelFor="nonAccr.minDue"
                    />
                    <AppNumberInput
                      label="minDueNonAccural"
                      value={values.nonAccr.minDue!}
                      onChange={handleOnChange("nonAccr.minDue")}
                      onBlur={handleBlur}
                      placeholder="Enter"
                    />
                  </Box>
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName="How many days past due before debits are restricted?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("pastDueRestrictDays")?.tooltip_text_main
                      }
                      labelFor="pastDueRestrictDays"
                    />
                    <AppNumberInput
                      label="Transaction sequence"
                      value={values.pastDueRestrictDays}
                      onChange={handleOnChange("pastDueRestrictDays")}
                      onBlur={handleBlur}
                      placeholder="Enter"
                    />
                  </Box>
                  <Heading as="h6" className="app-heading-box">
                    Enable any of the following
                  </Heading>
                  <Flex
                    justify={"space-between"}
                    className="app-form-field-container app-heading-box-contents"
                  >
                    <AppFormLabel
                      labelName="Do you want to automatically trigger the non-accrual event based on
              the cutoff configuration?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isAutoTrigger")?.tooltip_text_main
                      }
                    />
                    <SplitButton
                      name="nonAccr.isAutoTrigger"
                      onSelect={handleOnChange("nonAccr.isAutoTrigger")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.nonAccr.isAutoTrigger as boolean}
                    />
                  </Flex>
                  <Flex
                    justify={"space-between"}
                    className="app-form-field-container app-heading-box-contents"
                  >
                    <AppFormLabel
                      labelName="Do you want payments made to interest and other charges to be
              applied to principal until the book balance is paid off?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isPaidToPrin")?.tooltip_text_main
                      }
                    />
                    <SplitButton
                      name="nonAccr.isPaidToPrin"
                      onSelect={handleOnChange("nonAccr.isPaidToPrin")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.nonAccr.isPaidToPrin as boolean}
                    />
                  </Flex>
                  <Flex
                    justify={"space-between"}
                    className="app-form-field-container app-heading-box-contents last-item"
                  >
                    <AppFormLabel
                      labelName="Allow system to generate receivables in non-accrual?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isReceivable")?.tooltip_text_main
                      }
                    />
                    <SplitButton
                      name="nonAccr.isReceivable"
                      onSelect={handleOnChange("nonAccr.isReceivable")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.nonAccr.isReceivable as boolean}
                    />
                  </Flex>
                </AccordionComponent>
              </CardContainer>

              <FieldArray
                name="pastDueTerms"
                render={({ insert, remove, push }) => (
                  <>
                    {values?.pastDueTerms?.length
                      ? values?.pastDueTerms?.map((pastDueTermItem, index) => (
                          <CardContainer key={`pastDueTermCard${index}`}>
                            <AccordionComponent
                              key={`pastDueTermAccordion${index}`}
                              accordionTitle={`Past-due term ${index + 1}`}
                              deleteIcon
                              onDeleteHandler={() => remove(index)}
                              isExpand={0}
                            >
                              <Box className="app-form-field-container">
                                <DurationInput
                                  labelName={"What is the past due term?"}
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get("term")
                                      ?.tooltip_text_main
                                  }
                                  value={pastDueTermItem.term || ""}
                                  onChange={handleFormArrChange(
                                    "pastDueTerms",
                                    "term",
                                    index
                                  )}
                                  modalLinkName={"Enter code manually"}
                                  modalTitle={"Enter due term duration"}
                                  modalLabelName={"What is the past due term?"}
                                  modalLabelTooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get("term")
                                      ?.tooltip_text_main
                                  }
                                />
                                {(errors?.pastDueTerms?.[index] as any)?.term &&
                                  touched?.pastDueTerms?.[index]?.term && (
                                    <ErrorAlert>
                                      <span>
                                        {
                                          (errors?.pastDueTerms?.[index] as any)
                                            ?.term
                                        }
                                      </span>
                                    </ErrorAlert>
                                  )}
                              </Box>
                            </AccordionComponent>
                          </CardContainer>
                        ))
                      : ""}
                    <Box className="add-limit-add-new-button">
                      <AddNewButton
                        label={
                          values?.pastDueTerms?.length
                            ? "Add another past-due term"
                            : "Add past-due term"
                        }
                        onClick={() => {
                          push({
                            ...initialPastDueTermVal,
                          });
                        }}
                      />
                    </Box>
                  </>
                )}
              ></FieldArray>

              <FieldArray
                name="pastDueNotices"
                render={({ insert, remove, push }) => (
                  <>
                    {values?.pastDueNotices?.length
                      ? values?.pastDueNotices?.map((notice, index) => (
                          <CardContainer key={`pastDueNoticeCard${index}`}>
                            <AccordionComponent
                              key={`pastDueNoticeAccordion${index}`}
                              accordionTitle={`Past-due notice ${index + 1}`}
                              deleteIcon
                              onDeleteHandler={() => remove(index)}
                              isExpand={0}
                            >
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="What is the notice name?"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get("noticeName")
                                      ?.tooltip_text_main
                                  }
                                />
                                <InputText
                                  value={notice.noticeName}
                                  onChange={handleFormArrChange(
                                    "pastDueNotices",
                                    "noticeName",
                                    index
                                  )}
                                  onBlur={handleBlur}
                                  label="What is the notice name?"
                                  name="noticeName"
                                  placeholder="Enter"
                                />
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="How many days past due does the notice have to be sent?"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get("pastDueDays")
                                      ?.tooltip_text_main
                                  }
                                  labelFor="pastDueRestrictDays"
                                />
                                <AppNumberInput
                                  label="due notice"
                                  value={notice.pastDueDays || 0}
                                  onChange={handleFormArrChange(
                                    "pastDueNotices",
                                    "pastDueDays",
                                    index
                                  )}
                                  onBlur={handleBlur}
                                  placeholder="Enter"
                                />
                              </Box>
                            </AccordionComponent>
                          </CardContainer>
                        ))
                      : ""}

                    <Box className="add-limit-add-new-button">
                      <AddNewButton
                        label={
                          values.pastDueNotices?.length
                            ? "Add another past-due notice"
                            : "Add past-due notice"
                        }
                        onClick={() => {
                          push({
                            ...initialPastDueNoticeVal,
                          });
                        }}
                      />
                    </Box>
                  </>
                )}
              ></FieldArray>
            </>
          ) : (
            ""
          )}
        </Box>
      </form>
    </FormikProvider>
  );
}
