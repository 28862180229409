"use client";
import { documentCenterBannerImage } from "public/assets";
import { Box, Text, Button, Flex } from "@/components/ChakraUiManager";
import Image from "next/image";
import { PrismicRichText } from "@prismicio/react";
import "./DocumentCenterBanner.scss";
import { Content } from "@prismicio/client";

function DocumentCenterBanner(props: {
  name: string;
  docCenterHero: Content.DocCenterConfigDocumentDataDocCenterHeroItem[];
  isUserAuthenticated: boolean;
}) {
  const bannerData = props.docCenterHero[0];
  const heading = bannerData?.headline || "Welcome to Docs";

  return (
    <Flex>
      <Box className="doc-hero-banner-slider-container">
        <Box className="banner-background"></Box>
        <Box className="banner-text">
          <Text as="h2">
            {heading}
            {props.name ? `, ${props.name}` : ""}
          </Text>
          <Text as="p" className="banner-text-desc">
            <PrismicRichText field={bannerData?.description} />
          </Text>
          {!props.isUserAuthenticated && (
            <Box className="banner-buttons">
              <Button
                className="app-btn-solid-white"
                onClick={() => {
                  window.location.href = `/profile-setup`;
                }}
              >
                Enroll now
              </Button>
              <Button
                className="app-btn-inverse-secondary"
                onClick={() => {
                  window.location.href = `/console/login?returnPage=${window.location.pathname}`;
                }}
              >
                Log in
              </Button>
            </Box>
          )}
        </Box>
        <Box className="banner-image">
          <Image
            src={documentCenterBannerImage}
            alt="graphic representing documents"
          />
        </Box>
      </Box>
    </Flex>
  );
}
export default DocumentCenterBanner;
