import React, { useState } from "react";
import ChatWindow from "./ChatWindow";
import { Button, Image } from "../ChakraUiManager";
import { vector, vectorSelected } from "public/assets";
import { useUserInfo } from "@/app/providers";
import { useSelector } from "react-redux";
import { getOrganizations } from "@/store";

const ChatIcon: React.FC = () => {
  const { user } = useUserInfo();
  const allOrgs = useSelector(getOrganizations);
  const isInternalUser =
    user.signedIn &&
    user.organization &&
    allOrgs[user.organization]?.category === "Internal";
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  if (!isInternalUser) {
    return null;
  }

  return (
    <>
      <Button
        id="icon"
        aria-label="Search"
        variant="ghost"
        onClick={toggleChat}
        className="nav-search-icon"
      >
        <Image
          src={!isChatOpen ? vector.src : vectorSelected.src}
          className="icon-right"
          alt="Right arrow"
        />
      </Button>

      {isChatOpen && (
        <ChatWindow
          messages={messages}
          onClose={toggleChat}
          setMessages={setMessages}
        />
      )}
    </>
  );
};

export default ChatIcon;
