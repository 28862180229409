"use client";

import { DefaultFormDetails } from "@/components/institutional-configuration/model";
import { TransCodeEntries, TransCodeResponse } from "../../models";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  ModalSelector,
  MultiSelectDropdown,
} from "@/components/common";
import { useContext, useEffect, useMemo, useState } from "react";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_STATUS,
  WORKFLOW_STATUS,
  deepCopy,
  generateYupSchema,
} from "@/utils";
import { useFormik } from "formik";
import {
  getEntryStageStatus,
  updateTransCode,
} from "../../transaction-code-api-service";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, useToast } from "@chakra-ui/react";

import {
  IMMUTABLE_LIST_FIELDS,
  REQD_BASE_LIST_FIELDS,
  getSchemaPointers,
} from "./FieldSpecUtils";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";

interface TcFieldSpecificationsProp extends DefaultFormDetails {
  data: {
    formData: TransCodeResponse | null;
    trnCodeSchema: PostSchema;
  };
}

interface TcFieldSpecificationsFormProp {
  trnCode?: string;
  reqdList: string[];
  immutableList: string[];
}
const initialFormValues: TcFieldSpecificationsFormProp = {
  reqdList: [],
  immutableList: [],
};

const FieldSpecifications = (props: TcFieldSpecificationsProp) => {
  const {
    depositName,
    featureName,
    version,
    mainTitle,
    subText,
    data: { formData, trnCodeSchema },
  } = props;
  const trnCodeEntriesSchema = trnCodeSchema.definitions.TrnCodeEntries;
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    getQueryParam,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    entityWorkflowData,
    sideBarSubMenuIndex,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const toastId = "tc-field-specifications";

  const currEntriesIndex: number =
    Number(getQueryParam?.(QUERY_PARAM_KEY.TC_ENTRY_INDEX)) || 0;
  const entrySetupStatus = entityWorkflowData?.filter(
    (wfd) =>
      wfd.stage === TC_STAGE_STATUS.entry_setup &&
      wfd.status === WORKFLOW_STATUS.completed
  )?.[0]?.status;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateToNextPage?.();
    }
  };

  const onSubmit = async (
    values: TcFieldSpecificationsFormProp,
    actions: any
  ) => {
    const existingFormData = deepCopy(formData);
    if (!dirty) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_field_specification,
                entityWorkflowData
              ),
      });
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };

    let constructedVal = {
      ...values,
      trnCode: existingFormData?.trnCode,
    };

    if (existingFormData?.entries?.length) {
      existingFormData.entries[currEntriesIndex].reqdList = values.reqdList;
      existingFormData.entries[currEntriesIndex].immutableList =
        values.immutableList;
    } else {
      if (existingFormData)
        existingFormData.entries = [{ ...constructedVal } as TransCodeEntries];
    }
    const response = await updateTransCode({
      formData: {
        ...existingFormData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : entrySetupStatus
            ? entrySetupStatus
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_field_specification,
                entityWorkflowData
              ),
    });

    if (response) {
      moveToNextStep();
    }
  };

  const handleOnChange = (key: string) => (values: any[]) => {
    setFieldValue(key, values);
  };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    if (
      sideBarSubMenuIndex ===
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_field_specification
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  const savedCurrEntries = formData?.entries?.[currEntriesIndex as any];
  let constructedFormValues: TcFieldSpecificationsFormProp = {
    trnCode: savedCurrEntries?.trnCode,
    ...initialFormValues,
  };

  if (savedCurrEntries?.trnCode) {
    constructedFormValues = {
      ...savedCurrEntries,
      immutableList: savedCurrEntries.immutableList ?? [],
      reqdList: savedCurrEntries.reqdList ?? [],
    };
  }

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const fields = ["entries"];
      const yupSchema = await generateYupSchema(fields, trnCodeSchema);
      setValidationSchema(yupSchema.entries.innerType);
    })();
  }, [trnCodeSchema]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    initialValues: constructedFormValues,
    validationSchema,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    dirty,
    errors,
    touched,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const formTitle = (
    <>
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />
    </>
  );
  //Logic from legacy to derive options list.
  const immutableOptions = useMemo(() => {
    return IMMUTABLE_LIST_FIELDS.map((itm) => {
      const { title } = trnCodeEntriesSchema.properties?.[itm] ?? {};
      return {
        label: title ? `${itm} - ${title}` : itm,
        value: itm,
      };
    });
  }, [trnCodeEntriesSchema.properties]);

  const [reqFieldOpts, setReqFieldOpts] = useState<any[]>([]);
  useEffect(() => {
    if (!reqFieldOptions.length) {
      const options: { label: string; value: string }[] = [];
      for (const itm of REQD_BASE_LIST_FIELDS.sort()) {
        options.push({
          label: trnCodeEntriesSchema.properties?.[itm]?.title
            ? `entry.${itm} - ${trnCodeEntriesSchema.properties[itm].title}`
            : `entry.${itm}`,
          value: itm,
        });
      }
      /**
       * Savana uses required listing to drive which fields are required by the UI.  They need an exhaustive list
       * of all fields on the trn.  That is what code provides.  See CORE-5236.
       */
      getSchemaPointers(
        trnCodeSchema,
        trnCodeSchema,
        true,
        "trn",
        ".",
        true
      ).then((ptrs: any) => {
        for (let i = 0; i < ptrs.length; i++) {
          options.push({
            label: ptrs[i],
            value: ptrs[i],
          });
        }
        /**
         * Savana is also using listing of invalid fields in their configuration.  For now we have to provide also,
         * but hope to be able to remove in the future.
         */
        options.push({
          label: "trn.order.originator.addrs.city",
          value: "trn.order.originator.addrs.city",
        });
        options.push({
          label: "trn.order.originator.addrs.cntry",
          value: "trn.order.originator.addrs.cntry",
        });
        options.push({
          label: "trn.order.originator.addrs.postCode",
          value: "trn.order.originator.addrs.postCode",
        });
        options.push({
          label: "trn.order.originator.addrs.region",
          value: "trn.order.originator.addrs.region",
        });
        options.push({
          label: "trn.order.originator.addrs.street",
          value: "trn.order.originator.addrs.street",
        });

        options.push({
          label: "trn.order.counterParty.addrs.city",
          value: "trn.order.counterParty.addrs.city",
        });
        options.push({
          label: "trn.order.counterParty.addrs.cntry",
          value: "trn.order.counterParty.addrs.cntry",
        });
        options.push({
          label: "trn.order.counterParty.addrs.postCode",
          value: "trn.order.counterParty.addrs.postCode",
        });
        options.push({
          label: "trn.order.counterParty.addrs.region",
          value: "trn.order.counterParty.addrs.region",
        });
        options.push({
          label: "trn.order.counterParty.addrs.street",
          value: "trn.order.counterParty.addrs.street",
        });

        options.sort((a, b) => {
          return a.label.localeCompare(b.label);
        });

        setReqFieldOpts([...reqFieldOptions, ...options]);
      });
    }
  }, []);

  const reqFieldOptions = useMemo(() => {
    return reqFieldOpts ?? [];
  }, [reqFieldOpts]);

  return (
    <>
      <form onSubmit={handleSubmit} noValidate id="finxact-form">
        {formTitle}
        <CardContainer customClass="app-form-container">
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Do you want to add any immutable fields? Choose all that apply"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("immutableList")
                  ?.tooltip_text_main
              }
            />
            <ModalSelector
              linkName={"+ Select immutable field(s)"}
              modalTitle={"Add an immutable field"}
              optionsTitle={"Immutable Field"}
              optionList={immutableOptions}
              isSingleSelect={false}
              onChange={handleOnChange("immutableList")}
              value={values.immutableList}
            />
            {errors.immutableList && touched.immutableList && (
              <ErrorAlert>
                <span>{errors.immutableList}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Do you want to add any required fields? Choose all that apply."
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("reqdList")
                  ?.tooltip_text_main
              }
            />
            <ModalSelector
              linkName={"+ Select required field(s)"}
              modalTitle={"Add an required field"}
              optionsTitle={"Span Expression Rule"}
              optionList={reqFieldOptions}
              isSingleSelect={false}
              onChange={handleOnChange("reqdList")}
              value={values.reqdList}
            />
            {errors.reqdList && touched.reqdList && (
              <ErrorAlert>
                <span>{errors.reqdList}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
      </form>
    </>
  );
};

export default FieldSpecifications;
