import React, { useState } from "react";
import { Box, Checkbox, Grid, Text } from "@chakra-ui/react";
import ProjEnvTable from "./ProjEnvTable";
import {
  OrgEnvDropdownMenuHeaderItem,
  OrgEnvDropdownMenuItemBase,
  OrgEnvDropdownMenuItemOrg,
} from "./Models";
import OrgEnvProjSearchBar from "./OrgEnvProjSearchBar";
import "./OrganizationTable.scss";
import OrgEnvProjTableHeader from "./OrgEnvProjTableHeader";
import OrgEnvProjTableItem from "./OrgEnvProjTableItem";

interface OrganizationTableProps {
  menuItems: OrgEnvDropdownMenuHeaderItem[];
  selectedOrganization: OrgEnvDropdownMenuItemOrg | null;
  selectedEnvironment: OrgEnvDropdownMenuItemBase | null;
  onHeaderExpansion: (
    item: OrgEnvDropdownMenuHeaderItem,
    index: number
  ) => void;
  onOrganizationSelect: (org: OrgEnvDropdownMenuItemOrg) => void;
  onEnvProjSelect: (index: number, isProject: boolean) => void;
  onFavourite: (orgId: number) => void;
  groupingEnabled: boolean | undefined;
  setGroupingEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const OrganizationTable: React.FC<OrganizationTableProps> = ({
  menuItems,
  onHeaderExpansion,
  onOrganizationSelect,
  selectedOrganization,
  selectedEnvironment,
  onEnvProjSelect,
  onFavourite,
  groupingEnabled,
  setGroupingEnabled
}) => {
  const [searchOrgQuery, setOrgSearchQuery] = useState("");
  const [searchProjEnvQuery, setProjEnvSearchQuery] = useState("");

  const searchedElements = (item: OrgEnvDropdownMenuHeaderItem) =>
    item.subItems.filter((subItem) =>
      subItem.name
        .toLocaleLowerCase()
        .includes(searchOrgQuery.toLocaleLowerCase())
    );

  return (
    <Box
      className={`org-table-main-container ${selectedOrganization ? "enable-right-column" : ""}`}
    >
      <Grid className="grid-columns">
        <Box className="grid-column-left-container">
          <Box className="column-left-with-out-divider">
            <Text className="org-env-title-org">Orgs</Text>
            <OrgEnvProjSearchBar onSearch={setOrgSearchQuery} />
            <Box className="box-items">
              {selectedOrganization && (
                <OrgEnvProjTableItem
                  data={selectedOrganization}
                  onSelect={() => {}}
                  key={selectedOrganization.name}
                  shouldShowFavourite={true}
                  onFavourite={onFavourite}
                  isFirstSelectedItem={true}
                  itemType="org"
                />
              )}
              {menuItems.map((item, index) => (
                <Box key={item.id} className="item">
                  <OrgEnvProjTableHeader
                    title={item.name}
                    isExpanded={item.isExpanded}
                    onExpand={() => onHeaderExpansion(item, index)}
                    shouldShowBorder={true}
                    shouldShowExpandIcon={true}
                    alwaysExpanded={item.alwaysExpanded}
                  />
                  {searchedElements.length > 0
                    ? item.isExpanded &&
                      searchedElements(item).map((subItem, locIndex) => (
                        <OrgEnvProjTableItem
                          data={subItem}
                          onSelect={() => onOrganizationSelect(subItem)}
                          key={subItem.name}
                          shouldShowFavourite={true}
                          onFavourite={onFavourite}
                          isFirstSelectedItem={false}
                          itemType="org"
                        />
                      ))
                    : item.isExpanded && (
                        <Text className="no-element-text">
                          No organizations found.
                        </Text>
                      )}
                </Box>
              ))}
            </Box>
            <Checkbox
              spacing={3}
              isChecked={groupingEnabled}
              aria-label={"Grouping enabled"}
              className="grouping-checkbox"
              onChange={(checked) => setGroupingEnabled(checked.target.checked)}
            >
              Grouping Enabled
            </Checkbox>
          </Box>
        </Box>
        {selectedOrganization && (
          <Box>
            <Text className="org-env-title-env">Environment</Text>
            <Text className="org-env-sub-title-env">
              Available below based on Org selected
            </Text>
            <OrgEnvProjSearchBar onSearch={setProjEnvSearchQuery} />
            <ProjEnvTable
              selectedOrganization={selectedOrganization}
              selectedProjOrEnv={selectedEnvironment}
              onEnvProjSelect={onEnvProjSelect}
              searchedQuery={searchProjEnvQuery}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default OrganizationTable;
