"use client";

import {
  Box,
  Checkbox,
  Divider,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VisuallyHidden,
} from "@chakra-ui/react";
import "./DetailCard.scss";
import {
  ALM_ENROLLMENT_STATE,
  getBadgeByState,
} from "../../learning-util-service";
import { Badges } from "@/components/common";
import { downloadIcon, shareIcon } from "public/assets";
import Link from "next/link";
import React, { ReactNode, useState } from "react";
import { ROUTE_PATH } from "@/route-config/route-path";
import { StyleTwoTone } from "../../my-learning-profile/course-profile/course-profile-box/icons/StyleTwoTone/StyleTwoTone";
import { usePathname, useSearchParams } from "next/navigation";
import { safelyFetchDomain } from "@/utils";

type DetailCardProps = {
  id: string;
  imageUrl: string;
  title: string;
  subtitle: string;
  status: string;
  format?: string;
  date: string;
  type: string;
  onSelect?: (id: string) => void;
  onDownload?: (id: string) => void;
};

export default function DetailCard({
  id,
  imageUrl,
  title,
  subtitle,
  status,
  format,
  date,
  type,
  onSelect,
  onDownload,
}: DetailCardProps) {
  const [isItemActive, setItemActive] = useState<boolean>(false);
  const routePathName = usePathname();
  const routeSearchParams = useSearchParams()
  const domain = safelyFetchDomain()
  
  const getStatusTags = () => {
    switch (status) {
      case ALM_ENROLLMENT_STATE.COMPLETED:
        return (
          <>
            <Badges
              size="lg"
              {...getBadgeByState({
                status,
                label: "ACHIEVED",
                showState: true,
              })}
            ></Badges>
            <Badges
              size="lg"
              {...getBadgeByState({
                status: "NOT STARTED",
                label: `EARNED ON ${date}`,
                showState: true,
              })}
            ></Badges>
          </>
        );
      case ALM_ENROLLMENT_STATE.STARTED:
        return (
          <>
            <Badges
              size="lg"
              {...getBadgeByState({
                status,
                label: "IN PROGRESS",
                showState: true,
              })}
            ></Badges>
            <Badges
              size="lg"
              {...getBadgeByState({
                status: "NOT STARTED",
                label: `LAST VISITED ON ${date}`,
                showState: true,
              })}
            ></Badges>
          </>
        );
      default:
        return (
          <Box>
            <Badges
              size="lg"
              {...getBadgeByState({
                status: "NOT STARTED",
                label: format,
                showState: true,
              })}
            ></Badges>
          </Box>
        );
    }
  };

  const toggleActiveState = () => {
    setItemActive((prev) => !prev);
  };

  const shareItem = () => {
    window.open(`https://${domain}${routePathName}/share?id=${id}&${routeSearchParams?.toString()}`);
  };

  const checkItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemActive(e.target.checked);
    onSelect && onSelect(id);
  };

  return (
    <HStack className="main-holder">
      {status === ALM_ENROLLMENT_STATE.COMPLETED && onSelect && (
        <Checkbox
          spacing={10}
          value={id}
          aria-label={title}
          className="detail-checkbox"
          onChange={checkItem}
        >
          <VisuallyHidden>{title}</VisuallyHidden>
        </Checkbox>
      )}
      <Box className={`detail-item-container ${isItemActive ? "active" : ""}`}>
        <HStack className="detail-item-data">
          <Box className="detail-item-image">
            <Image src={imageUrl} alt={`${title} logo`} />
          </Box>

          <Box className="detail-item-content-holder">
            <Text as="h4" className="detail-item-title">
              {type === "badges" ? (
                <Link
                  href={`${ROUTE_PATH.ACADEMY_CATALOG_BADGE_PATH}?id=${id}`}
                >
                  {title}
                </Link>
              ) : (
                <Link
                  href={`${ROUTE_PATH.ACADEMY_BADGES_CERTIFICATION}/certification-path?id=${id}`}
                >
                  {title}
                </Link>
              )}
            </Text>
            <Text as="p" className="detail-item-subtitle">
              {subtitle}
            </Text>
          </Box>
        </HStack>
        <Divider className="detail-item-divider" />
        <HStack className="detail-item-tag-container">
          <Box className="detail-item-tag">{getStatusTags()}</Box>
          {status === ALM_ENROLLMENT_STATE.COMPLETED && (
            <Box>
              <Menu onOpen={toggleActiveState} onClose={toggleActiveState}>
                <MenuButton
                  className="ellipsis-button"
                  borderWidth={0}
                  as={IconButton}
                  aria-label="Options"
                  icon={<StyleTwoTone className="ellipsis-icon" />}
                  variant="outline"
                ></MenuButton>
                <MenuList className="menu-list-container">
                  {onDownload && (
                    <MenuItem
                      className="dropdown-menu-item"
                      onClick={() => onDownload(id)}
                    >
                      <Image
                        src={downloadIcon.src}
                        alt="Download"
                        className="menu-image"
                      />
                      <span className="menu-text">Download Badge</span>
                    </MenuItem>
                  )}
                  <MenuItem className="dropdown-menu-item" onClick={shareItem}>
                    <Image
                      src={shareIcon.src}
                      alt="Share"
                      className="menu-image"
                    />
                    <span className="menu-text">Share</span>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )}
        </HStack>
      </Box>
    </HStack>
  );
}
