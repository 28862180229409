"use client";

import {
  AppFormLabel,
  AppSingleSelectDropdown,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";
import "./FoundationalDetails.scss";
import { useFormik } from "formik";
import {
  FoundationDetailsProps,
  getValidationSchema,
} from "./FoundationalDetailsValidation";
import { Box, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import FileUploader from "@/components/common/file-uploader/FileUploader";
import {
  IC_ENTITY,
  NEW_IC_ENTITY_ID,
  Option,
  SIDEBAR_STATUS,
  useEntityId,
  useQueryParams,
} from "@/utils";
import { FinancialInstitutionData } from "../../model";
import {
  createBankParameters,
  updateBankParameters,
} from "@/components/institutional-configuration/ic-config-api-service";
import { ROUTE_PATH } from "@/route-config/route-path";
import { checkIfICWorflowNeedsToBeCreated } from "../../ic-utils-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    demographicInfoOptions: Option<string>[];
    institutionTimezoneOptions: Option<string>[];
    institutionalCalendarOptions: Option<string>[];
    defaultNetworkOptions: string[];
    formData: FinancialInstitutionData | null;
    bankParamOptionsData: PostSchema;
  };
};

const FoundationalDetails = (props: Props) => {
  const {
    demographicInfoOptions,
    institutionTimezoneOptions,
    institutionalCalendarOptions,
    defaultNetworkOptions,
    formData,
    bankParamOptionsData,
  } = props.data;
  const toast = useToast();
  const updateQueryParams = useQueryParams();
  const entityId = useEntityId();

  const onSubmit = async (values: FoundationDetailsProps, actions: any) => {
    if (!dirty) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.bank_parameters
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.bank_parameters,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "bank-params-foundation",
    };
    if (!formData) {
      response = await createBankParameters({
        formData: {
          ...props.data?.formData,
          _Id: values._Id,
          calendar: values.calendar !== "" ? `${values.calendar}` : null,
          chkBottom: values.chkBottom,
          dfltNetwork: values.dfltNetwork,
          isReqAcctBankTmZone: values.isReqAcctBankTmZone,
          tmZoneCode: values.tmZoneCode,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Financial institution (bank) parameters created.",
        },

        stageName: getCurrentStageStatusName(),
      });
    } else {
      response = await updateBankParameters({
        formData: {
          _vn: formData._vn,
          _Id: values._Id,
          // PATCH Fields
          calendar: values.calendar !== "" ? `${values.calendar}` : null,
          chkBottom: values.chkBottom,
          dfltNetwork: values.dfltNetwork,
          isReqAcctBankTmZone: values.isReqAcctBankTmZone,
          tmZoneCode: values.tmZoneCode,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
        },
        // Don't udpate workflow for first step of each entity as it will be handled while creating the Entity
        stageName: "",
      });
    }
    if (response) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          response?._Id ?? entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.bank_parameters
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: response?._Id ?? entityId,
          stage: IC_ENTITY.bank_parameters,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      navigateTo(
        `${
          ROUTE_PATH.IC_BANK_PARAMS_RATES_CURRENCIES_IDENTIFIERS
        }?${updateQueryParams("entityId", response._Id)}`
      );
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [bankParamOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      _Id: formData?._Id ?? "",
      tmZoneCode: formData?.tmZoneCode ?? "",
      isReqAcctBankTmZone: formData?.isReqAcctBankTmZone ?? undefined,
      calendar: formData?.calendar ?? "",
      chkBottom: formData?.chkBottom ?? "",
      dfltNetwork: formData?.dfltNetwork ?? "CORE",
      // @ts-ignore comfirmation needed from finxact
      logo: formData?.logo ?? null,
    },
  });
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    sideBarMainMenuIndex,
    sideBarSubMenuIndex,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    navigateTo,
    setSideBarCurrentIndex,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === IC_ENTITY.bank_parameters
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_RULES}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
  }, [isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CommonTitle
        depositName="financial institution (bank) parameters"
        featureName=""
        version=""
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
          "Foundational details"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
          "Capture essential information, including time zones, operational calendars, and default network settings for precise institutional alignment"
        }
      />
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the demographic information for this financial institution?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("_Id")?.tooltip_text_main
            }
            labelFor="bank-demographic-information_input"
          />
          <AppSingleSelectDropdown
            value={values._Id}
            disable={formData?._Id ? true : false}
            onChange={handleOnChange("_Id")}
            dropdownList={demographicInfoOptions}
            placeholder="Search Option"
            keyName="label"
            id={"bank-demographic-information"}
          />
          {errors._Id && touched._Id && (
            <ErrorAlert>
              <span>{errors._Id}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the logo?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("logo")?.tooltip_text_main
            }
            labelFor="logo"
          />
          <FileUploader
            file={values.logo}
            setFile={handleOnChange("logo")}
            id={"logo"}
          />
          {errors.logo && touched.logo && (
            <ErrorAlert>
              <span>{errors.logo}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the institutional time zone?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("tmZoneCode")
                ?.tooltip_text_main
            }
            labelFor="bank-institution-timezone_input"
          />
          <AppSingleSelectDropdown
            value={values.tmZoneCode}
            onChange={handleOnChange("tmZoneCode")}
            dropdownList={institutionTimezoneOptions}
            placeholder="Search Option"
            keyName="label"
            id={"bank-institution-timezone"}
          />
          {errors.tmZoneCode && touched.tmZoneCode && (
            <ErrorAlert>
              <span>{errors.tmZoneCode}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Request the system to check if the time zone of the financial institution and a newly opened account match."
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isReqAcctBankTmZone")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            value={values.isReqAcctBankTmZone}
            onSelect={handleOnChange("isReqAcctBankTmZone")}
            leftBtnName="Yes"
            rightBtnName="No"
          />
          {errors.isReqAcctBankTmZone && touched.isReqAcctBankTmZone && (
            <ErrorAlert>
              <span>{errors.isReqAcctBankTmZone}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the financial institution calendar?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("calendar")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.calendar}
            onChange={handleOnChange("calendar")}
            radioPropList={institutionalCalendarOptions}
            stackDirection="column"
          />
          {errors.calendar && touched.calendar && (
            <ErrorAlert>
              <span>{errors.calendar}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the default text for bottom of check?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("chkBottom")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.chkBottom}
            onChange={handleOnChange("chkBottom")}
          />
          {errors.chkBottom && touched.chkBottom && (
            <ErrorAlert>
              <span>{errors.chkBottom}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the default network?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("dfltNetwork")
                ?.tooltip_text_main
            }
            labelFor="default-network_input"
          />
          <AppSingleSelectDropdown
            value={values.dfltNetwork}
            onChange={handleOnChange("dfltNetwork")}
            dropdownList={defaultNetworkOptions}
            placeholder="Search Option"
            id={"default-network"}
          />
          {errors.dfltNetwork && touched.dfltNetwork && (
            <ErrorAlert>
              <span>{errors.dfltNetwork}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default FoundationalDetails;
