"use client";
import { Box, Stack, Heading, Text, Breadcrumb, BreadcrumbItem } from "@/components/ChakraUiManager";
import "./OverviewHeader.scss"

export default function OverviewHeader(props: any) {
  const { documentTitle, overView, breadCrumb } = props;
  return (
    <Box className="overview-header">
      <Stack className="text-stack">
      {breadCrumb?.length > 0 && (
            <Breadcrumb separator="|" className="breadcrumb">
              {breadCrumb.map((data: string, key: number) => (
                <BreadcrumbItem key={key}>
                  <Text className="doc-upper-text">
                    {data.toUpperCase()}
                  </Text>
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          )}
        <Heading className="headline" as="h1">
          {documentTitle}
        </Heading>
        {overView && <Text className="subheadline">{overView}</Text>}
      </Stack>
    </Box>
  );
}
