"use client";
import {
  Box,
  Button,
  CardBody,
  CloseButton,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CardContainer, HoverToolTip } from "@/components/common";
import Image from "next/image";
import StatusBubble from "@/components/common/status-bubble/StatusBubble";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import ProductSummaryFeatureDetailsCard from "./product-summary-feature-details-card/ProductSummaryFeatureDetailsCard";
import css from "./ProductSummaryFeatureDetails.module.scss";
import { ProductSummaryType } from "../product-summary-config";
import { getFeatureStatus } from "../../product-management-util-service";
import { IconEdit, binIcon } from "public/assets";
import CustomEditIcon from "@/components/common/icons/EditIcon";
import { CloseIcon } from "@chakra-ui/icons";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext } from "react";
import {
  CADENCES_FORM_SECTIONS,
  IFX_PRODUCT_TYPE_VAL,
  deepCopy,
  useProductTypeCheck,
} from "@/utils";
import { ProductType } from "../../model/product-models";
import { updateProductInSummary } from "../..";

interface CommonProps {
  handleClose: () => void;
  currProductId: string;
  navigateToProductConfig: (path: string) => void;
  updateWorkflowMatrix: (data: ProductType) => void;
}
interface ProductSummaryFeatureDetailsProp extends CommonProps {
  type: "product" | "feature";
  feature: ProductSummaryType;
}

const ProductSummaryFeatureDetails = (
  props: ProductSummaryFeatureDetailsProp
) => {
  const {
    type,
    feature: {
      name,
      status,
      steps = [],
      version,
      link,
      componentName,
      componentClass,
    },
    handleClose,
    currProductId,
    navigateToProductConfig,
    updateWorkflowMatrix,
  } = props;
  const { productDetails, prodMatrix } =
    useContext<ProductConfigurationContextType>(productConfigurationContext);
  const isDepositProduct = useProductTypeCheck(
    productDetails?.ifxAcctType ?? "",
    IFX_PRODUCT_TYPE_VAL.deposit
  );
  const toast = useToast();

  //Filtered the step for product attribute cadence data display
  const filteredStep = () => {
    return !isDepositProduct
      ? steps.filter(
          (item) =>
            item.name !== CADENCES_FORM_SECTIONS.INACTIVITY_PROTOCOLS &&
            item.name !== CADENCES_FORM_SECTIONS.DORMACY_MANAGEMENT
        )
      : steps;
  };

  function deleteComponent() {
    const productData = deepCopy(productDetails);
    if (productData && componentClass) {
      const componentIndex = productData?.components?.length
        ? productData?.components?.findIndex(
            (item) => item.componentClass === componentClass
          )
        : -1;
      if (componentIndex >= 0) {
        productData?.components?.splice(componentIndex, 1);
        updateProduct(productData);
      } else {
        sendAvailableComponentsToUpdate(productData);
      }
    }
  }

  function updateProduct(productData?: ProductType) {
    if (productData?.name) {
      updateProductInSummary(productData?.name, productData)
        .then(() => {
          sendAvailableComponentsToUpdate(productData);
        })
        .catch(() => {
          if (!toast.isActive("product-summary-feature-details")) {
            toast({
              id: "product-summary-feature-details",
              description: "Unable to save product",
              status: "error",
              duration: 5000,
            });
          }
        });
    }
  }

  function sendAvailableComponentsToUpdate(productData: ProductType) {
    if (!productData.components) {
      productData.components = [];
    }
    const constructedMatrixComponents =
      prodMatrix?.val?.split(",")?.filter((item) => item !== componentClass) ??
      [];
    if (constructedMatrixComponents?.length) {
      const productComponentClass = productData.components?.map(
        (item) => item?.componentClass
      );
      constructedMatrixComponents.forEach((item) => {
        if (!productComponentClass?.includes(item)) {
          productData.components?.push({
            componentClass: item,
          });
        }
      });
      updateWorkflowMatrix(productData);
    }
  }

  function isMatrixOrProdCompHasMultipleRecord() {
    const matrixComps = prodMatrix?.val?.split(",") ?? [];
    return (
      matrixComps?.length > 1 ||
      (productDetails?.components && productDetails?.components?.length > 1)
    );
  }

  return (
    <CardBody className={css["feature-details-container"]}>
      <Box className={css["feature-details-close-icon-wrapper"]}>
        <Box className={css["feature-details-heading-wrapper"]}>
          <StatusBubble
            status={getFeatureStatus(status ?? "")}
            iconWidth={24}
          />
          <Heading className={css["feature-details-heading"]}>{name}</Heading>

          {/* Deleting the component from product */}
          {type === "feature" &&
          componentClass &&
          isMatrixOrProdCompHasMultipleRecord() ? (
            <Button className={css["bin-button"]} onClick={deleteComponent}>
              <Image
                src={binIcon.src}
                width="16"
                height="16"
                alt="Remove component"
              />
            </Button>
          ) : (
            ""
          )}
        </Box>
        <CloseButton onClick={handleClose}>
          <CloseIcon className={css["close-icon"]} />
        </CloseButton>
      </Box>
      {props.type === "feature" && (
        <Box className={css["feature-details-version-wrapper"]}>
          <Box className={css["feature-details-feature-name-container"]}>
            <Text className={css["feature-details-feature-version-text"]}>
              {componentName ?? "[COMPONENT NAME]"}
            </Text>
            <Box
              onClick={() =>
                navigateToProductConfig(
                  `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${link}${ROUTE_PATH["PRODUCT_NAME_QUERY"]}${currProductId}`
                )
              }
            >
              <Box className={css["edit-icon"]}>
                <HoverToolTip placement="top" label="Edit component details">
                  <CustomEditIcon boxSize={10} />
                </HoverToolTip>
              </Box>
            </Box>
          </Box>
          <Box className={css["feature-details-feature-name-container"]}>
            <Text className={css["feature-details-feature-version-text"]}>
              Version:
            </Text>
            <Text className={css["feature-details-feature-version-text"]}>
              {version}
            </Text>
          </Box>
        </Box>
      )}
      {props.type === "feature" && (
        <CardContainer customClass={css["feature-details-card-container"]}>
          {steps.map((step, index: number) => (
            <ProductSummaryFeatureDetailsCard
              componentName={componentName ?? ""}
              currProductId={currProductId}
              key={`${props.type}${index}`}
              {...step}
            />
          ))}
        </CardContainer>
      )}
      {props.type === "product" && (
        <CardContainer customClass={css["feature-details-card-container"]}>
          <CardBody className={css["feature-details-wrapper"]}>
            <StatusBubble
              status={status}
              iconWidth={16}
              className={css["feature-details-card-status-icon"]}
            />
            <Box className={css["link-content-wrapper"]}>
              <Box className={css["feature-details-attribute-container"]}>
                {filteredStep().map((step, index) => (
                  <Box key={index} className={css["attribute-container"]}>
                    <Text className={css["feature-details-secondary-heading"]}>
                      {step.name}
                    </Text>
                    <Box className={css["attribute-questions-container"]}>
                      {step.fields.map((field, index) => (
                        <Box
                          key={index}
                          className={
                            css["feature-details-options-heading-container"]
                          }
                        >
                          {field.heading && (
                            <Heading
                              className={css["feature-details-content-heading"]}
                            >
                              {field.heading}
                            </Heading>
                          )}
                          <Box
                            className={css["feature-details-options-container"]}
                          >
                            <Text>
                              <span className={css["feature-details-heading"]}>
                                {field.label}
                              </span>
                              :
                              <span className={css["feature-details-value"]}>
                                {" " + field.value}
                              </span>
                            </Text>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box className={css["link-wrapper"]}>
                <Box
                  onClick={() =>
                    navigateToProductConfig(
                      `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${link}${ROUTE_PATH["PRODUCT_NAME_QUERY"]}${currProductId}`
                    )
                  }
                  className={`${css["edit-icon"]}`}
                  w={10}
                  h={10}
                  p={1}
                >
                  <Image
                    className="cp"
                    src={IconEdit}
                    alt="edit icon"
                    width="20"
                    height="20"
                  />
                </Box>
              </Box>
            </Box>
          </CardBody>
        </CardContainer>
      )}
    </CardBody>
  );
};

export default ProductSummaryFeatureDetails;
