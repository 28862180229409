"use client";
import { Box, Button, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  DurationInput,
  ErrorAlert,
  InputText,
  StaticText,
} from "@/components/common";
import { useContext, useEffect, useState } from "react";
import AdditionalProductStyle from "./AdditionalProductForm.module.scss";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  AdditionalProductFormProps,
  getValidationSchema,
} from "./AdditionalProductFormValidation";

import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import { ProductType } from "@/components/product-management/model/product-models";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  DASHBOARD_ACCORDION_STATUS,
  IFX_PRODUCT_TYPE_VAL,
} from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import {
  getIfxProdType,
  isProductTypeIsLoan,
} from "@/components/product-management/product-management-util-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: ProductType;
    prodBkOptionsData: PostSchema;
  };
};

const initialServiceConfig = {
  svcName: "",
};

const AdditionalProductForm = (props: Props) => {
  const toast = useToast();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const { formData } = props.data;

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(props.data.prodBkOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [props.data.prodBkOptionsData]);

  const onSubmit = async (values: AdditionalProductFormProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["additionalProductFeatures"],
          status: DASHBOARD_ACCORDION_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const payload = {
        fundTerm: values.fundTerm,
        zeroBalAlertDays: parseInt(values.zeroBalAlertDays),
        zeroBalCloseDays: parseInt(values.zeroBalCloseDays),
        apy: parseFloat(values.apy),
        svcs: values?.svcs?.length
          ? values?.svcs.filter((item) => item.svcName)
          : [],
      };
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify({
          // NOTE: _vn, componentName, version is required to do a PATCH update
          _vn: formData?._vn,
          // PATCH fields
          ...payload,
        } as ProductType),
      });

      if (res && productDetails?.name) {
        if (!toast.isActive("additional-product-components")) {
          toast({
            id: "additional-product-components",
            description: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
              "Additional Product Components"
            } updated`,
            status: "success",
          });
        }

        getExistingProduct?.(productDetails.name, true);
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails.name,
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["additionalProductFeatures"],
          status: DASHBOARD_ACCORDION_STATUS.completed,
        });
      }
    }
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      fundTerm: formData?.fundTerm ?? "",
      zeroBalAlertDays: formData?.zeroBalAlertDays?.toString() ?? "",
      zeroBalCloseDays: formData?.zeroBalCloseDays?.toString() ?? "",
      apy: formData?.apy?.toString() ?? "",
      svcs: formData?.svcs?.length ? formData.svcs : [],
    },
  });
  const {
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    dirty,
    handleBlur,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]); // Handle form field changes
  const handleFormData =
    (key: string, index?: number) => (value: string | number) => {
      if (key === "svcName" && index !== undefined) {
        setFieldValue(`svcs.${index}.${key}`, value);
      }
      setFieldValue(key, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (navigateToNextPage) navigateToNextPage();
  }, [canCheckFormStatus]);

  return (
    <FormikProvider value={formik}>
      <form id="finxact-form" onSubmit={handleSubmit} noValidate>
        <Box pt={9.6}>
          <StaticText
            heading
            headingName={
              tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
              "Additional Product Components"
            }
          />
        </Box>
        <Box
          className={
            AdditionalProductStyle["additional-product-feature-container"]
          }
        >
          <CardContainer customClass={"app-form-container"}>
            <CardBody>
              <StaticText
                className="additional-description-area"
                textValue={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                    ?.section_subhead ||
                  "Highlight the unique attributes and regulations related to your product that differentiate it in the market."
                }
              />
              {!isProductTypeIsLoan(productDetails) ? (
                <>
                  <Box
                    className={
                      AdditionalProductStyle["component-box-seperator"]
                    }
                  >
                    <DurationInput
                      labelName={"What is the funding term?"}
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("fundTerm")
                          ?.tooltip_text_main
                      }
                      value={values.fundTerm}
                      onChange={handleFormData("fundTerm")}
                      modalLinkName={"Enter code manually"}
                      modalTitle={"Enter Funding Term"}
                      modalLabelName={"What is funding term?"}
                      modalLabelTooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("fundTerm")
                          ?.tooltip_text_main
                      }
                      showDashedLeftBorder
                    />
                    {errors.fundTerm && touched.fundTerm && (
                      <ErrorAlert>
                        <span>{errors.fundTerm}</span>
                      </ErrorAlert>
                    )}
                  </Box>

                  <Box
                    className={
                      AdditionalProductStyle["component-box-seperator"]
                    }
                  >
                    <AppFormLabel
                      labelName="What are the zero balance close days?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get(
                          "zeroBalCloseDays"
                        )?.tooltip_text_main
                      }
                    />
                    <StaticText
                      className={
                        AdditionalProductStyle[
                          "static-description-above-text-field"
                        ]
                      }
                      textValue="How long should an account stay at a zero balance before closing?"
                    />
                    <AppNumberInput
                      value={
                        values.zeroBalCloseDays ? values.zeroBalCloseDays : ""
                      }
                      onChange={handleFormData("zeroBalCloseDays")}
                    />
                    {errors.zeroBalCloseDays && touched.zeroBalCloseDays && (
                      <ErrorAlert>
                        <span>{errors.zeroBalCloseDays}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                  <Box
                    className={
                      AdditionalProductStyle["component-box-seperator"]
                    }
                  >
                    <AppFormLabel
                      labelName="What are the zero balance alert days?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get(
                          "zeroBalAlertDays"
                        )?.tooltip_text_main
                      }
                    />
                    <StaticText
                      className={
                        AdditionalProductStyle[
                          "static-description-above-text-field"
                        ]
                      }
                      textValue="How many days before closing should an alert be sent out?"
                    />
                    <AppNumberInput
                      value={
                        values.zeroBalAlertDays ? values.zeroBalAlertDays : ""
                      }
                      onChange={handleFormData("zeroBalAlertDays")}
                    />
                    {errors.zeroBalAlertDays && touched.zeroBalAlertDays && (
                      <ErrorAlert>
                        <span>{errors.zeroBalAlertDays}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                  <Box
                    className={
                      AdditionalProductStyle["component-box-seperator"]
                    }
                  >
                    <AppFormLabel labelName="What is the annual percentage yield (APY)?" />
                    <AppNumberInput
                      value={values.apy}
                      onChange={handleFormData("apy")}
                      valueSuffix="%"
                      placeholder="0%"
                    />
                    {errors.apy && touched.apy && (
                      <ErrorAlert>
                        <span>{errors.apy}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                </>
              ) : (
                ""
              )}
              <FieldArray
                name="svcs"
                render={({ insert, remove, push }) => (
                  <>
                    <Box
                      className={
                        AdditionalProductStyle["component-box-seperator"]
                      }
                    >
                      <AppFormLabel
                        labelName="What are the services offered with this product?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("svcName")
                            ?.tooltip_text_main
                        }
                      />
                      {values.svcs?.map((service, index) => (
                        <Box key={`additionAttributeServiceBox${index}`} mb={6}>
                          <InputText
                            key={`additionAttributeServiceInput${index}`}
                            value={service.svcName}
                            onChange={handleFormData("svcName", index)}
                            onBlur={handleBlur}
                            label="What are the services offered with this product?"
                            name="svcName"
                            placeholder="Enter service name"
                          />
                        </Box>
                      ))}
                    </Box>
                    <Button
                      mt={5}
                      className="app-btn-link"
                      onClick={() => {
                        push({
                          ...initialServiceConfig,
                        });
                      }}
                    >
                      + Add new service
                    </Button>
                  </>
                )}
              />
            </CardBody>
          </CardContainer>
        </Box>
      </form>
    </FormikProvider>
  );
};

export default AdditionalProductForm;
