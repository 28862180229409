"use client";

import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AddNewButton,
  AppFormLabel,
  AppNumberInput,
  AppSingleSelectDropdown,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  DragDropContainer,
  DurationInput,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  FIDCRelatedFieldsProps,
  getValidationSchema,
} from "./FdicRelatedFieldsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { FinancialInstitutionUSBankInfoData } from "@/components/institutional-configuration/model";
import { updateBankParametersUSBankParams } from "@/components/institutional-configuration/ic-config-api-service";
import {
  preventMainFormSubmitOnRJSFSubmit,
  shouldUpdateWorkflowStatus,
} from "@/utils/common";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { IC_ENTITY, RjsfData, TRANSCODE_FLOW_NAME } from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";
import { addNewComponent } from "@/components/product-management/product-configuration/product-config-client-service";
import { exceptRuleUiSchema } from "@finxact/finxact-shared-ui";

type Props = {
  data: {
    formData: FinancialInstitutionUSBankInfoData;
    restrictionCodeOptions: string[];
    signTypOption: { label: "string"; value: number }[];
    transactionCodesOptions: {
      value: string;
      label: string;
    }[];
    bankParamOptionsData: PostSchema;
    addNewExceptRuleEndpoint: string;
    exceptRuleSchema: OptionsSchema;
  };
};
const FDICRelatedFields = ({ data }: Props) => {
  const { fdic, int1099Min, isAggTaxData, misc1099Min } = data.formData;

  const { bankParamOptionsData, exceptRuleSchema, addNewExceptRuleEndpoint } =
    data;
  const toast = useToast();
  const params = useSearchParams();
  const entityId = params?.get(QUERY_PARAM_KEY.ENTITY_ID);
  const entityType = params?.get(QUERY_PARAM_KEY.ENTITY_TYPE);
  const tcId = params?.get(QUERY_PARAM_KEY.TC_ID);
  const fieldName = params?.get(QUERY_PARAM_KEY.FIELD_NAME);

  const onSubmit = async (values: FIDCRelatedFieldsProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty && !tcId) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "fdic-370",
    };
    response = await updateBankParametersUSBankParams({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        // PATCH Feilds
        fdic: {
          inputPath: values.fdic.inputPath ?? null,
          outputPath: values.fdic.outputPath ?? null,
          authRole: values.fdic.authRole,
          maxDepInsAmt: Number(values.fdic.maxDepInsAmt),
          deathCovrgTerm: values.fdic.deathCovrgTerm,
          restrictCode: values.fdic.restrictCode,
          uninsuredTrnCode: values.fdic.uninsuredTrnCode,
          signDocType: Number(values.fdic.signDocType),
        },
        int1099Min: Number(values.int1099Min),
        isAggTaxData: values.isAggTaxData,
        misc1099Min: Number(values.misc1099Min),
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };
  const handleOnChangeFdic = (key: any) => (value: any | any[]) => {
    setFieldValue("fdic", { ...values.fdic, [key]: value });
  };

  const handleOnChange = (key: any) => (value: any | any[]) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema.fields.bankparamUSBankInfo);
    })();
  }, [bankParamOptionsData]);

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      fdic: {
        inputPath: fdic?.inputPath ?? "",
        outputPath: fdic?.outputPath ?? "",
        maxDepInsAmt: fdic?.maxDepInsAmt.toString() ?? "",
        deathCovrgTerm: fdic?.deathCovrgTerm ?? "",
        restrictCode: fdic?.restrictCode ?? "",
        uninsuredTrnCode:
          fieldName === "uninsuredTrnCode" && tcId
            ? tcId
            : data.formData?.fdic?.uninsuredTrnCode ?? "",
        authRole:
          (fdic?.authRole?.map((authRole) => authRole) as { role: string }[]) ??
          [],
        signDocType: fdic?.signDocType as number,
      },
      int1099Min: int1099Min ?? "",
      isAggTaxData: isAggTaxData ?? undefined,
      misc1099Min: misc1099Min ?? "",
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const handleRoleChange = (index: number) => (value: any) => {
    setFieldValue(`fdic.authRole.${index}.role`, value);
  };

  const setAuthorizedRole = (fn: any) => {
    setFieldValue("fdic", {
      ...values.fdic,
      ["authRole"]: fn(values.fdic.authRole),
    });
  };

  const exceptRuleOptionsSchema = useMemo(() => {
    const { actions, name } = exceptRuleSchema;
    return { name, ...actions.POST };
  }, [exceptRuleSchema]);

  const addNewExceptRule = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewExceptRuleEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    handleOnChangeFdic("restrictCode")(response.code);
  };

  return (
    <FormikProvider value={formik}>
      <form
        id="finxact-form"
        noValidate
        onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      >
        <CommonTitle
          depositName="FINANCIAL INSTITUTION (BANK) PARAMETERS"
          featureName=""
          version=""
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
            "[US] FDIC370 related fields"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
            "Configure specific FDIC370 compliance fields, including authorized roles, data paths, and codes to meet regulatory requirements"
          }
        />
        <CardContainer customClass="app-form-field-container app-form-container">
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the input path?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("inputPath")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={formik.values.fdic.inputPath}
              onChange={handleOnChangeFdic("inputPath")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.fdic?.inputPath &&
              formik.touched.fdic?.inputPath && (
                <ErrorAlert>
                  <span>{formik.errors.fdic.inputPath}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the output path?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("outputPath")
                  ?.tooltip_text_main
              }
              labelFor="fdic-out"
            />
            <InputText
              value={formik.values.fdic.outputPath}
              onChange={handleOnChangeFdic("outputPath")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.fdic?.outputPath &&
              formik.touched.fdic?.outputPath && (
                <ErrorAlert>
                  <span>{formik.errors.fdic.outputPath}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppNumberInput
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("maxDepInsAmt")
                  ?.tooltip_text_main
              }
              labelName="What is the maximum deposit insurance amount?"
              value={values.fdic.maxDepInsAmt}
              onChange={handleOnChangeFdic("maxDepInsAmt")}
              valuePrefix="$"
              onBlur={handleBlur}
            />
            {errors.fdic?.maxDepInsAmt && touched.fdic?.maxDepInsAmt && (
              <ErrorAlert>
                <span>{errors.fdic.maxDepInsAmt}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <DurationInput
              labelName={"What is the post death term coverage?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("deathCovrgTerm")
                  ?.tooltip_text_main
              }
              modalLinkName={"Enter code manually"}
              modalTitle={"Enter post death term coverage"}
              modalLabelName={"What is the post death term coverage?"}
              modalLabelTooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("deathCovrgTerm")
                  ?.tooltip_text_main
              }
              regex={REGEX_PATTERN.RESTRICT_DECIMAL}
              onChange={handleOnChangeFdic("deathCovrgTerm")}
              value={values.fdic.deathCovrgTerm}
              showDashedLeftBorder
            />
            {errors.fdic?.deathCovrgTerm && touched.fdic?.deathCovrgTerm && (
              <ErrorAlert>
                <span>{errors.fdic.deathCovrgTerm}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the restriction code?"
              labelFor="restrictionCode_input"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("restrictCode")
                  ?.tooltip_text_main
              }
            />
            <CoupleRadioWithDropdown
              id="restrictionCode_fee"
              primaryOptionValue={values.fdic.restrictCode}
              primaryRadioName={"Select an existing restriction code"}
              secondaryRadioName={"Add a new restriction code"}
              handlePrimaryOptionSelect={handleOnChangeFdic("restrictCode")}
              placeHolderName="Select existing restriction code"
              dropdownList={data.restrictionCodeOptions}
              schema={exceptRuleOptionsSchema}
              uiSchema={exceptRuleUiSchema}
              onAddNewSubmit={addNewExceptRule}
            />
            {errors.fdic?.restrictCode && touched.fdic?.restrictCode && (
              <ErrorAlert>
                <span>{errors.fdic.restrictCode}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the uninsured transaction code?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("uninsuredTrnCode")
                  ?.tooltip_text_main
              }
              labelFor="fdic_uninsured_tc_input"
            />
            <CoupleRadioWithDropdown
              primaryOptionValue={values.fdic.uninsuredTrnCode}
              primaryRadioName="Select from existing transaction codes"
              secondaryRadioName="Create new transaction code"
              dropdownList={data.transactionCodesOptions.map(
                (trn: { label: string; value: string }) => trn.label
              )}
              handlePrimaryOptionSelect={handleOnChangeFdic("uninsuredTrnCode")}
              setFieldValue={setFieldValue}
              placeHolderName="Select transaction code"
              onBlur={handleBlur}
              addNewFieldType="routeToTransCode"
              addNewFieldQueryParam={`${QUERY_PARAM_KEY["CONFIG_ID"]}=${entityId}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.finInstFdic}${entityType ? `&${QUERY_PARAM_KEY.ENTITY_TYPE}=${entityType}` : ""}&${QUERY_PARAM_KEY.FIELD_NAME}=uninsuredTrnCode`}
              id="fdic_uninsured_tc"
            />
            {errors.fdic?.uninsuredTrnCode &&
              touched.fdic?.uninsuredTrnCode && (
                <ErrorAlert>
                  <span>{errors.fdic?.uninsuredTrnCode}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the signature card doc type for FDIC?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("signDocType")
                  ?.tooltip_text_main
              }
            />

            <RadioButtonGroup
              isBoxedRadio={true}
              value={values.fdic.signDocType}
              onChange={handleOnChangeFdic("signDocType")}
              radioPropList={data.signTypOption}
              stackDirection={"row"}
              spacing={"1rem"}
            />
            {errors.fdic?.signDocType && touched.fdic?.signDocType && (
              <ErrorAlert>
                <span>{errors.fdic.signDocType}</span>
              </ErrorAlert>
            )}
          </Box>

          <FieldArray
            name="fdic.authRole"
            render={({ insert, remove, push }) => (
              <>
                <DragDropContainer
                  items={values.fdic.authRole?.map(
                    (role: any, index: number) => {
                      return {
                        id: index + 1,
                        deleteHandler: () => remove(index),
                        showDeleteIcon: true,
                        content: (
                          <Box key={index} className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the authorized role?"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "authRole"
                                )?.tooltip_text_main
                              }
                            />
                            <InputText
                              value={role.role}
                              onChange={handleRoleChange(index)}
                            />
                            {touched.fdic?.authRole &&
                              Array.isArray(errors.fdic?.authRole) &&
                              errors.fdic?.authRole[index] && (
                                <ErrorAlert>
                                  <span>
                                    {
                                      (errors.fdic?.authRole[index] as any)
                                        ?.role
                                    }
                                  </span>
                                </ErrorAlert>
                              )}
                          </Box>
                        ),
                      };
                    }
                  )}
                  setItems={setAuthorizedRole}
                  isElement
                />

                <Box marginY="32px">
                  <AddNewButton
                    label="Add authorized role"
                    onClick={() => {
                      push({ role: "" });
                    }}
                  />
                </Box>
              </>
            )}
          ></FieldArray>
          <Box className="app-form-field-container">
            <AppNumberInput
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("int1099Min")
                  ?.tooltip_text_main
              }
              labelName="What is the 1099-INT reward minimum?"
              value={values.int1099Min}
              onChange={handleOnChange("int1099Min")}
              valuePrefix="$"
              onBlur={formik.handleBlur}
            />
            {errors.int1099Min && touched.int1099Min && (
              <ErrorAlert>
                <span>{errors.int1099Min}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppNumberInput
              labelName="What is the 1099-MISC bonus minimum?"
              value={formik.values.misc1099Min}
              onChange={handleOnChange("misc1099Min")}
              valuePrefix="$"
              onBlur={formik.handleBlur}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("misc1099Min")
                  ?.tooltip_text_main
              }
            />
            {errors.misc1099Min && touched.misc1099Min && (
              <ErrorAlert>
                <span>{errors.misc1099Min}</span>
              </ErrorAlert>
            )}
          </Box>

          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Aggregate tax data indicator?"
              isRequired
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isAggTaxData")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              name="taxDataIndicator"
              onSelect={handleOnChange("isAggTaxData")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.isAggTaxData}
            />
            {errors.isAggTaxData && touched.isAggTaxData && (
              <ErrorAlert>
                <span>{errors.isAggTaxData}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
      </form>
    </FormikProvider>
  );
};
export default FDICRelatedFields;
