"use client";
import {
  AppNumberInput,
  CardContainer,
  CommonTitle,
  ErrorAlert,
} from "@/components/common";

import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";

import {
  AccountTypeRelationshipPartySpecificationsValueProps,
  accountTypeRelationshipPartySpecifications,
  getValidationSchema,
} from "./AccountTypeRelationshipPartySpecificationsValidation";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

import { REGEX_PATTERN } from "@/utils/regex-pattern";
import { updateAccountType } from "../../ic-config-api-service";
import { AccountTypeData } from "../../model";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import { constructRelData } from "../../ic-utils-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type AccountTypeRelationshipPartySpecificationsProps = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    formData: AccountTypeData | null;
    acctTypeOptionsData: PostSchema;
  };
};

const AccountTypeRelationshipPartySpecifications = (
  props: AccountTypeRelationshipPartySpecificationsProps
) => {
  const toast = useToast();
  const toastId = "account-type-rel-party-spec";
  const { depositName, featureName, version, mainTitle, subText } = props;
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    getQueryParam,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const currEntriesIndex = getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX)
    ? Number(getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX))
    : 0;
  const { minParties, maxParties, ...rest } =
    props.data.formData?.rels?.[currEntriesIndex] || {};

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.acctTypeOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.acctTypeOptionsData]);

  const onSubmit = async (
    values: AccountTypeRelationshipPartySpecificationsValueProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.acctType!,
          stage: IC_ENTITY.account_type,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const relData: any = constructRelData(
      props.data.formData,
      {
        maxParties: Number(values.maxParties),
        minParties: Number(values.minParties),
      },
      currEntriesIndex
    );
    const response = await updateAccountType({
      formData: {
        _vn: props.data.formData?._vn,
        acctType: props.data.formData?.acctType,
        rels: relData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };

  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  // using useFormik hook from Formik Library
  // accountTypeRelationshipPartySpecifications,
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      minParties:
        minParties?.toString() ??
        accountTypeRelationshipPartySpecifications.minParties,
      maxParties:
        maxParties?.toString() ??
        accountTypeRelationshipPartySpecifications.maxParties,
    },
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const displayMaxPartiesTypeOptionError = errors?.maxParties &&
    touched?.maxParties && (
      <ErrorAlert>
        <span>{errors.maxParties}</span>
      </ErrorAlert>
    );

  const displayMinPartiesTypeOptionError = errors?.minParties &&
    touched?.minParties && (
      <ErrorAlert>
        <span>{errors.minParties}</span>
      </ErrorAlert>
    );

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />

      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the minimum number of parties that can be associated with this account type?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("minParties")
                ?.tooltip_text_main
            }
            value={values.minParties}
            onChange={handleOnChange("minParties")}
            onBlur={handleBlur}
            label="Minimum parties"
            regex={REGEX_PATTERN.RESTRICT_DECIMAL}
          />
          {displayMinPartiesTypeOptionError}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the maximum number of parties that can be associated with this account type?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxParties")
                ?.tooltip_text_main
            }
            value={values.maxParties}
            onChange={handleOnChange("maxParties")}
            onBlur={handleBlur}
            label="Maximum parties"
            regex={REGEX_PATTERN.RESTRICT_DECIMAL}
          />
          {displayMaxPartiesTypeOptionError}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AccountTypeRelationshipPartySpecifications;
