"use client";
import { useContext, useEffect, useState } from "react";
import {
  PriorityPostingDetails,
  PriorityPostingProps,
  getValidationSchema,
} from "./PriorityPostingValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  DragDropContainer,
  ErrorAlert,
  FrequencyModule,
  MultiSelectDropdown,
  RadioButtonGroup,
} from "@/components/common";
import { FinancialInstitutionData } from "../../model";
import { updateBankParameters } from "../../ic-config-api-service";
import { ROUTE_PATH } from "@/route-config/route-path";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { useSearchParams } from "next/navigation";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type TaxPartyPhnProps = {
  data: {
    priorityPostingOderOptions: { label: string; value: number }[];
    networkPriorityOrderOptions: { label: string }[];
    formData: FinancialInstitutionData;
    bankParamOptionsData: PostSchema;
  };
};
const PriorityPosting = ({ data }: TaxPartyPhnProps) => {
  const toast = useToast();
  const params = useSearchParams();

  const { bankParamOptionsData } = data;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      if (params?.get("initialFlow")) {
        navigateTo(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
      } else {
        navigateTo(
          `${ROUTE_PATH.IC_COMPLETION_SCREEN}?entityId=${data.formData?._Id}&entityType=${IC_ENTITY.bank_parameters}`
        );
      }
    }
    return;
  };

  const onSubmit = async (values: PriorityPostingProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }

    let response;
    const commonToastOptions = {
      toast,
      toastId: "priority_posting_type",
    };
    response = await updateBankParameters({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        // PATCH Fields
        priorityOrder:
          values.priorityOrder ?? PriorityPostingDetails.priorityOrder,
        networkPriority:
          values.priorityOrder < 3
            ? (dragSelectionList as [string]) ??
              (PriorityPostingDetails.networkPriority as [string])
            : null,
        postingPriorityFreq: values.postingPriorityFreq,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },

      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });

    if (response) {
      moveToNextStep();
      return;
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [bankParamOptionsData]);

  const {
    values,
    handleSubmit,
    dirty,
    errors,
    touched,
    setFieldValue,
    setTouched,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      priorityOrder: data.formData?.priorityOrder as number,
      networkPriority: (data.formData?.networkPriority as string[]) ?? [],
      postingPriorityFreq: data.formData?.postingPriorityFreq ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  const [dragSelectionList, setDragSelectionList] = useState<string[]>(
    values.networkPriority
  );
  const [showNetworkPriorityOrder, setShowNetworkPriorityOrder] =
    useState<boolean>(false);

  const handleMultiSelectChange = (value: string[] | any) => {
    setDragSelectionList((prevItem: string[]) => {
      if (prevItem && value.length > prevItem.length) {
        const newItem: string = value[value?.length - 1];
        return [...prevItem, newItem];
      } else {
        const extraItem = prevItem.filter((item) => !value.includes(item));
        return prevItem.filter((item) => !extraItem.includes(item));
      }
    });
  };

  const prioritypostingHandler = (key: string) => (value: string | number) => {
    setFieldValue(key, value);
    const selectedObject = data.priorityPostingOderOptions.find(
      (obj: { label: string; value: number }) => obj.value === value
    );
    if (selectedObject?.label.includes("Network")) {
      setShowNetworkPriorityOrder(true);
    } else {
      setShowNetworkPriorityOrder(false);
      setFieldValue("networkPriority", []);
    }
  };

  const postingPriorityFormHandler =
    (key: string) => (value: string | string[]) => {
      setFieldValue(key, value);
      handleMultiSelectChange(value);
    };

  const handleChange = (key: string) => (value: string | number | boolean) => {
    setFieldValue(key, value);
  };

  useEffect(() => {
    if (
      data.formData?.networkPriority?.length! > 0 &&
      values.priorityOrder < 3
    ) {
      setShowNetworkPriorityOrder(true);
    } else {
      setShowNetworkPriorityOrder(false);
    }
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit} id="finxact-form">
        <Box className="app-form-container">
          <CommonTitle
            depositName="FINANCIAL INSTITUTION (BANK) PARAMETERS"
            featureName=""
            version=""
            mainTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
              "Priority posting"
            }
            subText={
              tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
              "Determine the transaction posting protocols, including frequency, order, and network priority to optimize operational efficiency"
            }
          />
          <CardContainer>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the posting priority order?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("priorityOrder")
                    ?.tooltip_text_main
                }
              />
              <RadioButtonGroup
                value={values.priorityOrder}
                onChange={prioritypostingHandler("priorityOrder")}
                isBoxedRadio={true}
                radioPropList={data.priorityPostingOderOptions as any}
                stackDirection="column"
              />
              {errors.priorityOrder && touched.priorityOrder && (
                <ErrorAlert>
                  <span>{errors.priorityOrder}</span>
                </ErrorAlert>
              )}
            </Box>

            {showNetworkPriorityOrder && (
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the network priority order?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("networkPriority")
                      ?.tooltip_text_main
                  }
                  labelFor="network-priority_input"
                />
                <MultiSelectDropdown
                  dropdownList={data.networkPriorityOrderOptions.map(
                    (d: { label: string }) => d.label
                  )}
                  values={values.networkPriority}
                  onChange={postingPriorityFormHandler("networkPriority")}
                  placeholder="Select"
                  showCheckbox={true}
                  id={"network-priority"}
                />
                {errors.networkPriority && touched.networkPriority && (
                  <ErrorAlert>
                    <span>{errors.networkPriority}</span>
                  </ErrorAlert>
                )}
              </Box>
            )}
            {showNetworkPriorityOrder && values.networkPriority?.length > 1 && (
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Drag the selections to prioritize the list."
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("dragPriority")
                      ?.tooltip_text_main
                  }
                />
                <DragDropContainer
                  items={dragSelectionList}
                  setItems={setDragSelectionList}
                  displayIndex={true}
                />
              </Box>
            )}

            <Box className="app-form-field-container">
              <FrequencyModule
                value={values.postingPriorityFreq}
                onChange={handleChange("postingPriorityFreq")}
                frequencyQuestionTitle="How often should posting occur?"
                frequencyQuestionDescription={
                  tooltipFlyoutDetails?.tooltipsMap?.get("postingPriorityFreq")
                    ?.tooltip_text_main
                }
                monthDropdownLabel="On what date should the posting occur?"
                monthDropdownTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get(
                    "postingPriorityFreqMon"
                  )?.tooltip_text_main
                }
                notBusinessDayLabel="Please select details of day of the frequency"
                notBusinessDayTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get(
                    "postingPriorityFreqNotBusiness"
                  )?.tooltip_text_main
                }
                frequencyCodeText="Enter code manually"
                frequencyOutputContent="The payments will be occur every"
              />
              {errors.postingPriorityFreq && (
                <ErrorAlert>
                  <span>{errors.postingPriorityFreq}</span>
                </ErrorAlert>
              )}
            </Box>
          </CardContainer>
        </Box>
      </form>
    </div>
  );
};

export default PriorityPosting;
