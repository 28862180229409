"use client";

import React, { useState, useEffect } from "react";
import {
  Checkbox,
  VStack,
  Divider,
  Text,
  Box,
} from "@/components/ChakraUiManager";
import { getTutorialFilterGroups } from "@/prismic-api/tutorial-service";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";

interface FilterGroup {
  name: string;
  tags: string[];
}

interface FilterData {
  groups: FilterGroup[];
}

interface TutorialsTagsFilterProps {
  selectedTags: string[];
  handleTagChange: (tag: string) => void;
  clearFilters: () => void;
  page: string;
}

const TutorialsTagsFilter: React.FC<TutorialsTagsFilterProps> = ({
  selectedTags,
  handleTagChange,
  clearFilters,
}) => {
  const prismicClient = useSelector(getAppPrismicClient);

  const [filters, setFilters] = useState<FilterData | null>(null);

  // Fetch filters
  const getFilters = async () => {
    const filtersData = await getTutorialFilterGroups(prismicClient);
    setFilters(filtersData);
  };

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <Box className="tutorials-filter">
      {filters && (
        <VStack
          className="full-group"
          gap={0}
          alignItems="flex-start"
          divider={<Divider className="divider" />}
        >
          <Box className="header">
            <h5>Tag Filters</h5>
          </Box>
          {filters.groups.map((group, key) => (
            <Box key={key}>
              <Text className="group-name">{group.name}</Text>
              <VStack alignItems="flex-start">
                {group.tags.map((tag, tagKey) => (
                  <Box key={tagKey} mt="4" className="label">
                    <Checkbox
                      isChecked={selectedTags.includes(tag)}
                      onChange={() => handleTagChange(tag)}
                    >
                      {tag}
                    </Checkbox>
                  </Box>
                ))}
              </VStack>
            </Box>
          ))}
          <Box alignItems="flex-start">
            <button onClick={clearFilters} className="clear">
              Clear Filters
            </button>
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default TutorialsTagsFilter;
