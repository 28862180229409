"use client";

import { SliceZone } from "@prismicio/react";
import { components } from "@/slices";
import DocCenterPageHeader from "./doc-center-page-header/DocCenterPageHeader";
import {
  CardContainer,
  FooterNotLoggedInUser,
  FooterWidgetIframe,
} from "../../../components/common";
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Stack,
  Link as ResourceLink,
} from "@/components/ChakraUiManager";
import { Breadcrumb, BreadcrumbItem, VStack } from "@chakra-ui/react";
import "./DocumentDetailPage.scss";
import { useUserInfo, UserInfo } from "@/app/providers";

import { Key, useEffect, useMemo, useRef, useState } from "react";
import { updateUserFastProfile } from "@/api-config/api-service";
import { useData } from "../../../app/docs/providers";
import { fetchUrlForResources } from "@/prismic-api/common-service";
import { DOC_READ_SPEED_WORDS_PER_MIN, TIME_TO_VIEW_IMAGE } from "@/utils";
import { CODE_BLOCK_SLICE_CLASSNAME } from "@/slices/CodeBlock";

function addToRecentlyViewed(recentlyViewed: any[], uid: string, type: string) {
  // Remove the value if it already exists in the array
  recentlyViewed = recentlyViewed.filter((item) => item.uid !== uid);

  // Add value to the front of the array
  recentlyViewed.unshift({ uid, type });
  if (recentlyViewed.length > 3) {
    // Remove the last element if array size exceeds 3
    recentlyViewed.pop();
  }
  return recentlyViewed;
}

function DocumentDetailPage(props: { home: any }) {
  const dateString = props.home.firstPublicationDate;
  const documentTitle = props.home.articleMainTitle;
  const docUrl = props.home.url;
  const resourceSlice = props.home.slices.filter((slice: any) => {
    return slice.type === "resources_links_section";
  });
  const additionalResources = resourceSlice.length ? resourceSlice[0].data : [];
  const ID_TOP_OF_THE_PAGE = "top-of-the-page";
  const [activeSection, setActiveSection] =
    useState<string>(ID_TOP_OF_THE_PAGE);
  const docBodyRef = useRef<HTMLDivElement | null>(null);

  const { user, setUser } = useUserInfo();
  const recentlyViewedDocs =
    user?.fastProfile?.doc_center?.recently_viewed || [];
  const uid = props.home.uid;
  const type = props.home.type;
  const updatedRecentlyViewed = addToRecentlyViewed(
    recentlyViewedDocs,
    uid,
    type
  );
  const [breadCrumb, setBreadcrumb] = useState<string[]>([]);
  const { loading, breadCrumbs } = useData();
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };
  const observer = new IntersectionObserver((entries) => {
    const id = entries.filter((entry) => entry.isIntersecting)[0]?.target.id;
    if (id) setActiveSection(id);
  }, observerOptions);
  const isUserAuthenticated = user.loading == false && user.signedIn == true;

  const filteredPageIndexes = useMemo(
    () => props.home.pageIndex.filter((pI: any) => pI.content.length > 0),
    [props.home.pageIndex]
  );

  useEffect(() => {
    const updateUser = () => {
      const updatedUserData: UserInfo = {
        ...user,
        fastProfile: {
          ...user.fastProfile,
          doc_center: {
            ...user.fastProfile?.doc_center,
            recently_viewed: updatedRecentlyViewed,
          },
        },
      };
      setUser(updatedUserData);
    };
    updateUserFastProfile({
      ...user.fastProfile,
      doc_center: {
        recently_viewed: updatedRecentlyViewed,
      },
    }).then((response) => {
      if (response.status === 200) updateUser();
    });
  }, [uid]);

  // Function to handle the activation of a link.
  const handleSetActive = (to: string) => {
    setActiveSection(to);
  };

  useEffect(() => {
    if (!loading) {
      const breadCrumb = breadCrumbs;
      if (breadCrumb?.[type]?.[uid]) {
        setBreadcrumb(breadCrumb[type][uid].split(","));
      }
      scrollToTop();
    }
  }, [loading, uid]);

  useEffect(() => {
    if (!loading && filteredPageIndexes) {
      const pageIdsWithTopOfThePage = [
        ...filteredPageIndexes,
        { content: ID_TOP_OF_THE_PAGE },
      ];
      setTimeout(() => {
        pageIdsWithTopOfThePage.map((pageIndex: any) => {
          const id = String(pageIndex.content.replaceAll(" ", "-"));
          let target = document.getElementById(id);
          if (target) {
            observer.observe(target);
          }
        });
        // As we do not not how long it takes to load all the prismic slices
        // After that we can add observer on that.
      }, 2000);
    }
  }, [loading, filteredPageIndexes]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      handleSetActive(ID_TOP_OF_THE_PAGE);
    }
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      handleSetActive(id);
    }
  };

  const scrollToTop = () => {
    const element = document.getElementById(ID_TOP_OF_THE_PAGE);
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };

  const readTime = useMemo(() => {
    if (docBodyRef.current) {
      let allText = "";
      let child = docBodyRef.current.firstChild as HTMLElement;
      const nestedImageCount =
        docBodyRef.current.getElementsByClassName("prismic-image").length;
      while (child) {
        // Ignore any code block slices from the calculation of read time
        if (!child.className?.includes(CODE_BLOCK_SLICE_CLASSNAME)) {
          allText += child.innerText;
        }
        child = child?.nextSibling as HTMLElement;
      }
      // Get total number of words
      const words = allText.replace("\n", " ").split(" ").length;
      const time =
        words / DOC_READ_SPEED_WORDS_PER_MIN +
        nestedImageCount * TIME_TO_VIEW_IMAGE;
      // Calculate time in multiples of 2min if total time less than 10mins, else in multiple of 5mins
      return time < 10
        ? Math.ceil(time / 2) * 2
        : Number(time / 5) % 1 > 0.5 // if value is closer to higher multiple of 5
          ? Math.ceil(time / 5) * 5
          : Math.floor(time / 5) * 5;
    }
    return 0;
    // Re-calculate time to read when inner text changes
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [docBodyRef.current?.innerText]);

  const getJumpLink = (key: Key, id: string, content: string) => {
    return (
      <ResourceLink
        key={key}
        onClick={(event: React.SyntheticEvent) => {
          event.preventDefault();
          scrollTo(id);
        }}
        className={`jump-link ${id === activeSection ? `active` : ``}`}
      >
        {content}
      </ResourceLink>
    );
  };

  return (
    <VStack
      id="doc-center-overview"
      className="doc-detail-page"
      align="stretch"
      overflowY="hidden"
    >
      <Box className="overview-header" overflowY="hidden">
        <Stack className="text-stack">
          {breadCrumb && (
            <Breadcrumb separator="|" className="breadcrumb">
              {breadCrumb.map((data, key) => (
                <BreadcrumbItem key={key}>
                  <Text className="doc-upper-text">{data.toUpperCase()}</Text>
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          )}
          <Heading className="headline" as="h1">
            {documentTitle}
          </Heading>
        </Stack>
      </Box>
      <Box
        className="document-page"
        id="doc-center-page"
        overflowY="scroll"
        onScroll={handleScroll}
      >
        <Grid className="grid" id={ID_TOP_OF_THE_PAGE}>
          <GridItem className="doc-center-body">
            <CardContainer>
              <Stack gap={8} ref={docBodyRef}>
                <DocCenterPageHeader
                  documentTitle={documentTitle}
                  slices={props.home.slices}
                  dateString={dateString}
                  docUrl={docUrl}
                  readTime={readTime}
                />
                <SliceZone components={components} slices={props.home.slices} />
              </Stack>
            </CardContainer>
          </GridItem>
          <GridItem colSpan={1}>
            <Stack className="sticky">
              <Box className="on-this-page">
                <div className="header">On this page</div>
                <Stack as="nav">
                  {getJumpLink(-1, ID_TOP_OF_THE_PAGE, documentTitle)}
                  {filteredPageIndexes.map((pageIndex: any, index: number) => {
                    const id = String(pageIndex.content.replaceAll(" ", "-"));
                    return getJumpLink(index, id, pageIndex.content);
                  })}
                </Stack>
              </Box>
              {additionalResources?.length > 0 && (
                <Box className="additional-resources">
                  <div className="header">Additional resources</div>
                  <Stack>
                    {additionalResources.map(
                      (additionalResource: any, key: any) => {
                        return (
                          <Box className="resource-box" key={key}>
                            <ResourceLink
                              className="app-btn-link"
                              href={fetchUrlForResources(additionalResource)}
                            >
                              {additionalResource.link_title}
                            </ResourceLink>
                          </Box>
                        );
                      }
                    )}
                  </Stack>
                </Box>
              )}
            </Stack>
          </GridItem>
        </Grid>
        <Box marginTop={32}>
          <FooterWidgetIframe />
        </Box>
        {!isUserAuthenticated && <FooterNotLoggedInUser />}
      </Box>
    </VStack>
  );
}

export default DocumentDetailPage;
