"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  StaticText,
} from "@/components/common";
import React, { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  GeneralFeatureProps,
  generalFeatureDetails,
  getValidationSchema,
} from "./TransactionGeneralFeatureSetupValidation";
import { useFormik } from "formik";
import {
  createChargeOffComponent,
  createTransactionDepositComponent,
} from "../../product-config-client-service";
import { useParams } from "next/navigation";
import {
  COMPONENT_CLASS,
  PRODUCT_CLASS_NAME,
  PRODUCT_CLASS_NAME_WITH_COMPONENT,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
interface commonTitleProps {
  componentClass: string;
  name: string;
  componentName: string;
  version: string;
  labelName: string;
  textPlaceholder: string;
  versionLabelName: string;
  versionStaticText: string;
  currencyLabelName: string;
  mainTitleCommonTitle?: string;
  data?: {
    formData: {
      componentName: string;
      version: number;
    } | null;
    generalComponentOptionsData: PostSchema;
  };
}
export default function TransactionGeneralFeatureSetup({
  name,
  componentName,
  version,
  labelName,
  textPlaceholder,
  versionLabelName,
  versionStaticText,
  mainTitleCommonTitle = "General Component Setup",
  componentClass,
  data,
}: commonTitleProps) {
  const featureData = data?.formData;
  const generalComponentOptionsData = data?.generalComponentOptionsData;
  const toast = useToast();
  const toastId = "general-feature-toast";

  const onSubmit = async (values: GeneralFeatureProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: componentClass,
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      let response;
      const formData = {
        componentName: values.componentName,
        version: 1,
      };
      const commonToastOptions = {
        toast,
        toastId,
      };
      switch (componentClass) {
        case COMPONENT_CLASS.chargeOff:
          response = await createChargeOffComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "Charge Off component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;
        case COMPONENT_CLASS.transactionDeposit:
          response = await createTransactionDepositComponent({
            productDetails,
            formData,
            toastOptions: {
              ...commonToastOptions,
              successMessage: "Transaction deposit component created.",
            },
            stageName: getWorflowStatusToBeUpdated?.() ?? "",
          });
          break;
        default:
          console.error("Invalid feature");
          break;
      }

      // Navigate only when there is a successful response
      if (response && productDetails?.name) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(generalComponentOptionsData!);
      setValidationSchema(yupSchema);
    })();
  }, [generalComponentOptionsData]);

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      componentName: featureData?.componentName ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    getCurrentStageStatusName,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  //Handling Form
  const transactionsGeneralFeatureSetupFormHandler =
    (key: string) => (value: string | any[]) => {
      setFieldValue(key, value);
    };
  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="app-form-container">
        <Box className={"app-form-field-container"}>
          <CommonTitle
            depositName={name}
            featureName={
              values?.componentName ? values?.componentName : componentName
            }
            version={`VERSION ${featureData?.version || 1}`}
            mainTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : mainTitleCommonTitle
            }
            subText={
              tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
                : ""
            }
          />
        </Box>
        <Box className={"app-form-field-container"}>
          <CardContainer>
            <Box className={"app-form-field-container"}>
              <AppFormLabel
                labelName={labelName}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("componentName")
                    ?.tooltip_text_main
                }
                isRequired
              />
              <InputText
                disabled={Boolean(featureData?.componentName)}
                value={values.componentName}
                onChange={transactionsGeneralFeatureSetupFormHandler(
                  "componentName"
                )}
                placeholder={textPlaceholder}
                name="componentName"
              />
              {errors.componentName && touched.componentName && (
                <ErrorAlert>
                  <span>{errors.componentName}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box>
              <AppFormLabel
                labelName={versionLabelName}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("version")
                    ?.tooltip_text_main
                }
                isRequired
              />
              <StaticText
                textValue={featureData?.version || versionStaticText}
              />
            </Box>
          </CardContainer>
        </Box>
      </Box>
    </form>
  );
}
