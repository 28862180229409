"use client";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  getValidationSchema,
  securitySpecificationProps,
} from "./CollateralSecurityValidation";
import { useFormik } from "formik";
import {
  CollateralComponentData,
  PostSchema,
} from "@/components/product-management/model/types";
import { createCollateralComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management/product-management-util-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
//Interface for GET_DATA
interface CollateralOption {
  label: string;
  value: string;
}
interface optionprops {
  collSecurityOption: CollateralOption[];
  formData?: CollateralComponentData["data"][number] | null;
  collateralSecuritySpecificationsOptionsData: PostSchema;
}
interface CollateralSecurityProps {
  data: optionprops;
}
const CollateralSecuritySpecifications = (props: CollateralSecurityProps) => {
  const {
    data: {
      formData: featureData,
      collateralSecuritySpecificationsOptionsData,
    },
  } = props;

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.collateralSecuritySpecificationsOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.collateralSecuritySpecificationsOptionsData]);

  // Form Submit Handler
  const onSubmit = async (values: securitySpecificationProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["collateral"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createCollateralComponent({
        productDetails,
        formData: {
          ...props.data.formData,
          // PATCH fields
          secLvl: values.secLvl,
          secPct: parseFloat(values.secPct),
        },
        toastOptions: {
          toast,
          toastId: "installment-collateral",
          successMessage: `${
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Security Specifications"
          } updated`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // using useFormik hook Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      secLvl: props.data.formData?.secLvl ?? -1,
      secPct:
        props.data.formData?.secLvl === 0
          ? "0"
          : props.data.formData?.secLvl === 1
            ? "100"
            : props.data.formData?.secPct?.toString() ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMenuList,
    sideBarMainMenuIndex,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const toast = useToast();
  const toastId = "general-feature-toast";

  //handle Collateral_Security_Specification_Form_Data
  const _collateralFormDataHandler =
    (key: string) => (value: string | number) => {
      if (key === "secLvl") {
        if (value === 0) setFieldValue("secPct", "0");
        else if (value === 1) setFieldValue("secPct", "100");
        else setFieldValue("secPct", "");
      }
      setFieldValue(key, value);
    };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CommonTitle
        depositName={getConstructedFeatureNameWithComponent(
          "componentCollateral",
          getIfxProdType(productDetails),
          true
        )}
        featureName={props?.data?.formData?.componentName || ""}
        version={`VERSION ${props?.data?.formData?.version || 1}`}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
            : "Security Specifications"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            : ""
        }
      />
      <Box className={"app-form-container"}>
        <CardContainer>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the collateral security level?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("secLvl")
                  ?.tooltip_text_main
              }
            />
            <RadioButtonGroup
              isBoxedRadio={true}
              value={values.secLvl}
              onChange={_collateralFormDataHandler("secLvl")}
              radioPropList={props.data.collSecurityOption}
              stackDirection={"column"}
              spacing={"1rem"}
            />
          </Box>

          <Box>
            <AppFormLabel
              labelName="What percentage of the loan is covered by securities?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("secPct")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={values.secPct}
              isDisabled={values.secLvl === 0 || values.secLvl === 1}
              onChange={_collateralFormDataHandler("secPct")}
              valueSuffix="%"
            />
            {errors.secPct && touched.secPct && (
              <ErrorAlert>
                <span>{errors.secPct}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
};
export default CollateralSecuritySpecifications;
