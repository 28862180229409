import React from "react";
import { HStack, Image, Spacer, Text, VStack } from "@chakra-ui/react";
import "./OrgEnvProjTableHeader.scss";
import { IconEnvOrgExpandMore } from "public/assets";

interface OrganizationTableProps {
  title: string;
  isExpanded: boolean;
  onExpand: () => void;
  shouldShowBorder: boolean;
  shouldShowExpandIcon: boolean;
  alwaysExpanded: boolean;
}

const OrgEnvProjTableHeader: React.FC<OrganizationTableProps> = ({
  title,
  isExpanded,
  onExpand,
  shouldShowBorder,
  shouldShowExpandIcon,
  alwaysExpanded
}) => {
  return (
    <VStack className={`org-env-container`} onClick={() => {
      if (!alwaysExpanded) {
        onExpand()
      }
    }}>
      <HStack className="h-stack">
        <Text className="org-env-header-title">{title}</Text>
        <Spacer />
        {shouldShowExpandIcon && !alwaysExpanded && (
          <Image
            src={IconEnvOrgExpandMore.src}
            alt="IconEnvOrgNotFav"
            className={`icon-expand-collapsed ${isExpanded ? "rotate-expanded" : ""}`}
          />
        )}
      </HStack>
    </VStack>
  );
};

export default OrgEnvProjTableHeader;
