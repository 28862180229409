"use client";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  DatePicker,
  SplitButton,
  TimeField,
} from "@/components/common";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Heading, Stack, useToast } from "@chakra-ui/react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

import { ROUTE_PATH } from "@/route-config/route-path";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  COMPONENT_CLASS,
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  makeDateTime,
  Option,
  RjsfData,
  shouldUpdateWorkflowStatus,
  splitDateTime,
  useQueryParams,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  addNewComponent,
  getCompLatestVersion,
} from "@/components/product-management/product-configuration/product-config-client-service";
import { OptionsSchema } from "@/components/product-management/model/types";
import { useSearchParams } from "next/navigation";

type FinOrgRuleProps = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    formData: any;
    finOrgFormData: any;
    rulesOptionsData: Option<string>[];
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
  };
};
const FinOrgRulesComponent = (props: FinOrgRuleProps) => {
  const toast = useToast();
  const toastId = "fin-org-rules";
  const updateQueryParams = useQueryParams();
  const params = useSearchParams();

  const {
    depositName,
    featureName,
    version,
    mainTitle,
    subText,
    data: {
      formData,
      finOrgFormData,
      rulesOptionsData,
      addNewSchema,
      addNewOptionEndpoint,
    },
  } = props;

  const schema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("ruleOption", response?.componentName ?? "");
    return { ...response, rulesData: rjsfData?.formData ?? null };
  };

  const moveToNextStep = (paramsArg: string) => {
    if (!canCheckFormStatus) {
      if (params?.get("initialFlow")) {
        navigateTo(
          `${ROUTE_PATH.IC_INTERSTITIAL_SCREEN}?${updateQueryParams()}`
        );
      } else {
        navigateTo(
          `${ROUTE_PATH.IC_COMPLETION_SCREEN}?entityId=${paramsArg}&entityType=${IC_ENTITY.financial_organization}`
        );
      }
    }
    return;
  };

  const onSubmit = async (values: any, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.fin_org_rules;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: finOrgFormData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep(formData?._Id ?? "");
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const compResponse = await getComponentDetails(values.ruleOption);
    const rulesConfig = compResponse?.data?.[0] ?? null;
    if (rulesConfig || !values.addRules) {
      const response = await updateFinOrg({
        formData: {
          _vn: finOrgFormData?._vn,
          _Id: finOrgFormData?._Id,
          party_orgRules: {
            ...(values.addRules && rulesConfig
              ? {
                  ...rulesConfig,
                }
              : {}),
            endDtm: makeDateTime(
              values.operationalEndSectionDate,
              values.operationalEndSectionTime
            ),
          },
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${mainTitle} updated.`,
        },
        stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
          ? currentStage
          : "",
      });
      if (response) {
        moveToNextStep(formData?._Id ?? "");
        return;
      }
    }
  };

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  async function getComponentDetails(value: string) {
    if (value) {
      return await getCompLatestVersion(COMPONENT_CLASS.rules, value);
    }
    return null;
  }

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    initialValues: {
      addRules: formData?.componentName ? true : undefined,
      ruleOption: formData?.componentName,
      operationalStartSectionDate: formData?.endDtm
        ? splitDateTime(formData?.endDtm).date
        : null,
      operationalStartSectionTime: formData?.endDtm
        ? splitDateTime(formData?.endDtm).time
        : "",
    },
  });

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    updateGoingBackState,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const formTitle = (
    <CommonTitle
      depositName={depositName}
      featureName={featureName}
      version={version}
      mainTitle={tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle}
      subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
    />
  );

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      updateGoingBackState?.(false);
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      {formTitle}
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <Heading className="font-size-24px" as="h3">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
              ?.section_title || "Add new rule"}
          </Heading>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel labelName="Add rules component" isRequired={false} />
          <SplitButton
            name="addRules"
            onSelect={handleOnChange("addRules")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.addRules}
          />
        </Box>
        {values.addRules && (
          <>
            <Box className="app-form-field-container">
              <CoupleRadioWithDropdown
                primaryOptionValue={values.ruleOption as any}
                primaryRadioName={"Select from existing rule component"}
                secondaryRadioName={"Add new rule component"}
                handlePrimaryOptionSelect={handleOnChange("ruleOption")}
                placeHolderName="Select existing rule component"
                dropdownList={rulesOptionsData?.map(
                  (d: { label: string }) => d.label
                )}
                onBlur={handleBlur}
                id="add_rule_Option"
                schema={schema}
                onAddNewSubmit={addNewOption}
              />
            </Box>

            <Box display="flex" gap="2rem">
              <Stack flexBasis="50%">
                <AppFormLabel
                  labelName="What is end date?"
                  labelFor="end_date"
                />
                <DatePicker
                  id="end_date"
                  value={values.operationalStartSectionDate}
                  onUpdate={handleOnChange("operationalStartSectionDate")}
                  placeholder="Select"
                />
              </Stack>

              <Stack flexBasis="50%">
                <AppFormLabel labelName="Time" labelFor="end_time_input" />
                <TimeField
                  value={values.operationalStartSectionTime}
                  onChange={handleOnChange("operationalStartSectionTime")}
                  ariaLabel="end time"
                  id="end_time_input"
                />
              </Stack>
            </Box>
          </>
        )}
      </CardContainer>
    </form>
  );
};

export default FinOrgRulesComponent;
