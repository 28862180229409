import React from "react";
import {
  ChakraProvider,
  HStack,
  IconButton,
  Image,
  Spacer,
  Spinner,
  Text,
  UseToastOptions,
  extendTheme,
  useToast,
} from "@chakra-ui/react";
import {
  IconEnvOrgFav,
  IconEnvOrgNotFav,
  IconOrgEnvSelected,
} from "public/assets";
import "./OrgEnvProjTableItem.scss";
import {
  OrgEnvDropdownMenuItemBase,
  OrgEnvDropdownMenuItemOrg,
} from "./Models";
import { addFavOrg, removeFavOrg } from "@/api-config/api-service";
import { getEnvId, getOrgID, getEnvType } from "@/store";
import { useSelector } from "react-redux";

interface OrganizationTableItemProps {
  data: OrgEnvDropdownMenuItemBase | OrgEnvDropdownMenuItemOrg;
  onSelect: () => void;
  shouldShowFavourite: boolean;
  onFavourite: (orgId: number) => void;
  isFirstSelectedItem: boolean;
  itemType: string;
}

const OrgEnvProjTableItem: React.FC<OrganizationTableItemProps> = ({
  data,
  onSelect,
  shouldShowFavourite,
  onFavourite,
  isFirstSelectedItem,
  itemType,
}) => {
  const orgData = data as OrgEnvDropdownMenuItemOrg;
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useToast();
  const envId = useSelector(getEnvId);
  const orgId = useSelector(getOrgID);
  const envType = useSelector(getEnvType);

  const toastOptions = (success: boolean, message: string): UseToastOptions => {
    return {
      description: message,
      status: success ? "success" : "error",
      duration: 3000,
      isClosable: true,
    };
  };

  const handleSuccess = (response: any) => {
    setIsLoading(false);
    onFavourite(data.id);
    toast(toastOptions(true, response.status));
    // if (isFirstSelectedItem) orgData.isFavourite = !orgData.isFavourite;
  };

  const handleError = (error: any) => {
    setIsLoading(false);
    toast(toastOptions(false, error.error ?? "Something went wrong!"));
  };

  const handleFavourite = () => {
    setIsLoading(true);

    orgData.isFavourite
      ? removeFavOrg(data.id).then(handleSuccess).catch(handleError)
      : addFavOrg(data.id).then(handleSuccess).catch(handleError);
  };

  const theme = extendTheme({
    components: {
      Spinner: {
        baseStyle: {
          color: "#F7CE46"
        },
      },
    },
  });  

  return (
    <HStack
      className={`org-env-item-container ${isFirstSelectedItem ? "is-first-selected-item" : ""}`}
    >
      {(envType === "env" && itemType === "env" && data.id === envId) || 
      (envType === "proj" && itemType === "proj" && data.id === envId) ||
      (itemType === "org" && data.id === Number(orgId)) ? (
        <Image src={IconOrgEnvSelected.src} aria-label="selection icon" />
      ) : (
        <div className="no-left-icon-div" />
      )}
      <Text className={`title ${data.isSelected ? "selected" : ""}`} onClick={onSelect}>
        {data.name}
      </Text>
      <Spacer onClick={onSelect}/>
      {shouldShowFavourite && data.id !== -1 && (
        <IconButton
          className="fav-button"
          icon={
            isLoading ? (
              <ChakraProvider theme={theme}>
                <Spinner />
              </ChakraProvider>
            ) : (
              <Image
                src={
                  orgData.isFavourite ? IconEnvOrgFav.src : IconEnvOrgNotFav.src
                }
                alt="IconEnvOrgNotFav"
              />
            )
          }
          aria-label="Fav button"
          onClick={handleFavourite}
        />
      )}
    </HStack>
  );
};

export default OrgEnvProjTableItem;
