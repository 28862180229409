// OrgSelectorButton.tsx

import React, { forwardRef } from "react";
import { Button, HStack, Image, Spacer, Spinner, Text } from "@chakra-ui/react";
import "./OrgSelectorButton.scss";
import { IconEnvOrgDropDownButtonExpandMore } from "public/assets";

interface OrgSelectorButtonProps {
  isOpen: boolean;
  onClick: () => void;
  envName?: string;
  isAPILoading: boolean;
}

// eslint-disable-next-line react/display-name
const OrgSelectorButton = forwardRef(
  (props: OrgSelectorButtonProps, ref: any) => {
    const { isOpen, onClick, envName, isAPILoading } = props;
    return (
      <Button
        onClick={() => {
          if (!isAPILoading) {
            onClick();
          }
        }}
        className={`button-org-env ${isOpen ? "isOpen" : ""} ${!isAPILoading ? "api-loaded" : ""}`}
        ref={ref}
        disabled={isAPILoading}
      >
        <HStack className="h-stack">
          <Text className="title">
            {isAPILoading ? "Loading environments..." : "Org / Env"}
          </Text>
          <Spacer />
          {isAPILoading ? (
            <Spinner color="white" />
          ) : (
            <Image
              className={`drop-down-icon`}
              src={IconEnvOrgDropDownButtonExpandMore.src}
              alt="icon expand"
            />
          )}
        </HStack>
      </Button>
    );
  },
);

export default OrgSelectorButton;
