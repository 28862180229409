"use client";
import {
  AppFormLabel,
  AppNumberInput,
  AppSingleSelectDropdown,
  CardContainer,
  CommonTitle,
  DurationInput,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";

import React, { useContext, useEffect, useState } from "react";

import { INTERNAL_SCHEMA_TYPE } from "@/components/data/form-data";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  RepaymentPrincipalAndInterestProps,
  getValidationSchema,
  repaymentPrincipalAndInterestDetails,
} from "./RepaymentPrincipalInterestConfigurationValidation";
import { useFormik } from "formik";
import { Box, useToast } from "@/components/ChakraUiManager";
import { createRepaymentComponent } from "../../product-config-client-service";
import { PostSchema, RepaymentComponentData } from "../../../model/types";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import {
  COMPONENT_CLASS,
  Option,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { getIfxProdType } from "@/components/product-management";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
type RepaymentPrincipalInterestProps = {
  data: {
    calculatingPrincipal: INTERNAL_SCHEMA_TYPE<string>[];
    interestCalculatedOption: string[];
    balanceBaseOption: INTERNAL_SCHEMA_TYPE<string>[];
    oddDaysIntOptOptions: Option<number>[];
    formData?: RepaymentComponentData | null;
    principalInterestOptionsData: PostSchema;
  };
};

const RepaymentPrincipalAndInterestConfiguration = (
  props: RepaymentPrincipalInterestProps
) => {
  const { data } = props;

  const toast = useToast();
  const findInterestCalculatedOptionIndex = (
    interestCalculatedOptions: string[],
    targetValue: string
  ): number => {
    return (
      interestCalculatedOptions.findIndex((option) => option === targetValue) +
      1
    );
  };
  const getInterestCalculatedOptionByIndex = (
    interestCalculatedOptions: string[],
    index: number
  ): string | undefined => {
    return interestCalculatedOptions[index - 1];
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.principalInterestOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.principalInterestOptionsData]);

  const onSubmit = async (
    values: RepaymentPrincipalAndInterestProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["repay"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = props.data;
    if (formData && productDetails) {
      const res = await createRepaymentComponent({
        productDetails,
        formData: {
          ...formData,
          // PATCH fields
          prinMthd: values.prinMthd,
          fixPrinAmt: parseFloat(values.fixPrinAmt),
          intMthd: values.intMthd as number,
          fixIntAmt: parseFloat(values.fixIntAmt),
          prinBalPct: parseFloat(values.prinBalPct),
          prinBalBase: values.prinBalBase,
          amortizeDur: values.amortizeDur,
          oddDaysIntOpt: values.oddDaysIntOpt,
        },
        toastOptions: {
          toast,
          toastId: "repayment-config",
          successMessage: `${
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Principal and Interest Configuration"
          } updated`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };
  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      prinMthd:
        data.formData?.prinMthd ??
        repaymentPrincipalAndInterestDetails.prinMthd,
      fixPrinAmt:
        data.formData?.fixPrinAmt?.toString() ??
        repaymentPrincipalAndInterestDetails.fixPrinAmt,
      intMthd:
        data.formData?.intMthd ?? repaymentPrincipalAndInterestDetails.intMthd,
      fixIntAmt:
        data.formData?.fixIntAmt?.toString() ??
        repaymentPrincipalAndInterestDetails.fixIntAmt,
      prinBalPct:
        data.formData?.prinBalPct?.toString() ??
        repaymentPrincipalAndInterestDetails.prinBalPct,
      prinBalBase:
        data.formData?.prinBalBase ??
        repaymentPrincipalAndInterestDetails.prinBalBase,
      amortizeDur:
        data.formData?.amortizeDur ??
        repaymentPrincipalAndInterestDetails.amortizeDur,
      oddDaysIntOpt:
        data.formData?.oddDaysIntOpt ??
        repaymentPrincipalAndInterestDetails.oddDaysIntOpt,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    productDetails,
    canCheckFormStatus,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const formTitle = (
    <CommonTitle
      depositName={getConstructedFeatureNameWithComponent(
        COMPONENT_CLASS["repay"],
        getIfxProdType(productDetails),
        true
      )}
      featureName={props.data.formData?.componentName ?? ""}
      version={`VERSION ${props.data.formData?.version ?? "1"}`}
      mainTitle={
        tooltipFlyoutDetails?.pageHeaderDetails?.heading
          ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
          : "Principal and Interest Configuration"
      }
      subText={
        tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
          ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
          : ""
      }
    />
  );

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const repaymentPrincipallinterestFormHandler =
    (key: string) => (value: string | number | boolean) => {
      setFieldValue(key, value);
    };

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      {formTitle}
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Choose a method for calculating principal payments"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("prinMthd")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.prinMthd}
            onChange={repaymentPrincipallinterestFormHandler("prinMthd")}
            radioPropList={props.data.calculatingPrincipal}
            stackDirection={"column"}
            spacing={"1rem"}
          />
          {errors.prinMthd && touched.prinMthd && (
            <ErrorAlert>
              <span>{errors.prinMthd}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the fixed amount for principal repayment?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("fixPrinAmt")
                ?.tooltip_text_main
            }
            value={values.fixPrinAmt}
            onChange={repaymentPrincipallinterestFormHandler("fixPrinAmt")}
            valuePrefix="$"
          />
          {errors.fixPrinAmt && touched.fixPrinAmt && (
            <ErrorAlert>
              <span>{errors.fixPrinAmt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="How will interest be calculated?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("intMthd")
                ?.tooltip_text_main
            }
          />
          <AppSingleSelectDropdown
            dropdownList={props.data.interestCalculatedOption}
            placeholder="Select Option"
            value={values.intMthd!}
            onChange={repaymentPrincipallinterestFormHandler("intMthd")}
            keyName="label"
            id="interestCalculated"
          ></AppSingleSelectDropdown>
          {errors.intMthd && touched.intMthd && (
            <ErrorAlert>
              <span>{errors.intMthd}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the fixed interest amount?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("fixIntAmt")
                ?.tooltip_text_main
            }
            value={values.fixIntAmt}
            onChange={repaymentPrincipallinterestFormHandler("fixIntAmt")}
            valuePrefix="$"
          />
          {errors.fixIntAmt && touched.fixIntAmt && (
            <ErrorAlert>
              <span>{errors.fixIntAmt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What percentage of the balance should be used for calculation?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("prinBalPct")
                ?.tooltip_text_main
            }
            value={values.prinBalPct}
            onChange={repaymentPrincipallinterestFormHandler("prinBalPct")}
            valueSuffix="%"
          />
          {errors.prinBalPct && touched.prinBalPct && (
            <ErrorAlert>
              <span>{errors.prinBalPct}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the balance base?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("prinBalBase")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.prinBalBase}
            onChange={repaymentPrincipallinterestFormHandler("prinBalBase")}
            radioPropList={props.data.balanceBaseOption}
            stackDirection={"row"}
            spacing={"1rem"}
          />
          {errors.prinBalBase && touched.prinBalBase && (
            <ErrorAlert>
              <span>{errors.prinBalBase}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the odd days interest option?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("oddDaysIntOpt")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.oddDaysIntOpt}
            onChange={repaymentPrincipallinterestFormHandler("oddDaysIntOpt")}
            radioPropList={props.data.oddDaysIntOptOptions}
            stackDirection="column"
            spacing={"1rem"}
          />
          {errors.oddDaysIntOpt && touched.oddDaysIntOpt && (
            <ErrorAlert>
              <span>{errors.oddDaysIntOpt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <DurationInput
            labelName="What is the amortization duration"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("amortizeDur")
                ?.tooltip_text_main
            }
            value={values.amortizeDur}
            onChange={repaymentPrincipallinterestFormHandler("amortizeDur")}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter Amortization Duration"}
            modalLabelName="What is the amortization duration?"
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("amortizeDur")
                ?.tooltip_text_main
            }
          />

          {errors.amortizeDur && touched.amortizeDur && (
            <ErrorAlert>
              <span>{errors.amortizeDur}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default RepaymentPrincipalAndInterestConfiguration;
