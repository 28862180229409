"use client";
import { AlgoliaSearchFilter, TopBannerContainer } from "@/components/common";
import {
  Box,
  Heading,
  Text,
  IconButton,
  CloseIcon,
  Icon,
  Button,
  Flex,
} from "@/components/ChakraUiManager";
import algoliasearch from "algoliasearch";
import { useSearchParams } from "next/navigation";
import "./AlgoliaSearch.scss";
import { ArrowBackIcon, ArrowForwardIcon, SearchIcon } from "@chakra-ui/icons";
import { useState, ReactNode } from "react";
import { CustomHit } from "@/utils/algolia";
import {
  InstantSearch,
  SearchBox,
  useInstantSearch,
  Pagination,
  Configure,
  Stats,
  usePagination,
  UsePaginationProps,
} from "react-instantsearch";

import { useRouter } from "next/navigation";

import { useSelector } from "react-redux";
import { getAppConfig } from "@/store";

type GlobalSearchResultProps = {
  data: any;
  pageSize?: number;
};
interface NoResultsBoundaryProps {
  children: ReactNode;
  fallback: ReactNode;
  slug?: string;
  indexName: string;
}
interface InitialUiState {
  [indexName: string]: {
    query: string;
  };
}

export default function AlgoliaSearch() {
  const searchParams = useSearchParams();
  const searchQuery = searchParams?.get("searchQuery") || "";
  const slugQuery = searchParams?.get("slug") || "";
  const [searchText, setSearchText] = useState("");
  const appConfig = useSelector(getAppConfig);

  const router = useRouter();

  const clearSeearch = () => {
    setSearchText("");
  };

  const searchClient = algoliasearch(
    appConfig?.searchAppId!,
    appConfig?.searchKey!
  );

  const clearSearchParam = (param: string) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.delete(param);
      router.push(url.toString(), { scroll: false });
    }
  };

  const hitsPerPage = slugQuery === "tutorials" ? 75 : 35;

  return (
    <>
      <TopBannerContainer domId="#algoliaTopBanner">
        <Heading as="h1" fontSize={"36px"} py={2} px={4}>
          Global Search Results
        </Heading>
        <Text fontSize={"18px"} px={4} py={1}>
          {searchQuery && <>Showing results for: {searchQuery}</>}
        </Text>
      </TopBannerContainer>
      <Box className="algolia-global-search-container">
        <div className="algolia-search-container">
          <InstantSearch
            indexName={appConfig?.searchIndex}
            searchClient={searchClient}
            initialUiState={
              appConfig?.searchIndex
                ? {
                    [appConfig?.searchIndex]: {
                      query: searchQuery,
                    },
                  }
                : undefined
            }
          >
            <Flex className="global-search" alignItems="center">
              <Box className="search-container-expanded">
                <IconButton
                  aria-label="Search"
                  icon={<SearchIcon />}
                  variant="ghost"
                  className="nav-search-icon"
                />
                <SearchBox
                  placeholder="Search across Console, Finxact Academy, & API References"
                  classNames={{
                    root: "global-search-input",
                    form: "global-search-input",
                    input: "global-search-input",
                    submitIcon: "display-none",
                  }}
                />
                {searchText && (
                  <Box className="icon-close">
                    <Icon
                      as={CloseIcon}
                      className="tag-icon-styling-close"
                      onClick={clearSeearch}
                    />
                  </Box>
                )}
              </Box>
              <Box className="w-100">
                <div className="filter-container">
                  {slugQuery && (
                    <Button
                      size="sm"
                      onClick={() => clearSearchParam("slug")}
                      mb={6}
                      className="app-btn-reg-secondary-transparent"
                    >
                      <div className="filter-by">{`Clear Context "${slugQuery}"`}</div>
                    </Button>
                  )}
                  <AlgoliaSearchFilter
                    refinementAttribute="slug"
                    ignoreLabel={true}
                    refinementSortBy={["count:desc"]}
                  />
                </div>

                <NoResultsBoundary
                  slug={slugQuery}
                  indexName={appConfig!.searchIndex}
                  fallback={<NoResults />}
                >
                  <Pagination />

                  <Pagination />
                </NoResultsBoundary>
                <Configure hitsPerPage={hitsPerPage} />
              </Box>
            </Flex>
          </InstantSearch>
        </div>
      </Box>
    </>
  );
}

function NoResultsBoundary({
  children,
  fallback,
  slug,
  indexName,
}: NoResultsBoundaryProps) {
  const { results, uiState, indexUiState } = useInstantSearch();
  const searchQuery = uiState[indexName]?.query;

  if (!indexUiState.query) {
    return "";
  }

  if (!results.hits.length && results.nbHits === 0) {
    return fallback;
  }

  return (
    <>
      <h6 className="results-count">
        <Stats
          translations={{
            rootElementText({ nbHits }) {
              return `${nbHits.toLocaleString()} results`;
            },
          }}
        />
      </h6>
      <Flex className="search-results">
        <CustomHit slug={slug} location="results" />
        <CustomPagination />
      </Flex>
    </>
  );
}

function NoResults() {
  const { setUiState, uiState } = useInstantSearch();
  return (
    <Flex className="search-results flex-acenter">
      <p>No results found.</p>
    </Flex>
  );
}

function CustomPagination(props: UsePaginationProps) {
  const {
    pages,
    currentRefinement,
    nbHits,
    nbPages,
    isFirstPage,
    isLastPage,
    canRefine,
    refine,
    createURL,
  } = usePagination(props);
  const firstPageIndex = 0;
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const lastPageIndex = nbPages - 1;
  const currPage = currentRefinement === 0 ? 1 : currentRefinement + 1;
  return (
    <ul className="algolia-pagination-panel">
      <PaginationItem
        isDisabled={isFirstPage}
        href={createURL(previousPageIndex)}
        onClick={() => refine(previousPageIndex)}
      >
        <IconButton
          isRound={true}
          aria-label="Done"
          fontSize={30}
          icon={<ArrowBackIcon />}
          variant="outline"
          p={2}
          size={"xl"}
          isDisabled={isFirstPage}
          onClick={() => refine(previousPageIndex)}
          className="pag-nav-btn"
        />
      </PaginationItem>
      <div className="pages">
        {pages.map((page) => {
          const label = page + 1;

          return (
            <PaginationItem
              className={label === currPage ? "active" : ""}
              key={page}
              isDisabled={false}
              aria-label={`Page ${label}`}
              href={createURL(page)}
              onClick={() => refine(page)}
            >
              {label}
            </PaginationItem>
          );
        })}
      </div>
      <PaginationItem
        isDisabled={isLastPage}
        href={createURL(nextPageIndex)}
        onClick={() => refine(nextPageIndex)}
      >
        <IconButton
          isRound={true}
          variant="outline"
          aria-label="Done"
          fontSize={30}
          icon={<ArrowForwardIcon />}
          p={2}
          size={"xl"}
          onClick={() => refine(nextPageIndex)}
          isDisabled={isLastPage}
          className="pag-nav-btn"
        />
      </PaginationItem>
    </ul>
  );
}

type PaginationItemProps = Omit<React.ComponentProps<"a">, "onClick"> & {
  onClick: NonNullable<React.ComponentProps<"a">["onClick"]>;
  isDisabled: boolean;
};

function PaginationItem({
  isDisabled,
  href,
  onClick,
  ...props
}: PaginationItemProps) {
  if (isDisabled) {
    return (
      <li>
        <span {...props} />
      </li>
    );
  }

  return (
    <li>
      <a
        href={href}
        onClick={(event) => {
          if (isModifierClick(event)) {
            return;
          }

          event.preventDefault();

          onClick(event);
        }}
        {...props}
      />
    </li>
  );
}

function isModifierClick(event: React.MouseEvent) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey
  );
}
