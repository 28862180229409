"use client";
import DocumentList from "./landing-page/doc-list/DocumentList";
import DocCenterExplore from "./landing-page/doc-center-explore/DocCenterExplore";
import DocumentCenterBanner from "./landing-page/document-center-banner/DocumentCenterBanner";

import { useUserAuthenticated, useUserInfo } from "@/app/providers";
import {FooterNotLoggedInUser, FooterWidgetIframe} from "@/components/common";
import {docCenterConfigType} from "@/prismic-api/document-service";
import { Content } from "@prismicio/client";
import { Box } from "@chakra-ui/react";


function DocumentCenter(props: {
  recentlyViewed: Content.AllDocumentTypes[];
  docCenterConfig: docCenterConfigType;
}) {
  const userAuthenticated = useUserAuthenticated();
  const { user } = useUserInfo();

  const recentlyViewedHeaderData =
    props.docCenterConfig?.recently_viewed_docs?.[0] || null;
  const recentlyViewedList =
    props.recentlyViewed?.length > 0
      ? props.recentlyViewed.map((document: any) => ({
          title: document.data?.article_main_title[0]?.text,
          description: document.data?.flyout_subhead,
          url: document.uid,
          type: document.type,
        }))
      : [];

  const isUserAuthenticated = user.loading == false && user.signedIn == true;
  return (
    <>
      <DocumentCenterBanner
        name={isUserAuthenticated ? user.firstName : ""}
        docCenterHero={props.docCenterConfig?.doc_center_hero || []}
        isUserAuthenticated={isUserAuthenticated}
      />
      {props.docCenterConfig?.three_up_featured_documents && (
        <DocCenterExplore
          {...props.docCenterConfig.three_up_featured_documents}
        />
      )}
      {recentlyViewedList?.length > 0 && (
        <DocumentList
          title={recentlyViewedHeaderData?.headline || "Recently viewed docs"}
          subTitle={
            recentlyViewedHeaderData?.subhead ||
            "Refer back to the latest docs you've taken a look at"
          }
          docList={recentlyViewedList}
        />
      )}
      {props.docCenterConfig?.recommendedDocs && (
        <DocumentList {...props.docCenterConfig.recommendedDocs} />
      )}
      <hr />
      <Box marginTop={32}>
        <FooterWidgetIframe />
      </Box>
      {!isUserAuthenticated && <FooterNotLoggedInUser />}
    </>
  );
}
export default DocumentCenter;
