"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  ModalSelector,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import React, { useContext, useEffect, useState } from "react";
import {
  TransactionCodeSpecificationsProps,
  getValidationSchema,
} from "./NSFTransactionCodeSpecificationValidation";
import { useFormik } from "formik";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { createNSFComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
  Option,
} from "@/utils";
import { NsfComponentResponse, PostSchema } from "../../../model/types";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { getIfxProdType } from "@/components/product-management";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type NSFConfigurationProps = {
  data: {
    transactionCodes: Option<string>[];
    formData?: NsfComponentResponse["data"][number] | null;
    nsfTransactionCodeSpecificationOptionsData: PostSchema;
  };
};
const NSFTransactionCodeSpecification = (props: NSFConfigurationProps) => {
  const toast = useToast();

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.nsfTransactionCodeSpecificationOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.nsfTransactionCodeSpecificationOptionsData]);
  const onSubmit = async (
    values: TransactionCodeSpecificationsProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["nsf"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createNSFComponent({
        productDetails,
        formData: {
          ...props.data.formData,
          // PATCH fields
          trnCodeExcl: values.trnCodeExcl,
        },
        toastOptions: {
          toast,
          toastId: "nfs-transaction-code-specification",
          successMessage: `${
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "NSF - Transaction Code Specifications"
          } updated`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };
  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    useFormik({
      onSubmit,
      validationSchema,
      initialValues: {
        trnCodeExcl: props.data.formData?.trnCodeExcl ?? [],
      },
    });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const transactionsCodeSpecificationFormHandler =
    (key: string) => (value: any[]) => {
      setFieldValue(key, value);
    };

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="app-form-container">
        <CommonTitle
          depositName={
            getConstructedFeatureNameWithComponent(
              "componentNsf",
              getIfxProdType(productDetails),
              true
            ) || "Component Details"
          }
          featureName={props.data.formData?.componentName ?? ""}
          version={`VERSION ${props.data.formData?.version || 1}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "NSF - Transaction Code Specifications"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : "Let's start with the basics and then dive into the details"
          }
        />
        <Box>
          <CardContainer>
            <Box className={"app-form-field-container"}>
              <AppFormLabel
                labelName="What transaction codes are excluded?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("trnCodeExcl")
                    ?.tooltip_text_main
                }
              />
              <ModalSelector
                value={values.trnCodeExcl}
                linkName={"+ Select transaction code(s)"}
                modalTitle={"Select the transaction code"}
                optionList={props.data.transactionCodes}
                isSingleSelect={false}
                onChange={transactionsCodeSpecificationFormHandler(
                  "trnCodeExcl"
                )}
              />
            </Box>
          </CardContainer>
        </Box>
      </Box>
    </form>
  );
};
export default NSFTransactionCodeSpecification;
