"use client";

import { DefaultFormDetails } from "@/components/institutional-configuration/model";
import { TransCodeEntries, TransCodeResponse } from "../../models";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, useToast, Flex, Text } from "@chakra-ui/react";
import { useContext, useEffect, useMemo } from "react";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  CardContainer,
  CommonTitle,
  StaticText,
} from "@/components/common";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  DASHBOARD_ACCORDION_STATUS,
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUMMARY_LIST,
  TC_STAGE_ORDER,
  TC_STAGE_STATUS,
  WORKFLOW_STATUS,
  deepCopy,
  useQueryParams,
} from "@/utils";
import { updateTransCode } from "../../transaction-code-api-service";
import Link from "next/link";
import { IconEdit } from "public/assets";
import Image from "next/image";
import "./TcEntrySummary.scss";
import { useRouter } from "next/navigation";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useSelector } from "react-redux";
import { getEnvIsDisable } from "@/store";

interface TcEntrySummaryProps extends DefaultFormDetails {
  data: {
    formData: TransCodeResponse | null;
  };
}

interface TcEntrySummaryFormProps {
  entries: TransCodeEntries[];
}
const initialFormValues: TcEntrySummaryFormProps = {
  entries: [],
};
const TcEntrySummary = (props: TcEntrySummaryProps) => {
  const router = useRouter();
  const {
    depositName,
    featureName,
    version,
    mainTitle,
    subText,
    data: { formData },
  } = props;
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateTo,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    setSideBarCurrentIndex,
    checkFormStatus,
    sideBarMainMenuIndex,
    sideBarSubMenuIndex,
    entityWorkflowData,
    tooltipFlyoutDetails,
    selectedBtnType,
    navigateToNextPage,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const toastId = "tc-entry-summary";
  const updateQueryParams = useQueryParams();
  const isEnvDisabled = useSelector(getEnvIsDisable);

  const entrySetupStatus = useMemo(() => {
    return entityWorkflowData?.filter(
      (wfd) =>
        wfd.stage === TC_STAGE_STATUS.entry_setup &&
        wfd.status === WORKFLOW_STATUS.completed
    )?.[0]?.status;
  }, [entityWorkflowData]);

  const onSubmit = async (values: TcEntrySummaryFormProps, actions: any) => {
    const existingFormData = deepCopy(formData);
    const url = `${ROUTE_PATH.TC_INTERSTITAL_SCREEN}?${updateQueryParams()}`;
    if (!dirty) {
      moveToNextStep();
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : WORKFLOW_STATUS.completed,
      });
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };

    if (existingFormData)
      existingFormData.entries = values.entries as TransCodeEntries[];
    const response = await updateTransCode({
      formData: {
        ...existingFormData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : DASHBOARD_ACCORDION_STATUS.completed,
    });

    if (response) {
      moveToNextStep();
    }
  };

  function moveToNextStep() {
    // User navigates to respective page on sidebar click
    if (selectedBtnType === "sidebar") {
      navigateToNextPage?.("sidebar");
      return;
    }
    const isFlowNameExist = updateQueryParams()?.includes(
      QUERY_PARAM_KEY.FLOW_NAME
    );
    if (!isFlowNameExist && (isEnvDisabled || entrySetupStatus)) {
      navigateTo?.(`${ROUTE_PATH.TC_LANDING_PAGE}`);
    } else {
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      // reset checkFormStatus
      checkFormStatus?.(false);
      navigateTo?.(
        `${ROUTE_PATH.TC_INTERSTITAL_SCREEN}?${updateQueryParams()}`
      );
    }
  }

  useEffect(() => {
    values.entries = formData?.entries || [];
  }, [formData]);

  let constructedFormValues: TcEntrySummaryFormProps = initialFormValues;
  if (formData?.entries)
    constructedFormValues.entries = formData?.entries || [];

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    initialValues: constructedFormValues,
  });
  // using useFormik hook from Formik Library
  const { values, handleSubmit, setFieldValue, setValues, dirty } =
    formikConfigs;
  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${ROUTE_PATH.TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS}?${updateQueryParams()}`
      );

      updateStatusInSideBarMenuList?.(
        entityWorkflowData
          ?.find((item) => item.stage === TC_STAGE_STATUS.entry_setup)
          ?.status.includes(DASHBOARD_ACCORDION_STATUS.completed)
          ? SIDEBAR_STATUS.completed
          : SIDEBAR_STATUS.not_started
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    setSideBarCurrentIndex?.(
      TC_STAGE_ORDER.entry_setup,
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_summary
    );
  }, []);

  useEffect(() => {
    if (
      sideBarSubMenuIndex === TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_summary
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  const formTitle = (
    <>
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />
    </>
  );
  const getUrlQueryParam = (index: number) => {
    return `?${QUERY_PARAM_KEY.ENTITY_ID}=${entityId}&${QUERY_PARAM_KEY.TC_ENTRY_INDEX}=${index}`;
  };
  return (
    <>
      <FormikProvider value={formikConfigs}>
        <form onSubmit={handleSubmit} noValidate id="finxact-form">
          {formTitle}
          <FieldArray
            name="entries"
            render={({ insert, remove, push }) => (
              <>
                {values?.entries?.map(
                  (entry: TransCodeEntries, index: number) => (
                    <CardContainer key={index} customClass="app-form-container">
                      <AccordionComponent
                        accordionTitle={`Transaction entry ${index + 1}`}
                        limitName={entry.entryName}
                        deleteIcon
                        onDeleteHandler={() => {
                          remove(index);
                        }}
                        isExpand={index === 0 ? 0 : 1}
                      >
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="Entry name"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "entryName"
                              )?.tooltip_text_main
                            }
                          />
                          <StaticText textValue={entry.entryName} />
                        </Box>
                        <Flex
                          className="app-form-field-container"
                          direction={"column"}
                          gap={13}
                        >
                          {TC_ENTRY_SETUP_SUMMARY_LIST.map(
                            (item, stepIndex: number) => (
                              <Flex
                                key={item.name}
                                className="tc-entry-summary-item"
                                justifyContent={"space-between"}
                                gap={13}
                                p={9}
                              >
                                <Text>{item.name}</Text>
                                <Link
                                  href={`${item.href}${getUrlQueryParam(index)}`}
                                  onClick={() => {
                                    setSideBarCurrentIndex?.(
                                      sideBarMainMenuIndex,
                                      stepIndex
                                    );
                                  }}
                                >
                                  <Image src={IconEdit} alt="edit icon" />
                                </Link>
                              </Flex>
                            )
                          )}
                        </Flex>
                      </AccordionComponent>
                    </CardContainer>
                  )
                )}
                <Box
                  className="app-form-container app-form-field-container"
                  mt={13}
                >
                  <Text className="tc-summary-add-additional-text">
                    Add additional transaction entries as needed
                  </Text>
                  <Link
                    href={`${ROUTE_PATH.TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS}${getUrlQueryParam(values.entries.length)}`}
                  >
                    <AddNewButton
                      label={
                        values.entries.length
                          ? "Add another transaction entry"
                          : "Add a transaction entry"
                      }
                      onClick={() => {}}
                    />
                  </Link>
                </Box>
              </>
            )}
          ></FieldArray>
        </form>
      </FormikProvider>
    </>
  );
};
export default TcEntrySummary;
