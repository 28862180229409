"use client";

import { Box, useToast } from "@/components/ChakraUiManager";
import {
  CardContainer,
  AppFormLabel,
  RadioButtonGroup,
  AppNumberInput,
  CommonTitle,
  ErrorAlert,
  DurationInput,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { INTEREST_DEPOSIT_LABELS } from "@/components/data/component-config-label";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  FoundationalInterestProps,
  foundationalInterest,
  getValidationSchema,
} from "./FoundationalInterestValidation";
import { Option } from "@/utils/types";
import { InterestComponentData, PostSchema } from "../../../model/types";
import { createInterestComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  REGEX_PATTERN,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

interface LoanOption {
  label: string;
  value: string;
}
//Interface for GET_DATA
export interface FoundationalInterestSchemaProps {
  calculationMethod: Option<number>[];
  calculateAccrual: Option<number>[];
  formData: InterestComponentData | null;
  interestComponentOptionsData: PostSchema;
}
export interface FoundationalInterestDataProps {
  data: FoundationalInterestSchemaProps;
}
export default function FoundationalInterestDeposit(
  props: FoundationalInterestDataProps
) {
  const {
    calculationMethod: calculationMethodList,
    calculateAccrual: calculateAccrualList,
    formData,
    interestComponentOptionsData,
  } = props.data;
  const {
    WHAT_IS_CALCULATION_METHOD,
    BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY,
    WHAT_IS_MINIMUM_BALANCE,
    FOUNDATIONAL_INTEREST,
    FOUNDATIONAL_INTEREST_DETAILS,
    WHAT_RATE_ADJUSTMENT_TERM,
  } = INTEREST_DEPOSIT_LABELS;
  const toast = useToast();

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMenuList,
    sideBarMainMenuIndex,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const onSubmit = async (values: FoundationalInterestProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            balOpt: values.balOpt,
            calcMthd: values.calcMthd,
            accrMinBal: parseFloat(values.accrMinBal),
            adjTerm: values.adjTerm,
          },
          toastOptions: {
            toast,
            toastId: "foundational-int",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : FOUNDATIONAL_INTEREST_DETAILS
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      balOpt: formData?.balOpt ?? foundationalInterest.balOpt,
      calcMthd: formData?.calcMthd ?? foundationalInterest.calcMthd,
      accrMinBal:
        formData?.accrMinBal?.toString() ?? foundationalInterest.accrMinBal,
      adjTerm: formData?.adjTerm ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //Handling Form
  const foundationalInterestHandler =
    (key: string) => (value: string | number) => {
      setFieldValue(key, value);
    };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  // Validating Calculation_Method
  const dispalycalculationMethod = errors.calcMthd && (
    <ErrorAlert>
      <span>{errors.calcMthd}</span>
    </ErrorAlert>
  );
  // Validating Calculate_Accrual
  const displayCalculateAccrual = errors.balOpt && touched.balOpt && (
    <ErrorAlert>
      <span>{errors.balOpt}</span>
    </ErrorAlert>
  );
  // Validating Minimum_Balance
  const displayMinimumBalance = errors.accrMinBal && touched.accrMinBal && (
    <ErrorAlert>
      <span>{errors.accrMinBal}</span>
    </ErrorAlert>
  );

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <Box className="app-form-container">
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            "componentInt",
            getIfxProdType(productDetails),
            true
          )}
          featureName={`${formData?.componentName ?? "[COMPONENT_NAME"}`}
          version={`VERSION ${formData?.version ?? "1"}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : FOUNDATIONAL_INTEREST_DETAILS
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : ""
          }
        />
        <CardContainer>
          <Box className="app-form-field-container">
            <AppFormLabel
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("calcMthd")
                  ?.tooltip_text_main
              }
              labelName={WHAT_IS_CALCULATION_METHOD}
            />

            <RadioButtonGroup
              value={values.calcMthd}
              onChange={foundationalInterestHandler("calcMthd")}
              isBoxedRadio={true}
              radioPropList={calculationMethodList}
              stackDirection="column"
            />
            {dispalycalculationMethod}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("balOpt")
                  ?.tooltip_text_main
              }
              labelName={BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY}
            />

            <RadioButtonGroup
              value={values.balOpt}
              onChange={foundationalInterestHandler("balOpt")}
              isBoxedRadio={true}
              radioPropList={calculateAccrualList}
              stackDirection="column"
            />
            {displayCalculateAccrual}
          </Box>

          <Box className="app-form-field-container">
            <AppNumberInput
              value={values.accrMinBal}
              onChange={foundationalInterestHandler("accrMinBal")}
              valuePrefix={"$"}
              labelName={WHAT_IS_MINIMUM_BALANCE}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("accrMinBal")
                  ?.tooltip_text_main
              }
            />
            {/* we are displaying MinimumBalace_Error based on condition*/}
            {displayMinimumBalance}
          </Box>
          <Box className="app-form-field-container">
            <DurationInput
              labelName={WHAT_RATE_ADJUSTMENT_TERM}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("adjTerm")
                  ?.tooltip_text_main
              }
              value={values.adjTerm}
              onChange={foundationalInterestHandler("adjTerm")}
              modalLinkName={"Enter code manually"}
              modalTitle={"Enter Promotional Rate Term"}
              modalLabelName={WHAT_RATE_ADJUSTMENT_TERM}
              modalLabelTooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("adjTerm")
                  ?.tooltip_text_main
              }
              regex={REGEX_PATTERN.RESTRICT_DECIMAL}
            />

            {errors.adjTerm && touched.adjTerm && (
              <Box className="app-form-field-container">
                <ErrorAlert>
                  <span>{errors.adjTerm}</span>
                </ErrorAlert>
              </Box>
            )}
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
}
