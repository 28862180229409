"use client";
import { Box, Flex, Text } from "@/components/ChakraUiManager";
import Image from "next/image";
import "./DocumentListCard.scss";
import resourceIcon from "public/assets/images/resource.svg";
import arrowForwardIcon from "public/assets/images/arrow-forward.svg";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { Tooltip } from "@chakra-ui/react";
import { ROUTE_PATH } from "@/route-config/route-path";

type DocumentListCardProp = {
  title: string;
  description: string;
  [key: string]: any;
  url: string;
  type: string;
};
function DocumentListCard(props: DocumentListCardProp) {
  const { title, description, url, type } = props;
  return (
    <Box className="doc-card-wrapper">
      <Link
        href={`${ROUTE_PATH["DOCUMENT_DETAIL"]}${ROUTE_PATH["DOC_TYPE_QUERY"]}${type}${ROUTE_PATH["DOC_ID_QUERY"]}${url}`}
      >
        <Flex className="doc-card-container">
          <Image src={resourceIcon} alt="Document icon" />
          <Box className="doc-card-content">
            <Text className="title">{title}</Text>
          </Box>
          <Flex className="doc-tag-arrow-card">
            <Image src={arrowForwardIcon} alt="arrow forward" />
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
}
export default DocumentListCard;
