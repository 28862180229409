"use client";
import * as prismic from "@prismicio/client";
import { DocumentTypes } from "@/utils/constants";
import { Content } from "@prismicio/client";
import { AllDocumentTypes } from "../../prismicio-types";


export interface PatternFilterGroups {
  groups: PatternFilterGroup[];
}

export interface PatternFilterGroup {
  name: string;
  tags: string[];
}

export interface PatternPreviewInterface {
  title: string;
  uid: string;
  tags: string[];
}

function parseFilterTags(filterTagsData: any): PatternFilterGroup {
  let filterGroup: PatternFilterGroup = {
    name: filterTagsData.filter_group_name,
    tags: [],
  };
  let tags: string[] = [];
  for (const tag of filterTagsData.filters) {
    tags.push(tag.tag);
  }
  filterGroup.tags = tags;
  return filterGroup;
}

export async function getTutorialFilterGroups(prismicClient: prismic.Client<AllDocumentTypes>) {
  const query = `{
        patternnav {
            pattern_filters {
                filter_group {
                    ...on pattern_filter {
                        ...pattern_filterFields
                    }
                }
            }
        }
    }`;
  const response:any = await prismicClient.getByUID("patternnav", "main", {
    graphQuery: query,
  });

  let filterGroups: PatternFilterGroups = {
    groups: [],
  };
  for (const group of response.data.pattern_filters) {
    filterGroups.groups.push(parseFilterTags(group.filter_group.data));
  }
  return filterGroups;
}

function getPreview(patternData: any) {
  let firstParagraph = patternData.data.body.find(
    (x:any) => x.slice_type == "Paragraphlegacy"
  );
  const previewText = firstParagraph?.primary?.text_block_paragraph;

  return {
    title: patternData.data.article_main_title[0].text,
    tags: patternData.tags,
    uid: patternData.uid,
    previewText,
  } as PatternPreviewInterface;
}

function parsePreviews(patternsData: Content.PatternDocument<string>[]) {
  let previews: PatternPreviewInterface[] = [];
  for (const pattern of patternsData) {
    const preview = getPreview(pattern);
    previews.push(preview);
  }
  return previews;
}

export async function getTutorials(prismicClient: prismic.Client<AllDocumentTypes>, tags:string[] = []) {
  const filters = [];
  if (tags.length > 0) {
    filters.push(prismic.filter.any("document.tags", tags));
  }
  const data = await prismicClient.getAllByType(DocumentTypes.Pattern, {
    filters: filters,
  });
  const previews = parsePreviews(data);

  return previews;
}
