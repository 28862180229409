import { Dropdown } from "react-bootstrap";
import * as headerStyles from "./Header.module.scss";
import { MenuDivider, MenuGroup, MenuItem } from "@chakra-ui/react";
import { ADMIN_OPERATIONS_REGEX, useIsPermitted } from "@/utils";
import Link from "next/link";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";
import { getConsolePermissions, getOrgID } from "@/store";
import { useUserInfo } from "@/app/providers";

/**
 * Dropdown admin menu that appears in the top navigation bar.  Allows user to choose from various administration
 * options (e.g. manage users, manage organizations...) based on console role.
 * @author Kyle Fox
 */
function SettingsDropdownSection() {
  const orgId = useSelector(getOrgID);
  const isPermitted = useIsPermitted();
  const { user } = useUserInfo();
  const pathName = usePathname();
  const consolePermissions = useSelector(getConsolePermissions);

  const orgLink = (() => {
    if (!user.loading && user.signedIn && consolePermissions?.length > 0) {
      if (user.superUser && isPermitted("cnsl", "admin-organization-list")) {
        return (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.ORGANIZATION_ADMIN ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.ORGANIZATION_ADMIN}
          >
            Organizations
          </MenuItem>
        );
      } else if (isPermitted("cnsl", "admin-organization-read")) {
        return (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === `/admin/organization/edit/${orgId}` ? "isActive" : ""}`}
            href={`/admin/organization/edit/${orgId}`}
          >
            Organization
          </MenuItem>
        );
      }
    }
    return null;
  })();

  const hasAccessManagementAccess =
    isPermitted("cnsl", "access-management-access") &&
    isPermitted("cnsl", [
      "admin-account-list",
      "admin-prfl-tmpl-list",
      "admin-console-role-list",
    ]);

  return (!user.loading &&
    user.signedIn &&
    consolePermissions?.length > 0 &&
    isPermitted("cnsl", ADMIN_OPERATIONS_REGEX)) ||
    hasAccessManagementAccess ||
    isPermitted("primary-org-cnsl", "admin-environment-list") ? (
    <>
      <MenuGroup title="SETTINGS">
        {hasAccessManagementAccess ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.ACCESS_MANAGEMENT ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.ACCESS_MANAGEMENT}
          >
            Access management
          </MenuItem>
        ) : null}
        {isPermitted("cnsl", "admin-account-list") ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.ACCOUNT_ADMIN ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.ACCOUNT_ADMIN}
          >
            Accounts
          </MenuItem>
        ) : null}
        {isPermitted("cnsl", "admin-course-list") ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.COURSES_ADMIN ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.COURSES_ADMIN}
          >
            Courses
          </MenuItem>
        ) : null}
        {isPermitted("primary-org-cnsl", "admin-environment-list") ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.ENVIRONMENT_ADMIN ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.ENVIRONMENT_ADMIN}
          >
            Environments
          </MenuItem>
        ) : null}
        {orgLink}
        {isPermitted("cnsl", /^admin-prfl-tmpl-.*$/) ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.PROFILE_TEMPLATE_ADMIN ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.PROFILE_TEMPLATE_ADMIN}
          >
            Profile Templates
          </MenuItem>
        ) : null}
        {isPermitted("cnsl", /^admin-report-.*$/) ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.REPORT_ADMIN ? "isActive" : ""}`}
            href={`${ROUTE_BASE_URL.REPORT_ADMIN}`}
          >
            Reports
          </MenuItem>
        ) : null}
        {isPermitted("cnsl", "admin-console-role-list") ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.CONSOLE_ROLES ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.CONSOLE_ROLES}
          >
            Roles &amp; Permissions
          </MenuItem>
        ) : null}
        {isPermitted("cnsl", "admin-core-build-list") ? (
          <MenuItem
            as="a"
            className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.CORE_BUILDS_ADMIN ? "isActive" : ""}`}
            href={ROUTE_BASE_URL.CORE_BUILDS_ADMIN}
          >
            Core Builds
          </MenuItem>
        ) : null}
      </MenuGroup>
      <MenuDivider />
    </>
  ) : null;
}

export default SettingsDropdownSection;
