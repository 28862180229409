"use client";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  ChargeOffTimingThresholdsProps,
  getValidationSchema,
} from "./ChargeOffTimingAndThresholdValidation";
import { createChargeOffComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management/product-management-util-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

const ChargeOffTimingAndThreshold = (props: { data: any }) => {
  const toast = useToast();

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.ChargeOffTimingAndThresholdOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.ChargeOffTimingAndThresholdOptionsData]);
  // Form Submit Handler
  const onSubmit = async (
    values: ChargeOffTimingThresholdsProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["chargeOff"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }

    if (productDetails) {
      const res = await createChargeOffComponent({
        productDetails,
        formData: {
          ...props.data.formData,
          // PATCH fields
          chrgOffDays: parseInt(values.chrgOffDays),
          chrgOffMin: parseFloat(values.chrgOffMin),
          chrgOffOpt: parseInt(values.chrgOffOpt),
        },
        toastOptions: {
          toast,
          toastId: "chargeOff-config",
          successMessage: `${
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Charge-Off Timing and Thresholds"
          } updated`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };
  // using useFormik hook from Formik Library
  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      chrgOffDays: props.data.formData?.chrgOffDays.toString() ?? "",
      chrgOffMin: props.data.formData?.chrgOffMin.toString() ?? "",
      chrgOffOpt: props.data.formData?.chrgOffOpt ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMenuList,
    sideBarMainMenuIndex,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  const changeoffTimingAndThresholdFromHandler =
    (key: string) => (value: string) => {
      setFieldValue(key, value);
    };
  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <Box>
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            "componentDepChrgOff",
            getIfxProdType(productDetails),
            true
          )}
          featureName={props.data.formData?.componentName ?? "[COMPONENT NAME]"}
          version={`VERSION ${props.data.formData?.version || 1}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Charge-Off Timing and Thresholds"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : ""
          }
        />
        <Box className="app-form-container">
          <CardContainer>
            <CardBody>
              <Box className={"app-form-field-container"}>
                <AppFormLabel
                  labelName="How many days before a deposit position with a negative balance should be automatically charged off?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("chrgOffDays")
                      ?.tooltip_text_main
                  }
                />
                <AppNumberInput
                  value={values.chrgOffDays}
                  onChange={changeoffTimingAndThresholdFromHandler(
                    "chrgOffDays"
                  )}
                />
              </Box>
              <Box className={"app-form-field-container"}>
                <AppFormLabel
                  labelName="What is the minimum negative balance required for a deposit position to be eligible for automatic charge-off?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("chrgOffMin")
                      ?.tooltip_text_main
                  }
                />
                <AppNumberInput
                  value={values.chrgOffMin}
                  onChange={changeoffTimingAndThresholdFromHandler(
                    "chrgOffMin"
                  )}
                  valuePrefix="$"
                />
                {errors.chrgOffMin && touched.chrgOffMin && (
                  <ErrorAlert>
                    <span>{errors.chrgOffMin}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box>
                <AppFormLabel
                  labelName="After an automatic charge-off, should the deposit position be closed, or should it remain open?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("chrgOffOpt")
                      ?.tooltip_text_main
                  }
                />
                <RadioButtonGroup
                  value={values.chrgOffOpt}
                  onChange={changeoffTimingAndThresholdFromHandler(
                    "chrgOffOpt"
                  )}
                  radioPropList={props.data.chargeOffOptions}
                  stackDirection={"column"}
                  spacing={"0.8rem"}
                  isBoxedRadio={true}
                />
                {errors.chrgOffOpt && touched.chrgOffOpt && (
                  <ErrorAlert>
                    <span>{errors.chrgOffOpt}</span>
                  </ErrorAlert>
                )}
              </Box>
            </CardBody>
          </CardContainer>
        </Box>
      </Box>
    </form>
  );
};

export default ChargeOffTimingAndThreshold;
