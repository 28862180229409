"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import OrgSelectorButton from "./OrgSelectorButton";
import OrganizationTable from "./OrganizationTable";
import {
  getAllEnvironments,
  getAllProjects,
} from "../../../api-config/api-service";
import { getCookie, setCookie } from "cookies-next";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnvId,
  setEnvId,
  setEnvType,
  setOrgId,
  getOrganizations,
  AppDispatch,
  setAllowConfigChangeState,
  setEnvStatus,
} from "../../../store/index";
import {
  OrgEnvDropdownMenuHeaderItem,
  OrgEnvDropdownMenuItemBase,
  OrgEnvDropdownMenuItemOrg,
  OrgEnvDropdownMenuItemProject,
  OrgEnvDropdownMenuItemEnvironment,
} from "./Models";
import "./OrgEnvDropdown.scss";
import localforage from "localforage";
import {
  ORGANIZATION_COOKIE,
  ENV_ID_KEY,
  ENV_TYPE_KEY,
  keyGroupingEnabledOrgDropdown,
} from "../../../utils/constants";
import { UserContext } from "@/app/providers";

const OrgEnvDropdown: React.FC = () => {
  const [allOrgs, setAllOrgs] = useState<OrgEnvDropdownMenuItemOrg[]>();
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrgEnvDropdownMenuItemOrg | null>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [organizationItems, setOrganizationItems] = useState<
    OrgEnvDropdownMenuHeaderItem[]
  >([]);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedEnv, setSelectedEnv] =
    useState<OrgEnvDropdownMenuItemBase | null>(null);
  const [groupingEnabled, setGroupingEnabled] = useState<boolean>();
  const FAVORITE_GROUP_ID = 999;
  const OTHER_GROUP_ID = 998;
  const orgCookie = getCookie(ORGANIZATION_COOKIE);
  const selectedOrgFromCookie = orgCookie ? Number(orgCookie) : null;
  const [isAPILoading, setIsAPILoading] = React.useState<boolean>(false);
  const envId = useSelector(getEnvId);
  const userOrgs = useSelector(getOrganizations);
  const { user } = useContext(UserContext);

  // If fxcOrg cookie not found, use user's primary org
  useEffect(() => {
    if (selectedOrgFromCookie == null) {
      setCookie(ORGANIZATION_COOKIE, user.organization);
    }
  }, [user, selectedOrgFromCookie]);

  const groupOrganizations = () => {
    const grouped: OrgEnvDropdownMenuHeaderItem[] = [];

    // push favs
    const favOrgs = (allOrgs ?? []).filter((org) => org.isFavourite);
    if (favOrgs.length > 0) {
      grouped.push({
        id: FAVORITE_GROUP_ID,
        isExpanded: true,
        isSelected: false,
        name: "Favorites",
        subItems: [...favOrgs],
        alwaysExpanded: true,
      });
    }

    // Then all other sections
    (allOrgs ?? []).forEach((org, index) => {
      const presentGroups = grouped.filter(
        (gOrg) => gOrg.name === org.groupName
      );
      if (presentGroups.length > 0) {
        presentGroups[0].subItems.push(org);
      } else {
        grouped.push({
          id: index,
          alwaysExpanded: false,
          isExpanded:
            organizationItems.filter((oI) => oI.name === org.groupName)[0]
              ?.isExpanded ?? false,
          isSelected: false,
          name: org.groupName,
          subItems: [org],
        });
      }
    });

    setOrganizationItems(grouped);
  };

  const ungroupOrganizations = () => {
    let locMenuItems: OrgEnvDropdownMenuHeaderItem[] = [];
    const favOrgs = (allOrgs ?? []).filter((org) => org.isFavourite);
    const nonFavOrgs = (allOrgs ?? []).filter((org) => !org.isFavourite);

    if (favOrgs.length > 0) {
      locMenuItems.push({
        id: FAVORITE_GROUP_ID,
        isExpanded: true,
        isSelected: false,
        name: "Favorites",
        subItems: [...favOrgs],
        alwaysExpanded: true,
      });
    }

    locMenuItems.push({
      id: OTHER_GROUP_ID,
      isExpanded: true,
      isSelected: false,
      name: "Others",
      subItems: [...nonFavOrgs],
      alwaysExpanded: true,
    });

    setOrganizationItems(locMenuItems);
  };

  useEffect(() => {
    localforage.getItem(keyGroupingEnabledOrgDropdown).then((result) => {
      const isGroupingEnabled = result as boolean;
      setGroupingEnabled(isGroupingEnabled);
      isGroupingEnabled ? groupOrganizations() : ungroupOrganizations();
    });
  }, [allOrgs]);

  useEffect(() => {
    setAllOrgs([
      ...Object.values(userOrgs).map((org: any): OrgEnvDropdownMenuItemOrg => {
        const subItem = getSubItem(org);

        // Set the org from cookie, or user's primary org
        if (selectedOrgFromCookie) {
          if (subItem.id === selectedOrgFromCookie) {
            setSelectedOrganization(subItem);
          }
        } else if (subItem.id === user.organization) {
          setSelectedOrganization(subItem);
        }

        return subItem;
      }),
    ]);
  }, [userOrgs, selectedOrgFromCookie, user.organization]);

  const getSubItem = (
    org: any,
    envs: OrgEnvDropdownMenuItemEnvironment[] = [],
    projs: OrgEnvDropdownMenuItemProject[] = []
  ): OrgEnvDropdownMenuItemOrg => {
    return {
      environments: selectedOrgFromCookie === org.id ? envs : [],
      projects: selectedOrgFromCookie === org.id ? projs : [],
      id: org.id,
      isFavourite: org.isFavorite,
      isSelected: selectedOrgFromCookie === org.id,
      name: org.name,
      groupName: org.category,
    };
  };

  const removeEnv = () => {
    localforage.setItem(ENV_ID_KEY, undefined);
    localforage.setItem(ENV_TYPE_KEY, undefined);
    setSelectedEnv(null);
    dispatch(setEnvType(null));
    dispatch(setEnvId(null));
    dispatch(setEnvStatus([]));
  };

  useEffect(() => {
    if (selectedOrganization) {
      setIsAPILoading(true);
      fetchEnvsAndProjs().then((envProjResponse) => {
        const conv_envs = envProjResponse[0];
        const conv_projs = envProjResponse[1];

        const _allOrgs = [...(allOrgs ?? [])];
        _allOrgs.map((org) => {
          org.isSelected = false;
          if (org.id === selectedOrganization.id) {
            org.isSelected = true;
            org.environments = [...conv_envs];
            org.projects = [...conv_projs];
          }
        });
        setIsAPILoading(false);
        setAllOrgs([..._allOrgs]);
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (groupingEnabled !== undefined) {
      localforage
        .setItem(keyGroupingEnabledOrgDropdown, groupingEnabled)
        .then(() => {
          if (groupingEnabled !== undefined) {
            groupingEnabled ? groupOrganizations() : ungroupOrganizations();
          }
        });
    }
  }, [groupingEnabled]);

  const fetchEnvsAndProjs = async () => {
    const resEnvsAndProjs = await Promise.all([
      getAllEnvironments(),
      getAllProjects(),
    ]);
    const envs = resEnvsAndProjs[0].environments;
    const projs = resEnvsAndProjs[1];
    const conv_envs: OrgEnvDropdownMenuItemEnvironment[] = Object.values(
      envs
    ).map((env: any) => {
      const _env = {
        id: env.id,
        name: env.name,
        isSelected: env.id === envId,
        ignoreConfigAsCodeChecks: env.ignoreConfigAsCodeChecks,
      };
      if (env.id === envId) {
        setSelectedEnv(_env);
      }
      return _env;
    });
    const conv_projs: OrgEnvDropdownMenuItemProject[] = Object.values(
      projs
    ).map((proj: any) => {
      const _proj = {
        id: proj.id,
        name: proj.name,
        isSelected: proj.id === envId,
      };
      if (proj.id === envId) {
        setSelectedEnv(_proj);
      }
      return _proj;
    });
    return [conv_envs, conv_projs];
  };

  const onHeaderExpansion = (
    item: OrgEnvDropdownMenuHeaderItem,
    index: number
  ) => {
    const _i = { ...item };
    _i.isExpanded = !_i.isExpanded;
    const _mI = [...organizationItems];
    _mI[index] = _i;
    setOrganizationItems([..._mI]);
  };

  const handleEnvProjectSelection = (index: number, isProject: boolean) => {
    const id = isProject
      ? selectedOrganization?.projects[index].id
      : selectedOrganization?.environments[index].id;
    const envType = isProject ? "proj" : "env";

    Promise.all([
      localforage.setItem(ENV_ID_KEY, id),
      localforage.setItem(ENV_TYPE_KEY, envType),
    ]).then(() => {
      if (selectedOrganization) {
        let locSelectedEnv;
        if (!isProject) {
          locSelectedEnv = selectedOrganization.environments[index];
        } else {
          locSelectedEnv = selectedOrganization.projects[index];
        }
        dispatch(setEnvType(envType));
        dispatch(setEnvId(id!));
        locSelectedEnv.isSelected = true;
        setSelectedEnv(locSelectedEnv);
        onToggle();
      }
    });
  };

  const handleOnFavourite = (orgId: number) => {
    const _allOrgs = [...(allOrgs ?? [])];
    _allOrgs.map((org) => {
      if (org.id === orgId) {
        org.isFavourite = !org.isFavourite;
      }
    });
    setAllOrgs([..._allOrgs]);
  };

  const ref = useRef(null);
  return (
    <Popover
      id="test_data"
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-end"
      offset={[0, 15]}
    >
      <PopoverTrigger>
        <OrgSelectorButton
          onClick={onToggle}
          isOpen={isOpen}
          envName={selectedEnv?.name}
          isAPILoading={isAPILoading}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent className="popover-content">
          <PopoverArrow />
          {isOpen && (
            <OrganizationTable
              menuItems={organizationItems}
              onOrganizationSelect={(org) => {
                setSelectedOrganization(org);
                setCookie(ORGANIZATION_COOKIE, `${org.id}`);
                dispatch(setOrgId(org.id));
                removeEnv();
                // reset allowConfigChanges when org changes
                dispatch(setAllowConfigChangeState(null));
              }}
              onHeaderExpansion={onHeaderExpansion}
              selectedOrganization={selectedOrganization}
              selectedEnvironment={selectedEnv}
              onEnvProjSelect={handleEnvProjectSelection}
              onFavourite={handleOnFavourite}
              groupingEnabled={groupingEnabled}
              setGroupingEnabled={setGroupingEnabled}
            />
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default OrgEnvDropdown;
