"use client";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CloseIcon,
  Flex,
  Text,
} from "@/components/ChakraUiManager";
import {
  AppModal,
  CardContainer,
  ResourceLink,
  SplitButton,
  ToolTip,
} from "@/components/common";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./ProductCatalog.scss";
import { PRODUCT_CATALOG } from "@/data/product-catalog-data";
import {
  ProductCatalogCard,
  ProductLaunchPadQuickStartModal,
} from "@/components";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ROUTE_PATH } from "@/route-config/route-path";
import Image from "next/image";
import { overview, trainingDocumentation } from "public/assets";
import { ProductType } from "../..";
import { getProductLaunchpadResources } from "@/prismic-api/prismic-api-service";
import { buildURL } from "@/prismic-api/common-service";
import {
  ENTITLEMENT_STATUS,
  IFX_PRODUCT_TYPE_VAL,
  useIsPermitted,
} from "@/utils";
import { useSelector } from "react-redux";
import { getAppPrismicClient, getEntitlement } from "@/store";
import { productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

interface ProductCatalogProp {
  productList: ProductType[];
  setProductIfxAcctInfo: (data: any) => void;
  isEnvDisabled: boolean;
  isAccleratorSupported: boolean;
}

type ProductDetails =
  (typeof PRODUCT_CATALOG)[keyof typeof PRODUCT_CATALOG]["productList"][number];

function ProductCatalog(prop: ProductCatalogProp) {
  const {
    productList = [],
    setProductIfxAcctInfo,
    isEnvDisabled,
    isAccleratorSupported,
  } = prop;
  const { navigateTo } = useContext(productConfigurationContext);
  const prismicClient = useSelector(getAppPrismicClient);
  const isPermitted = useIsPermitted();

  const [highlightedProduct, setHighlightedProduct] = useState<string | null>(
    null
  );
  const [currProductDetail, setCurrentProductDetail] =
    useState<ProductDetails | null>(null);
  const [productCatalogType, setProductCatalogType] =
    useState<SplitBtnInputValType>(true);
  const [resources, setResources] = useState<any>(null);
  const modalRef = useRef<any>();
  const entitlement = useSelector(getEntitlement);
  const hasAcceleratorAccess = isPermitted(
    "cnsl",
    "product-accelerators-access"
  );

  function showProductDetails(productDetail: ProductDetails) {
    setCurrentProductDetail(productDetail);
    setHighlightedProduct((prevState) => {
      return prevState !== productDetail.productName
        ? productDetail.productName
        : null;
    });
  }

  const prodResources = useMemo(() => {
    if (currProductDetail && resources) {
      const typeSlug = currProductDetail.ifxAcctType.toLowerCase();
      return resources[typeSlug]?.data.slices[0].items ?? [];
    }
    return [];
  }, [highlightedProduct, resources, currProductDetail]);

  const handleProductCatalogTypeSelect = (
    selectedValue: SplitBtnInputValType
  ) => {
    setProductCatalogType(selectedValue);
    setHighlightedProduct(null);
  };

  function showQuickStartModal(productData: ProductDetails) {
    if (productData) {
      setCurrentProductDetail(productData);
      setProductIfxAcctInfo(productData);
      // If org has accelerator access, launch quickstart modal, else route to product walkthrough
      if (hasAcceleratorAccess) {
        showModal();
      } else {
        navigateTo(
          `${ROUTE_PATH["PRODUCT_WALKTHROUGH_FULLPATH"]}?${ROUTE_PATH.IFX_ACCT_TYPE_QUERY}${productData.ifxAcctType}&${ROUTE_PATH.PROD_SUB_TYPE_QUERY}${productData.prodSubType}`
        );
      }
    }
  }
  async function getResourcesDetails() {
    const data = await getProductLaunchpadResources(prismicClient);
    await Promise.all([data]).then((values) => {
      setResources(values[0]);
    });
  }

  function showModal() {
    if (modalRef?.current) {
      modalRef.current.openModal();
    }
  }

  useEffect(() => {
    if (!resources) {
      getResourcesDetails();
    }
  }, []);

  // Only showing completed accelerator products based on isAccelerator__ and product status
  const filteredProducts = useMemo(() => {
    return productList.filter((product) => {
      const productIfxFilter =
        product.ifxAcctType === currProductDetail?.ifxAcctType;
      // If accelerator not supported all products are treated as accelerators
      const acceleratorFilter = isAccleratorSupported
        ? Boolean(product.isAccelerator__)
        : true;
      // Filter on product subtype
      const isProductSubtypeSame =
        product?.prodSubType === currProductDetail?.prodSubType;
      return (
        product.status === "completed" &&
        isProductSubtypeSame &&
        productIfxFilter &&
        acceleratorFilter
      );
    });
  }, [productList, isAccleratorSupported, currProductDetail]);

  return (
    <>
      <Box mb="3rem">
        <Text as="h3" className="explore-title">
          Explore & Engage
        </Text>
        <Text>
          Explore the product groups to discover the best fit for your needs.
          Click &apos;Configure&apos; to begin customizing your product.
        </Text>
        <Flex mt="1.6rem" gap={"3rem"}>
          <Text>
            <span className="text-bold">Build your own: </span> Customize every
            aspect from start to finish, creating a product unique to your
            institution
          </Text>
          <Text>OR</Text>
          <Text>
            <span className="text-bold">Quickstart Configuration: </span> Begin
            with a pre-configured solution and refine it to your institutions
            needs
          </Text>
        </Flex>
      </Box>
      <Box
        className={`configured-products-container ${
          highlightedProduct ? "product-expectation" : ""
        }`}
      >
        <CardContainer customClass={highlightedProduct ? `showProdDetail` : ""}>
          <SplitButton
            leftBtnName="Retail Products"
            rightBtnName="Commercial Products"
            onSelect={handleProductCatalogTypeSelect}
            value={productCatalogType}
            ignoreConfigFlag
          />
          <Box mt={12.8} className="configured-products-list">
            <Box
              className={`${
                productCatalogType === false ? "display-none" : ""
              }`}
            >
              <Box marginBottom="3.5rem">
                <Text
                  className="product-category-name"
                  fontWeight="600"
                  as="h3"
                >
                  {PRODUCT_CATALOG.retailProducts.categoryName}
                  <ToolTip
                    content={PRODUCT_CATALOG.retailProducts.tooltipDesc}
                    placement={"right"}
                    IconDefault={true}
                  />
                </Text>
                {PRODUCT_CATALOG.retailProducts.productList.map(
                  (item, itemIndex) => (
                    <ProductCatalogCard
                      key={`productCard${itemIndex}`}
                      name={item.productName}
                      isProdDetailVisible={
                        highlightedProduct === item.productName
                      }
                      description={item.productDescription || ""}
                      showProductDetails={() => showProductDetails(item)}
                      onProductSelection={() => showQuickStartModal(item)}
                      isEnvDisabled={
                        isEnvDisabled ||
                        (item.prodType === IFX_PRODUCT_TYPE_VAL.deposit
                          ? entitlement.pc_dep !== ENTITLEMENT_STATUS.allAccess
                          : false) ||
                        (item.prodType === IFX_PRODUCT_TYPE_VAL.loan
                          ? entitlement.pc_loan !== ENTITLEMENT_STATUS.allAccess
                          : false)
                      }
                    />
                  )
                )}
              </Box>
            </Box>
            <Box
              className={`${productCatalogType === true ? "display-none" : ""}`}
            >
              <Box marginBottom="3.5rem">
                <Text
                  className="product-category-name"
                  fontWeight="600"
                  as="h3"
                >
                  {PRODUCT_CATALOG.commercialProducts.categoryName}
                  <ToolTip
                    content={PRODUCT_CATALOG.commercialProducts.tooltipDesc}
                    placement={"right"}
                    IconDefault={true}
                  />
                </Text>
                {PRODUCT_CATALOG.commercialProducts.productList.map((item) => (
                  <ProductCatalogCard
                    key={item.productName}
                    name={item.productName}
                    isProdDetailVisible={
                      highlightedProduct === item.productName
                    }
                    description={item.productDescription}
                    showProductDetails={() => showProductDetails(item)}
                    onProductSelection={() => showQuickStartModal(item)}
                    isEnvDisabled={
                      isEnvDisabled ||
                      (item.prodType === IFX_PRODUCT_TYPE_VAL.deposit
                        ? entitlement.pc_dep !== ENTITLEMENT_STATUS.allAccess
                        : false) ||
                      (item.prodType === IFX_PRODUCT_TYPE_VAL.loan
                        ? entitlement.pc_loan !== ENTITLEMENT_STATUS.allAccess
                        : false)
                    }
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </CardContainer>
        <Box className="selected-product-definition">
          <div className="selected-product-definition-continer">
            <Flex mb={4.8} justifyContent="space-between">
              <Text className="main-title" as="h3">
                {currProductDetail?.productDetailTitle || ""}
              </Text>
              <CloseIcon
                onClick={() => setHighlightedProduct(null)}
                className="close-icon"
              />
            </Flex>
            <Text mb={8}>{currProductDetail?.productDescription}</Text>
            <CardContainer>
              <Flex mb={4} gap={4}>
                <Image width={24} height={24} src={overview} alt="overview" />
                <Text fontWeight={"600"} as="h3">
                  Product overview
                </Text>
              </Flex>
              <Text>
                Explore potential scenarios designed to inspire customization
                tailored to your consumers needs.
              </Text>
              <Text mt={8} className="additional-product-title" as="h3">
                Component Configuration
              </Text>
              {currProductDetail?.componentConfigurationList?.length
                ? currProductDetail?.componentConfigurationList.map(
                    ({ configName, configDesc }: any, detailIndex: number) => (
                      <Accordion
                        key={`productDetailAcc${detailIndex}`}
                        className="product-catalog-detail-accordion"
                        allowMultiple
                      >
                        <AccordionItem
                          key={`productDetailAccItem${detailIndex}`}
                        >
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                {configName}
                              </Box>
                              <AccordionIcon fontSize={24} />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>{configDesc}</AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    )
                  )
                : ""}
            </CardContainer>
            <Flex mt="3rem" mb={9.6} gap={"1.2rem"}>
              <Image src={trainingDocumentation} alt="training" />
              <Text as="h3" className="available-title">
                {resources?.heading || "Available training & documentation"}
              </Text>
            </Flex>
            {prodResources.map((learningDetail: any, index: number) => (
              <ResourceLink
                key={index}
                name={learningDetail?.link_title || ""}
                href={
                  learningDetail.link_type === "prismic link"
                    ? buildURL(
                        learningDetail?.prismic_link?.type,
                        learningDetail?.prismic_link?.uid
                      )
                    : learningDetail.link_type === "academy"
                      ? `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=` +
                        `${learningDetail?.academy_course_link}`
                      : learningDetail?.external_link?.link_type === "Document"
                        ? buildURL(
                            learningDetail?.external_link?.type,
                            learningDetail?.external_link?.uid
                          )
                        : learningDetail?.external_link?.url
                }
                customClass="preconfigured-product-resource-links"
              />
            ))}
          </div>
        </Box>
        <Flex
          justifyContent={"center"}
          className="build-another-product-container"
        >
          <Text className="build-another-product-link">
            Don’t see what you’re looking for?{" "}
            <span style={{ cursor: isEnvDisabled ? "not-allowed" : "auto" }}>
              <a
                target="_blank"
                href={ROUTE_PATH["LEGACY_NEW_PRODUCT"]}
                className="app-btn-link"
                style={{
                  pointerEvents: isEnvDisabled ? "none" : "auto",
                }}
              >
                Build another product
              </a>
            </span>
          </Text>
        </Flex>
      </Box>
      <AppModal
        customClass="app-modal-selector product-catalog-quick-start-modal"
        ref={modalRef}
        modalTitle={""}
      >
        {currProductDetail?.productName ? (
          <ProductLaunchPadQuickStartModal
            productName={currProductDetail.productName}
            tableDataList={filteredProducts}
          />
        ) : (
          ""
        )}
      </AppModal>
    </>
  );
}

export default ProductCatalog;
