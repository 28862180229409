"use client";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  Flex,
  Heading,
  Text,
  Box,
  Button,
  Grid,
  GridItem,
  Divider,
  HStack,
} from "@chakra-ui/react";
import Image from "next/image";
import {
  dashboardLockIcon,
  dashboardStarIcon,
  dashBoardJumpstartIcon,
  dashboardFoundationalSetupIcon,
  dashboardProductConfigIcon,
  dashboardTestDeployIcon,
} from "public/assets";

import "./Dashboard.scss";

import { ChevronRightIcon } from "@chakra-ui/icons";
import ResourceSection from "./resource-section/ResourceSection";
import { CardContainer, CarouselButtonGroup } from "@/components/common";
import ExploreFinxact from "./explore-finxact/ExploreFinxact";
import JumpstartActivation from "./jumpstart-activation/JumpstartActivation";
import FoundationalSetup from "./foundational-setup/FoundationalSetup";
import {
  getAppPrismicClient,
  getConsolePermissions,
  getEnvId,
  getSelectedOrg,
} from "@/store";
import { useSelector } from "react-redux";
import EnvironmentContainer from "./environment-container/EnvironmentContainer";
import {
  DashboardWorkflowResponse,
  createWorkflowGeneric,
  getAllWorkflowGeneric,
} from "@/api-config/api-service";
import { getDashboardConfig } from "@/prismic-api/dashboard-service";
import Link from "next/link";
import {
  DASHBOARD_JOURNEY,
  DASHBOARD_MODEL_KEY,
  DASHBOARD_ACCORDION_STATUS,
  TICKET_STATUS,
  totalNumberOfStepsForJumpStart,
  ORG_PHASE,
  TICKET_REQUESTS,
  ValueOf,
  DASHBOARD_COMPLETED_ORG_PHASES,
  JUMPSTART_ACTIVATION_STEPS_NAME,
} from "@/utils";
import ProductConfiguration from "./product-configuration/ProductConfiguration";
import TestAndDeploy from "./test-and-deploy/TestAndDeploy";
import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import DefaultEnvironmentContainer from "./environment-container/DefaultEnvironmentContainer";
import moment from "moment";
import {
  ProductType,
  fetchProductList,
  filterProductsWithWorkflowStatus,
} from "../product-management";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "../context-api/product-configuration-context/ProductConfigurationReducer";
import RecentSection from "./recent-product/RecentSection";
import { JourneyDetailsProps } from "./model";
import Carousel from "react-multi-carousel";
import { CONSOLE_ENDPOINTS } from "@/api-config/urls";
import { EnvStatus } from "@/store/types";
import DOMPurify from "dompurify";

export interface TicketedRequestInterface {
  requestType: ValueOf<typeof TICKET_REQUESTS>;
  requestDtm: string;
  ticketSystem: string;
  ticketId: string;
  ticketLastStatus: string;
  orgId: number;
  userId: number;
}

interface DashboardProps {
  data: {
    envData: EnvStatus[];
  };
}

const checkAdminAccess = (cnslPermissions: string[]) => {
  if (cnslPermissions.length === 0) {
    return {
      platformSetup: false,
      accessManagement: false,
    };
  } else {
    const adminOrg = cnslPermissions.includes("admin-organization-update");
    const adminAcc = cnslPermissions.includes("admin-account-update");

    return {
      platformSetup: adminOrg ? true : false,
      accessManagement: adminAcc ? true : false,
    };
  }
};

const getNextJourneyName = (activeJourneyNumber: number) => {
  switch (activeJourneyNumber) {
    case 1:
      return DASHBOARD_JOURNEY.jumpstartActivation;
    case 2:
      return DASHBOARD_JOURNEY.foundationalSetup;
    case 3:
      return DASHBOARD_JOURNEY.productConfiguration;
    case 4:
      return DASHBOARD_JOURNEY.testAndDeploy;
    default:
      return DASHBOARD_JOURNEY.testAndDeploy;
  }
};

const Dashboard = (props: DashboardProps) => {
  const prismicClient = useSelector(getAppPrismicClient);
  const { envData } = props.data;
  const envId = useSelector(getEnvId);
  const [announcements, setAnnouncements] = useState<any>([]);
  const [active, setActive] = useState<number>(1);
  const [currentTab, setCurrentTab] = useState<string>(
    DASHBOARD_JOURNEY.exploreFinxact
  );
  const consolePermissions = useSelector(getConsolePermissions);

  const [access, setAccess] = useState<{
    accessManagement: boolean;
  }>({
    accessManagement: false,
  });

  const { resetProductInfo } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const [productList, setProductList] = useState<ProductType[]>([]);
  const [inProgress, setInProgress] = useState<number>(0);
  const [completed, setCompleted] = useState<number>(0);
  const [journeyDetails, setJourneyDetails] = useState<JourneyDetailsProps>({
    explore_finxact: {},
    jumpstart_activation: {},
    foundational_setup: {},
    product_configuration: {},
    test_and_deploy: {},
    recent_section: {},
    resources_section: {},
    upgradeVersionLink: null,
  });
  const [ticketStatus, setTicketStatus] = useState<TicketedRequestInterface[]>(
    []
  );
  const [dashboardWorkflowData, setWorkflowData] = useState<
    DashboardWorkflowResponse[]
  >([]);

  const currentOrg = useSelector(getSelectedOrg);
  const isProspect = currentOrg?.phase === ORG_PHASE.prospect;

  const isEnvDown = useMemo(() => {
    const currectEnv = envData.filter((data) => data.id === envId);
    //If version is null means env is down
    return currectEnv.some((data) => data.version === null);
  }, [envData, envId]);

  async function getProducts() {
    await fetchProductList().then((productsWithPagination) => {
      if (productsWithPagination?.length) {
        const prodList = productsWithPagination.filter(
          (product) => product.isAccelerator__ !== true
        );
        setProductList(prodList);
        const filteredProduct = filterProductsWithWorkflowStatus(prodList);
        setInProgress(
          filteredProduct.filter((data) => data.status === "in_progress").length
        );
        setCompleted(
          filteredProduct.filter((data) => data.status === "completed").length
        );
      }
    });
  }
  useEffect(() => {
    const prismicData = getDashboardConfig(prismicClient);
    Promise.all([prismicData]).then((values) => {
      setJourneyDetails(values[0] as JourneyDetailsProps);
    });
  }, []);

  useEffect(() => {
    if ((!productList || productList.length === 0) && active >= 4) {
      getProducts();
    }
    resetProductInfo?.();
  }, [active]);

  useEffect(() => {
    let cPhase = currentOrg?.phase ?? "";

    const getData = async () => {
      // If org in production/uat move user to product config tab, no need to call the below apis
      if (DASHBOARD_COMPLETED_ORG_PHASES.includes(cPhase)) {
        setCurrentTab(DASHBOARD_JOURNEY.productConfiguration);
        setActive(4);
        return;
      }
      const api = new ApiClient({ baseUrl: CONSOLE_ENDPOINTS.ticketRequests });
      const [reqData, data] = await Promise.all([
        api.get() as Promise<TicketedRequestInterface[]>,
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.dashboard) as Promise<
          DashboardWorkflowResponse[]
        >,
      ]).then((data) => data);

      setTicketStatus(reqData);
      setWorkflowData(data);

      const adminAccess = checkAdminAccess(consolePermissions);
      setAccess(adminAccess);

      const jumpstartActivationStepsCompleted = data?.filter(
        (d: any) =>
          d.modelKey === DASHBOARD_MODEL_KEY.jumpstartActivation &&
          d.status === DASHBOARD_ACCORDION_STATUS.completed
      );

      const foundationalSetupStepsCompleted = data?.filter(
        (d) =>
          d.modelKey === DASHBOARD_MODEL_KEY.foundationalSetup &&
          d.status === DASHBOARD_ACCORDION_STATUS.completed
      );

      // Only if environment is available and up
      if (!isEnvDown && envData.length !== 0) {
        // If request sandbox experience jira ticket is marked done
        if (
          reqData?.find(
            (data) =>
              data.requestType === TICKET_REQUESTS.sandbox &&
              data.ticketLastStatus === TICKET_STATUS.done
          )
        ) {
          // Unlock Product config tab if org is in Prospect phase
          if (cPhase === ORG_PHASE.prospect) {
            setCurrentTab(DASHBOARD_JOURNEY.productConfiguration);
            setActive(4);
            return;
          } else if (jumpstartActivationStepsCompleted?.length < 4) {
            setCurrentTab(DASHBOARD_JOURNEY.jumpstartActivation);
            setActive(2);
          } else if (foundationalSetupStepsCompleted.length < 5) {
            setCurrentTab(DASHBOARD_JOURNEY.foundationalSetup);
            setActive(3);
          } else if (foundationalSetupStepsCompleted.length === 5) {
            setCurrentTab(DASHBOARD_JOURNEY.productConfiguration);
            setActive(4);
          }
        }
      }
    };

    getData();
  }, [envData, consolePermissions, currentOrg, isEnvDown]);

  // Create Platform setup workflow once an environment is available
  useEffect(() => {
    const jumpstartWorkflows = dashboardWorkflowData?.filter(
      (workflow) =>
        workflow.modelKey === DASHBOARD_MODEL_KEY.jumpstartActivation
    );

    if (
      envData.length > 0 &&
      jumpstartWorkflows?.length === 0 &&
      ticketStatus.some(
        (data: any) =>
          data.requestType === TICKET_REQUESTS.sandbox &&
          data.ticketLastStatus === TICKET_STATUS.done
      )
    ) {
      createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.dashboard,
        key: DASHBOARD_MODEL_KEY.jumpstartActivation,
        stage: JUMPSTART_ACTIVATION_STEPS_NAME.platformSetup,
        status: DASHBOARD_ACCORDION_STATUS.inProgress,
      });
    }
  }, [ticketStatus]);

  useEffect(() => {
    fetch(`${API_ROUTE_CONFIGURATION.announcements}true?strt=0`)
      .then((res) => res.json())
      .catch(() => setAnnouncements([]))
      .then((data: any) => {
        const ids = data?.results?.map((data: any) => data.content.id);

        Promise.all(
          ids?.map((id: string) =>
            fetch(`/console/confluence/page/${id}/dW5kZWZpbmVk`).then(
              (response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error("Not found");
                }
              }
            )
          )
        )
          .then((accouncement: any) => {
            const parser = new DOMParser();
            setAnnouncements(
              accouncement.map((data: any) => {
                if (
                  data &&
                  data.body &&
                  data.body["export_view"] &&
                  data.history
                ) {
                  const doc = parser.parseFromString(
                    data.body["export_view"].value,
                    "text/html"
                  );
                  const textContent = doc.body.textContent;

                  return {
                    title: data.title,
                    content: textContent,
                    htmlContent: data.body?.["export_view"]?.value,
                    lastModified: data.history.createdDate,
                  };
                }
              })
            );
          })
          .catch((err) => {
            setAnnouncements([]);
            console.error(err);
          });
      })
      .catch((err) => {
        setAnnouncements([]);
        console.error(err);
      });
  }, []);

  const handleActiveJourneyStep = (goToNextStep: boolean) => {
    if (goToNextStep) {
      setCurrentTab(getNextJourneyName(active));
      setActive(active + 1);
    }
  };

  const onClickJourneyHandler = (value: string) => {
    setCurrentTab(value);
  };
  const carouselRef = useRef<any>();
  const carouselResponsiveSettings = {
    desktop: {
      breakpoint: { max: 3000, min: 1640 },
      items: 5,
      slidesToSlide: 5,
    },
    desktopmin: {
      breakpoint: { max: 1640, min: 1200 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1200, min: 800 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet1: {
      breakpoint: { max: 800, min: 600 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [slideIndex, setSlideIndex] = useState<number>(0);
  useEffect(() => {
    if (currentTab) {
      let index = 0;
      switch (currentTab) {
        case DASHBOARD_JOURNEY.jumpstartActivation:
          index = 1;
          break;
        case DASHBOARD_JOURNEY.foundationalSetup:
          index = 2;
          break;
        case DASHBOARD_JOURNEY.productConfiguration:
          index = 3;
          break;
        case DASHBOARD_JOURNEY.testAndDeploy:
          index = 4;
          break;
      }
      const { currentSlide } = carouselRef.current.state;
      setSlideIndex(index - currentSlide);
    }
  }, [currentTab]);
  const resizeHandler = () => {
    setSlideIndex(0);
    setCurrentTab(DASHBOARD_JOURNEY.exploreFinxact);
    carouselRef?.current?.goToSlide?.(0);
  };
  useEffect(() => {
    window?.addEventListener?.("resize", resizeHandler);
    return () => {
      window?.removeEventListener?.("resize", resizeHandler);
    };
  }, []);
  const containerActiveArrow = {
    content: "''",
    position: "absolute",
    left: `${75 + slideIndex * 158}px`,
  };

  return (
    <Grid gap={16} className="dashboard-container">
      <GridItem colSpan={5}>
        <CardContainer customClass="journey-container">
          <Box>
            <Heading as={"h3"}>Your Finxact journey</Heading>
            <Text className="dashboard-desc-text">
              A step-by-step guide through your Console experience
            </Text>
          </Box>
          <Box>
            <Carousel
              swipeable={false}
              draggable={false}
              responsive={carouselResponsiveSettings}
              ssr={false}
              infinite={false}
              autoPlay={false}
              keyBoardControl={false}
              itemClass="carousel-item"
              containerClass="carousel-container dashboard-progress"
              ref={carouselRef}
              slidesToSlide={1}
              renderButtonGroupOutside={true}
              arrows={false}
              partialVisbile={false}
              customButtonGroup={<CarouselButtonGroup />}
            >
              <Flex
                onClick={onClickJourneyHandler.bind(
                  null,
                  DASHBOARD_JOURNEY.exploreFinxact
                )}
                className={`dashboard-progress-box ${
                  currentTab === DASHBOARD_JOURNEY.exploreFinxact
                    ? "active"
                    : ""
                }`}
              >
                <HStack w={"100%"}>
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line invisible"
                  />
                  {active >= 1 ? (
                    <Image
                      className="icon-img"
                      src={dashboardStarIcon}
                      alt="Learning"
                    />
                  ) : (
                    <Image
                      className="icon-img"
                      src={dashboardLockIcon}
                      alt="Learning"
                    />
                  )}
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                </HStack>
                <Text className="dashboard-progress-item-name">
                  {DASHBOARD_JOURNEY.exploreFinxact}
                </Text>
              </Flex>
              <Flex
                onClick={onClickJourneyHandler.bind(
                  null,
                  DASHBOARD_JOURNEY.jumpstartActivation
                )}
                className={`dashboard-progress-box ${
                  currentTab === DASHBOARD_JOURNEY.jumpstartActivation
                    ? "active"
                    : ""
                }`}
              >
                <HStack w={"100%"}>
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                  {envId && active >= 2 && !isProspect ? (
                    <Image
                      className="icon-img"
                      src={dashBoardJumpstartIcon}
                      alt="Learning"
                    />
                  ) : (
                    <Image
                      className="icon-img"
                      src={dashboardLockIcon}
                      alt="Learning"
                    />
                  )}
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                </HStack>
                <Text className="dashboard-progress-item-name">
                  {DASHBOARD_JOURNEY.jumpstartActivation}
                </Text>
              </Flex>

              <Flex
                onClick={onClickJourneyHandler.bind(
                  null,
                  DASHBOARD_JOURNEY.foundationalSetup
                )}
                className={`dashboard-progress-box ${
                  currentTab === DASHBOARD_JOURNEY.foundationalSetup
                    ? "active"
                    : ""
                }`}
              >
                <HStack w={"100%"}>
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                  {envId && active >= 3 && !isProspect ? (
                    <Image
                      className="icon-img"
                      src={dashboardFoundationalSetupIcon}
                      alt="Learning"
                    />
                  ) : (
                    <Image
                      className="icon-img"
                      src={dashboardLockIcon}
                      alt="Learning"
                    />
                  )}
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                </HStack>
                <Text className="dashboard-progress-item-name">
                  {DASHBOARD_JOURNEY.foundationalSetup}
                </Text>
              </Flex>
              <Flex
                onClick={onClickJourneyHandler.bind(
                  null,
                  DASHBOARD_JOURNEY.productConfiguration
                )}
                className={`dashboard-progress-box ${
                  currentTab === DASHBOARD_JOURNEY.productConfiguration
                    ? "active"
                    : ""
                }`}
              >
                <HStack w={"100%"}>
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                  {envId && active >= 4 ? (
                    <Image
                      className="icon-img"
                      src={dashboardProductConfigIcon}
                      alt="Learning"
                    />
                  ) : (
                    <Image
                      className="icon-img"
                      src={dashboardLockIcon}
                      alt="Learning"
                    />
                  )}
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                </HStack>
                <Text className="dashboard-progress-item-name">
                  {DASHBOARD_JOURNEY.productConfiguration}
                </Text>
              </Flex>
              <Flex
                onClick={onClickJourneyHandler.bind(
                  null,
                  DASHBOARD_JOURNEY.testAndDeploy
                )}
                className={`dashboard-progress-box ${
                  currentTab >= DASHBOARD_JOURNEY.testAndDeploy ? "active" : ""
                }`}
              >
                <HStack w={"100%"}>
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line"
                  />
                  {envId && completed > 0 && !isProspect ? (
                    <Image
                      className="icon-img"
                      src={dashboardTestDeployIcon}
                      alt="Learning"
                    />
                  ) : (
                    <Image
                      className="icon-img"
                      src={dashboardLockIcon}
                      alt="Learning"
                    />
                  )}
                  <Divider
                    orientation="horizontal"
                    className="dashboard-progress-line invisible"
                  />
                </HStack>
                <Text className="dashboard-progress-item-name">
                  {DASHBOARD_JOURNEY.testAndDeploy}
                </Text>
              </Flex>
            </Carousel>
          </Box>
          <Box
            className="dashboard-main-content-container"
            _before={containerActiveArrow}
          >
            {currentTab === DASHBOARD_JOURNEY.exploreFinxact && (
              <ExploreFinxact
                title={"Explore the value Finxact can offer your organization"}
                exploreDetails={journeyDetails?.explore_finxact}
                disabledState={active >= 1}
                ticketStatus={ticketStatus}
                updateTicketStatus={setTicketStatus}
              />
            )}
            {currentTab === DASHBOARD_JOURNEY.jumpstartActivation && (
              <JumpstartActivation
                title={"Your steps to get set up for your Finxact journey"}
                jumpstartDetails={journeyDetails?.jumpstart_activation}
                disabledState={active >= 2}
                activeStep={handleActiveJourneyStep}
                access={access}
              />
            )}
            {currentTab === DASHBOARD_JOURNEY.foundationalSetup && (
              <FoundationalSetup
                title={
                  "Lay the groundwork for your institution to facilitate product configuration"
                }
                foundationalDetails={journeyDetails?.foundational_setup}
                disabledState={active >= 3}
                activeStep={handleActiveJourneyStep}
              />
            )}
            {currentTab === DASHBOARD_JOURNEY.productConfiguration && (
              <ProductConfiguration
                title={
                  active >= 4
                    ? "Configure products with our intuitive Console experience"
                    : "Come back after finishing Jumpstart and foundational setup to start product configuration"
                }
                productConfigDetails={journeyDetails?.product_configuration}
                disabledState={active >= 4 || isProspect}
                completed={active >= 4 && completed > 0 ? completed : null}
                inprogress={active >= 4 && inProgress > 0 ? inProgress : null}
              />
            )}
            {currentTab === DASHBOARD_JOURNEY.testAndDeploy && (
              <TestAndDeploy
                title={"Launch your products in market"}
                testAndDeployDetails={journeyDetails?.test_and_deploy}
                disabledState={completed > 0}
                completed={active >= 5 ? 6 : null}
              />
            )}
          </Box>
        </CardContainer>

        <CardContainer customClass="recent-product-section">
          <RecentSection
            recentHeaders={journeyDetails?.recent_section}
            productList={productList}
            showProductTable={active >= 4}
          />
        </CardContainer>

        {/* Based on the journey we can change resources */}
        <ResourceSection resourceData={journeyDetails?.resources_section} />
      </GridItem>

      <GridItem colSpan={2} className="right-panel">
        <Heading as={"h4"}>Environments</Heading>
        <Box className="dashboard-env-content">
          {envData && envData?.length > 0 ? (
            <EnvironmentContainer
              envData={envData}
              upgradeVersionLink={journeyDetails?.upgradeVersionLink}
            />
          ) : (
            <DefaultEnvironmentContainer
              ticketStatus={ticketStatus}
              updateTicketStatus={setTicketStatus}
            />
          )}
        </Box>
        <Box className="dashboard-right-contents">
          <Heading as={"h4"}>Announcements</Heading>
          <Box>
            {announcements && announcements.length > 0 ? (
              announcements.map((data: any, index: number) => (
                <>
                  {index <= 1 && (
                    <Text className="announcements-container" key={index}>
                      <Box className="announcements-title">{data.title}</Box>
                      <Box className="announcements-date">
                        {moment(data.lastModified).format("l")}
                      </Box>

                      <div className="announcements-description">
                        {/* Sanitizing and inserting text/html content in innerHtml  */}
                        {data?.htmlContent ? (
                          <div
                            className="announcements-html-description"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(data?.htmlContent),
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Text>
                  )}
                </>
              ))
            ) : (
              <Text>There are no new announcements.</Text>
            )}
            {announcements && announcements.length > 2 ? (
              <Link href={"/news"}>
                <Button className="app-btn-link links">
                  See all <ChevronRightIcon className="chakra-icon" />
                </Button>
              </Link>
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box className="dashboard-right-contents">
          <Heading as={"h4"}>Release notes</Heading>

          <Text>
            What’s new in the Finxact Core? Stay current with updates,
            enhancements, and breaking changes.
          </Text>

          <a href={"/release"}>
            <Button className="app-btn-link links">
              Visit release notes <ChevronRightIcon className="chakra-icon" />
            </Button>
          </a>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default Dashboard;
