"use client";

import { Box, Heading, VStack, HStack, useToast, Text } from "@chakra-ui/react";
import "./CustomRoles.scss";
import { useState, useContext, useRef, useEffect } from "react";
import {
  AppFormLabel,
  AppModal,
  CardContainer,
  ErrorAlert,
  InputText,
  MultiSelectCheckBox,
  RadioButtonGroup,
} from "@/components/common";
import { useFormik } from "formik";
import {
  createConsoleRole,
  deleteRole,
  updateConsoleRole,
} from "../access-management-api-service";
import { MAIN_FORM_ID, TAB_NAMES } from "@/utils";
import { useRouter, useSearchParams } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { initialFormValues, validationSchema } from "./CustomRolesValidation";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import Image from "next/image";
import { accordionDeleteIcon } from "public/assets";
export type RoleType = "core" | "console" | "";

export const CUSTOM_ROLES_LIST: any[] = [
  { label: "Console", value: "console" },
  { label: "Core", value: "core" },
];

const CONSOLE_PERMISSIONS_TABLE_COLUMN_DEF = [
  { header: "Operations", accessorKey: "id", enableSorting: false },
  { header: "Description", accessorKey: "description", enableSorting: false },
  { header: "", accessorKey: "action", enableSorting: false },
];

const CustomRoles = ({ data }: { data: any }) => {
  const modalRef = useRef<any>();
  const [removeRole, setRemoveRole] = useState("");
  const [removalId, setRemovalId] = useState("");
  const [currnetModal, setCurrentModal] = useState<string>("");

  const { consolePermissions, supplementalPermissions, formData } = data;
  const [isEdit] = useState<boolean>(formData ? true : false);
  const [roleType, setRoleType] = useState<RoleType>("");

  const toast = useToast();
  const toastId = "custom-roles";
  const router = useRouter();

  const params = useSearchParams();
  const isView = params?.has("view")!;

  const { tooltipFlyoutDetails, isSaveDisabled, onSaveDisabled } =
    useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    onSaveDisabled?.(isView);
  }, [isView, isSaveDisabled]);

  const handleChange = (event: any) => {
    setRoleType(event);
    if (event === "core") {
      onSaveDisabled?.(true);
    } else {
      onSaveDisabled?.(false);
    }
  };

  const showModal = (row: any, modal: string) => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.openModal();
        setRemoveRole(row.name);
        setRemovalId(row?.id);
        setCurrentModal(modal);
      }
    });
  };

  const handlePrimaryButtonClick = async () => {
    let res;
    res = await deleteRole({
      id: removalId,
      toastOptions: {
        toast,
        toastId,
        successMessage: `${removeRole} has been deleted`,
      },
    });
    closeModal();
  };

  const closeModal = () => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.closeModal();
        setRemovalId("");
      }
    });
  };

  const MODALS: any = {
    removeRole: {
      title: `Are you sure you want to remove ${removeRole} role?`,
      description: "",
      primaryButtonClick: handlePrimaryButtonClick,
      secondaryButtonClick: closeModal,
      primaryButtonName: "Yes, remove",
      secondaryButtonName: "Cancel",
      component: null,
    },
  };

  const onSubmit = async (values: any, actions: any) => {
    let toastMsg = {
      toast: toast,
      toastId: toastId,
      msg: `Custom role ${isEdit ? "updated" : "created"} successfully`,
    };
    let formValues = { ...values };
    delete formValues.description;
    let response;
    if (isEdit) {
      response = await updateConsoleRole(values, toastMsg);
    } else {
      response = await createConsoleRole(values, toastMsg);
    }
    if (response) {
      setTimeout(() => {
        router.push(
          `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}?tab=${TAB_NAMES.roles}`
        );
      }, 3000);
    }
  };
  let initialCustomRoleValues = { ...initialFormValues };
  if (formData) {
    initialCustomRoleValues = { ...formData };
  }
  const formikConfigs = useFormik({
    onSubmit,
    validationSchema,
    initialValues: initialCustomRoleValues,
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formikConfigs;
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };
  const handleOnConsolePermChange = (value: any) => {
    setFieldValue("type", "cnsl");
    setFieldValue("permissions", value);
  };
  const handleOnSupplPermChange = (value: any) => {
    setFieldValue("type", "core");
    setFieldValue("permissions", value);
  };
  return (
    <>
      <Box width="55%">
        <form onSubmit={handleSubmit} id={MAIN_FORM_ID} noValidate>
          <VStack
            className="access-management-details-container"
            align={"start"}
          >
            <HStack>
              <Heading as={"h3"}>
                {isEdit ? (isView ? "View" : "Edit") : "Create custom"} role
              </Heading>
              {values.organization && (
                <Image
                  src={accordionDeleteIcon}
                  alt="delete icon"
                  onClick={() => showModal(values, "removeRole")}
                />
              )}
            </HStack>
            <CardContainer customClass="custom-roles-container">
              {!isEdit && (
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="Select the custom role type"
                    isRequired={true}
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("roleType")
                        ?.tooltip_text_main
                    }
                  />
                  <RadioButtonGroup
                    isBoxedRadio={true}
                    value={roleType}
                    onChange={handleChange}
                    radioPropList={CUSTOM_ROLES_LIST}
                    stackDirection={"row"}
                    spacing={"1rem"}
                    customClass="roles-selection-radio-group"
                  />
                </Box>
              )}
              {(isEdit || roleType === "console") && (
                <>
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName="Console role name"
                      labelFor="consoleRoleName"
                      isRequired={true}
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("name")
                          ?.tooltip_text_main
                      }
                    />
                    <InputText
                      id="consoleRoleName"
                      onChange={handleOnChange("name")}
                      value={values.name}
                      onBlur={handleBlur}
                      placeholder="Insert name here"
                      disabled={isView}
                    />
                    {errors.name && touched.name && (
                      <ErrorAlert>
                        <span>{ERROR_MESSAGE}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName="Console role description"
                      labelFor="consoleRoleDesc"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("desc")
                          ?.tooltip_text_main
                      }
                    />
                    <InputText
                      id="consoleRoleDesc"
                      onChange={handleOnChange("description")}
                      value={values.description}
                      onBlur={handleBlur}
                      placeholder="Insert description here"
                      disabled={isView}
                    />
                  </Box>
                  <Box
                    className={`app-form-field-container ${(isEdit && formData.type === "core") || (values.permissions?.length && values.type === "core") ? "disabled" : ""}`}
                  >
                    <MultiSelectCheckBox
                      data={consolePermissions}
                      setSelectedIds={handleOnConsolePermChange}
                      selectedIds={
                        values.type === "cnsl" ? values.permissions : []
                      }
                      keyName={"id"}
                      columnDef={CONSOLE_PERMISSIONS_TABLE_COLUMN_DEF}
                      tableLabel="Define permissions for this custom Console role"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("permissions")
                          ?.tooltip_text_main
                      }
                      isDisabled={isView}
                    />
                  </Box>
                  <Box
                    className={`app-form-field-container ${(isEdit && formData.type === "cnsl") || (values.permissions?.length && values.type === "cnsl") ? "disabled" : ""}`}
                  >
                    <MultiSelectCheckBox
                      data={supplementalPermissions}
                      setSelectedIds={handleOnSupplPermChange}
                      selectedIds={
                        values.type === "core" ? values.permissions : []
                      }
                      keyName={"id"}
                      columnDef={CONSOLE_PERMISSIONS_TABLE_COLUMN_DEF}
                      tableLabel="Define permissions for these supplemental operations"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("supPermissions")
                          ?.tooltip_text_main
                      }
                      isDisabled={isView}
                    />
                  </Box>
                </>
              )}
              {!isEdit && roleType === "core" && (
                <Box className="core-role-desc">
                  <Text my={8}>
                    Custom Core roles are part of Core configuration and follow
                    the SDLC in place for Core configuration changes. Once you
                    are out of your implementation phase, Core role changes
                    should be executed in a project, included in a build, and
                    loaded to upper tier environments via a deployment of the
                    new build that includes them.
                  </Text>
                  <Text mb={8}>
                    For custom Core roles, navigate{" "}
                    <a href={`${ROUTE_BASE_URL.ROLE}`} className="app-btn-link">
                      here
                    </a>
                  </Text>
                </Box>
              )}
            </CardContainer>
          </VStack>
        </form>
      </Box>
      <AppModal
        ref={modalRef}
        customClass="access-management-remove-app-modal"
        modalTitle={MODALS[currnetModal]?.title}
        description={MODALS[currnetModal]?.description}
        primaryBtnSelect={MODALS[currnetModal]?.primaryButtonClick}
        secondaryBtnSelect={MODALS[currnetModal]?.secondaryButtonClick}
        primaryBtnProp={{
          name: `${MODALS[currnetModal]?.primaryButtonName}`,
          btnClassName: "app-btn-inverse-secondary-add-new",
        }}
        secondaryBtnProp={{
          name: `${MODALS[currnetModal]?.secondaryButtonName}`,
          btnClassName: "app-btn-reg-secondary",
        }}
        isCentered
        showCloseIcon={false}
      >
        {MODALS[currnetModal]?.component}
      </AppModal>
    </>
  );
};

export default CustomRoles;
