"use client";

import { Box, Flex } from "../components/ChakraUiManager";
import { useHits } from "react-instantsearch";
import { constructURL } from "@finxact/finxact-shared-ui";
import { useUserAuthenticated } from "@/app/providers";
import { checkIfRestricted } from "./common";

export function Hit(props: any) {
  const isAuthenticated = useUserAuthenticated();
  const url = constructURL(props.hit.type, props.hit.uid, props.hit.url);

  const { hit, location } = props;

  let description;
  if (location === "results") {
    description = hit.description || "No description found";
  } else if (location === "topmenu") {
    description = hit.description || "No description found";
  } else {
    description = hit.description
      ? hit.description.length > 125
        ? `${hit.description.slice(0, 125)}...`
        : hit.description
      : "No description found";
  }

  return (
    <>
      {!isAuthenticated &&
      props?.hit?.tags &&
      checkIfRestricted(props.hit?.tags) ? (
        ""
      ) : (
        <Flex className="result">
          <Box className="result-detail">
            <a href={url}>
              <h3 className="title">{props.hit.title || "No title found"}</h3>
            </a>
            <Box className="description">
              {props.hit.description && props.location === "results"
                ? props.hit.description
                : props.hit.description && props.hit.description.length > 125
                  ? `${props.hit.description.slice(0, 125)}...`
                  : props.hit.description || "No description found"}
            </Box>
          </Box>
          <Box className="result-module">{props.hit.slug}</Box>
        </Flex>
      )}
    </>
  );
}

interface Hit {
  tags: string[];
  slug: string;
  objectID: string;  
 
}


export function CustomHit(props: any) {
const { hits } = useHits() as any;

  const slug = props.slug;

  const docnavHits = hits.filter((hit: Hit) => hit.tags.includes("docnav"));
  const slugHits = hits.filter((hit: Hit) => !hit.tags.includes("docnav") && hit.slug === slug);
  const otherHits = hits.filter((hit: Hit) => !hit.tags.includes("docnav") && hit.slug !== slug);

  const sortedHits = [...slugHits, ...docnavHits, ...otherHits];

  const displayedHits = props.location === "topmenu" ? sortedHits.slice(0, 5) : sortedHits;

  return (
    <div>
      {displayedHits.map(hit => (
        <Hit key={hit.objectID} location={props.location} hit={hit} />
      ))}
    </div>
  );
}
