"use client";
import { useEffect, useState, useRef, ReactNode } from "react";

import {
  Box,
  Flex,
  Text,
  Image,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Button,
  MenuDivider,
} from "../ChakraUiManager";
import { OnboardingGuidedTooltip } from "../common";

import { ChevronDownIcon, ChevronRightIcon } from "../ChakraUiManager";
import { WrappedLink } from "@/components/common";

import "./TopMenuManager.scss";

import { SearchIcon } from "@chakra-ui/icons";
import { AvatarIcon } from "../common";
import { useUserAuthenticated, useUserInfo } from "@/app/providers";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";
import {
  getConsolePermissions,
  getSelectedOrg,
  getAppConfig,
  getAppPrismicClient,
} from "@/store";

import { Hit, CustomHit } from "@/utils/algolia";

import algoliasearch from "algoliasearch";

import {
  InstantSearch,
  SearchBox,
  SearchBoxProps,
  useInstantSearch,
  Hits,
  useHits,
  Configure,
} from "react-instantsearch";

import { getMenu } from "@/prismic-api/prismic-api-service";
import { finxactLogoWhite } from "public/assets";
import OrgEnvDropdown from "../common/OrgEnvSelector/OrgEnvDropdown";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { ONBOARDING_TOUR_GUIDED_TOOLTIP_STEPS } from "./../data/data";
import {
  DASHBOARD_COMPLETED_ORG_PHASES,
  useIsPermitted,
} from "@/utils";
import {
  MainMenuItemsSliceDefaultItem,
  Simplify,
} from "../../../prismicio-types";
import SettingsDropdownSection from "./SettingsDropdownSection";
import ChatButton from "../chat/ChatButton";

interface NoResultsBoundaryProps {
  children: ReactNode;
  fallback: ReactNode;
  pathName: string | null;
}

export default function TopMenuManager() {
  const prismicClient = useSelector(getAppPrismicClient);

  const ref = useRef<HTMLDivElement>(null);
  const { user } = useUserInfo();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [showSearchText, setShowSearchText] = useState(false);
  const pathName = usePathname();
  const userAuthenticated = useUserAuthenticated();
  const [run, setRun] = useState(false);
  const [topMenuLinks, setTopMenuLinks] =
    useState<Simplify<MainMenuItemsSliceDefaultItem>[]>();
  const consolePermissions = useSelector(getConsolePermissions);
  const selectedOrg = useSelector(getSelectedOrg);
  const isPermitted = useIsPermitted();
  const appConfig = useSelector(getAppConfig);
  const isTeaser =
    pathName &&
    (pathName === "" || pathName === "/" || pathName.startsWith("/solutions"));

  function toggleSearch() {
    setShowSearchText(!showSearchText);
  }

  const searchClient = algoliasearch(
    appConfig?.searchAppId!,
    appConfig?.searchKey!,
  );

  const dashboardLink = isPermitted("cnsl", "workflow-dashboard-access") ? '/dashboard' : '/resourcesDash'

  const queryHook: SearchBoxProps["queryHook"] = (query, search) => {
    search(query);
  };

  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const handleOutSideClick = (event: any) => {
      if (!ref.current?.contains(event.target)) {
        setShowSearchText(false);
      }
    };
    window.addEventListener("mousedown", handleOutSideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [ref]);

  useEffect(() => {
    const onboarding_guided = user.fastProfile.onboarding_guided_viewed;
    if (
      onboarding_guided !== undefined &&
      onboarding_guided !== null &&
      !onboarding_guided &&
      userAuthenticated
    ) {
      setRun(true);
    } else {
      setRun(false);
    }
  }, [user]);

  useEffect(() => {
    if (
      !topMenuLinks &&
      !user.loading &&
      ((user.signedIn && consolePermissions?.length > 0) || !user.signedIn)
    ) {
      let topMenuLinksRaw = getMenu(prismicClient, "top-nav-plain-1");
      Promise.all([topMenuLinksRaw]).then((values) => {
        if (!user.signedIn) {
          setTopMenuLinks(values[0] ?? []);
        } else {
          const menuItems = values[0];
          const dashboardMenu = menuItems?.find(
            (menu) => menu.menu_link_plain === "/dashboard",
          );

          if (dashboardMenu) {
            dashboardMenu.menu_link_plain = dashboardLink
          }

          setTopMenuLinks(
            menuItems?.filter((value) => {
              if (
                value.menu_link_plain === "/academy" &&
                !isPermitted("cnsl", "academy-access")
              ) {
                return false;
              }

              if (
                value.menu_link_plain === "/docs" &&
                !isPermitted("cnsl", "documentation-access")
              ) {
                return false;
              }

              if (
                value.menu_link_plain === "/apis" &&
                !isPermitted("cnsl", "api-library-access")
              ) {
                return false;
              }

              return true;
            }),
          );
        }
      });
    }
  }, [
    topMenuLinks,
    consolePermissions,
    selectedOrg?.phase,
    setTopMenuLinks,
    isPermitted,
    user.signedIn,
    user.loading,
  ]);

  const handleClickStart = () => {
    setIsTooltipOpen(true);
  };

  const handleOnChange = () => async (event: any) => {
    if (event) {
      setShowSearchText(true);
    } else {
      setShowSearchText(false);
      setIsTooltipOpen(false);
    }
  };

  return (
    <>
      <Box className="external-flex-items">
        <Flex
          flex={{ base: 1 }}
          justifyContent="space-between"
          justify={{ base: "center", md: "start" }}
        >
          <Flex>
            <WrappedLink href={dashboardLink} style={{ display: "flex" }}>
              <Image
                src={finxactLogoWhite.src}
                alt="MAIN Logo"
                className="nav-logo"
              />
            </WrappedLink>
            <DesktopNav topmenu={topMenuLinks} />
          </Flex>
          <OnboardingGuidedTooltip
            tooltipSteps={ONBOARDING_TOUR_GUIDED_TOOLTIP_STEPS}
            onClickNext={handleOnChange()}
            resetTooltip={isTooltipOpen}
            onTooltipRender={setRun}
            tooltipRenderstate={run}
          >
            <Flex alignItems="center">
              {!showSearchText && (
                <>
                  {user && user.signedIn && (
                    <Box paddingRight={8}>
                      <OrgEnvDropdown />
                    </Box>
                  )}
                  <ChatButton />
                  {user.loading == false && user.signedIn == true ? (
                    <Box className="user-avatar-menu">
                      <Menu autoSelect={false}>
                        <MenuButton as={IconButton}>
                          <AvatarIcon type="access" size="xl" />
                        </MenuButton>
                        <MenuList>
                          <div className="menuListContainer">
                            <SettingsDropdownSection />
                            <MenuGroup
                              title={`${user.firstName} ${user.lastName}`}
                            >
                              <MenuItem
                                as="a"
                                href={ROUTE_BASE_URL.PROFILE}
                                className={`menu-item-profile-state ${pathName === ROUTE_BASE_URL.PROFILE ? "isActive" : ""}`}
                              >
                                Profile
                              </MenuItem>
                              <MenuItem
                                as="a"
                                href={ROUTE_BASE_URL.PROFILE}
                                className={`menu-item-profile-state`}
                              >
                                Environment &amp; keys
                              </MenuItem>
                            </MenuGroup>
                            <MenuDivider />
                            <MenuItem
                              onClick={() => {
                                localStorage.setItem("loggingOut", "true");
                                window.location.href = `/console/logout?returnPage=${window.location.pathname}`;
                              }}
                              className="menu-item-sign-out"
                            >
                              Sign out
                            </MenuItem>
                          </div>
                        </MenuList>
                      </Menu>
                    </Box>
                  ) : (
                    ""
                  )}

                  {isTeaser && (
                    <Flex className="teaser-links">
                      <a className="" href="/profile-setup">
                        Sign up
                      </a>
                      |
                      <a className="" href={dashboardLink}>
                        Log in
                      </a>
                    </Flex>
                  )}

                  <div className="search-container">
                    <IconButton
                      aria-label="Search"
                      icon={<SearchIcon />}
                      variant="ghost"
                      onClick={toggleSearch}
                      className="nav-search-icon"
                    />
                  </div>
                </>
              )}
            </Flex>

            {showSearchText && (
              <InstantSearch
                indexName={appConfig?.searchIndex}
                searchClient={searchClient}
              >
                <Flex className="global-search" alignItems="center" ref={ref}>
                  <Box className="search-container-expanded">
                    <IconButton
                      aria-label="Search"
                      icon={<SearchIcon />}
                      variant="ghost"
                      className="nav-search-icon"
                    />
                    <SearchBox
                      placeholder="Search across Console, Finxact Academy, & API References"
                      classNames={{
                        root: "global-search-input",
                        form: "global-search-input",
                        input: "global-search-input",
                        submitIcon: "display-none",
                      }}
                      queryHook={queryHook}
                      searchAsYouType={true}
                    />
                    <NoResultsBoundary pathName={pathName} fallback={<NoResults />}>
                      <Flex className="search-results"></Flex>
                    </NoResultsBoundary>
                    <Configure hitsPerPage={100} />
                  </Box>
                </Flex>
              </InstantSearch>
            )}
          </OnboardingGuidedTooltip>
        </Flex>
      </Box>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </>
  );
}

function NoResultsBoundary({ children, fallback, pathName }: NoResultsBoundaryProps) {
  const { results, uiState, indexUiState } = useInstantSearch();
  const appConfig = useSelector(getAppConfig);

  if (!indexUiState.query || !appConfig?.searchIndex) {
    return "";
  }

  const searchQuery = uiState[appConfig?.searchIndex].query;

  if (!results.hits.length && results.nbHits === 0) {
    return fallback;
  }

  const path = pathName;

  const extractPart = (pathname: string) => {
    const parts = pathname.split('/').filter(Boolean); 

 
  if (parts[0] === 'algolia-search') {
    return null; 
  }

  if (parts[1] === 'tutorials') {
    return parts[1];
  }

  return parts[0] || '';

  };

  const slug = extractPart(pathName || "");

 const href = slug
  ? `/algolia-search?searchQuery=${searchQuery}&slug=${slug}`
  : `/algolia-search?searchQuery=${searchQuery}`;

  return (
    <Flex className="search-results">
      <CustomHit
        slug={slug}
   
        useHits={useHits}
        location="topmenu"

      />
      <Box display="flex" justifyContent="end" className="btn-view-all-results">
        <a href={href}>
          <Button className="app-btn-reg-secondary-transparent">
            View All Results
          </Button>
        </a>
      </Box>
    </Flex>
  );
}

function NoResults() {
  const { setUiState, uiState } = useInstantSearch();
  return (
    <Flex className="search-results flex-acenter">
      <p>No results found.</p>
    </Flex>
  );
}
const DesktopNav = ({
  topmenu,
}: {
  topmenu: Simplify<MainMenuItemsSliceDefaultItem>[] | undefined;
}) => {
  const linkColor = useColorModeValue("#ffffff", "gray.200");
  const popoverContentBgColor = useColorModeValue("black", "gray.800");

  const [topMenuLinks, setTopMenuLinks] = useState<any>();

  const pathName = usePathname();

  return (
    <>
      <div className="topmenu">
        {topmenu?.map((navItem: any, index: any) => (
          <WrappedLink href={navItem.menu_link_plain} key={index}>
            <Box
              key={navItem.menu_title_plain}
              className={`topmenu-box ${
                pathName?.includes(navItem.menu_link_plain)
                  ? "topmenu-navlink-active"
                  : ""
              }`}
            >
              <Popover trigger={"hover"} placement={"bottom-start"}>
                <PopoverTrigger>
                  <Box className="topmenu-box-link" color={linkColor}>
                    {navItem.menu_title_plain}
                  </Box>
                </PopoverTrigger>

                {navItem?.children && (
                  <PopoverContent
                    boxShadow={"xl"}
                    bg={popoverContentBgColor}
                    rounded={"xl"}
                    className="popovercontent-nav"
                  >
                    <Stack>
                      {navItem?.children.map((child: any) => (
                        <DesktopSubNav key={child.label} {...child} />
                      ))}
                    </Stack>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          </WrappedLink>
        ))}
      </div>
    </>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as="a"
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Inspiration",
    children: [
      {
        label: "Explore Design Work",
        subLabel: "Trending Design to inspire you",
        href: "#",
      },
      {
        label: "New & Noteworthy",
        subLabel: "Up-and-coming Designers",
        href: "#",
      },
    ],
  },
  {
    label: "Find Work",
    children: [
      {
        label: "Job Board",
        subLabel: "Find your dream design job",
        href: "#",
      },
      {
        label: "Freelance Projects",
        subLabel: "An exclusive list for contract work",
        href: "#",
      },
    ],
  },
  {
    label: "Learn Design",
    href: "#",
  },
  {
    label: "Hire Designers",
    href: "#",
  },
];
