import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Link, Button, Text, Stack } from "@/components/ChakraUiManager";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { ROUTE_PATH } from "@/route-config/route-path";

import "./NextApiReference.scss";
import { NavigationDocumentItemData } from "@/app/apis/providers";
/**
 * Props for `NextApiReference`.
 */
export type NextApiReferenceProps =
  SliceComponentProps<Content.NextApiReferenceSlice>;

/**
 * Component for "NextApiReference" Slices.
 */
const NextApiReference = ({
  title,
  uid,
  type,
  path
}: NavigationDocumentItemData): JSX.Element => {
  return (
    <section className="next-api-ref">
      <Stack gap="16px">
        <Text className="title">Next API reference</Text>
        <Link
          className="reference"
          href={path}
          width="fit-content"
        >
          {/* using DownloadIcon from chakra ui as for the Image Icon, color is not possible to change. */}
          <Button rightIcon={<ChevronRightIcon />} variant="outline">
            {title}
          </Button>
        </Link>
      </Stack>
    </section>
  );
};

export default NextApiReference;
