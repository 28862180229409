"use client";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppSingleSelectDropdown,
  CardContainer,
  CommonTitle,
  ErrorAlert,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext, useEffect, useState } from "react";
import {
  RolloverProductInterestRatesProps,
  getValidationSchema,
} from "./RolloverProductInterestRatesConfigurationValidation";
import { useFormik } from "formik";
import { PostSchema, TermComponentData } from "../../../model/types";
import { createTermComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type RolloverProductInterestRatesConfigurationProps = {
  data: {
    formData: TermComponentData | null;
    termComponentOptionsData: PostSchema;
    prodListOption: string[];
  };
};

const RolloverProductInterestRatesConfiguration = (
  props: RolloverProductInterestRatesConfigurationProps
) => {
  const toast = useToast();

  const onSubmit = async (
    values: RolloverProductInterestRatesProps,
    actions: any
  ) => {
    const { formData } = props.data;
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["term"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData) {
      if (productDetails) {
        const res = await createTermComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            rollProd: values.rollProd,
          },
          toastOptions: {
            toast,
            toastId: "rol-prod-int-rat-config",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : "Rollover Product Interest and Rates Configuration"
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.termComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.termComponentOptionsData]);

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      rollProd: props.data.formData?.rollProd ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const rolloverProductFormHandler =
    (key: keyof RolloverProductInterestRatesProps) =>
    (value: string | number) => {
      setFieldValue(key, value);
    };
  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="app-form-container">
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            COMPONENT_CLASS["term"],
            getIfxProdType(productDetails),
            true
          )}
          featureName={props?.data?.formData?.componentName ?? ""}
          version={`VERSION ${props?.data?.formData?.version ?? "1"}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Rollover Product Interest and Rates Configuration"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : ""
          }
        />
        <CardContainer>
          <CardBody>
            <AppFormLabel
              labelName={"What is the rollover product?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("rollProd")
                  ?.tooltip_text_main
              }
              labelFor="rollover_product_input"
            />
            <AppSingleSelectDropdown
              id="rollover_product"
              value={values.rollProd}
              onChange={rolloverProductFormHandler("rollProd")}
              dropdownList={props.data.prodListOption}
            />
            {errors.rollProd && touched.rollProd && (
              <ErrorAlert>
                <span>{errors.rollProd}</span>
              </ErrorAlert>
            )}
          </CardBody>
        </CardContainer>
      </Box>
    </form>
  );
};

export default RolloverProductInterestRatesConfiguration;
