"use client";
import {
  AlertStatus,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  AddNewButton,
  AppFormLabel,
  AppPortal,
  CardContainer,
  InputText,
  StatusBubble,
  FeatureTooltip,
  FormFieldMsg,
} from "../../../common";
import css from "./ProductSummary.module.scss";
import styleVars from "@/styles/_export.module.scss";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { ProductSummaryFeatureDetails } from "@/components";

import {
  DEPOSIT_COMPONENT_CLASS_FOR_SUMMARY,
  getCompleteAttributeList,
  getCurrentProductFormLinkToNavigate,
  LOAN_COMPONENT_CLASS_FOR_SUMMARY,
  PRODUCT_SUMMARY_STEPS,
  PRODUCT_SUMMARY_STEPS_LOAN,
  ProductSummaryType,
} from "../product-summary-config";
import {
  DASHBOARD_ACCORDION_STATUS,
  IFX_ACCT_GL_CATEGORY,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_POSN_CLASS_TYPE,
  IFX_PRODUCT_TYPE_VAL,
  PRODUCT_CLASS_NAME,
  RECOMENDED_PRODUCT_FEATURES,
  checkReadOnlyEntitlement,
  deepCopy,
  useUserEntitlement,
} from "@/utils";
import {
  ProductType,
  ProductWorkflowModelType,
  fetchExistingProduct,
  getFeatureStatus,
  getQuickStartFlag,
  constructProductSummaryFeatureDetails,
  setQuickStartFlagInStorage,
  updateProductInSummary,
  mapSelectedAttributeData,
  ProductComponentType,
  getIfxProdType,
  shouldUpdateMatrix,
  getRemovedComponent,
} from "@/components/product-management";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  addDesiredComponent,
  getDesiredProdFromMatrix,
  getSelectedFeatureData,
  updateCompsVerAndCreateProd,
  updateMatrix,
  updateSelectedAttributeOptionsFromApi,
} from "../../product-configuration/product-config-client-service";
import {
  createWorkflowGeneric,
  deleteWorkflowGeneric,
  deleteWorkflowStage,
  getWorkflowGeneric,
} from "@/api-config/api-service";
import {
  arrowForwardBlack,
  arrowForwardBlue,
  IconEdit,
  IconFeatureDetails,
  dotPatternWalkthrough,
} from "public/assets";
import Image from "next/image";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { useRouter } from "next/navigation";
import { isArray } from "lodash-es";

type FeatureCardProps = {
  feature: ProductSummaryType;
  selected: boolean;
  keyVal: string;
  isAdditionalAttributes: boolean;
  handleSelectedFeature?: () => void;
};

function constructFeatureCardClass(selected: boolean) {
  return {
    p: "20px",
    marginBottom: "16px",
    borderRadius: "8px",
    boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.10)",
    gap: "18px",
    backgroundColor: "white",
    cursor: "pointer",
    zIndex: 1,
    marginRight: "70px",
    ...(selected
      ? {
          clipPath: "inset(-15px 0px -15px -15px)",
          boxShadow: "-6px 0px 14px 0px rgba(0, 0, 0, 0.30)",
          borderRightRadius: 0,
          border: `2px solid ${styleVars.appDefaultLinkColor}`,
          borderRight: "none",
          marginRight: "-2.3px",
        }
      : {}),
  };
}

// Not placing this in components because styling of the card only is specific to this screen
const FeatureCard = (props: FeatureCardProps) => {
  const { feature, selected, handleSelectedFeature, keyVal } = props;
  return (
    <Flex
      key={keyVal}
      sx={constructFeatureCardClass(selected)}
      onClick={handleSelectedFeature}
    >
      <HStack w="100%">
        <StatusBubble
          status={getFeatureStatus(feature?.status || "")}
          iconWidth={24}
        />
        <Text
          as="span"
          fontSize="20px"
          fontWeight={600}
          lineHeight="28px"
          paddingLeft="20px"
        >
          {feature?.name}
        </Text>
        <Spacer />
        <Image
          src={selected ? arrowForwardBlue : arrowForwardBlack}
          alt="Right arrow 1"
        />
      </HStack>
    </Flex>
  );
};

export default function ProductSummary() {
  //context API
  const {
    currProductId,
    isSummaryQucikStartFlow,
    productDetails,
    ifxAcctType,
    ifxAcctProductType,
    ifxAcctProductSubType,
    productWorkflowData,
    resetProductInfo,
    setProductDetails,
    setProductId,
    navigateWithProductNameParam,
    navigateTo,
    setSummaryQuickStartFlag,
    setIfxAcctType,
    updateSidebarMenu,
    showLoader,
    setProductWorkflowData,
    tooltipFlyoutDetails,
    setProdMatrix,
    prodMatrix,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const [featureList, setFeatureList] = useState<ProductSummaryType[]>();
  const [productAttributesData, setProductAttributesData] =
    useState<ProductSummaryType[]>();
  const [isEditing, toggleEditState] = useState(true);
  const [selectedFeature, setSelectedFeature] =
    useState<ProductSummaryType | null>(null);
  const [selectedAttribute, setSelectedAttributes] =
    useState<ProductSummaryType | null>(null);
  const [showFeatureSection, toggleFeatureSection] = useState<boolean>(true);
  const [disableProductName, setProductNameDisable] = useState<boolean>(false);
  const toast = useToast();
  const envIsDisabled = useSelector(getEnvIsDisable);
  const router = useRouter();
  const entitlement = useSelector(getEntitlement);
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);
  function isSavedProduct(): boolean {
    return productDetails?.name && !isThisSummaryQuickStartFlow()
      ? true
      : false;
  }

  function getFeatureSteps() {
    return getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
      ? deepCopy(PRODUCT_SUMMARY_STEPS_LOAN)
      : deepCopy(PRODUCT_SUMMARY_STEPS);
  }

  const handleFeatureClick = (feature: ProductSummaryType) => async () => {
    if (feature.componentClass && productDetails) {
      const featureSteps = getFeatureSteps();
      const currStep = deepCopy(featureSteps[feature.componentClass]);
      let componentData = { data: [] }; //this can be reponse of any component like FeeComponent

      let updatedCurrStep: any[] = currStep?.length ? currStep : [];
      if (
        productDetails.components?.find(
          (component) => component.componentClass === feature.componentClass,
        )?.componentName
      ) {
        componentData = await getSelectedFeatureData(
          feature.componentClass,
          feature.componentName ?? "",
          feature.version ?? 1,
        )
          .then((data) => data)
          .catch((error) => {
            console.error(error);
          });
        const workFlowData = productWorkflowData?.length
          ? productWorkflowData
          : await getWorkflowGeneric({
              model: API_ROUTE_CONFIGURATION.product,
              key: productDetails.name,
            });
        const currentStageStatus = workFlowData?.length
          ? workFlowData?.find(
              (workFlowItem: ProductWorkflowModelType) =>
                workFlowItem.stage === feature.componentClass,
            )?.status
          : DASHBOARD_ACCORDION_STATUS.completed;
        const currentStageIndex = currStep?.findIndex(
          (step: any) => step.stageStatus === currentStageStatus,
        );
        updatedCurrStep = constructProductSummaryFeatureDetails(
          currStep,
          componentData,
          currentStageIndex,
        );
      }

      setSelectedFeature({
        ...feature,
        steps: updatedCurrStep?.length ? updatedCurrStep : [],
      });
    }
    setSelectedAttributes(null);
  };

  const handleAttributeClick = (attribute: ProductSummaryType) => async () => {
    const updatedProductDetails = productDetails
      ? await updateSelectedAttributeOptionsFromApi(productDetails)
      : {};

    const updatedAttributeData = mapSelectedAttributeData(
      deepCopy(updatedProductDetails),
      attribute,
    );
    setSelectedAttributes(updatedAttributeData);
    setSelectedFeature(null);
  };

  function isThisSummaryQuickStartFlow() {
    return getQuickStartFlag() || isSummaryQucikStartFlow;
  }

  function setProductNameOrDesc(isNameUpdate: boolean, value: string) {
    let prodDetails: any = deepCopy(productDetails) || {};
    if (isNameUpdate) {
      prodDetails.name = value;
    } else {
      prodDetails.desc = value;
    }
    if (setProductDetails) setProductDetails(prodDetails);
  }

  function setInitialValForNewProduct(
    productDetails: ProductType,
  ): ProductType {
    let prodType;
    if (ifxAcctType) {
      productDetails!.ifxAcctType = ifxAcctType;
      productDetails!.glCat = IFX_ACCT_GL_CATEGORY[ifxAcctType];
      prodType = IFX_ACCT_PRODUCT_TYPE[ifxAcctType];
    }
    if (ifxAcctProductSubType) {
      productDetails!.prodSubType = ifxAcctProductSubType;
    }
    if (prodType) {
      productDetails!.posnClass = IFX_POSN_CLASS_TYPE[prodType];
    }
    if (!productDetails?.components) {
      const componentList: ProductComponentType[] =
        featureList?.map((feature) => ({
          componentClass: feature?.componentClass || "",
          version: 1,
        })) || [];
      productDetails.components = componentList;
    }
    return deepCopy(productDetails);
  }

  async function saveProductName() {
    if (currProductId && !isThisSummaryQuickStartFlow()) {
      updateExistingProduct();
    } else if (productDetails?.name) {
      const newProdDetails = setInitialValForNewProduct(productDetails);
      const shouldCreateWorkflow = !isThisSummaryQuickStartFlow();
      // New products cannot be accelerators
      const addedComponents = newProdDetails?.components?.map(
        (component) => component.componentClass,
      );

      shouldCreateWorkflow &&
        newProdDetails.hasOwnProperty("components") &&
        delete newProdDetails.components;

      await updateCompsVerAndCreateProd(
        { ...newProdDetails, isAccelerator__: false },
        shouldCreateWorkflow,
      )
        .then(async (res) => {
          if (res?.name) {
            showToast(
              "add-product-summary-success",
              "Saved the product successfully",
              "success",
            );
            await addDesiredComponent(
              newProdDetails.name,
              addedComponents ?? [],
            );
            toggleEditState(false);
            updateWorkflow(res, shouldCreateWorkflow);
          }
        })
        .catch((err: any) => {
          toggleFeatureSection(false);
          toggleEditState(true);
          const errMsg =
            err?.errors[0]?.errorDesc || "Unable to save the product";
          showToast("add-product-summary-error", errMsg, "error");
        });
    }
  }

  async function updateWorkflow(
    prodDetails: ProductType,
    shouldCreateWorkflow: boolean,
  ) {
    const updateProductPath = () => {
      callExistingProduct();
      //setting quickstart flow to false
      setSummaryQuickStartFlag?.(false);
      setQuickStartFlagInStorage(false);
      //setting product name here only for new products
      setProductId?.(prodDetails.name);
      //this will change the route url without the state change
      navigateWithProductNameParam(
        `${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}`,
        prodDetails.name,
      );
    };
    let completeProductList =
      featureList && productAttributesData
        ? [...featureList, ...productAttributesData]
        : [];
    if (shouldCreateWorkflow) {
      await Promise.all(
        completeProductList.map(async (component) => {
          const response = await createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: prodDetails?.name ?? "",
            stage: component?.componentClass ?? "",
            status: DASHBOARD_ACCORDION_STATUS.notStarted,
          });
        }),
      ).then(() => {
        updateProductPath();
      });
    } else {
      updateProductPath();
    }
  }

  function updateExistingProduct(data?: ProductType) {
    const productData = data?.name ? data : productDetails;
    if (productData?.name) {
      updateProductInSummary(productData?.name, productData)
        .then((res) => {
          showToast(
            "updated-product-summary-success",
            "Updated the product successfully",
            "success",
          );
          toggleEditState(false);
          callExistingProduct();
        })
        .catch((err) => {
          toggleEditState(true);
          const errMsg =
            err?.errors[0]?.errorDesc || "Unable to update the product";
          showToast("updated-product-summary-error", errMsg, "error");
        });
    }
  }

  async function updateWorkflowAndMatrix(prodData?: ProductType) {
    setSelectedFeature(null);
    if (prodData?.name) {
      const compToUpdateMatrix =
        prodData.components?.map((comp) => comp.componentClass) || [];
      const componentStages = productWorkflowData?.filter(
        (item) => PRODUCT_CLASS_NAME[item.stage],
      );
      if (componentStages?.length && isArray(compToUpdateMatrix)) {
        const removedComponents = getRemovedComponent(
          compToUpdateMatrix,
          componentStages,
        );
        if (removedComponents?.length) {
          await Promise.all(
            removedComponents.map(async (component) => {
              const response = await deleteWorkflowStage({
                productName: prodData?.name ?? "",
                stage: component.stage,
              });
            }),
          );
          getWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: currProductId,
          }).then((data) => {
            setProductWorkflowData?.(data);
          });
        }
      }
      configureMatrix(compToUpdateMatrix);
    } else {
      callExistingProduct();
    }
  }

  async function configureMatrix(compToUpdateMatrix: any[] = []) {
    const matrixComps = prodMatrix?.val?.split(",") ?? [];
    if (
      prodMatrix &&
      matrixComps &&
      compToUpdateMatrix?.length &&
      shouldUpdateMatrix(matrixComps, compToUpdateMatrix)
    ) {
      prodMatrix.val = compToUpdateMatrix.join(",");
      await updateMatrix({
        matrixes: [prodMatrix],
      });
      callExistingProduct();
    } else {
      callExistingProduct();
    }
  }

  function showToast(toastId: string, msg: string, status: AlertStatus) {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        description: msg,
        status: status,
        duration: 5000,
      });
    }
  }

  function initProductSummary() {
    if (!currProductId && ifxAcctType) {
      const featureList: ProductSummaryType[] = [];
      const recomendedComponents = RECOMENDED_PRODUCT_FEATURES[ifxAcctType];
      const ProductTypeConfig =
        IFX_ACCT_PRODUCT_TYPE[ifxAcctType] === IFX_PRODUCT_TYPE_VAL.loan
          ? LOAN_COMPONENT_CLASS_FOR_SUMMARY
          : DEPOSIT_COMPONENT_CLASS_FOR_SUMMARY;

      recomendedComponents?.forEach((component) => {
        if (component.componentClass) {
          featureList.push({
            ...ProductTypeConfig[component.componentClass],
          });
        }
      });
      setFeatureList([...featureList]);
    }
    callExistingProduct(true);
  }

  async function callExistingProduct(canConfigureWorkflow = false) {
    if (currProductId) {
      showLoader?.(true);
      await fetchExistingProduct(currProductId)
        .then(async (res) => {
          const prodDetails: ProductType = res?.[0];
          if (canConfigureWorkflow) {
            configureWorkflowData(prodDetails);
          }
          if (prodDetails?.name) {
            if (isThisSummaryQuickStartFlow()) {
              prodDetails.name = "";
              prodDetails.desc = prodDetails.desc;
            }
            if (!isThisSummaryQuickStartFlow()) {
              setProductNameDisable(true);
              setIfxAcctType?.(prodDetails?.ifxAcctType!);
            }
            await getDesiredProdFromMatrix(currProductId).then(
              (prodMatrixRes) => {
                setProdMatrix?.(prodMatrixRes?.data?.[0]);
                return prodMatrixRes;
              },
            );
            setProductDetails?.(prodDetails);
            checkForExistingProductAndAdditionalFeature(prodDetails);
          } else {
            resetAndNavigateToLaunchPad();
          }
          showLoader?.(false);
        })
        .catch((err) => {
          showLoader?.(false);
          const errMsg =
            err?.errors?.[0]?.errorDesc ||
            "Unable to fetch the product details";
          showToast("existing-product-data-error", errMsg, "error");
          resetAndNavigateToLaunchPad();
        });
    } else {
      setSummaryInitialState();
      setProductNameDisable(false);
      setDefaultAttributes();
    }
  }

  function setDefaultAttributes() {
    let updatedProductAttributeData = deepCopy(
      getCompleteAttributeList(ifxAcctType ?? ""),
    );
    updatedProductAttributeData = updatedProductAttributeData.map(
      (attributeData: ProductSummaryType) => ({
        ...attributeData,
        status: DASHBOARD_ACCORDION_STATUS.notStarted,
      }),
    );
    if (updatedProductAttributeData?.length) {
      setProductAttributesData(updatedProductAttributeData);
    }
  }

  function configureWorkflowData(prodDetails: ProductType) {
    let updatedProductAttributeData = deepCopy(
      getCompleteAttributeList(prodDetails.ifxAcctType ?? ""),
    );

    getWorkflowGeneric({
      model: API_ROUTE_CONFIGURATION.product,
      key: currProductId,
    })
      .then((data) => {
        setProductWorkflowData?.(data);
        if (updatedProductAttributeData?.length) {
          updatedProductAttributeData = updatedProductAttributeData.map(
            (attributeData: ProductSummaryType) => ({
              ...attributeData,
              status:
                data.find(
                  (worfFlowItem: ProductWorkflowModelType) =>
                    worfFlowItem.stage === attributeData.componentClass,
                ).status ?? DASHBOARD_ACCORDION_STATUS.notStarted,
            }),
          );
          setProductAttributesData(updatedProductAttributeData);
        }
        removeCompletedWorkflow(prodDetails, data);
        return data;
      })
      .catch(() => {
        if (updatedProductAttributeData?.length) {
          updatedProductAttributeData = updatedProductAttributeData.map(
            (attributeData: ProductSummaryType) => ({
              ...attributeData,
              status:
                prodDetails.status === DASHBOARD_ACCORDION_STATUS.completed
                  ? DASHBOARD_ACCORDION_STATUS.completed
                  : DASHBOARD_ACCORDION_STATUS.notStarted,
            }),
          );
          setProductAttributesData(updatedProductAttributeData);
        }
      });
  }

  function resetAndNavigateToLaunchPad() {
    if (resetProductInfo) resetProductInfo();
    navigateTo(`${ROUTE_PATH["PRODUCT_LAUNCHPAD_FULLPATH"]}`);
  }

  function checkForExistingProductAndAdditionalFeature(
    prodDetails: ProductType,
  ) {
    if (prodDetails?.name) {
      configFeatureList(prodDetails);
    }
    toggleFeatureSection(!isThisSummaryQuickStartFlow());
    toggleEditState(isThisSummaryQuickStartFlow());
    toast?.close("new-product-summary-toast");
  }

  function setSummaryInitialState() {
    toggleFeatureSection(isSavedProduct());
    toggleEditState(!isSavedProduct());
  }

  function showSaveProductToast() {
    if (!isSavedProduct() && !toast.isActive("new-product-summary-toast")) {
      toast({
        id: "new-product-summary-toast",
        description: "Save product to add features",
        status: "warning",
      });
    }
  }

  async function configFeatureList(prodDetails: ProductType) {
    const prodFromMatrix = await getDesiredProdFromMatrix(prodDetails.name);
    const featureList: ProductSummaryType[] = [];
    const ProductTypeConfig =
      getIfxProdType(prodDetails) === IFX_PRODUCT_TYPE_VAL.loan
        ? LOAN_COMPONENT_CLASS_FOR_SUMMARY
        : DEPOSIT_COMPONENT_CLASS_FOR_SUMMARY;

    if (prodDetails?.components?.length) {
      const additionalProdCompToDisplay = prodFromMatrix?.data
        ?.find((item) => item.dimKey === prodDetails?.name)
        ?.val?.split(",")
        ?.filter(
          (comp) =>
            !prodDetails?.components?.some(
              (component) => component.componentClass === comp,
            ),
        );

      prodDetails.components.forEach((item) => {
        if (item.componentClass) {
          featureList.push({
            ...ProductTypeConfig[item.componentClass],
            ...item,
          });
        }
      });

      additionalProdCompToDisplay?.length! > 0 &&
        additionalProdCompToDisplay?.forEach((comp) => {
          featureList.push({
            ...ProductTypeConfig[comp],
          });
        });
    } else {
      prodFromMatrix?.data?.[0]?.val?.split(",").forEach((comp) => {
        featureList.push({
          ...ProductTypeConfig[comp],
        });
      });
    }
    setFeatureList([...featureList]);
  }

  //removed workflow when product is completed
  function removeCompletedWorkflow(
    prodDetails: ProductType,
    workFlowData: any[],
  ) {
    const isWorkflowCompleted = workFlowData.every(
      (item) => item.status === DASHBOARD_ACCORDION_STATUS.completed,
    );
    if (
      prodDetails?.status === DASHBOARD_ACCORDION_STATUS.completed &&
      isWorkflowCompleted
    ) {
      deleteWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.product,
        key: prodDetails.name,
      });
      setProductWorkflowData?.(null);
    } else {
      setProductWorkflowData?.(workFlowData);
    }
  }

  const navigateToProductConfig = async (path?: string) => {
    const url =
      typeof path === "string" && path
        ? `${path}`
        : `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${ROUTE_BASE_URL["PRODUCT_CONFIG"]}${ROUTE_PATH["PRODUCT_NAME_QUERY"]}${currProductId}`;

    if (!path) {
      if (productWorkflowData?.length && productDetails && prodMatrix) {
        let href: string =
          getCurrentProductFormLinkToNavigate(
            productDetails,
            productWorkflowData,
            prodMatrix?.val?.split(",") ?? [],
          ) ?? url;
        navigateTo(href);
        return;
      }
    }

    navigateTo(url);
  };

  useEffect(() => {
    initProductSummary();
    //resetting sidebar menu to avoid repopulating old menu
    updateSidebarMenu?.([]);
  }, [currProductId, ifxAcctType]);

  return (
    <Box className={css["product-summary-container"]}>
      {/* Header */}
      <Box marginTop={isEditing ? 0 : 24} px={12}>
        <Flex gap={6}>
          {!isEditing && (
            <>
              <StatusBubble status={productDetails?.status} iconWidth={24} />
              <Text as="span" fontWeight={600} fontSize={24}>
                {productDetails?.name || ""}
              </Text>
            </>
          )}
        </Flex>
        <Flex className={css["product-info"]}>
          {isEditing ? (
            <Flex flexBasis="40%">
              <Box alignSelf="flex-start" mt="35px" mr="10px">
                <StatusBubble status={productDetails?.status} iconWidth={24} />
              </Box>
              <Stack width="100%" marginRight="5%">
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="Product name*"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("name")
                        ?.tooltip_text_main
                    }
                  />
                  <InputText
                    placeholder="Enter product name"
                    disabled={disableProductName}
                    value={productDetails?.name || ""}
                    onChange={(value) => setProductNameOrDesc(true, value)}
                  />
                  <Box className={css["form-field-message"]}>
                    <FormFieldMsg
                      msg={"You cannot edit your product name after saving"}
                    />
                  </Box>
                </Box>

                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="Product description*"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("desc")
                        ?.tooltip_text_main
                    }
                  />
                  <InputText
                    placeholder="Enter description"
                    textarea={true}
                    value={productDetails?.desc || ""}
                    onChange={(value) => setProductNameOrDesc(false, value)}
                  />
                </Box>

                <ButtonGroup gap={"1rem"} sx={{ alignSelf: "flex-end" }}>
                  {disableProductName && !isThisSummaryQuickStartFlow() ? (
                    <Button
                      onClick={() => toggleEditState(false)}
                      className="app-btn-reg-secondary"
                    >
                      Cancel
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    className={`app-btn-inverse-secondary ${
                      !productDetails ||
                      !productDetails?.name ||
                      !productDetails.desc
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      saveProductName();
                    }}
                    isDisabled={checkEntitlement || envIsDisabled}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Stack>
            </Flex>
          ) : (
            <Box sx={{ flexBasis: "40%" }}>
              <Flex position={"relative"}>
                <Text as="span" fontSize="18px" fontWeight={600}>
                  Product description
                </Text>
                <FeatureTooltip
                  content={"Edit your product description"}
                  placement={"right"}
                  customClass={"product-summary-feature-tooltip"}
                >
                  <Image
                    className={css["edit-image"]}
                    style={{ cursor: "pointer" }}
                    src={IconEdit}
                    alt="edit_icon"
                    onClick={() => toggleEditState(true)}
                  />
                </FeatureTooltip>
              </Flex>

              <Text>{productDetails?.desc || ""}</Text>
            </Box>
          )}
          <Box sx={{ flexBasis: "33%" }} gap="8px">
            <Text as="span" fontSize="18px" fontWeight={600}>
              Product type
            </Text>
            <Text>{productDetails?.prodType || "-"}</Text>
          </Box>
          <Box sx={{ flexBasis: "33%" }}>
            <Text as="span" fontSize="18px" fontWeight={600}>
              Product subtype
            </Text>
            <Text>{productDetails?.prodSubType || "-"}</Text>
          </Box>
          <Box sx={{ flexBasis: "33%" }}>
            <Text as="span" fontSize="18px" fontWeight={600}>
              Product group
            </Text>
            <Text>{productDetails?.prodGroup || "-"}</Text>
          </Box>
        </Flex>
      </Box>

      {/* Main content */}
      {
        <>
          <Flex
            pl="32px"
            className={`${!showFeatureSection ? "disabled" : ""}`}
          >
            {/* Feature List - Left Section */}
            <Box className={css["product-details-section"]} pt="40px">
              <Text as="div" fontWeight={600} fontSize={24}>
                Product details
              </Text>
              <Text as="div" fontWeight={600} fontSize={20} mt="28px">
                Component details
              </Text>
              <Stack my="32px">
                {featureList?.length
                  ? featureList.map((feature, featureIndex) => (
                      <FeatureCard
                        key={`featureContainer${featureIndex}`}
                        feature={feature}
                        selected={
                          feature.componentClass ===
                          selectedFeature?.componentClass
                        }
                        keyVal={`featureContainerItem${featureIndex}`}
                        isAdditionalAttributes={false}
                        handleSelectedFeature={handleFeatureClick(feature)}
                      />
                    ))
                  : ""}
                <Box marginRight="70px" marginTop="32px">
                  <AddNewButton
                    className={`${css["add-button"]} ${checkEntitlement || envIsDisabled ? "disabled" : ""}`}
                    label="Add component"
                    onClick={() => {
                      navigateWithProductNameParam(
                        `${ROUTE_PATH["ADDITIONAL_FEATURES_FULLPATH"]}`,
                      );
                    }}
                  />
                </Box>
              </Stack>
              <Text as="div" fontWeight={600} fontSize={20} mt="40px">
                Additional product attributes
              </Text>
              <Stack my="32px">
                {productAttributesData?.map((feature, index) => (
                  <FeatureCard
                    key={`featureAdditionalContainer${index}`}
                    feature={feature}
                    selected={
                      feature?.name === selectedAttribute?.name ? true : false
                    }
                    keyVal={`featureAdditionalContainerItem${index}`}
                    isAdditionalAttributes={true}
                    handleSelectedFeature={handleAttributeClick(feature)}
                  />
                ))}
              </Stack>
            </Box>

            {/* Feature Details - Right Section*/}
            <Box flex={1} mb={-4}>
              {selectedFeature || selectedAttribute ? (
                <CardContainer customClass={css["feature-details-container"]}>
                  {selectedFeature && currProductId && (
                    <ProductSummaryFeatureDetails
                      type="feature"
                      feature={selectedFeature}
                      handleClose={() => {
                        setSelectedFeature(null);
                        setSelectedAttributes(null);
                      }}
                      currProductId={currProductId || ""}
                      navigateToProductConfig={navigateToProductConfig}
                      updateWorkflowMatrix={updateWorkflowAndMatrix}
                    />
                  )}
                  {selectedAttribute && currProductId && (
                    <ProductSummaryFeatureDetails
                      type="product"
                      feature={selectedAttribute as any}
                      handleClose={() => {
                        setSelectedFeature(null);
                        setSelectedAttributes(null);
                      }}
                      currProductId={currProductId || ""}
                      navigateToProductConfig={navigateToProductConfig}
                      updateWorkflowMatrix={updateWorkflowAndMatrix}
                    />
                  )}
                </CardContainer>
              ) : (
                <Stack
                  alignItems="center"
                  pt={40}
                  className={css["feature-details-continer"]}
                >
                  <Box className={css["choose-continer"]}>
                    <Image
                      src={IconFeatureDetails}
                      alt="feature details icon"
                    />
                    <Text
                      className={css["feature-details-text"]}
                      width="50%"
                      mt={7.2}
                    >
                      Choose a section on the left to review and edit
                      configuration details
                    </Text>
                  </Box>

                  <Box>
                    <Image
                      src={dotPatternWalkthrough}
                      alt="feature details icon"
                    />
                  </Box>
                </Stack>
              )}
            </Box>
          </Flex>

          {/* Footer */}
          <AppPortal domId="#appFooter">
            <Box className="configured-product-footer">
              <Button
                className={`app-btn-inverse-secondary ${
                  !productDetails ||
                  !productDetails?.name ||
                  !showFeatureSection
                    ? "disabled"
                    : ""
                }`}
                onClick={() => navigateToProductConfig()}
                size="md"
              >
                {checkEntitlement ? "View Configuration" : "Edit Configuration"}
              </Button>
            </Box>
          </AppPortal>
        </>
      }
    </Box>
  );
}
