import { SplitBtnInputValType } from "../common/split-button/SplitButton";

const INTEREST_DEPOSIT_LABELS = {
  WHAT_IS_CALCULATION_METHOD:
    "What calculation method should be used to calculate the daily accrued interest?",
  BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY:
    "What position balance would you like to use to calculate the interest accrual?",
  WHAT_IS_MINIMUM_BALANCE:
    "What is the minimum balance required to accrue interest on the position?",
  DEPOSIT_NAME: "interest COMPONENT (Deposit)",
  FOUNDATIONAL_INTEREST: "foundational interest",
  FOUNDATIONAL_INTEREST_DETAILS: "Foundational Interest Details",
  INDEXED_RATE: "Indexed rate",
  INDEXED_RATE_FOUNDATIONAL_DETAIL: "Indexed Rate – Foundational Details",
  INDEXED_RATE_DETAILS: "Do you want to add an indexed interest rate?",
  WHAT_IS_INDEX_NAME: "What is the index name?",
  WHAT_IS_INDEX_NOMINAL_RATE: "What is the index rate in nominal rate?",
  LEVERAGE_EXISTING_INDEX: "Select an existing interest rate index",
  ADD_NEW_INDEX: "Add a new interest rate index",
  SELECT_INDEX: "Select index",
  YES: "Yes",
  NO: "No",
  ADD_RATE_OFFSET: "Add rate offset?",
  WHAT_RATE_ADJUSTMENT_TERM: "What is the rate adjustment term?",
  WHAT_IS_RATE_OFFSET_VAL: "What is the rate offset value?",
  WHAT_IS_PROMOTIONAL_RATE_TERM: "What is the promotional rate term?",
  SKIP_SECTION_INTEREST_DEPOSIT_FORM_MSG:
    "You will skip the following sections: Indexed Rate – Rate Adjustment and Ranges, Indexed Rate – Rate Offset, & Rounding, Indexed Rate – Review Frequency",
  SKIP_SECTION_INTEREST_DEPOSIT_PROMOTIONAL_FORM_MSG:
    "You will skip the following sections: Promotional Rate - Rate Adjustments & Ranges, Promotional Rate - Rate Offset & Rounding",
};

const INTEREST_LOAN_LABELS = {
  WHAT_IS_CALCULATION_METHOD:
    "What calculation method should be used to calculate the daily accrued interest?",
  BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY:
    "What position balance would you like to use to calculate the interest accrual?",
  WHAT_IS_MINIMUM_BALANCE:
    "What is the minimum balance required to accrue interest on the position?",
  DEPOSIT_NAME: "interest COMPONENT (loan)",
  FOUNDATIONAL_INTEREST: "foundational interest",
  FOUNDATIONAL_INTEREST_DETAILS: "Foundational Interest Details",
  INDEXED_RATE: "Indexed rate",
  INDEXED_RATE_FOUNDATIONAL_DETAIL: "Indexed Rate – Foundational Details",
  INDEXED_RATE_DETAILS: "Do you want to add an indexed rate detail?",
  WHAT_IS_INDEX_NAME: "What is the index name?",
  WHAT_IS_INDEX_NOMINAL_RATE: "What is the index rate in nominal rate?",
  LEVERAGE_EXISTING_INDEX: "Leverage existing index",
  ADD_NEW_INDEX: "Add new index",
  SELECT_INDEX: "Select index",
  YES: "Yes",
  NO: "No",
  ADD_RATE_OFFSET: "Add rate offset?",
  WHAT_RATE_ADJUSTMENT_TERM: "What is the rate adjustment term?",
  WHAT_IS_RATE_OFFSET_VAL: "What is the rate offset value?",
  WHAT_IS_PROMOTIONAL_RATE_TERM: "What is the promotional rate term?",
};

const LENDING_INTEREST_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Interest",
  depositName: "Interest Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
};

const LENDING_REPAYMENT_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Repayment",
  depositName: "Repayment Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_Name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
};

const LENDING_COLLATERAL_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Collateral",
  depositName: "Collateral Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_Name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
};

const COLLATERAL_SECURITY_SPECIFICATIONS_LABELS = {
  WHAT_IS_LOAN_PERCENTAGE_VALUE:
    "What is the percentage of the loan that is covered by securities?",
  LOAN_PERCENTAGE_IS_REQUIRED: true,
  LOAN_PERCENTAGE_TOOLTIP_DESC: "No Desc",
};

const FEE_COMPONENT_LOAN_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Fee",
  depositName: "Fee Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_Name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
  currencyLabel: "Set the currency for this component",
};

const FEE_CONFIGURATION_DEPOSITE_SETUP = {
  depositName: "FEE COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Fee Configuration",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_CONFIGURATION_LOAN_SETUP = {
  depositName: "FEE COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Fee Configuration",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_SERVICE_CHARGE_DETAILS_DEPOSITE_SETUP = {
  depositName: "FEE COMPONENT (DEPOSIT)",
  featureName: "",
  version: "VERSION 1",
  mainTitle: "Service Charge Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_SERVICE_CHARGE_DETAILS_LOAN_SETUP = {
  depositName: "FEE COMPONENT (LOAN)",
  featureName: "[component name]",
  version: "VERSION 1",
  mainTitle: "Service Charge Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_EARNING_ANALYSIS_DEPOSITE_SETUP = {
  depositName: "FEE COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Earning Analysis",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_EARNING_ANALYSIS_LOAN_SETUP = {
  depositName: "FEE COMPONENT (LOAN)",
  featureName: "[component name]",
  version: "VERSION 1",
  mainTitle: "Earning Analysis",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const TERM_MATURITY_CONFIGURATION = {
  WHAT_IS_PROMOTIONAL_RATE_TERM:
    "How long before the maturity date do you want to alert the customer of the impending maturity?",
  WHAT_RATE_ADJUSTMENT_TERM:
    "How long before the maturity date do you want to alert the customer of the impending maturity?",
  WHAT_IS_THE_MATURITY_OPTION: "What are the available options at maturity?",
};

//Asset Catalog
const ASSET_CATALOG_FOUNDATIONAL_DETAILS_SETUP = {
  depositName: "Asset catalog",
  featureName: "",
  version: "",
  mainTitle: "Asset catalog foundational details",
  subText:
    "Properly classify, identify attributes, and evaluate assets for your institution",
};
const ASSET_ORIGIN_AND_CLASSIFICATION_SETUP = {
  depositName: "Asset catalog",
  featureName: "",
  version: "",
  mainTitle: "Asset origin & classification",
  subText:
    "Provide industry sector information for data analysis and reporting & additional information",
};

const ASSET_FINANCIAL_SPECIFICATIONS_SETUP = {
  depositName: "Asset catalog",
  featureName: "",
  version: "",
  mainTitle: "Financial specifications",
  subText: "Define details when an asset is used as collateral",
};

const ASSET_GENERAL_LEDGER_DETAILS_SETUP = {
  depositName: "Asset catalog",
  featureName: "",
  version: "",
  mainTitle: "General Ledger details",
  subText:
    "Ensure a clear and comprehensive view of your assets in relation to your general ledger account group and numbers",
};

const ACCOUNT_TYPE_FOUNDATIONAL_DETAILS = {
  depositName: "Account types",
  featureName: "",
  version: "",
  mainTitle: "Account type foundational details",
  subText:
    "Begin setting up your account type for clear classification to ensure transactions are recorded accurately",
};

const ACCOUNT_TYPE_RELATIONSHIP_FOUNDATIONAL_DETAILS = {
  depositName: "Account types",
  featureName: "",
  version: "",
  mainTitle: "Account type relationship foundational details",
  subText:
    "Define inter-party connections, enabling accurate reporting and analysis",
};

const ACCOUNT_TYPE_RELATIONSHIP_PARTY_SPECIFICATIONS = {
  depositName: "Account types",
  featureName: "",
  version: "",
  mainTitle: "Account type relationship party specifications",
  subText:
    "Important for account management, relationship management and risk assessment",
};

const ACCOUNT_TYPE_RELATIONSHIP_ADDITIONAL_SELECTIONS = {
  depositName: "Account Types",
  featureName: "",
  version: "",
  mainTitle: "Account type relationship additional selections",
  subText:
    "Customize billing and statement processes, accommodating customer preferences, and ensuring accurate tax reporting",
};

const ACCOUNT_TYPE_RELATIONSHIP_SUMMARY = {
  depositName: "Account types",
  featureName: "",
  version: "",
  mainTitle: "Account type relationship summary details",
  subText:
    "This is a summary view of all the relationship details for this account type. Add additional relationship type as needed.",
};

const FINANCIAL_ORGANIZATION_IDENTIFICATION = {
  depositName: "financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial institution-specific parameters - 1 of 8",
  subText:
    "Identify the financial organization to facilitate accurate and secure transactions",
};

const FINANCIAL_ORGANIZATION_OTHER_IDENTIFICATION = {
  depositName: "financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial institution-specific parameters - 2 of 8",
  subText:
    "Append additional identifications and specify the ID type for ease of recognition",
};
const FINANCIAL_ORGANIZATION_SPECIFICATION = {
  depositName: "financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial institution-specific parameters - 3 of 8",
  subText:
    "Classify the financial institution within the banking system by defining the routing category for transaction processing",
};
const CORRESPONDING_NETWORK_IDENTIFICATION = {
  depositName: "Financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial organization specific parameters - 4 of 8",
  subText:
    "Begin setting up a corresponding network by providing key identification details",
};

const FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_DESTINATION = {
  depositName: "Financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial organization specific parameters - 5 of 8",
  subText:
    "Set up the destination network to facilitate communication and information exchange",
};

const FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_ORIGIN = {
  depositName: "Financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial organization specific parameters - 6 of 8",
  subText:
    "Set up the origin network to facilitate communication and information exchange",
};

const FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_SUMMARY = {
  depositName: "Financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial organization specific parameters - 7 of 8",
  subText: "Review your corresponding network configuration(s)",
};

const FINANCIAL_ORGANIZATION_RULES = {
  depositName: "financial institution setup",
  featureName: "",
  version: "",
  mainTitle: "Financial organization specific parameters 8 of 8",
  subText: "Set up rules at the institution level",
};

const LOAN_INTEREST_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate – Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTEREST_INDEX_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTEREST_INDEX_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Offset, Terms & Rounding",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTRO_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Introduction Rate - Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTRO_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Introduction Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTEREST_INTRO_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Introduction Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_INDEX_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_INDEX_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Offset, Terms & Rounding",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTRO_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTRO_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Promotional Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_INTRO_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Promotional Rate - Rate Offset, Terms & Rounding",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const FIN_ORG_PARTY_LIMIT_ADD_LIMIT = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 1 of 4",
  subText:
    "Facilitate setting up accumulated transaction limits, offering options to utilize existing components or create new parameters for transactional control",
};
const FIN_ORG_PARTY_LIMIT_ACCUMULATED_TRANSACTION = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 2 of 4",
  subText:
    "Facilitate setting up accumulated transaction limits, offering options to utilize existing components or create new parameters for transactional control",
};
const FIN_ORG_PARTY_LIMIT_SINGLE_TRANSACTION = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 3 of 4",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const FIN_ORG_PARTY_LIMIT_GEN_LIMIT = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 4 of 4",
  subText:
    "Facilitate setting up accumulated transaction limits, offering options to utilize existing components or create new parameters for transactional control",
};

const TC_FOUNDATIONAL_DETAILS_TITLE = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Foundational details",
  subText:
    "Establish key identifiers, included networks, and transaction limits ",
};
const TC_ACCOUNTING_SEGEMENT_TITLE = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Accounting segments",
  subText:
    "Add an accounting segment to this transaction code associated with the structure of your general ledger",
};

const TC_HOLD_SPECIFICATIONS_TITLE = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Hold specifications",
  subText:
    "Configure key controls for transactions to enable security, risk management, and regulatory compliance",
};

const TC_FIELD_SPECIFICATIONS_TITLE = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Field specifications (immutable vs. required)",
  subText:
    "Identify properties that are exempt from the impacts of a posted transaction or necessary when using this transaction code",
};

const TC_TAG_SETUP = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Transaction tag setup",
  subText: "If desired, add a distinguishable label for the transaction",
};

const TC_ENTRY_SETUP_FOUNDATIONAL_DETAILS = {
  depositName: "Transaction entry setup",
  featureName: "",
  version: "",
  mainTitle: "Transaction entry foundational details",
  subText:
    "Ensure accurate and consistent transaction entries that balance debits and credits",
};

const TC_ENTRY_SETUP_ADVANCED = {
  depositName: "Transaction entry setup",
  featureName: "",
  version: "",
  mainTitle: "Transaction entry advanced setup",
  subText:
    "Continue setting up important details related to position identification, funding, fees, and associated set codes for risk management and regulatory compliance    ",
};

const TC_STATISTICAL_GROUP_EXPRESSION_SETUP = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Statistical group expression entries",
  subText: "Track transaction statistics for data analytics and monitoring",
};
const TC_ENTRY_SETUP_SUMMARY = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Transaction entry summary",
  subText:
    "This is a summary view of all the transaction entries for this transaction code. Add additional transaction entries as needed.",
};

const TC_TEMPLATES = {
  depositName: "",
  featureName: "",
  version: "",
  mainTitle: "Templates",
  subText:
    "Enhance the user experience by providing distinct description displays for each transaction entry",
};

export {
  INTEREST_DEPOSIT_LABELS,
  LENDING_INTEREST_GENERAL_COMPONENT_SETUP,
  LENDING_REPAYMENT_GENERAL_COMPONENT_SETUP,
  LENDING_COLLATERAL_GENERAL_COMPONENT_SETUP,
  COLLATERAL_SECURITY_SPECIFICATIONS_LABELS,
  FEE_COMPONENT_LOAN_GENERAL_COMPONENT_SETUP,
  FEE_CONFIGURATION_DEPOSITE_SETUP,
  FEE_CONFIGURATION_LOAN_SETUP,
  FEE_SERVICE_CHARGE_DETAILS_DEPOSITE_SETUP,
  FEE_SERVICE_CHARGE_DETAILS_LOAN_SETUP,
  FEE_EARNING_ANALYSIS_DEPOSITE_SETUP,
  FEE_EARNING_ANALYSIS_LOAN_SETUP,
  TERM_MATURITY_CONFIGURATION,
  ASSET_CATALOG_FOUNDATIONAL_DETAILS_SETUP,
  ASSET_ORIGIN_AND_CLASSIFICATION_SETUP,
  ASSET_FINANCIAL_SPECIFICATIONS_SETUP,
  ASSET_GENERAL_LEDGER_DETAILS_SETUP,
  ACCOUNT_TYPE_FOUNDATIONAL_DETAILS,
  ACCOUNT_TYPE_RELATIONSHIP_FOUNDATIONAL_DETAILS,
  ACCOUNT_TYPE_RELATIONSHIP_PARTY_SPECIFICATIONS,
  ACCOUNT_TYPE_RELATIONSHIP_ADDITIONAL_SELECTIONS,
  FINANCIAL_ORGANIZATION_IDENTIFICATION,
  FINANCIAL_ORGANIZATION_OTHER_IDENTIFICATION,
  FINANCIAL_ORGANIZATION_SPECIFICATION,
  LOAN_INTEREST_FOUNDATIONAL_DETAILS,
  DEPOSIT_INTEREST_FOUNDATIONAL_DETAILS,
  LOAN_INTEREST_INDEX_RATE_ADJUSTMENTS,
  DEPOSIT_INTEREST_INDEX_RATE_ADJUSTMENTS,
  LOAN_INTEREST_INDEX_RATE_OFFSET,
  DEPOSIT_INTEREST_INDEX_RATE_OFFSET,
  LOAN_INTRO_FOUNDATIONAL_DETAILS,
  DEPOSIT_INTRO_FOUNDATIONAL_DETAILS,
  LOAN_INTRO_RATE_ADJUSTMENTS,
  DEPOSIT_INTRO_RATE_ADJUSTMENTS,
  LOAN_INTEREST_INTRO_RATE_OFFSET,
  DEPOSIT_INTEREST_INTRO_RATE_OFFSET,
  INTEREST_LOAN_LABELS,
  CORRESPONDING_NETWORK_IDENTIFICATION,
  FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_DESTINATION,
  FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_ORIGIN,
  FINANCIAL_ORGANIZATION_SPECIFIC_PARAMETERS_CORRESPONDING_SUMMARY,
  FIN_ORG_PARTY_LIMIT_ADD_LIMIT,
  FIN_ORG_PARTY_LIMIT_ACCUMULATED_TRANSACTION,
  FIN_ORG_PARTY_LIMIT_SINGLE_TRANSACTION,
  FIN_ORG_PARTY_LIMIT_GEN_LIMIT,
  TC_FOUNDATIONAL_DETAILS_TITLE,
  TC_HOLD_SPECIFICATIONS_TITLE,
  TC_TAG_SETUP,
  TC_ENTRY_SETUP_FOUNDATIONAL_DETAILS,
  TC_ENTRY_SETUP_ADVANCED,
  TC_STATISTICAL_GROUP_EXPRESSION_SETUP,
  TC_FIELD_SPECIFICATIONS_TITLE,
  TC_ENTRY_SETUP_SUMMARY,
  TC_ACCOUNTING_SEGEMENT_TITLE,
  TC_TEMPLATES,
  ACCOUNT_TYPE_RELATIONSHIP_SUMMARY,
  FINANCIAL_ORGANIZATION_RULES,
};
