"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  DurationInput,
  ErrorAlert,
  InputText,
  StaticText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import React, { useContext, useEffect, useState } from "react";
import {
  GeneralFeatureSetupProps,
  generalFeatureSetupDetails,
  getValidationSchema,
} from "./TermGeneralFeatureComponentValidation";
import { useFormik } from "formik";
import { createTermComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

interface commonTitleProps {
  name: string;
  featureName: string;
  version: string;
  labelName: string;
  textPlaceholder: string;
  versionLabelName: string;
  versionStaticText: string;
  data: {
    formData: {
      _vn?: number;
      featureName: string;
      version: number;
      term: string;
    } | null;
    generalComponentOptionsData: PostSchema;
  };
}

const TermGeneralFeatureComponent = (props: commonTitleProps) => {
  const toast = useToast();
  const toastId = "general-feature-toast";

  const onSubmit = async (values: GeneralFeatureSetupProps, actions: any) => {
    if (!dirty && featureData) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["term"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
    }

    if (productDetails) {
      let response;
      const formData = {
        componentName: values.componentName,
        version: 1,
        ...(featureData ? { _vn: featureData._vn } : {}),
        term: values.term,
      };
      const commonToastOptions = {
        toast,
        toastId,
      };
      if (!featureData) {
        response = await createTermComponent({
          productDetails,
          formData,
          toastOptions: {
            ...commonToastOptions,
            successMessage: "Term component created.",
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
      }

      // Navigate only when there is a successful response
      if (response && productDetails?.name) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.generalComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.generalComponentOptionsData]);

  const {
    data: { formData: featureData },
  } = props;
  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: featureData
      ? {
          componentName: featureData.featureName,
          term: featureData.term,
        }
      : generalFeatureSetupDetails,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMenuList,
    sideBarMainMenuIndex,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const generalFeatureFormHandler =
    (key: string) => (value: string | number | boolean | any[]) => {
      setFieldValue(key, value);
    };

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="app-form-container">
        <Box className={"app-form-field-container"}>
          <CommonTitle
            depositName={getConstructedFeatureNameWithComponent(
              COMPONENT_CLASS["term"],
              getIfxProdType(productDetails),
              true
            )}
            featureName={props?.featureName ?? ""}
            version={`VERSION ${props.data.formData?.version ?? "1"}`}
            mainTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : "General Component Setup"
            }
            subText={
              tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
                : ""
            }
          />
        </Box>
        <Box className={"app-form-field-container"}>
          <CardContainer>
            <Box className={"app-form-field-container"}>
              <AppFormLabel
                labelName={props.labelName}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("componentName")
                    ?.tooltip_text_main
                }
                isRequired
              />
              <InputText
                disabled={Boolean(props.data.formData?.featureName)}
                value={values.componentName}
                onChange={generalFeatureFormHandler("componentName")}
                placeholder={props.textPlaceholder}
                name="component name"
              />
              {errors.componentName && touched.componentName && (
                <ErrorAlert>
                  <span>{errors.componentName}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className={"app-form-field-container"}>
              <AppFormLabel
                labelName={props.versionLabelName}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("version")
                    ?.tooltip_text_main
                }
              />
              <StaticText
                textValue={
                  props.data?.formData?.version || props.versionStaticText
                }
              />
            </Box>
            <Box>
              <DurationInput
                labelName={
                  "What number of days, months, or years until the maturity date of the CD?"
                }
                value={values.term}
                onChange={generalFeatureFormHandler("term")}
                modalLinkName={"Enter code manually"}
                modalTitle={"What number of days the maturity date of the CD"}
                modalLabelName={"Enter Number of Days"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("term")
                    ?.tooltip_text_main
                }
                modalLabelTooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("term")
                    ?.tooltip_text_main
                }
              />

              {errors.term && touched.term && (
                <ErrorAlert>
                  <span>{errors.term}</span>
                </ErrorAlert>
              )}
            </Box>
          </CardContainer>
        </Box>
      </Box>
    </form>
  );
};

export default TermGeneralFeatureComponent;
