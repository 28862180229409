"use client";

import { Box, Heading, useToast } from "@/components/ChakraUiManager";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppModal,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  AccumulatedLimitDetailsProps,
  DefaultFormDetails,
  FinancialInstitutionData,
} from "@/components/institutional-configuration/model";
import { ROUTE_PATH } from "@/route-config/route-path";
import { Option, RjsfData, deepCopy, useQueryParams } from "@/utils";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useMemo, useRef } from "react";
import {
  AccumulatedLimitDefaultFormProps,
  AccumulatedLimitNewFormProps,
  accumulatedNewLimits,
  limitConfigSchema as validationSchema,
} from "./AccumulatedTransactionLimitValidation";
import moment from "moment";
import { modalBtnProps } from "@/components/data/global-config";
import { OptionsSchema } from "@/components/product-management/model/types";
import { AccumulatedTransactionLimitForm } from "@/components";
import { AccumTransRadioBtnProps } from "@/components/institutional-configuration/account-types/add-limit/AccumulatedTransactionLimitForm";
import "../PartyLimit.scss";
import { addNewComponent } from "@/components/product-management/product-configuration/product-config-client-service";

interface AccumumatedTransLimitProp extends DefaultFormDetails {
  data: {
    formData: FinancialInstitutionData | null;
    definedByOptions: Option<string>[];
    accumulationGroupOptions: string[];
    violationActionOptions: Option<string>[];
    violationFeeOptions: string[];
    transactionCodes: Option<string>[];
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    addNewTransactionStatOptionEndpoint: string;
    addNewTransactionSchema: OptionsSchema;
    addNewViolationSchema: OptionsSchema;
    accumTrnLimit: OptionsSchema;
  };
}

export default function AccumulatedTransactionLimit(
  props: AccumumatedTransLimitProp
) {
  const {
    depositName,
    featureName,
    version,
    mainTitle,
    subText,
    data: {
      formData,
      definedByOptions,
      accumulationGroupOptions,
      violationActionOptions,
      violationFeeOptions,
      transactionCodes,
    },
  } = props;
  const updateQueryParams = useQueryParams();
  const modalRef = useRef<any>();
  const formContainerRef = useRef<any>();
  const toast = useToast();
  const toastId = "party-limit-accumulated";

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const onSubmit = async (
    values: { accumTrnLimits: AccumulatedLimitNewFormProps[] },
    actions: any
  ) => {
    const url = `${
      ROUTE_PATH["IC_FIN_ORG_PARTY_LIMIT_GENERAL_LIMIT"]
    }?${updateQueryParams()}`;

    // Party limit payload
    let acculumatedLimitsPayload: AccumulatedLimitDetailsProps[] =
      values?.accumTrnLimits?.map((limit, index) => {
        const accumLimit = constructPayload(limit);
        let payload = {
          ...limit,
          ...accumLimit,
        };
        if (limit.temporaryLimit) {
          payload.temporaryLimit = constructPayload(limit.temporaryLimit);
        }
        return payload;
      }) || [];

    if (!dirty) {
      navigateTo(url);
    }
  };

  function constructPayload(limit: AccumulatedLimitDefaultFormProps) {
    let payload: Partial<AccumulatedLimitDetailsProps> = {
      crAmt: Number(limit.crAmt),
      crCnt: Number(limit.crCnt),
      definedBy: Number(limit.definedBy),
      drAmt: Number(limit.drAmt),
      drCnt: Number(limit.drCnt),
      totAmt: Number(limit.totAmt),
      totCnt: Number(limit.totCnt),
      violationAct: Number(limit.violationAct),
    };
    if (limit.expDtm) {
      payload["expDtm"] = new Date(
        `${moment(new Date(limit.expDtm as any)).format("YYYY-MM-DD")}T${
          values.accumTrnLimits[0].expTime
        }`
      );
    }
    return payload;
  }

  let initialAccumulatedLimits: AccumulatedLimitNewFormProps[] = formData
    ?.partyLimit?.accumTrnLimits?.length
    ? formData?.partyLimit?.accumTrnLimits?.map((limit, index) => {
        const defaultVal = deepCopy(setSupportingInitialValue(limit));
        const tempDefaultVal = deepCopy(setSupportingInitialValue(limit, true));
        return {
          ...limit,
          ...accumulatedNewLimits,
          idx: index,
          needTempLimits:
            limit?.temporaryLimit !== undefined ? true : undefined,
          ...defaultVal,
          temporaryLimit: {
            ...accumulatedNewLimits.temporaryLimit,
            ...tempDefaultVal,
          },
        };
      })
    : [];

  function setSupportingInitialValue(
    limit: AccumulatedLimitDetailsProps,
    isTempFlow = false
  ) {
    let defaultVal: Partial<AccumulatedLimitNewFormProps> = {
      selectLimitType: limit?._Id ? "leverage_existing" : "add_new",
      violationFee: limit?.violationFee || "",
      feeForViolationOption: limit.violationFee !== "" ? "PRIMARY" : "",
    };
    if (isTempFlow) {
      defaultVal["expTime"] = "";
      defaultVal["expDtm"] = limit?.expDtm
        ? new Date(limit?.expDtm as string)
        : "";
    }
    return defaultVal;
  }

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      accumTrnLimits: initialAccumulatedLimits,
    },
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    validateForm,
    setFieldTouched,
    dirty,
    isValid,
  } = formikConfigs;

  const formTitle = (
    <>
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />
      <Heading className="font-size-24px" as="h2">
        {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
          ?.section_title || "Accumulated transaction limit configuration"}
      </Heading>
    </>
  );

  const handleOnChange = (key: string, index: number) => (value: any) => {
    if (key === "selectLimitType" && value === "add_new") {
      setTimeout(() => {
        modalRef?.current?.openModal?.();
      });
    }
    setFieldValue(`accumTrnLimits.${index}.${key}`, value);
  };

  const handleTempOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`accumTrnLimits.${index}.temporaryLimit.${key}`, value);
  };

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`accumTrnLimits.${index}.${key}`, true);
  };

  function onAddAccumulatedLimitsModalAdd(limitIndex: number) {
    if (limitIndex >= 0) {
      if (checkNewLimitFormIsValid(limitIndex)) {
        modalRef?.current?.closeModal();
      }
    }
  }

  const onAddAccumulatedLimitsModalCancel = (limitIndex: number) => {
    setFieldValue(`accumTrnLimits.${limitIndex}.selectLimitType`, "");
  };

  function checkNewLimitFormIsValid(index: number) {
    if (
      errors?.accumTrnLimits?.[index] &&
      Object.keys(errors.accumTrnLimits?.[index]).length == 1 &&
      (errors?.accumTrnLimits?.[index] as AccumulatedLimitNewFormProps)
        .temporaryLimit
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_PARTY_LIMIT_ADD_LIMIT"]
        }?${updateQueryParams()}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  const transactionschema = useMemo(() => {
    const { actions, name } = props.data.addNewTransactionSchema;
    return { name, ...actions.POST };
  }, [props.data.addNewTransactionSchema]);

  const addNewTransactionOption =
    (index: number) => async (rjsfData: RjsfData) => {
      const response = await addNewComponent(
        props.data.addNewTransactionStatOptionEndpoint,
        rjsfData.formData,
        toast
      );
      if (!response) {
        throw new Error("Error");
      }
      setFieldValue(`accumTrnLimits.${index}.statGroup`, response.statGroup);
    };

  const addNewViolationOption =
    (index: number) => async (rjsfData: RjsfData) => {
      const response = await addNewComponent(
        props.data.addNewOptionEndpoint,
        rjsfData.formData,
        toast
      );
      if (!response) {
        throw new Error("Error");
      }
      setFieldValue(`accumTrnLimits.${index}.violationFee`, response.name);
    };
  const schema = useMemo(() => {
    const { actions, name } = props.data.addNewSchema;
    return { name, ...actions.POST };
  }, [props.data.addNewSchema]);

  return (
    <FormikProvider value={formikConfigs}>
      <form
        onSubmit={handleSubmit}
        id="finxact-form"
        className="party-limit-accumulated-form"
        noValidate
      >
        <Box className="app-form-container">
          {formTitle}
          <FieldArray
            name="accumTrnLimits"
            render={({ insert, remove, push }) => (
              <>
                {values.accumTrnLimits?.map(
                  (limit: AccumulatedLimitNewFormProps, index: any) => (
                    <CardContainer key={`accumLimitContainer${index}`}>
                      <AccordionComponent
                        accordionTitle={`Accumulated Transaction Limit ${
                          index + 1
                        }`}
                        limitName={limit.name}
                        deleteIcon
                        onDeleteHandler={() => remove(index)}
                        isExpand={0}
                      >
                        <Box
                          key={`accumLimitDefault${index}`}
                          className="add-limit-bottom-spacing"
                        >
                          <AppFormLabel
                            labelName="How would you like to establish this limit?"
                            isRequired
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "selectLimitType"
                              )?.tooltip_text_main
                            }
                            labelFor="acc_group_input"
                          />
                          <RadioButtonGroup
                            isBoxedRadio={true}
                            value={limit.selectLimitType}
                            onChange={handleOnChange("selectLimitType", index)}
                            radioPropList={AccumTransRadioBtnProps}
                            stackDirection={"row"}
                            spacing={12}
                          />
                          {touched.accumTrnLimits &&
                            touched.accumTrnLimits[index]?.selectLimitType &&
                            errors.accumTrnLimits &&
                            (errors.accumTrnLimits[index] as any)
                              ?.selectLimitType && (
                              <ErrorAlert>
                                <span>
                                  {
                                    (errors.accumTrnLimits[index] as any)
                                      ?.selectLimitType
                                  }
                                </span>
                              </ErrorAlert>
                            )}
                        </Box>
                        <div
                          className="party-limit-accumlated-form-portal"
                          ref={formContainerRef}
                        />
                        <AppModal
                          key={`accumLimitModal${index}`}
                          customClass="app-accum-limit-form"
                          modalTitle={"Accumulated transaction limit"}
                          ref={modalRef}
                          {...modalBtnProps}
                          primaryBtnProp={{
                            name: "Add",
                            btnClassName: "app-btn-inverse-secondary",
                            type: "submit",
                          }}
                          primaryBtnSelect={() =>
                            onAddAccumulatedLimitsModalAdd(index)
                          }
                          secondaryBtnSelect={() =>
                            onAddAccumulatedLimitsModalCancel(index)
                          }
                          containerRef={formContainerRef}
                        >
                          <AccumulatedTransactionLimitForm
                            key={`accumLimitModalForm${index}`}
                            touched={touched}
                            errors={errors}
                            limit={limit}
                            index={index}
                            showLimitType={false}
                            definedByOptions={definedByOptions || []}
                            accumulationGroupOptions={
                              accumulationGroupOptions || []
                            }
                            violationActionOptions={
                              violationActionOptions || []
                            }
                            violationFeeOptions={violationFeeOptions || []}
                            transactionCodes={transactionCodes || []}
                            handleOnChange={handleOnChange}
                            transactionschema={transactionschema}
                            addNewTransactionOption={addNewTransactionOption}
                            schema={schema}
                            addNewViolationOption={addNewViolationOption}
                            accumTrnLimit={undefined}
                          />
                        </AppModal>
                        {limit.selectLimitType === "leverage_existing" ? (
                          <Box
                            key={`accumLimitExisting${index}`}
                            className="add-limit-accumulated-width"
                          >
                            <AppFormLabel
                              labelName="What is the unique accumulated limit identifier?"
                              isRequired
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get("_Id")
                                  ?.tooltip_text_main
                              }
                              labelFor="acc_group_input"
                            />
                            <InputText
                              value={limit._Id}
                              onChange={handleOnChange("_Id", index)}
                            />
                            {touched.accumTrnLimits &&
                              touched.accumTrnLimits[index]?._Id &&
                              errors.accumTrnLimits &&
                              (errors.accumTrnLimits[index] as any)?._Id && (
                                <ErrorAlert>
                                  <span>
                                    {(errors.accumTrnLimits[index] as any)?._Id}
                                  </span>
                                </ErrorAlert>
                              )}
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="Add a temporary accumulated transaction limit?"
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "needTempLimits"
                              )?.tooltip_text_main
                            }
                            isRequired
                          />
                          <SplitButton
                            value={limit.needTempLimits}
                            onSelect={handleOnChange("needTempLimits", index)}
                            leftBtnName="Yes"
                            rightBtnName="No"
                            onBlur={handleOnBlur("needTempLimits", index)}
                          />
                          {touched.accumTrnLimits &&
                            touched.accumTrnLimits[index]?.needTempLimits &&
                            errors.accumTrnLimits &&
                            (errors.accumTrnLimits[index] as any)
                              ?.needTempLimits && (
                              <ErrorAlert>
                                <span>
                                  {
                                    (errors.accumTrnLimits[index] as any)
                                      ?.needTempLimits
                                  }
                                </span>
                              </ErrorAlert>
                            )}
                        </Box>
                        {limit.needTempLimits ? (
                          <>
                            <Heading className="font-size-18px" mb={6} as="h6">
                              Temporary single transaction limit
                            </Heading>
                            <Box>Foundational details</Box>
                            <Box mb={6}>
                              Configure temporary single limits on transactions,
                              detailing conditions for alerts, fees on
                              violations, and specific actions upon breach,
                              alongside period-specific maximums and limit
                              definitions
                            </Box>
                            <AccumulatedTransactionLimitForm
                              key={`accumLimitModalForm${index}`}
                              touched={touched}
                              errors={errors}
                              limit={{
                                ...(limit.temporaryLimit as AccumulatedLimitNewFormProps),
                                needTempLimits: limit.needTempLimits ?? null,
                              }}
                              index={index}
                              showLimitType={false}
                              definedByOptions={definedByOptions || []}
                              accumulationGroupOptions={
                                accumulationGroupOptions || []
                              }
                              violationActionOptions={
                                violationActionOptions || []
                              }
                              violationFeeOptions={violationFeeOptions || []}
                              transactionCodes={transactionCodes || []}
                              handleOnChange={handleTempOnChange}
                              isTempFlow={true}
                              transactionschema={transactionschema}
                              addNewTransactionOption={addNewTransactionOption}
                              schema={schema}
                              addNewViolationOption={addNewViolationOption}
                              accumTrnLimit={undefined}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </AccordionComponent>
                    </CardContainer>
                  )
                )}

                <Box marginTop={5}>
                  <AddNewButton
                    label={
                      values?.accumTrnLimits?.length
                        ? "Add another accumulated transaction limit"
                        : "Add an accumulated transaction limit"
                    }
                    onClick={() => {
                      push({ ...accumulatedNewLimits, idx: Math.random() });
                    }}
                  />
                </Box>
              </>
            )}
          ></FieldArray>
        </Box>
      </form>
    </FormikProvider>
  );
}
