"use client";
import { useContext, useEffect, useState } from "react";
import ReviewFrequencyStyle from "./ReviewFrequency.module.scss";
import { Box, Stack, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  DatePicker,
  ErrorAlert,
  FrequencyModule,
  SplitButton,
  TimeField,
} from "@/components/common";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import {
  ReviewFrequencyProps,
  reviewFrequency,
  getValidationSchema,
} from "./ReviewFrequencyValidation";
import { InterestComponentData, PostSchema } from "../../../model/types";
import {
  makeDateTime,
  getConstructedFeatureNameWithComponent,
  splitDateTime,
} from "@/utils/common";
import { useParams } from "next/navigation";
import { createInterestComponent } from "../../product-config-client-service";
import { COMPONENT_CLASS } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: InterestComponentData | null;
    interestComponentOptionsData: PostSchema;
  };
};

const ReviewFrequency = (props: Props) => {
  const { formData, interestComponentOptionsData } = props.data;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (values: ReviewFrequencyProps, actions: any) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            index: {
              ...formData.index,
              isReviewDly: values.index.isReviewDly,
              ...(values.index.indexReviewDate && values.index.isReviewDly
                ? {
                    nextReviewDtm: makeDateTime(
                      values.index.indexReviewDate,
                      values.index.indexReviewTime
                    ),
                  }
                : { nextReviewDtm: null }),
              reviewFreq:
                values.index.isReviewDly === false
                  ? values.index.reviewFreq
                  : null,
            },
          },
          toastOptions: {
            toast,
            toastId: "index-rate-loan-review-frequency-detail",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
              "Indexed Rate – Review Frequency"
            } updated`,
          },

          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      index: {
        isReviewDly:
          formData?.index?.isReviewDly ?? reviewFrequency.index.isReviewDly,
        reviewFreq:
          formData?.index?.reviewFreq ?? reviewFrequency.index.reviewFreq,
        indexReviewDate: formData?.index?.nextReviewDtm
          ? splitDateTime(formData.index.nextReviewDtm).date
          : reviewFrequency.index.indexReviewDate,
        indexReviewTime: formData?.index?.nextReviewDtm
          ? splitDateTime(formData.index.nextReviewDtm).time
          : reviewFrequency.index.indexReviewTime,
      },
    },
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const reviewFrequencyFormHandler =
    (key: string) =>
    (value: string | SplitBtnInputValType | Date | boolean) => {
      if (key === "isReviewDly") {
        setFieldValue("index", { ...reviewFrequency.index, [key]: value });
      } else if (key === "reviewFrequencyModelInputValue") {
        setFieldValue("index", { ...values.index, [key]: value }).then(
          (res) => {
            setTouched({});
          }
        );
      } else {
        setFieldValue("index", { ...values.index, [key]: value });
      }
    };

  // Date and Time
  /*
   * Error_Display_Section
   */

  const DateTimeSelect = () => {
    return (
      <Box className={ReviewFrequencyStyle["date-time-section"]}>
        <AppFormLabel
          labelName="When is the next index review date/time?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("nextReviewDtm")
              ?.tooltip_text_main
          }
        />
        <Box className={ReviewFrequencyStyle["date-time-seperator"]}>
          <Stack flexBasis="50%">
            <AppFormLabel labelName="Date" />
            <DatePicker
              ariaLabel="index-review-date"
              value={values.index.indexReviewDate as Date}
              onUpdate={reviewFrequencyFormHandler("indexReviewDate")}
              onBlur={handleBlur}
              place="auto"
              placeholder="Select"
            />
            {errors.index?.indexReviewDate &&
              touched.index?.indexReviewDate && (
                <ErrorAlert>
                  <span>{errors.index?.indexReviewDate}</span>
                </ErrorAlert>
              )}
          </Stack>
          <Stack flexBasis="50%">
            <AppFormLabel labelName=" Select time" />
            <TimeField
              ariaLabel="index review time"
              value={values.index.indexReviewTime}
              onChange={reviewFrequencyFormHandler("indexReviewTime")}
              onBlur={handleBlur}
            />

            {errors.index?.indexReviewTime &&
              touched.index?.indexReviewDate && (
                <ErrorAlert>
                  <span>{errors.index?.indexReviewTime}</span>
                </ErrorAlert>
              )}
          </Stack>
        </Box>
      </Box>
    );
  };
  const formTitle = (
    <CommonTitle
      depositName={getConstructedFeatureNameWithComponent(
        "componentInt",
        getIfxProdType(productDetails),
        true
      )}
      featureName={formData?.componentName ?? ""}
      version={`VERSION ${formData?.version ?? "1"}`}
      mainTitle={
        tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
        "Indexed Rate – Review Frequency"
      }
      subText={
        tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
        "Utilize reviews to make informed decisions for future interest rate adjustments"
      }
    />
  );

  const frequencyModuleSection = (
    <Box className={ReviewFrequencyStyle["frequency-module-section"]}>
      {/* Frequency Module */}
      <FrequencyModule
        value={values.index.reviewFreq}
        onChange={reviewFrequencyFormHandler("reviewFreq")}
        frequencyQuestionTitle={
          "How often should the interest rate be reviewed?"
        }
        frequencyOutputContent="A review will occure every"
        frequencyQuestionDescription={
          tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreq")?.tooltip_text_main
        }
        monthDropdownTooltip={
          tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreqMon")
            ?.tooltip_text_main
        }
        notBusinessDayTooltip={
          tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreqNotBusiness")
            ?.tooltip_text_main
        }
        notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the review occur?"
      />
      {errors.index?.reviewFreq && touched.index?.reviewFreq && (
        <ErrorAlert>
          <span>{errors.index.reviewFreq}</span>
        </ErrorAlert>
      )}
    </Box>
  );

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      {formTitle}
      <Box className="app-form-container">
        <CardContainer
          customClass={ReviewFrequencyStyle["index-rate-review-frequency"]}
        >
          <Box pb="1rem">
            <AppFormLabel
              labelName="Is there a review of the interest rate acquired from index daily at accrual cutoff?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isReviewDly")
                  ?.tooltip_text_main
              }
            />
          </Box>

          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            onSelect={reviewFrequencyFormHandler("isReviewDly")}
            value={values.index.isReviewDly}
          />
          {errors.index?.isReviewDly && (
            <ErrorAlert>
              <span>{errors.index?.isReviewDly}</span>
            </ErrorAlert>
          )}
          {values.index.isReviewDly === false && frequencyModuleSection}
          {values.index.isReviewDly && DateTimeSelect()}
        </CardContainer>
      </Box>
    </form>
  );
};

export default ReviewFrequency;
