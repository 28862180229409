"use client";
import {
  AccordionComponent,
  AppFormLabel,
  AppSingleSelectDropdown,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  SplitButton,
  StaticText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import {
  USSpecificationFinancialOrganizationFedwireDetailsParameter,
  validationSchema,
} from "./FedwireDetailsValidation";
import {
  Box,
  Heading,
  Table,
  Th,
  Thead,
  Tr,
  Text,
  Tbody,
  Td,
  useToast,
} from "@/components/ChakraUiManager";
import "./FedwireDetails.scss";
import {
  FIN_ORG_SUB_STEPS,
  Option,
  SIDEBAR_STATUS,
  useQueryParams,
} from "@/utils";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { ROUTE_PATH } from "@/route-config/route-path";

type Props = {
  data: {
    region: Option<number>[];
    cntry: Option<number>[];
    formData: {
      financialOrg: FinancialInstitutionData;
      // finOrgFedwire: FinancialOrgFedwire;
    } | null;
  };
};

const FedwireDetails = (props: Props) => {
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const { region, cntry, formData } = props.data;

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };
  const toast = useToast();
  const toastId = "financial-organization";
  const updateQueryParams = useQueryParams();

  const onSubmit = async (values: any, actions: any) => {
    if (!dirty) {
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };

    if (formData) {
      const { financialOrg } = formData;
      const response = await Promise.all([
        updateFinOrg({
          formData: {
            _vn: financialOrg._vn,
            _Id: financialOrg._Id,
            addr: {
              city: values.city,
              cntry: values.country,
              geoLoc: {
                alt: values.altitude,
                geoplace: values.googlePlaceId,
                lat: values.latitude,
                lon: values.longitude,
              },
              postCode: values.postalCode,
              premise: values.premise,
              region: values.region,
              region2: values.region2,
              region3: values.region3,
              region4: values.region4,
              street: values.addressStreetLine,
              street2: values.addressStreetLine2,
              street3: values.addressStreetLine3,
              street4: values.addressStreetLine4,
            },
            finInstName: values.finanancialInstitutionName,
            isFundsEligible: values.fedwireFundsEligible === "selectLeft",
            isSecuritiesEligible:
              values.fedwireSecuritiesEligible === "selectLeft",
            isSettleOnly: values.fedwireSettlementOnly === "selectLeft",
            shortName: values.finanancialInstitutionShortName,
          },
          toastOptions: {
            ...commonToastOptions,
            successMessage: "Party US Bank Information - 2 of 2 updated.",
          },

          stageName: FIN_ORG_SUB_STEPS.party_us_fedwire_details,
        }),
      ]);
      if (response) {
        updateFormStatus?.(SIDEBAR_STATUS.completed);
        updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      }
    }
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: USSpecificationFinancialOrganizationFedwireDetailsParameter,
  });
  const {
    values,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    errors,
    dirty,
  } = formik;

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH.PARTY_US_SPECIFICATION_FINANCIAL_ORGANIZATION
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={formik.handleSubmit} id="finxact-form">
      <CommonTitle
        depositName="FINANCIAL ORGANIZATION"
        featureName=""
        version=""
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
          "Party US bank information - 2 of 2"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
          "Define parameters that are specific to operating within the US financial system, ensuring compliance and recognition in domestic transactions"
        }
      />
      <Box className="fedwire-box-default-width">
        <CardContainer>
          <Heading as="h3" className="heading-styles-h3">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
              ?.section_title || "Fedwire Details"}
          </Heading>
          <Box className="app-form-field-container">
            <Heading as="h4" className="heading-styles-h4">
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                ?.section_title || "Identification for Fedwire Funds Service"}
            </Heading>
            <StaticText
              textValue={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                  ?.section_subhead || ""
              }
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={
                "What is the financial institution's name used for Fedwire?"
              }
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("finInstName")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <InputText
              value={values.finanancialInstitutionName}
              onChange={handleOnChange("finanancialInstitutionName")}
              onBlur={handleBlur}
              label="finanancialInstitutionName"
              name="finanancialInstitutionName"
              placeholder="Enter"
            />
            {errors.finanancialInstitutionName &&
              touched.finanancialInstitutionName && (
                <ErrorAlert>
                  <span>{errors.finanancialInstitutionName}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={
                "What is the financial institution’s short name that is used for Fedwire?"
              }
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("shortName")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <InputText
              value={values.finanancialInstitutionShortName}
              onChange={handleOnChange("finanancialInstitutionShortName")}
              onBlur={handleBlur}
              label="finanancialInstitutionShortName"
              name="finanancialInstitutionShortName"
              placeholder="Enter"
            />
            {errors.finanancialInstitutionShortName &&
              touched.finanancialInstitutionShortName && (
                <ErrorAlert>
                  <span>{errors.finanancialInstitutionShortName}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <Heading as="h4" fontSize={"20px"} mt={"1.5rem"} mb={"3rem"}>
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                ?.section_title ||
                "Additional Specifications for Fedwire Funds Service"}
            </Heading>
            <StaticText
              textValue={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                  ?.section_subhead || ""
              }
            />
          </Box>
          <Box className="app-form-field-container">
            <Table className="table" variant="unstyled" borderRadius="2xl">
              <Thead className="custom-table-header">
                <Tr className="custom-table-tr-fedwire">
                  <Th className="fedwire-left-custom-table-tr">
                    <Text fontSize="xl">Enable any of the following</Text>
                  </Th>
                  <Th className="fedwire-right-custom-table-tr">
                    <Text className="custom-table-right-header">
                      header-right
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr className="custom-table-data-row">
                  <Td className="custom-table-padding">
                    <AppFormLabel
                      labelName={"Are fedwire funds eligible?"}
                      isRequired
                    />
                  </Td>
                  <Td className="custom-table-padding">
                    <SplitButton
                      name="fedwireFundsEligible"
                      onSelect={handleOnChange("fedwireFundsEligible")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.fedwireFundsEligible}
                    />
                    {errors.fedwireFundsEligible &&
                      touched.fedwireFundsEligible && (
                        <ErrorAlert>
                          <span>{errors.fedwireFundsEligible}</span>
                        </ErrorAlert>
                      )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-row">
                  <Td className="custom-table-padding">
                    <AppFormLabel
                      labelName={"Are fedwire securities eligible?"}
                      isRequired
                    />
                  </Td>
                  <Td className="custom-table-padding">
                    <SplitButton
                      name="fedwireSecuritiesEligible"
                      onSelect={handleOnChange("fedwireSecuritiesEligible")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.fedwireSecuritiesEligible}
                    />
                    {errors.fedwireSecuritiesEligible &&
                      touched.fedwireSecuritiesEligible && (
                        <ErrorAlert>
                          <span>{errors.fedwireSecuritiesEligible}</span>
                        </ErrorAlert>
                      )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-last-row">
                  <Td className="custom-table-padding">
                    <AppFormLabel
                      labelName={"Is this fedwire settlement only?"}
                      isRequired
                    />
                  </Td>
                  <Td className="custom-table-padding">
                    <SplitButton
                      name="fedwireSettlementOnly"
                      onSelect={handleOnChange("fedwireSettlementOnly")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.fedwireSettlementOnly}
                    />
                    {errors.fedwireSettlementOnly &&
                      touched.fedwireSettlementOnly && (
                        <ErrorAlert>
                          <span>{errors.fedwireSettlementOnly}</span>
                        </ErrorAlert>
                      )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Box className="app-form-field-container">
            <Heading as="h4" className="heading-styles-h4">
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                ?.section_title || "Address Details"}
            </Heading>
            <StaticText
              textValue={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                  ?.section_subhead || ""
              }
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Address Street"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("street")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <InputText
              value={values.addressStreet}
              onChange={handleOnChange("addressStreet")}
              onBlur={handleBlur}
              label="addressStreet"
              name="addressStreet"
              placeholder="Enter"
            />
            {errors.addressStreet && touched.addressStreet && (
              <ErrorAlert>
                <span>{errors.addressStreet}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Address street line 2"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("street2")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <InputText
              value={values.addressStreetLine2}
              onChange={handleOnChange("addressStreetLine2")}
              onBlur={handleBlur}
              label="addressStreetLine2"
              name="addressStreetLine2"
              placeholder="Enter"
            />
            {errors.addressStreetLine2 && touched.addressStreetLine2 && (
              <ErrorAlert>
                <span>{errors.addressStreetLine2}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Address street line 3"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("street3")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.addressStreetLine3}
              onChange={handleOnChange("addressStreetLine3")}
              onBlur={handleBlur}
              label="addressStreetLine3"
              name="addressStreetLine3"
              placeholder="Enter"
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Address street line 4"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("street4")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.addressStreetLine4}
              onChange={handleOnChange("addressStreetLine4")}
              onBlur={handleBlur}
              label="addressStreetLine4"
              name="addressStreetLine4"
              placeholder="Enter"
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Premise"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("premise")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <InputText
              value={values.premise}
              onChange={handleOnChange("premise")}
              onBlur={handleBlur}
              label="premise"
              name="premise"
              placeholder="Enter"
            />
            {errors.premise && touched.premise && (
              <ErrorAlert>
                <span>{errors.premise}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"City"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("city")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <InputText
              value={values.city}
              onChange={handleOnChange("city")}
              onBlur={handleBlur}
              label="city"
              name="city"
              placeholder="Enter"
            />
            {errors.city && touched.city && (
              <ErrorAlert>
                <span>{errors.city}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Region"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("region")
                  ?.tooltip_text_main
              }
              isRequired
              labelFor="region_input"
            />
            <AppSingleSelectDropdown
              value={values.region}
              onChange={handleOnChange("region")}
              dropdownList={region}
              onBlur={() => setFieldTouched("region")}
              placeholder="Enter"
              keyName="label"
              id="region"
            />
            {errors.region && touched.region && (
              <ErrorAlert>
                <span>{errors.region}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Region 2"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("region2")
                  ?.tooltip_text_main
              }
              labelFor="region2_input"
            />
            <AppSingleSelectDropdown
              value={values.region2}
              onChange={handleOnChange("region2")}
              dropdownList={region}
              onBlur={() => setFieldTouched("region2")}
              placeholder="Enter"
              keyName="label"
              id="region2"
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Region 3"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("region3")
                  ?.tooltip_text_main
              }
              labelFor="region3_input"
            />
            <AppSingleSelectDropdown
              value={values.region3}
              onChange={handleOnChange("region3")}
              dropdownList={region}
              onBlur={() => setFieldTouched("region3")}
              placeholder="Enter"
              keyName="label"
              id="region3"
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Region 4"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("region4")
                  ?.tooltip_text_main
              }
              labelFor="region4_input"
            />
            <AppSingleSelectDropdown
              value={values.region4}
              onChange={handleOnChange("region4")}
              dropdownList={region}
              onBlur={() => setFieldTouched("region4")}
              placeholder="Enter"
              keyName="label"
              id="region4"
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Country"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("cntry")
                  ?.tooltip_text_main
              }
              isRequired
              labelFor="country_input"
            />
            <AppSingleSelectDropdown
              value={values.country}
              onChange={handleOnChange("country")}
              dropdownList={cntry}
              onBlur={() => setFieldTouched("country")}
              placeholder="Enter"
              keyName="label"
              id="country"
            />
            {errors.country && touched.country && (
              <ErrorAlert>
                <span>{errors.country}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Postal Code"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("postCode")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <InputText
              value={values.postalCode}
              onChange={handleOnChange("postalCode")}
              onBlur={handleBlur}
              label="postalCode"
              name="postalCode"
              placeholder="Enter"
            />
            {errors.postalCode && touched.postalCode && (
              <ErrorAlert>
                <span>{errors.postalCode}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
        <CardContainer>
          <AccordionComponent
            accordionTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[4]
                ?.section_title || `Address Geolocation`
            }
            limitName={""}
            isExpand={0}
          >
            <Box className="app-form-field-container">
              <StaticText
                textValue={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[4]
                    ?.section_subhead || ""
                }
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={"Latitude"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("lat")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.latitude}
                onChange={handleOnChange("latitude")}
                onBlur={handleBlur}
                label="latitude"
                name="latitude"
                placeholder="Enter"
              />
              {errors.latitude && touched.latitude && (
                <ErrorAlert>
                  <span>{errors.latitude}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={"Longitude"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("lon")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.longitude}
                onChange={handleOnChange("longitude")}
                onBlur={handleBlur}
                label="longitude"
                name="longitude"
                placeholder="Enter"
              />
              {errors.longitude && touched.longitude && (
                <ErrorAlert>
                  <span>{errors.longitude}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={"Altitude"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("alt")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.altitude}
                onChange={handleOnChange("altitude")}
                onBlur={handleBlur}
                label="altitude"
                name="altitude"
                placeholder="Enter"
              />
              {errors.altitude && touched.altitude && (
                <ErrorAlert>
                  <span>{errors.altitude}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={"Google Place ID"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("geoLoc")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.googlePlaceId}
                onChange={handleOnChange("googlePlaceId")}
                onBlur={handleBlur}
                label="googlePlaceId"
                name="googlePlaceId"
                placeholder="Enter"
              />
              {errors.googlePlaceId && touched.googlePlaceId && (
                <ErrorAlert>
                  <span>{errors.googlePlaceId}</span>
                </ErrorAlert>
              )}
            </Box>
          </AccordionComponent>
        </CardContainer>
      </Box>
    </form>
  );
};

export default FedwireDetails;
