"use client";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  DurationInput,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import React, { useContext, useEffect, useState } from "react";
import "./GracePeriodsAndExtensions.scss";
import {
  GeneralFeatureSetupProps,
  getValidationSchema,
} from "./GracePeriodsAndExtensionValidation";
import { useFormik } from "formik";
import { createTermComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  Option,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import {
  PostSchema,
  TermComponentData,
} from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: TermComponentData;
    gracePeriodOptions: Option<string>[];
    termComponentOptionsData: PostSchema;
  };
};

const GracePeriodsAndExtension = (props: Props) => {
  const toast = useToast();
  const onSubmit = async (values: GeneralFeatureSetupProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["term"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = props.data;
    if (formData) {
      if (productDetails) {
        const res = await createTermComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            rollDrGrace: values.rollDrGrace,
            rollCrGrace: values.rollCrGrace,
            crTermExt: values.crTermExt,
            rollDrGraceAdj: values.rollDrGraceAdj,
          },
          toastOptions: {
            toast,
            toastId: "mat-config",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : "Grace Periods and Extensions"
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.termComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.termComponentOptionsData]);

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      rollDrGrace: props.data.formData.rollDrGrace ?? "",
      rollCrGrace: props.data.formData.rollCrGrace ?? "",
      crTermExt: props.data.formData.crTermExt ?? "",
      rollDrGraceAdj: props.data.formData.rollDrGraceAdj ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  //Handling Form
  const gracePeriodsFormHandler =
    (key: string) => (value: string | number | boolean) => {
      setFieldValue(key, value);
    };
  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="app-form-container">
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            COMPONENT_CLASS["term"],
            getIfxProdType(productDetails),
            true
          )}
          featureName={props?.data?.formData?.componentName ?? ""}
          version={`VERSION ${props?.data?.formData?.version ?? "1"}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Grace Periods and Extensions"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : ""
          }
        />
        <CardContainer>
          <CardBody>
            <Box className={"app-form-field-container"}>
              <DurationInput
                labelName={"What is the debit grace period?"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("rollDrGrace")
                    ?.tooltip_text_main
                }
                value={values.rollDrGrace}
                onChange={gracePeriodsFormHandler("rollDrGrace")}
                modalLinkName={"Enter code manually"}
                modalTitle={"What is the rate adjustment term?"}
                modalLabelName={"Enter adjustment term"}
                modalLabelTooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("rollDrGrace")
                    ?.tooltip_text_main
                }
              />

              {errors.rollDrGrace && touched.rollDrGrace && (
                <ErrorAlert>
                  <span>{errors.rollDrGrace}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={
                  "What is the debit grace period interest adjustment?"
                }
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("rollDrGraceAdj")
                    ?.tooltip_text_main
                }
              />
              <RadioButtonGroup
                value={values.rollDrGraceAdj}
                onChange={gracePeriodsFormHandler("rollDrGraceAdj")}
                radioPropList={props.data.gracePeriodOptions}
                stackDirection={"column"}
                spacing={"0.8rem"}
                isBoxedRadio={true}
              />
              {errors.rollDrGraceAdj && touched.rollDrGraceAdj && (
                <ErrorAlert>
                  <span>{errors.rollDrGraceAdj}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <DurationInput
                labelName={"How long is the rollover credit grace period?"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("rollCrGrace")
                    ?.tooltip_text_main
                }
                value={values.rollCrGrace}
                onChange={gracePeriodsFormHandler("rollCrGrace")}
                modalLinkName={"Enter code manually"}
                modalTitle={"What is the rate adjustment term?"}
                modalLabelName={"Enter adjustment term"}
                modalLabelTooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("rollCrGrace")
                    ?.tooltip_text_main
                }
              />

              {errors.rollCrGrace && touched.rollCrGrace && (
                <ErrorAlert>
                  <span>{errors.rollCrGrace}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <DurationInput
                labelName={"What is the credit term extension?"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("crTermExt")
                    ?.tooltip_text_main
                }
                value={values.crTermExt}
                onChange={gracePeriodsFormHandler("crTermExt")}
                modalLinkName={"Enter code manually"}
                modalTitle={"What is the rate adjustment term?"}
                modalLabelName={"Enter adjustment term"}
                modalLabelTooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("crTermExt")
                    ?.tooltip_text_main
                }
              />

              {errors.crTermExt && touched.crTermExt && (
                <ErrorAlert>
                  <span>{errors.crTermExt}</span>
                </ErrorAlert>
              )}
            </Box>
          </CardBody>
        </CardContainer>
      </Box>
    </form>
  );
};

export default GracePeriodsAndExtension;
