"use client";
import { useContext, useEffect, useMemo, useState } from "react";

import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  ErrorAlert,
  FrequencyModule,
  SplitButton,
} from "@/components/common";

import "./ServiceChargeDetails.scss";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  ServiceChargeProps,
  getValidationSchema,
  serviceChargeDetails,
} from "./ServiceChargeValidation";
import { useFormik } from "formik";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { Box, Stack, useToast } from "@/components/ChakraUiManager";
import {
  addNewComponent,
  createFeeComponent,
} from "../../product-config-client-service";
import {
  FeeComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";
import {
  COMPONENT_CLASS,
  RjsfData,
  TRANSCODE_FLOW_NAME,
  preventMainFormSubmitOnRJSFSubmit,
  useEntityIdFromParam,
  useFieldNameFromParam,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";

type ServiceChargeDetailsProps = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    formData: FeeComponentData | null;
    feeComponentOptionsData: PostSchema;
    matrixDDOptions: string[];
    addNewMatrixSchema: OptionsSchema;
    addNewMatrixEndpoint: string;
    transactionCodes: string[];
  };
};

const ServiceChargeDetails = (props: ServiceChargeDetailsProps) => {
  const { depositName, featureName, version, mainTitle, subText, data } = props;
  const toast = useToast();
  const {
    formData,
    addNewMatrixEndpoint,
    addNewMatrixSchema,
    matrixDDOptions,
  } = props.data;
  const entityId = useEntityIdFromParam();
  const fieldName = useFieldNameFromParam();

  // Form Submit Handler
  const onSubmit = async (values: ServiceChargeProps, actions: any) => {
    if (!dirty && !entityId) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["fee"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }

    if (formData) {
      if (productDetails) {
        const res = await createFeeComponent({
          productDetails,
          formData: {
            // NOTE: _vn is required to do a PATCH update
            ...formData,
            // PATCH fields
            feeMax: values.addPeriodicServiceCharge
              ? parseFloat(values.feeMax)
              : parseFloat(""),
            feeMin: values.addPeriodicServiceCharge
              ? parseFloat(values.feeMin)
              : parseFloat(""),
            feeMinTrnCode: values.addPeriodicServiceCharge
              ? values.feeMinTrnCode
              : null,
            feeMaxTrnCode: values.addPeriodicServiceCharge
              ? values.feeMaxTrnCode
              : null,
            svcChrgFreq: values.addPeriodicServiceCharge
              ? values.svcChrgFreq
              : null,
            svcChrgFreqMatrix: values.addPeriodicServiceCharge
              ? values.svcChrgFreqMatrix
              : null,
          },
          toastOptions: {
            toast,
            toastId: "fee-svc-chrg",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : mainTitle
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    } else {
      console.error("Component not found");
    }
  };

  // Schema to pass into RJSF (Add New flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewMatrixSchema;
    return { name, ...actions.POST };
  }, [addNewMatrixSchema]);

  const addNewMatrix = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewMatrixEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("svcChrgFreqMatrix", response.matrixName);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(data.feeComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [data.feeComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      addPeriodicServiceCharge:
        data.formData?.svcChrgFreq ||
        data.formData?.svcChrgFreqMatrix ||
        data.formData?.feeMin !== undefined ||
        data.formData?.feeMax !== undefined ||
        data.formData?.feeMinTrnCode !== undefined ||
        data.formData?.feeMaxTrnCode !== undefined ||
        ((fieldName === "feeMinTrnCode" || fieldName === "feeMaxTrnCode") &&
          entityId)
          ? true
          : serviceChargeDetails.addPeriodicServiceCharge,
      feeMax: data.formData?.feeMax?.toString() ?? serviceChargeDetails.feeMax,
      feeMin: data.formData?.feeMin?.toString() ?? serviceChargeDetails.feeMin,
      feeMinTrnCode:
        fieldName === "feeMinTrnCode" && entityId
          ? entityId
          : data.formData?.feeMinTrnCode?.toString() ??
            serviceChargeDetails.feeMinTrnCode,
      feeMaxTrnCode:
        fieldName === "feeMaxTrnCode" && entityId
          ? entityId
          : data.formData?.feeMaxTrnCode?.toString() ??
            serviceChargeDetails.feeMaxTrnCode,
      svcChrgFreq: data.formData?.svcChrgFreq ?? "",
      svcChrgFreqMatrix: data.formData?.svcChrgFreqMatrix ?? "",
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formik;

  // Context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  /**
   * Form Handler
   */
  const serviceChargeDetailsFormHandler =
    (key: string) =>
    (value: string | SplitBtnInputValType | boolean | string[]) => {
      setFieldValue(key, value);
    };

  const serviceChargeDetailsYesOrNoHandler = (value: SplitBtnInputValType) => {
    setFieldValue("addPeriodicServiceCharge", value).then((res) => {
      // reset other fields when split btn values changes
      setFieldValue("feeMin", "");
      setFieldValue("feeMax", "");
      setFieldValue("feeMinTrnCode", null);
      setFieldValue("feeMaxTrnCode", null);
      setFieldValue("svcChrgFreq", "");
    });
  };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const formTitle = (
    <CommonTitle
      depositName={depositName}
      featureName={props.data.formData?.componentName ?? featureName}
      version={`VERSION ${props.data.formData?.version || 1}`}
      mainTitle={
        tooltipFlyoutDetails?.pageHeaderDetails?.heading
          ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
          : mainTitle
      }
      subText={
        tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
          ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
          : subText
      }
    />
  );

  //First question when page gets loaded
  const yesOrNoQuestion = (
    <>
      <AppFormLabel
        labelName="Do you want to add a periodic service charge?"
        tooltipDesc={
          tooltipFlyoutDetails?.tooltipsMap?.get("addPeriodicServiceCharge")
            ?.tooltip_text_main
        }
      />
      <SplitButton
        leftBtnName="Yes"
        rightBtnName="No"
        value={values.addPeriodicServiceCharge}
        onSelect={serviceChargeDetailsYesOrNoHandler}
      />
      {errors.addPeriodicServiceCharge && (
        <ErrorAlert>
          <span>{errors.addPeriodicServiceCharge}</span>
        </ErrorAlert>
      )}
    </>
  );

  //By default "yes" will be selected and this below code will appear
  const yesSelected = (
    <>
      <div className="service-charge-frequency-duration-container">
        <AppFormLabel
          labelName="How often should the periodic service charge be applied to the position?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("svcChrgFreq")
              ?.tooltip_text_main
          }
          labelFor="promotional_rate_offset_input"
        />
        <CoupleRadioWithDropdown
          id="promotional_rate_offset"
          primaryOptionValue={values.svcChrgFreqMatrix}
          tertiaryOptionValue={values.svcChrgFreq}
          tertiaryFieldType="frequency"
          primaryRadioName={"Leverage existing matrix"}
          secondaryRadioName={"Add new rate matrix"}
          tertiaryRadioName={"Enter a single frequency"}
          handlePrimaryOptionSelect={
            serviceChargeDetailsFormHandler("svcChrgFreqMatrix") as any
          }
          handleTertiaryOption={serviceChargeDetailsFormHandler("svcChrgFreq")}
          freqOutContentDesc="
          If the date falls on a non-business day, such as a weekend or holiday, the charge will still be occur on the"
          freqOutContent="A new charge will be occur every"
          notBusinessDayLabel="
          If the scheduled date falls on a non-business day, when should the periodic service charge be applied?"
          monthDDTooltip={
            tooltipFlyoutDetails?.tooltipsMap?.get("svcChrgFreqMon")
              ?.tooltip_text_main
          }
          businessDayTooltip={
            tooltipFlyoutDetails?.tooltipsMap?.get("svcChrgFreqNotBusiness")
              ?.tooltip_text_main
          }
          placeHolderName={"Select matrix"}
          dropdownList={matrixDDOptions}
          schema={schema}
          uiSchema={matrixTypeUiSchema}
          onAddNewSubmit={addNewMatrix}
        />
      </div>

      {errors.svcChrgFreq && touched.svcChrgFreq && (
        <ErrorAlert>
          <span>{errors.svcChrgFreq}</span>
        </ErrorAlert>
      )}
      {errors.svcChrgFreqMatrix && touched.svcChrgFreqMatrix && (
        <ErrorAlert>
          <span>{errors.svcChrgFreqMatrix}</span>
        </ErrorAlert>
      )}

      <div className="periodic-service-charge-title">
        <AppFormLabel
          labelName="What are the minimum and the maximum periodic service charges?"
          tooltipDesc={
            (tooltipFlyoutDetails?.tooltipsMap?.get("feeMin")
              ?.tooltip_text_main || "") +
            (tooltipFlyoutDetails?.tooltipsMap?.get("feeMax")
              ?.tooltip_text_main || "")
          }
        />
      </div>
      <div className="periodic-service-charge-input">
        <Stack direction={"row"} spacing={"32px"} onBlur={handleBlur}>
          <Box>
            <AppNumberInput
              labelName="Min"
              value={values.feeMin}
              onChange={serviceChargeDetailsFormHandler("feeMin")}
              valuePrefix="$"
              onBlur={handleBlur}
            />
            {errors.feeMin && touched.feeMin && (
              <ErrorAlert>
                <span>{errors.feeMin}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box>
            <AppNumberInput
              labelName="Max"
              value={values.feeMax}
              onChange={serviceChargeDetailsFormHandler("feeMax")}
              valuePrefix="$"
              onBlur={handleBlur}
            />
            {errors.feeMax && touched.feeMax && (
              <ErrorAlert>
                <span>{errors.feeMax}</span>
              </ErrorAlert>
            )}
          </Box>
        </Stack>
      </div>
      <div className="service-charge-frequency-duration-container">
        <AppFormLabel
          labelName="What is the minimum fee adjustment transaction code?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("feeMinTrnCode")
              ?.tooltip_text_main
          }
          labelFor="min_fee_adjustment_transaction_code_input"
        />
        <CoupleRadioWithDropdown
          primaryOptionValue={values.feeMinTrnCode ?? ""}
          primaryRadioName="Select from existing transaction codes"
          secondaryRadioName="Create new transaction code"
          dropdownList={props.data.transactionCodes}
          handlePrimaryOptionSelect={serviceChargeDetailsFormHandler(
            "feeMinTrnCode"
          )}
          id="min_fee_adjustment_transaction_code"
          setFieldValue={setFieldValue}
          placeHolderName="Select transaction Code"
          onBlur={handleBlur}
          addNewFieldType="routeToTransCode"
          addNewFieldQueryParam={`${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${productDetails?.name}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.feeServiceCharge}&${QUERY_PARAM_KEY.FIELD_NAME}=feeMinTrnCode`}
        />
        {errors.feeMinTrnCode && touched.feeMinTrnCode && (
          <ErrorAlert>
            <span>{errors.feeMinTrnCode}</span>
          </ErrorAlert>
        )}
      </div>
      <div className="service-charge-frequency-duration-container">
        <AppFormLabel
          labelName="What is the maximum fee adjustment transaction code?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("feeMaxTrnCode")
              ?.tooltip_text_main
          }
          labelFor="max_fee_adjustment_transaction_code_input"
        />
        <CoupleRadioWithDropdown
          primaryOptionValue={values.feeMaxTrnCode ?? ""}
          primaryRadioName="Select from existing transaction codes"
          secondaryRadioName="Create new transaction code"
          dropdownList={props.data.transactionCodes}
          handlePrimaryOptionSelect={serviceChargeDetailsFormHandler(
            "feeMaxTrnCode"
          )}
          setFieldValue={setFieldValue}
          placeHolderName="Transaction Code"
          onBlur={handleBlur}
          addNewFieldType="routeToTransCode"
          id="max_fee_adjustment_transaction_code"
          addNewFieldQueryParam={`${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${productDetails?.name}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.feeServiceCharge}&${QUERY_PARAM_KEY.FIELD_NAME}=feeMaxTrnCode`}
        />
        {errors.feeMaxTrnCode && touched.feeMaxTrnCode && (
          <ErrorAlert>
            <span>{errors.feeMaxTrnCode}</span>
          </ErrorAlert>
        )}
      </div>
    </>
  );
  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id="finxact-form"
      noValidate
    >
      {/* This contains the version, main heading and sub text */}
      {formTitle}
      <Box className="app-form-container">
        <CardContainer customClass="service-charge-container">
          {/* This contains the yes or no question */}
          {yesOrNoQuestion}

          {/* This contains the code when yes is selected */}
          {values.addPeriodicServiceCharge && yesSelected}
        </CardContainer>
      </Box>
    </form>
  );
};

export default ServiceChargeDetails;
