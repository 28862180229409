import { SidebarManager } from "..";
import { Box, Heading, Text } from "../ChakraUiManager";
import "./HeroManager.scss";

type heroManagerProps = {
  firstLine: string;
  descriptiveText: string;
};

export default function HeroManager({
  firstLine,
  descriptiveText,
}: heroManagerProps) {
  return (
    <Box pt="1.125rem" display="flex" className="hero-manager-container">
      {/* <SidebarManager menuList={[]} /> */}
      <Box ml="1rem" className="hero-main-content">
        <h1 className="hero-main-txt">{firstLine}</h1>
        <Text
          mt="1rem"
          maxW={{ base: "100%", lg: "60%" }}
          noOfLines={3}
          className="hero-desc-txt"
        >
          {descriptiveText}
        </Text>
      </Box>
    </Box>
  );
}
