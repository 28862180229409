"use client";

import {
  HeroBannerWithSlider,
  InstructorCourse,
  LearningStatusCardUserInfoWithAchivements,
  RecommendedCourses,
  SavedCourses,
  RequestClassroom,
} from "@/components";
import "./LearningProfile.scss";
import { CardContainer } from "@/components/common";
import { LearningProgress } from "./learning-progress/LearningProgress";
import { Box, Divider } from "@chakra-ui/react";
import FooterWidgetIframe from "@/components/common/footer/footer-iframe";
import { useState } from "react";

const LearningProfile = ({ pageData, auth }: any) => {
  const [reloadSavedCourse, setReloadSavedCourse] = useState<boolean>(false);
  return (
    <>
      <HeroBannerWithSlider
        auth={auth}
        bannerImage={pageData.banner_image?.url}
        headline={pageData.headline}
        subhead={pageData.subhead?.[0]?.text}
      />
      <Box className={`academy-sub-content no-bottom-space`}>
        <CardContainer customClass="learning-status-card-container">
          <LearningStatusCardUserInfoWithAchivements />
          <Divider className="profile-divider" />
          <LearningProgress
            learningPathsOrder={pageData.learning_paths_order}
            headerData={pageData.learning_paths?.[0]}
          />
        </CardContainer>

        <RecommendedCourses
          headerData={pageData.recommended_learnings?.[0]}
          reload={reloadSavedCourse}
          setReload={setReloadSavedCourse}
        />
        <SavedCourses
          headerData={pageData.saved_by_me?.[0]}
          reload={reloadSavedCourse}
          setReload={setReloadSavedCourse}
        />
        <InstructorCourse headerData={pageData.instructor_sessions?.[0]} />
        <RequestClassroom headerData={pageData.request_classroom?.[0]} />

        <Box marginTop={13}>
          <FooterWidgetIframe className="academy" />
        </Box>
      </Box>
    </>
  );
};

export default LearningProfile;
