"use client";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Stepper,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import MainContent from "../main-content/MainContent";
import { AppFormLabel, AppModal, InputText } from "@/components/common";
import {
  DASHBOARD_JOURNEY,
  DASHBOARD_ACCORDION_STATUS,
  EXPLORE_FINXACT_STEPS_NAME,
  TICKET_REQUESTS,
  TICKET_STATUS,
  DASHBOARD_COMPLETED_ORG_PHASES,
} from "@/utils";

import "./ExploreFinxact.scss";
import {
  createEnvironmentRequest,
  getConsolePermissions,
} from "@/api-config/api-service";
import { UserContext } from "@/app/providers";
import { ExploreProps } from "../model";
import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import { useSelector } from "react-redux";
import { getOrganizations, getSelectedOrg } from "@/store";
import { TicketedRequestInterface } from "../Dashboard";

interface ExploreFinxactProps {
  title: string;
  exploreDetails?: ExploreProps;
  disabledState: boolean;
  ticketStatus: TicketedRequestInterface[];
  updateTicketStatus: (val: TicketedRequestInterface[]) => void;
}

const ExploreFinxact = (props: ExploreFinxactProps) => {
  const { title, exploreDetails, ticketStatus, updateTicketStatus } = props;
  const {
    user: { firstName, lastName, email, organization },
  } = useContext(UserContext);

  const appInputModalRef = useRef<any>();
  const [requestEntered, setRequestEntered] = useState<string>("");
  const [completed, setCompleted] = useState<number>(0);
  const [step, setStep] = useState<string>("");
  const allOrgs = useSelector(getOrganizations);
  const currOrg = useSelector(getSelectedOrg);

  const toast = useToast();
  const toastId = "request-finxact";

  const {
    sandbox_overview: sandboxOverviewDetails,
    sandbox_api: sandboxApiDetails,
    enterprise: enterpriseDetails,
  } = exploreDetails || {};
  const isCompleted = DASHBOARD_COMPLETED_ORG_PHASES.includes(currOrg?.phase);

  async function onModalInputSave() {
    const commonToastOptions = {
      toast,
      toastId,
    };
    await createEnvironmentRequest({
      body: {
        type:
          step === EXPLORE_FINXACT_STEPS_NAME.sandboxOverview
            ? TICKET_REQUESTS.sandbox
            : TICKET_REQUESTS.environment,
        message: requestEntered,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: "Request sent successfully",
      },
    })
      .then(async (response) => {
        const api = new ApiClient({ baseUrl: getConsolePermissions() });
        const [data] = await Promise.all([
          api.get(`${API_ROUTE_CONFIGURATION.requests}`),
        ]).then((data) => data);

        updateTicketStatus(data);
        return response;
      })
      .catch((err) => console.error(err));
    appInputModalRef.current.closeModal();
    // unset dropdown and input values
    setRequestEntered("");
  }

  function onModalInputCancel() {
    // Reset modal input value
    setRequestEntered("");
  }

  function showModal(name: string, e: any) {
    appInputModalRef?.current?.openModal();
    e.stopPropagation();
    setStep(name);
  }

  const accordionStatus = useCallback(
    (
      ticketStatus: TicketedRequestInterface[],
      type: string,
      inProgress?: boolean
    ) => {
      if (isCompleted) {
        return DASHBOARD_ACCORDION_STATUS.completed;
      }

      if (
        ticketStatus.length > 0 &&
        ticketStatus.some((data) => data.requestType === type)
      ) {
        if (inProgress) {
          return DASHBOARD_ACCORDION_STATUS.completed;
        }
        if (
          ticketStatus.some(
            (data) =>
              data.requestType === type &&
              data.ticketLastStatus === TICKET_STATUS.done
          )
        ) {
          return DASHBOARD_ACCORDION_STATUS.completed;
        } else {
          return DASHBOARD_ACCORDION_STATUS.inProgress;
        }
      } else {
        return DASHBOARD_ACCORDION_STATUS.notStarted;
      }
    },
    [isCompleted]
  );

  useEffect(() => {
    if (ticketStatus.length > 0) {
      // If both tickets have been submitted and marked done
      if (
        ticketStatus.length === 2 &&
        ticketStatus.every(
          (data) => data.ticketLastStatus === TICKET_STATUS.done
        )
      ) {
        setCompleted(3);
      } else {
        setCompleted(
          ticketStatus.filter(
            (data) => data.ticketLastStatus === TICKET_STATUS.done
          ).length + 1
        );
      }
    }
  }, [ticketStatus]);

  const handleOnClick = async (e: any) => {
    e.stopPropagation();
    window.location.href = "/account";
  };

  return (
    <>
      <Box>
        <Flex className="explore-finxact-content">
          <Heading as={"h4"}>{title}</Heading>
          <Text className="explore-finxact-status">{`${isCompleted ? 3 : completed} of 3 complete`}</Text>
        </Flex>
        <Stepper
          size="lg"
          index={isCompleted ? 3 : 4}
          orientation="vertical"
          gap={0}
          className="explore-finxact-stepper"
        >
          <MainContent
            btnIcon
            btnLink
            showBtn={isCompleted ? false : true}
            btnDisabled={
              ticketStatus.length > 0 &&
              ticketStatus.some(
                (data) => data.requestType === TICKET_REQUESTS.sandbox
              )
                ? true
                : false
            }
            btnName="Request a sandbox experience"
            title={sandboxOverviewDetails?.sectionTitle}
            desc={sandboxOverviewDetails?.sectionSubTitle}
            overviewHeader={sandboxOverviewDetails?.header}
            overview={sandboxOverviewDetails?.description}
            helpfullRes={sandboxOverviewDetails?.resourcesLinks}
            progressItem={DASHBOARD_JOURNEY.exploreFinxact}
            status={accordionStatus(
              ticketStatus,
              TICKET_REQUESTS.sandbox,
              true
            )}
            onClick={showModal.bind(
              null,
              EXPLORE_FINXACT_STEPS_NAME.sandboxOverview
            )}
          />
          <MainContent
            btnLink
            btnName="Access environment keys"
            showBtn={isCompleted ? false : true}
            btnDisabled={
              ticketStatus.length > 0 &&
              ticketStatus.some(
                (data) =>
                  data.requestType === TICKET_REQUESTS.sandbox &&
                  data.ticketLastStatus === TICKET_STATUS.done
              )
                ? false
                : true
            }
            onClick={handleOnClick}
            title={sandboxApiDetails?.sectionTitle}
            desc={sandboxApiDetails?.sectionSubTitle}
            overviewHeader={sandboxApiDetails?.header}
            overview={sandboxApiDetails?.description}
            helpfullRes={sandboxApiDetails?.resourcesLinks}
            status={accordionStatus(ticketStatus, TICKET_REQUESTS.sandbox)}
            progressItem={DASHBOARD_JOURNEY.exploreFinxact}
          />
          <MainContent
            btnLink={false}
            btnName={"Speak with Finxact"}
            showBtn={isCompleted ? false : true}
            btnDisabled={
              ticketStatus.length > 0 &&
              ticketStatus.some(
                (data) => data.requestType === TICKET_REQUESTS.environment
              )
                ? true
                : false
            }
            onClick={showModal.bind(
              null,
              EXPLORE_FINXACT_STEPS_NAME.enterpriseLicense
            )}
            title={enterpriseDetails?.sectionTitle}
            desc={enterpriseDetails?.sectionSubTitle}
            overviewHeader={enterpriseDetails?.header}
            overview={enterpriseDetails?.description}
            helpfullRes={enterpriseDetails?.resourcesLinks}
            progressItem={DASHBOARD_JOURNEY.exploreFinxact}
            status={accordionStatus(ticketStatus, TICKET_REQUESTS.environment)}
            isLastItem
          />
        </Stepper>
      </Box>

      {/* Open a modal when user clicks on "Request a sandbox experience" or "Speak with finxact" */}
      <AppModal
        customClass="app-modal-selectors"
        ref={appInputModalRef}
        modalTitle={"Speak with Finxact"}
        primaryBtnProp={{
          name: "Submit request",
          btnClassName: "app-btn-inverse-secondary",
        }}
        primaryBtnDisabled={requestEntered.trim() === ""}
        primaryBtnSelect={onModalInputSave}
        secondaryBtnSelect={onModalInputCancel}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-link",
          style: { marginRight: "1rem" },
        }}
      >
        <Box className="app-form-field-container-small">
          <Text>
            Let’s chat. We’d love to share more about our value offering and
            answer any questions you may have.{" "}
          </Text>
        </Box>
        <Box mb={12.8} mt={4.8}>
          <AppFormLabel labelName={"First name"} isRequired={true} />
          <InputText value={firstName} disabled />
        </Box>
        <Box mb={12.8}>
          <AppFormLabel labelName={"Last name"} isRequired={true} />
          <InputText value={lastName} disabled />
        </Box>
        <Box mb={12.8}>
          <AppFormLabel labelName={"Email"} isRequired={true} />
          <InputText value={email} disabled />
        </Box>
        <Box mb={12.8}>
          <AppFormLabel labelName={"Organization"} isRequired={true} />
          <InputText value={allOrgs[organization]?.name ?? ""} disabled />
        </Box>
        <Box mb={12.8}>
          <AppFormLabel labelName={"Enter request message"} />
          <InputText
            placeholder={"Enter"}
            textarea
            value={requestEntered}
            onChange={setRequestEntered}
          />
        </Box>

        <Divider />
      </AppModal>
    </>
  );
};

export default ExploreFinxact;
