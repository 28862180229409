"use client";
import {
  AddNewButton,
  AppFormLabel,
  CardContainer,
  CommonTitle,
  DatePicker,
  DragDropContainer,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  TimeField,
} from "@/components/common";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Stack, useToast } from "@/components/ChakraUiManager";
import {
  LendingPartyPhoneProps,
  lndingPrtyPhnProps,
  lppSortModuleValue,
  getValidationSchema,
} from "./LendingPartyPhoneValidation";
import {
  DEFAULT_PHONE_NO_EXTENSION_PLACEHOLDER,
  DEFAULT_PHONE_NO_PLACEHOLDER,
  IC_ENTITY,
  makeDateTime,
  shouldUpdateWorkflowStatus,
} from "@/utils";
import { updateBankParametersUSBankParams } from "../../ic-config-api-service";
import { FinancialInstitutionUSBankInfoData } from "../../model";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type SBAProps = {
  data: {
    phoneTypeOptions: { label: string; value: string | number }[];
    formData: FinancialInstitutionUSBankInfoData;
    bankParamOptionsData: PostSchema;
  };
};

const SBALendingPartyPhone = ({ data }: SBAProps) => {
  const { bankParamOptionsData } = data;
  const toast = useToast();
  const activeAccordionRef = useRef<any>();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const onSubmit = async (values: lndingPrtyPhnProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "SBA_Lending_Party_phone",
    };
    response = await updateBankParametersUSBankParams({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        // PATCH Feilds
        sbaLnContact: {
          phones: values.phones.map((phone: any, index: number) => ({
            phoneType: phone.phoneType,
            cntryCallCode: phone.cntryCallCode,
            data: phone.data,
            isPreferred: phone.isPreferred,
            label: phone.label,
            extension: phone.extension,
            validFromDtm:
              phone.fromDate && phone.fromTime !== ""
                ? makeDateTime(phone.fromDate, phone.fromTime)
                : null,
            validToDtm:
              phone.throughDate && phone.throughTime !== ""
                ? makeDateTime(phone.throughDate, phone.throughTime)
                : null,
            verifyDtm:
              phone.verifiedDate && phone.verifiedTime !== ""
                ? makeDateTime(phone.verifiedDate, phone.verifiedTime)
                : null,
          })),
        },
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };

  const initialSortModuleValue: LendingPartyPhoneProps[] =
    data.formData?.sbaLnContact?.phones &&
    data.formData?.sbaLnContact?.phones?.length > 0
      ? data.formData?.sbaLnContact?.phones?.map((phone) => ({
          phoneType: phone.phoneType ?? undefined,
          cntryCallCode: phone.cntryCallCode ?? "",
          data: phone.data ?? "",
          extension: phone.extension ?? "",
          label: phone.label ?? "",
          isPreferred: phone.isPreferred ?? undefined,
          fromDate: phone.validFromDtm
            ? new Date(phone.validFromDtm as string)
            : null,
          fromTime: phone.validFromDtm
            ? new Date(phone.validFromDtm as string).toLocaleTimeString("it-IT")
            : "",
          throughDate: phone.validToDtm
            ? new Date(phone.validToDtm as string)
            : null,
          throughTime: phone.validToDtm
            ? new Date(phone.validToDtm as string).toLocaleTimeString("it-IT")
            : "",
          verifiedDate: phone.verifyDtm
            ? new Date(phone.verifyDtm as string)
            : null,
          verifiedTime: phone.verifyDtm
            ? new Date(phone.verifyDtm as string).toLocaleTimeString("it-IT")
            : "",
        }))
      : [];

  const [validationSchema, setValidationSchema] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);

      setValidationSchema(
        yupSchema.fields.bankparamUSBankInfo.fields.sbaLnContact
      );
    })();
  }, [bankParamOptionsData]);

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      phones: initialSortModuleValue,
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const setPartyPhone = (fn: any) => {
    setFieldValue(`phones`, fn(values.phones));
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);
  const _handleLppForm = (key: string, index: number) => (value: any) => {
    setFieldValue(`phones.${index}.${key}`, value);
  };
  const _handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`phones.${index}.${key}`, true);
  };

  //Form_Error_Handler
  const _errorHandler = (key: keyof LendingPartyPhoneProps, index: number) => {
    return (
      <>
        {touched.phones?.[index]?.[key] &&
          touched.phones[index] &&
          (touched.phones[index] as any)[key] &&
          errors.phones &&
          errors.phones[index] &&
          (errors.phones[index] as any)[key] && (
            <ErrorAlert>
              <span>{(errors.phones[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form id="finxact-form" onSubmit={handleSubmit}>
        <CommonTitle
          depositName="FINANCIAL INSTITUTION (BANK) PARAMETERS"
          featureName=""
          version=""
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
            "[US] SBA lending party phone"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
            "If small business lending is applicable, define a party phone"
          }
        />
        <CardContainer customClass="app-form-field-container app-form-container">
          <FieldArray
            name="phones"
            render={({ insert, remove, push }) => (
              <Box className="app-form-field-container">
                <DragDropContainer
                  items={values.phones.map((sortEle, index: any) => {
                    return {
                      id: index + 1,
                      deleteHandler: () => remove(index),
                      showDeleteIcon: true,
                      content: (
                        <Box key={index} ref={activeAccordionRef}>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the phone type?"
                              labelFor="phone-type"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "phoneType"
                                )?.tooltip_text_main
                              }
                            />
                            <RadioButtonGroup
                              isBoxedRadio
                              value={sortEle.phoneType}
                              onChange={_handleLppForm("phoneType", index)}
                              radioPropList={data.phoneTypeOptions}
                              stackDirection={"row"}
                              spacing={"1rem"}
                              onBlur={_handleOnBlur("phoneType", index)}
                            />
                            {_errorHandler("phoneType", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the country calling code?"
                              labelFor="country-calling-code"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "cntryCallCode"
                                )?.tooltip_text_main
                              }
                            />
                            <InputText
                              value={sortEle.cntryCallCode}
                              onChange={_handleLppForm("cntryCallCode", index)}
                              onBlur={_handleOnBlur("cntryCallCode", index)}
                              placeholder="+1 US"
                            />
                            {_errorHandler("cntryCallCode", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the phone number?"
                              labelFor="phone-number-number"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get("phone")
                                  ?.tooltip_text_main
                              }
                            />
                            <InputText
                              value={sortEle.data}
                              onChange={_handleLppForm("data", index)}
                              onBlur={_handleOnBlur("data", index)}
                              placeholder={DEFAULT_PHONE_NO_PLACEHOLDER}
                            />
                            {_errorHandler("data", index)}
                          </Box>

                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the phone extension?"
                              labelFor="phone-number-extension"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get("extn")
                                  ?.tooltip_text_main
                              }
                            />
                            <InputText
                              value={sortEle.extension}
                              onChange={_handleLppForm("extension", index)}
                              onBlur={_handleOnBlur("extension", index)}
                              placeholder={
                                DEFAULT_PHONE_NO_EXTENSION_PLACEHOLDER
                              }
                            />
                            {_errorHandler("extension", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="Enter a recognizable label for the phone number"
                              labelFor="phone-number-label"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get("label")
                                  ?.tooltip_text_main
                              }
                            />
                            <InputText
                              value={sortEle.label}
                              onChange={_handleLppForm("label", index)}
                              onBlur={handleBlur}
                              placeholder="Enter a label"
                            />
                            {_errorHandler("label", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="Is this the preferred phone number?"
                              labelFor="preferred-phone-number"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "isPreferred"
                                )?.tooltip_text_main
                              }
                            />
                            <SplitButton
                              value={sortEle.isPreferred}
                              onSelect={_handleLppForm("isPreferred", index)}
                              leftBtnName="Yes"
                              rightBtnName="No"
                              onBlur={_handleOnBlur("isPreferred", index)}
                            />
                            {_errorHandler("isPreferred", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel labelName="When is the phone number valid from?" />
                            <Box display="flex" gap="2rem">
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Date"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "validFromDate"
                                    )?.tooltip_text_main
                                  }
                                />
                                <DatePicker
                                  ariaLabel="phone-number-verifeid-from"
                                  value={sortEle.fromDate}
                                  onUpdate={_handleLppForm("fromDate", index)}
                                  placeholder="Select"
                                  onBlur={_handleOnBlur("fromDate", index)}
                                />
                                {_errorHandler("fromDate", index)}
                              </Stack>
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Time"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "validFromTime"
                                    )?.tooltip_text_main
                                  }
                                />
                                <TimeField
                                  value={sortEle.fromTime}
                                  onChange={_handleLppForm("fromTime", index)}
                                  onBlur={_handleOnBlur("fromTime", index)}
                                  ariaLabel="time-for-address-valid-through"
                                />
                                {_errorHandler("fromTime", index)}
                              </Stack>
                            </Box>
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel labelName="When is the phone number valid through?" />
                            <Box display="flex" gap="2rem">
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Date"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "validToDate"
                                    )?.tooltip_text_main
                                  }
                                />
                                <DatePicker
                                  ariaLabel="phone-number-valid-through"
                                  value={sortEle.throughDate}
                                  onUpdate={_handleLppForm(
                                    "throughDate",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("throughDate", index)}
                                  placeholder="Select"
                                />
                                {_errorHandler("throughDate", index)}
                              </Stack>
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Time"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "validToTime"
                                    )?.tooltip_text_main
                                  }
                                />
                                <TimeField
                                  value={sortEle.throughTime}
                                  onChange={_handleLppForm(
                                    "throughTime",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("throughTime", index)}
                                  ariaLabel="time-for-address-valid-through"
                                />
                                {_errorHandler("throughTime", index)}
                              </Stack>
                            </Box>
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel labelName="When was the phone number verified?" />
                            <Box display="flex" gap="2rem">
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Date"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "verifyDate"
                                    )?.tooltip_text_main
                                  }
                                />
                                <DatePicker
                                  ariaLabel="phone-number-verified-on"
                                  value={sortEle.verifiedDate}
                                  onUpdate={_handleLppForm(
                                    "verifiedDate",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("verifiedDate", index)}
                                  placeholder="Select"
                                />
                                {_errorHandler("verifiedDate", index)}
                              </Stack>
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Time"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "verifyTime"
                                    )?.tooltip_text_main
                                  }
                                />
                                <TimeField
                                  value={sortEle.verifiedTime}
                                  onChange={_handleLppForm(
                                    "verifiedTime",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("verifiedTime", index)}
                                  ariaLabel="time-for-address-valid-through"
                                />
                                {_errorHandler("verifiedTime", index)}
                              </Stack>
                            </Box>
                          </Box>
                        </Box>
                      ),
                    };
                  })}
                  setItems={setPartyPhone}
                  isElement
                />

                <Box marginTop="32px">
                  <AddNewButton
                    label={
                      !values.phones.length
                        ? "Add SBA lending party phone?"
                        : "Add another party phone"
                    }
                    onClick={() => {
                      push({
                        ...lppSortModuleValue,
                      });
                    }}
                  />
                </Box>
              </Box>
            )}
          ></FieldArray>
        </CardContainer>
      </form>
    </FormikProvider>
  );
};
export default SBALendingPartyPhone;
